import React from 'react'
import { EllipsisHorizontalIcon, UserIcon } from '@heroicons/react/24/solid'
import GrantAccess from '../../Auth/GrantAccess'
import RoleSelect from './RoleSelect'
import './teammates.css'
import { useSelector } from 'react-redux'
import { customerUsersState } from '../../../app/slices/customerUsersSlice'
import { authState } from '../../../app/slices/authSlice'
import TeammatesDropdown from './TeammatesDropdown'

interface TeammatesTableProps {
  teammates: any
}

const TeammatesTable: React.FC<TeammatesTableProps> = ({ teammates }) => {
  const { roles } = useSelector(customerUsersState)
  const { user } = useSelector(authState)

  return (
    <div className="">
      <div className="mt-8 flex flex-col">
        <div className="">
          <div
            className="inline-block min-w-full py-2 align-middle "
            data-cy="information-block"
          >
            <div className="table-wrapper">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="sticky top-0 border-b bg-super-light-gray dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-light-black dark:text-dark-text sm:pl-6"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-light-black dark:text-dark-text sm:pl-6"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                    >
                      Settings
                    </th>
                  </tr>
                </thead>
                <tbody className="teammates-table">
                  {teammates.map((teammate: any) => (
                    <tr
                      key={teammate.id}
                      className="dark:bg-dark-primary dark:divide-gray-600"
                    >
                      <td className=" py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <UserIcon className="w-8 h-8 p-1 bg-blue-100 rounded-full" />
                          </div>
                          <div className="ml-4 flex-shrink">
                            <div className="text-gray-500">
                              {teammate.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex ">
                          <div className="">
                            <div
                              className={`rounded-md text-white p-2 ${
                                teammate.invitation
                                  ? 'bg-tuto-purple'
                                  : 'bg-tuto-green'
                              }`}
                            >
                              {teammate.invitation ? 'Pending' : 'Active'}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        data-cy="select-admin-or-user"
                      >
                        <GrantAccess
                          grantAccessToRoles={['user', 'playground']}
                        >
                          {teammate.role_name}
                        </GrantAccess>
                        <GrantAccess grantAccessToRoles={['admin']}>
                          {user.email === teammate.email ? (
                            teammate.role_name.toUpperCase()
                          ) : (
                            <RoleSelect
                              userRole={teammate.role_id}
                              rolesToSelect={roles}
                              userId={teammate.id}
                              fromInvitation={teammate.invitation}
                              invitation_id={teammate.id}
                            />
                          )}
                        </GrantAccess>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center cursor-pointer">
                        <GrantAccess grantAccessToRoles={['admin']}>
                          {teammate.invitation && (
                            <TeammatesDropdown
                              role={teammate.role_id}
                              email={teammate.email}
                              invitation={teammate.invitation}
                              invitation_id={teammate.id}
                            />
                          )}
                        </GrantAccess>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeammatesTable
