import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import FormInput from '../utils/forms/FormInput'
import { SubmitHandler, useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  continueToCodeStep,
  setMailToConfirm
} from '../../app/slices/authSlice'
import UserPool from './UserPool'
import AuthError from './AuthError'
import CONFIG from '../../config/config'
import { Mixpanel } from '../utils/mixPanel/mixPanel'

interface IFormRegistrationInputs {
  email: string
  password: string
  passwordConfirm: string
}



interface IRegistrationFormProps {
  setPassword: any
  invitationDetails?: any
}



const RegistrationForm: React.FC<IRegistrationFormProps> = ({
  setPassword,
  invitationDetails
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [redirectUri, setRedirectUri] = useState(CONFIG.dashboard)
  const [loading, setLoading] = useState(false)
 


  const [validationSchema, setValidationSchema] = useState(yup.object().shape({
    email: yup.string().email().matches(/(?=^((?!@gmail.com).)*$)(?=^((?!@yahoo.com).)*$)/, 'email must be a work email').required(),
    password: yup.string().required(),
    passwordConfirm: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }))
  
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { errors, touchedFields }
  } = useForm<IFormRegistrationInputs>({
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const invitation_id = searchParams.get('state')
    if (invitation_id) {
      setRedirectUri(`${CONFIG.dashboard}?state=${invitation_id}`)
    }
  }, [])

  useEffect(() => {
    if(invitationDetails.active) {
      setFocus('email', {shouldSelect: true})
    }

  }, [invitationDetails])

  const formRegistrationSubmitHandler: SubmitHandler<
    IFormRegistrationInputs
  > = async (formData: IFormRegistrationInputs) => {
    setLoading(true)
    Mixpanel.track('Signup Clicked', {email: formData.email})
    UserPool.signUp(
      formData.email,
      formData.password,
      [],
      [],
      async (err, data) => {
        if (err) {
          console.log(err)
          setError(err.message)
          setLoading(false)
        } else {
          
          setPassword(formData.password)
          dispatch(setMailToConfirm(formData.email))
          dispatch(continueToCodeStep('confirmation'))
          setLoading(false)
        }
      }
    )
  }

  return (
    <div>
      <img
        className="mb-12 w-32 xl:w-40"
        src="https://assets.torecord.it/recordlogoblack.png"
      />
      {invitationDetails.error && <div className=''>
      <AuthError title="Invitation Invalid" description={'Please contact your reffering admin or create a new account'} />
        </div>}
      <div className="flex flex-col items-start mt-4">
        <h2 className="general-title onboarding login-title">Signup {invitationDetails.active && `for ${invitationDetails.customer.name}`}</h2>
      </div>
      <div
        className="p-2 bg-tuto-light-blue text-white rounded shadow google-button flex cursor-pointer items-center my-4"
        onClick={() =>{
          Mixpanel.track('Clicked on Continue with Google',{});
          (window.location.href = `${CONFIG.auth}oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid phone profile&prompt=consent`)}
        }
      >
        <img
          src="https://assets.torecord.it/search.png"
          className="google-icon"
        />
        <div className="ml-2">Continue with Google</div>
      </div>
      <div className="flex items-center justify-center mb-4">or</div>
      <form
      id='registration_form'
        onSubmit={handleSubmit(formRegistrationSubmitHandler)}
        className="space-y-6"
      >
        <div className="relative space-y-1">
          <div className="mt-1">
            <FormInput
              name="email"
              type="email"
              label="Email"
              placeholder=""
              touched={invitationDetails.active ? true : touchedFields.email}
              error={errors.email?.message}
              watch={watch}
              registerFunction={register}
              isCustomClass={false}
              customClass={undefined}
              defaultValue={invitationDetails.active ? invitationDetails.invitation.email : ''}
              readOnly={invitationDetails.active}
            />
          </div>
        </div>
        <div className="relative space-y-1">
          <div className="mt-1">
            <FormInput
              name="password"
              type="password"
              label="Password"
              placeholder=""
              touched={touchedFields.password}
              error={errors?.password?.message}
              watch={watch}
              registerFunction={register}
              isCustomClass={false}
              customClass={undefined}
              
            />
          </div>
        </div>

        <div className="relative space-y-1">
          <div className="mt-1">
            <FormInput
              name="passwordConfirm"
              type="password"
              label="Confirm Password"
              placeholder=""
              touched={touchedFields.passwordConfirm}
              error={errors?.passwordConfirm?.message}
              watch={watch}
              registerFunction={register}
              isCustomClass={false}
              customClass={undefined}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm">
            <label htmlFor="remember_me" className="mr-2 text-sm text-gray-900">
              Alredy a member?
            </label>
            <Link to="/login" className="font-medium text-tuto-primary" onClick={() => {Mixpanel.track('Clicked on Signin', {})}}>
              Signin
            </Link>
          </div>
        </div>

        <div className="relative">
          <Button
            type={'submit'}
            disabled={false}
            loading={loading}
            withIcon={true}
            Icon={ArrowRightIcon}
            text={'Signup'}
            inverted={false}
          />
        </div>
      </form>
      <div className="mt-4">
        {error && <AuthError title="error" description={error} />}
      </div>
    </div>
  )
}

export default RegistrationForm
