// ValuesDropdown.tsx

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { unprotectedGetRequest } from '../../../../app/apis/apiGenerics'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import {
  investigationParametersState,
  mapForElastic,
  setSelectedValues
} from '../../../../app/slices/investigationParametersSlice'
import { investigationElastic } from '../../../../app/slices/investigationsSlice'

interface ValuesDropdownProps {
  onSelect: (values: string[]) => void
  isRadio?: boolean
  parameterId: string
  customDataValues?: any
  customKey: string
}

const ValuesDropdown = ({
  onSelect,
  isRadio = false,
  parameterId,
  customDataValues,
  customKey
}: ValuesDropdownProps) => {
  const [data, setData] = useState<any[]>([])
  const [values, setValues] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAppSelector(authState)

  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const { parameters } = useAppSelector(investigationParametersState)
  const params = useParams()
  const selectedParameter = parameters.find((param) => param.id === parameterId)

  useEffect(() => {
    if (selectedParameter?.parameter?.value === 'custom_data' && customKey) {
      const customValues = customDataValues[customKey].map(String)

      setData(customValues)
    } 
    if (!params.investigationId) {
      console.log('entered setValues([]) in !params.investigationId if')
      setValues([])
    } else {
      if (selectedParameter?.selectedValues) {
        const values: any = selectedParameter.selectedValues
        if (selectedParameter.parameter?.customOptions || selectedParameter?.parameter?.value === 'custom_data') {
          let key = Object.keys(values)
          setValues([...values[key[0]]])
          console.log('vslues dropdown investigationElastic')
          dispatch(
            investigationElastic({
              customer_id: user.customer_id,
              filters: parameters
            })
          )
        } else {
          setValues([...values])
        }
      }
    }
  }, [selectedParameter?.parameter])

  useEffect(() => {
    if (selectedParameter?.parameter?.value === 'custom_data' && customKey) {
      const customValues = customDataValues[customKey].map(String)

      setData(customValues)
    }
  }, [customKey])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    getValuesPerParam()
  }, [selectedParameter])


  const getValuesPerParam = async () => {
    if (selectedParameter?.parameter?.customOptions) {
      setData(selectedParameter?.parameter?.customOptions)
    } else if (selectedParameter?.parameter?.apiParams) {
      let res = await unprotectedGetRequest(
        `records/getFilterOptionsByParam?customer_id=${user.customer_id}&param=${selectedParameter?.parameter?.apiParams.param}&table=${selectedParameter?.parameter?.apiParams.table}`
      )

      const data = res.data.map(
        (option: any) =>
          option[`${selectedParameter?.parameter?.apiParams?.param}`] !== null &&
          option[`${selectedParameter?.parameter?.apiParams?.param}`] !== '' &&
          option[`${selectedParameter?.parameter?.apiParams?.param}`]
      )

      setData(data)
    } else if (selectedParameter?.parameter?.value !== 'custom_data') {
      let res = await unprotectedGetRequest(
        `records/getFilterOptionsByParam?customer_id=${user.customer_id}&param=${selectedParameter?.parameter?.value}`
      )
      if (
        selectedParameter?.parameter?.value === 'screen_width' ||
        selectedParameter?.parameter?.value === 'screen_height'
      ) {
        let strings = res.data.values.map(String)
        setData(strings)
      } else {
        setData(res.data.values)
      }
    }
  }

  const handleOptionToggle = (value: string) => {
    // if (isRadio) {
    //   setValues([value])
    //   dispatch(setSelectedValues({ id: parameterId, values: [value] }))
    //   onSelect([value])
    // } else {
    if (selectedParameter?.parameter?.customOptions) {
      const newSelectedValues = values.includes(value)
        ? [...values.filter((v) => v !== value)]
        : [...values, value]
      setValues(newSelectedValues)

      const valuesObj: any = {}
      valuesObj[selectedParameter?.parameter?.value] = [...newSelectedValues]
      onSelect(newSelectedValues)
      dispatch(setSelectedValues({ id: parameterId, values: valuesObj }))
    } else if (selectedParameter?.parameter?.value === 'custom_data') {
      const newSelectedValues = values.includes(value)
        ? [...values.filter((v) => v !== value)]
        : [...values, value]

      setValues(newSelectedValues)
      const valuesObj: any = {}
      valuesObj[customKey] = [...newSelectedValues]
      onSelect(newSelectedValues)
      dispatch(setSelectedValues({ id: parameterId, values: valuesObj }))
    } else {
      const newSelectedValues = values.includes(value)
        ? [...values.filter((v) => v !== value)]
        : [...values, value]

      setValues(newSelectedValues)
      onSelect(newSelectedValues)
      dispatch(
        setSelectedValues({ id: parameterId, values: newSelectedValues })
      )
    }
    // }
  }

  const handleCheckAll = () => {
    dispatch(setSelectedValues({ id: parameterId, values: data }))
    setValues(data)
    onSelect(data)
  }

  const handleClearAll = () => {
    dispatch(setSelectedValues({ id: parameterId, values: [] }))
    setValues([])
    onSelect([])
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredValues = selectedParameter?.parameter?.customOptions
    ? data.filter(
        (option) =>
          option.value &&
          option.value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : data.filter(
        (value) =>
          value &&
          value.length > 0 &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      )

  const handleAdd = () => {
    // dispatch(mapForElastic())
    setIsOpen(false)
    dispatch(
      investigationElastic({
        customer_id: user.customer_id,
        filters: parameters
      })
    )
  }

  const selectedValuesString = () => {
    if (values.length === 0) return ''
    if (values.length === 1) return `${selectedParameter?.parameter?.value === 'status' ? `${values[0]+'xx'}` : values[0]}`
    return `${selectedParameter?.parameter?.value === 'status' ? `${values[0]}xx (and ${values.length - 1} more...)` : `${values[0]} (and ${values.length - 1} more...)`}`
  }

  return (
    <div className="relative">
      <button className="parameter-tag" onClick={() => setIsOpen(!isOpen)}>
        {selectedValuesString() || 'Select Values'}
      </button>
      {isOpen && (
        <div
          ref={ref}
          className="absolute top-12  min-w-22 z-10 left-0  w-full bg-white border border-gray-300 rounded-lg  shadow-lg"
        >
          <div className="absolute h-2 w-2 bg-tuto-primary  rotate-45 -top-1 left-6"></div>
          <div className="px-4 py-2 bg-tuto-primary rounded-t-lg">
            <input
              type="text"
              className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
              placeholder="Search values..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <MagnifyingGlassIcon className="h-6 w-6 text-tuto-primary absolute right-6 top-4" />
          </div>
          <div className="flex items-center just0fy-center px-4 py-1">
            <button className="text-gray-500 text-sm" onClick={handleCheckAll}>
              All
            </button>
            <div className="mx-1 text-gray-500"> | </div>
            <button className="text-gray-500 text-sm" onClick={handleClearAll}>
              None
            </button>
          </div>
          <hr />
          <ul className="text-gray-800 h-52 overflow-scroll">
            {filteredValues.length > 0 ? (
              !selectedParameter?.parameter?.customOptions ? (
                filteredValues.map((value) => (
                  <li
                    key={value}
                    className="flex text-xs items-center px-4 py-2 cursor-pointer hover:bg-tuto-light-blue hover:bg-opacity-30"
                    onClick={() => handleOptionToggle(value)}
                  >
                    <input
                      type="checkbox"
                      checked={values.includes(value)}
                      readOnly
                      className="rounded checked:bg-tuto-light-blue"
                    />

                    <span className="ml-2 font-semibold">{value}</span>
                  </li>
                ))
              ) : (
                filteredValues.map((option) => (
                  <li
                    key={option.value}
                    className="flex text-xs items-center px-4 py-2 cursor-pointer hover:bg-light-gray"
                    onClick={() => handleOptionToggle(option.value)}
                  >
                    <input
                      type="checkbox"
                      checked={values.includes(option.value)}
                      readOnly
                      className="rounded checked:bg-tuto-light-blue"
                    />

                    <span className="ml-2 font-semibold">
                      {option.displayName}
                    </span>
                  </li>
                ))
              )
            ) : (
              <div className="flex w-full h-full items-center p-4 justify-center text-gray-400 text-center">
                <NoSymbolIcon className="w-6 h-5 mr-2" />
                No Results Found
              </div>
            )}
          </ul>
          <hr />
          <div
            onClick={handleAdd}
            className="px-4 py-2 bg-tuto-primary rounded-b-lg text-white font-semibold cursor-pointer text-center"
          >
            Add
          </div>
        </div>
      )}
    </div>
  )
}

export default ValuesDropdown
