import React, { FC, useEffect, useState } from 'react'
import CustomDataTab from '../Problems/CustomDataTab'
import RecordDataTab from '../Problems/RecordDataTab'
import WindowObjectTab from '../Problems/WindowObjectTab'


interface AdditionalInfoTabProps {
  regularData: any
  customData: any
  browserInfo:any
  windowObject: any
}

const AdditionalInfoTab:FC<AdditionalInfoTabProps> = ({regularData, customData, browserInfo, windowObject}) => {
  const [activeTab, setActiveTab] = useState('Record')

  useEffect(() => {
    console.log('windowObject', windowObject)
  }, [])
  return (
    <div className="">
      <div className=" rounded-2xl bg-gray-200 flex justify-between mb-4 sticky">
          <div
            onClick={() => setActiveTab('Record')}
            className={`cursor-pointer text-center flex-grow px-4 py-1 rounded-2xl ${
              activeTab === 'Record' ? 'bg-gray-300' : 'bg-gray-200'
            } `}
          >
            Record Data
          </div>
          <div
            onClick={() => setActiveTab('Custom')}
            className={`cursor-pointer text-center flex-grow px-4 py-1 rounded-2xl ${
              activeTab === 'Custom' ? 'bg-gray-300' : 'bg-gray-200'
            } `}
          >
            Your Custom Data
          </div>
          <div
            onClick={() => setActiveTab('Advanced')}
            className={`cursor-pointer text-center flex-grow px-4 py-1 rounded-2xl ${
              activeTab === 'Advanced' ? 'bg-gray-300' : 'bg-gray-200'
            } `}
          >
            Advanced
          </div>
          {/* <div className='flex items-center'>
            <div
              onClick={() => setActiveTab('Advanced')}
              className={`cursor-pointer text-center flex-grow px-4 py-1 rounded-2xl ${
                activeTab === 'Advanced' ? 'bg-gray-300' : 'bg-gray-200'
              } flex items-center`}
              >
                Advanced 
                <span className="inline-block bg-tuto-blue text-white px-1 py-0.5 text-xs font-semibold rounded-md ml-4">Beta</span>

            </div>
          </div> */}
         
          
        </div>
        {activeTab === 'Record' ? 
        browserInfo &&
        <RecordDataTab browserInfo={browserInfo} recordData={regularData} />
       : activeTab === 'Custom' ? 
       
        <CustomDataTab data={customData} />
        :
        
        <WindowObjectTab  data={windowObject}/> 
       }
    </div>
  )
}

export default AdditionalInfoTab