import { PlayIcon, XCircleIcon } from '@heroicons/react/24/solid'
import React, { useRef, useState } from 'react'
import { JsxElement } from 'typescript'
import Button from '../../utils/elements/Button'

interface Props {
  videoSource: string
  onClose: () => void
  title: string
  buttonText: string
  text: JSX.Element
}

const OnboardingPopup: React.FC<Props> = ({
  videoSource,
  onClose,
  title,
  text,
  buttonText
}) => {

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-40">
      <div className="bg-white relative flex flex-col gap-6 max-w-60 bg-opacity-30 backdrop-blur-md rounded-md p-12 px-20 border-white border-opacity-40 border-2 shadow-lg">
        <button
          onClick={onClose}
          className="mt-4 text-gray-200 font-bold py-2 px-4 rounded absolute top-0 right-0"
        >
          <XCircleIcon className="w-10 h-10" />
        </button>
        <div className="relative pl-8">
          <div className='w-2 bg-tuto-purple rounded-lg absolute left-0 top-0 bottom-0'></div>
          <h1 className="text-2xl font-light mb-2 text-white">{title}</h1>
          <div className="text-3xl text-white">{text}</div>
        </div>

        <video  controls src={videoSource} className="w-full h-auto rounded-md" />
        {/* {!isPlaying && 
          <div className="absolute cursor-pointer top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 py-12 px-20 glass-btn flex items-center justify-center">
            <PlayIcon className="h-20 w-20 text-white" onClick={handlePlayPause} />
          </div>
        } */}
        <div className="self-center">
          <Button
            inverted={false}
            loading={false}
            disabled={false}
            text={buttonText}
            type="button"
            withIcon={false}
            spacialClass=""
            clickFunction={onClose}
          />
        </div>
      </div>
    </div>
  )
}

export default OnboardingPopup
