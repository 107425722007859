import { useEffect, useState } from 'react'
import Button from '../../../utils/elements/Button'
import { PlayIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import Knowledge from '../../Knowledge/Knowledge'
import config from '../../../../config/config'
import { CopyBlock, a11yLight } from 'react-code-blocks'
import Videos from '../../Knowledge/Videos'
import { getCustomerDomains } from '../../../../app/slices/customerDomainsSlice'
import { unprotectedPostRequest } from '../../../../app/apis/apiGenerics'
import { authState } from '../../../../app/slices/authSlice'
import { OnBoardingStepsE } from '../../../utils/enums'
import AuthError from '../../../Auth/AuthError'
import { useNavigate } from 'react-router-dom'
import { setOnboardingMode } from '../../../../app/slices/uiSlice'
import VerificationPopup from '../VerificationPopup'

interface InstallationBlockProps {
  location: string
}

const InstallationBlock: React.FC<InstallationBlockProps> = ({ location }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [knowledgeOpen, setKnowledgeOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { user, isPlayground } = useAppSelector(authState)
  const [verificationPopup, setVerificationPopup] = useState(false)
  const script = ` <!--Start of Record Script-->
  <script>
  !function(b,e,d,f){
    var a=b.createElement("script");a.async=!0,
    a.id="torecord",a.src="${config.scriptSource}?key="+d;
    var c=b.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(a,c)
  }(document,window,"${isPlayground ? 'API_KEY' : user.customer_id}")
</script>
<!--End of Record Script-->`

  const recordSettings = `window.recordSettings = {
    name: "{YOUR_USER_NAME}",
    email: "{YUOR_USER_EMAIL}",
    customData: {
    //insert any additional data, for example:
    // key: value
    }
  };`

  const verifyInstallation = async () => {
    setLoading(true)
    const res = await dispatch(
      getCustomerDomains({ customer_id: user.customer_id })
    )
    if (location === 'onboarding') {
      if (res.payload.length > 0) {
        await insertOnboardingStep()
      } else {
        setVerificationPopup(true)
      }
    } else if (res.payload.length <= 0) {
      setVerificationPopup(true)
    }
    setLoading(false)
  }

  const insertOnboardingStep = async () => {
    const onBoardingStep = await unprotectedPostRequest(
      'onboarding/insertCustomerOBStep',
      {
        customer_id: user.customer_id,
        user_id: user.id,
        step_id: OnBoardingStepsE.INSTALLATION
      }
    )
    dispatch(setOnboardingMode(false))
    navigate('/settings/installation')
  }

  const trackBtnEvent = (event: string) => {
    Mixpanel.track(`Clicked on ${event} btn - Installation page`, {
      email: user.email,
      user_id: user.id,
      customer_id: user.customer_id
    })
  }

  return (
    <div>
      {/* <h1 className="onBoarding-title mb-8">
        Action Required: Install Your Script
      </h1> */}
      <h1 className='text-lg '>Record Snippet</h1>
      <p className="onBoarding-p">
      To ensure that Record functions properly, the code needs to be installed on your product or website. Please install Record between the  &lt;head&gt; tags.
      </p>
      <div className="my-4 border rounded-lg overflow-hidden copy-wrapper">
        <CopyBlock
          language={'html'}
          text={script}
          showLineNumbers={true}
          theme={a11yLight}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="flex">
          <Button
            inverted={false}
            loading={loading}
            disabled={false}
            text="Verify installation"
            type="button"
            withIcon={false}
            spacialClass=" mr-2"
            clickFunction={() => {
              Mixpanel.track('Verify Domain Clicked', {
                'Onboarding Step': 7
              })
              verifyInstallation()
            }}
          />
          <a
            href="https://www.youtube.com/watch?v=7myFkjPbGzg"
            target={'blank'}
            className="flex items-center text-tuto-purple cursor-pointer"
            onClick={() => {
              trackBtnEvent('How it works')
            }}
          >
            <PlayIcon className="h-5 w-5 mr-2" />
            <span className="">How to install</span>
          </a>
        </div>
        {/* <div className="flex">
          <div className="flex items-center justify-center">
            <div className="h-12 w-12 mr-2">
              <img src="https://assets.torecord.it/ISO.png" />
            </div>
            <div className="h-14 w-14">
              <img src="https://assets.torecord.it/GDPR.png" />
            </div>
          </div>
        </div> */}
      </div>
      <h1 className='text-lg mt-8'>User Identification & Custom data</h1>
      <p className="onBoarding-p">
      You can include name, email, and any other data you wish to associate with this Record, by utilizing the following API. more information can be found on our <a className='text-tuto-purple font-semibold hover:underline' target={'_blank'} href='https://torecord.it/docs'>Docs</a>
      </p>
      <div className="my-4 border rounded-lg overflow-hidden copy-wrapper">
        <CopyBlock
          language={'js'}
          text={recordSettings}
          showLineNumbers={true}
          theme={a11yLight}
          wrapLines={true}
          codeBlock
        />
      </div>
      
      <VerificationPopup
        open={verificationPopup}
        setOpen={setVerificationPopup}
        location={location}
      />
      <Knowledge
        title={'Getting Started'}
        isOpen={knowledgeOpen}
        setSlideOpen={setKnowledgeOpen}
      >
        <Videos />
      </Knowledge>
    </div>
  )
}

export default InstallationBlock
