import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  customerDomainsSelectors,
  setCopiedLink
} from '../../../app/slices/customerDomainsSlice'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import Button from '../../utils/elements/Button'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { encryptData } from '../../utils/encryption'
import CONFIG from '../../../config/config'
import {
  PlayIcon,
  UserPlusIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline'
import { authState } from '../../../app/slices/authSlice'

const RecordRequest = () => {
  const dispatch = useAppDispatch()
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const { user } = useAppSelector(authState)
  const verifiedDomains: any =
    domains &&
    domains.length > 0 &&
    domains.filter((domain) => {
      return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
    })
  const [selectedDomain, setSelectedDomain] = useState('')
  const [copied, setIsCopied] = useState(false)

  useEffect(() => {
    if(domains && domains.length > 0) {

      setSelectedDomain(domains[0].origin)
    }
  }, [domains])
  // if(!verifiedDomains) {
  //   return null
  // }
  return (
    <div className='flex items-center gap-8 col-span-3 px-4'>
      <h1 className='font-semibold'>Request New</h1>
      <div className="relative p-1 border rounded-xl flex flex-col sm:flex-row flex-grow">
        {/* <div className="text-md  focus:border-tuto-primary text-tuto-primary sm:text-2xl block w-full pl-7 pr-12 border-gray-300 rounded-md dark:bg-dark-secondary dark:border-dark-text dark:text-dark-text">
                  {domains && domains.length > 0
                    ? `${selectedDomain}/?isrecording=true&userId=${user.id}`
                    : ''}
                </div> */}

        <div className="flex items-center w-full">
          <label htmlFor="domain" className="">
           
          </label>
          
                
          <select
            id="domain"
            name="domain"
            className="focus:border-tuto-primary h-full py-0 pl-2 pr-7 border-transparent bg-transparent w-full text-gray-500 text-xs sm:text-sm rounded-md"
            onChange={(e) => {
              if (selectedDomain !== e.target.value) {
                Mixpanel.track('Switch Domain for Copy', {
                  'old domain': selectedDomain,
                  'new domain': e.target.value
                })
              }
              setSelectedDomain(e.target.value)
              console.log('before encription',e.target.value,
                user.id,
                user.name,
                user.company_name)
              const encData = encryptData(
                e.target.value,
                user.id,
                user.name,
                user.company_name
              ) as string
              dispatch(setCopiedLink(`${CONFIG}/links?redirect=${encData}`))
              navigator.clipboard.writeText(
                `${CONFIG.dashboard}links?redirect=${encodeURIComponent(
                  encData
                )}`
              )
              dispatch(setCoppiedTooltip(true))
            }}
          >
            {!verifiedDomains && 
            
            <option disabled>No Domains Installed</option>
            }
            {verifiedDomains &&
              verifiedDomains.map((domain: any) => (
                <option key={domain.id} value={domain.origin}>
                  {domain.origin.toUpperCase()}
                </option>
              ))}
          </select>

          <Button
            inverted={false}
            loading={false}
            disabled={!verifiedDomains}
            text=""
            clickFunction={() => {
              Mixpanel.track('Click on Copy Link', {
                link: `${selectedDomain}/?isrecording=true&userId=${user.id}`
              })
              setIsCopied(true)
              console.log(selectedDomain,
                user.id,
                user.name,
                user.company_name)
              const encData = encryptData(
                selectedDomain,
                user.id,
                user.name,
                user.company_name
              ) as string
              dispatch(
                setCopiedLink(
                  `${CONFIG.dashboard}links?redirect=${encodeURIComponent(
                    encData
                  )}`
                )
              )
              navigator.clipboard.writeText(
                `${CONFIG.dashboard}links?redirect=${encodeURIComponent(
                  encData
                )}`
              )
              dispatch(setCoppiedTooltip(true))
            }}
            type="button"
            withIcon={true}
            Icon={copied ? ClipboardDocumentCheckIcon : ClipboardIcon}
            spacialClass=""
          ></Button>
        </div>
      </div>
    </div>
  )
}

export default RecordRequest
