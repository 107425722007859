import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  investigationElastic,
  investigationsState,
  setCurrentSession
} from '../../../app/slices/investigationsSlice'
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CogLoader from '../../utils/shared/CogLoader'
import {
  problemsState,
} from '../../../app/slices/ProblemsSlice'
import { authState } from '../../../app/slices/authSlice'
import ShortUuid from '../../utils/tables/ShortUuid'
import InvestigationsDropdown from './InvestigationsDropdown'
import moment from 'moment'

interface InvestigationSidebarProps {
  setEmptyInvestigation: any
  emptyInvestigation: boolean
}

const InvestigationSidebar: FC<InvestigationSidebarProps> = ({
  setEmptyInvestigation,
  emptyInvestigation
}) => {
  const { currentInvestigation, loadingDetails, elasticResults } =
    useAppSelector(investigationsState)
  const { user } = useAppSelector(authState)
  const { loadingFilters } =
    useAppSelector(problemsState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (currentInvestigation && currentInvestigation.query_json) {
      const filters = JSON.parse(currentInvestigation.query_json)

      dispatch(
        investigationElastic({
          customer_id: user.customer_id,
          filters: filters
        })
      )
    } 
    
  }, [currentInvestigation])

  useEffect(() => {
    if (elasticResults && elasticResults.length > 0 && !params.sessionId) {
      dispatch(
        setCurrentSession({
          id: elasticResults[0].record_id,
          is_session: elasticResults[0].is_session
        })
      )
    }
  }, [elasticResults])

  useEffect(() => {
    return () => {
      setEmptyInvestigation(false)
      
    }
  }, [])

  

  return (
    <div className="h-full w-full relative z-50">
      {loadingDetails || loadingFilters ? (
        <div className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2">
          <CogLoader />
        </div>
      ) : (
        currentInvestigation && (
          <div className="relative grid grid-rows-8">
            <div className="sticky top-1 flex gap-2 items-center justify-between row-span-1">
              <div
                className="cursor-pointer flex gap-2 items-center"
                onClick={() => navigate('/investigate')}
              >
                <ChevronLeftIcon className="h-6 w-6 stroke-icon relative" />
                <h1 className="text-xl font-semibold">
                  {currentInvestigation && currentInvestigation.name}
                </h1>
              </div>
              <div className="flex items-center justify0center gap-2 cursor-pointer">
                <InvestigationsDropdown />
              </div>
            </div>
            <div className="pt-4 row-span-7 flex flex-col gap-2 max-h-80v overflow-scroll">
              {!loadingFilters &&
                elasticResults &&
                elasticResults.map((record: any, index: number) => (
                  <Link
                    key={record.record_id}
                    to={`/investigate/${currentInvestigation.id}/${record.record_id}`}
                    className={`border-transparent ${
                      record.record_id === params.sessionId ||
                      (record.record_id === elasticResults[0].record_id && !params.sessionId)
                        ? 'bg-tuto-light-blue bg-opacity-30'
                        : 'bg-transparent'
                    }  font-bold hover:bg-tuto-light-blue  text-tuto-primary items-center  p-2 rounded-lg border-b-2 text-sm`}
                  >
                    <div className="flex ">
                      <div className="flex-grow">
                        {record.visitor_name ? (
                          record.visitor_name
                        ) : (
                          record.visitor_id && <ShortUuid text={record.visitor_id} />
                        )}
                      </div>
                      {record.is_watched ? (
                        <div className="h-3 w-3 bg-transparent rounded-full ml-4"></div>
                      ) : (
                        <div className="h-3 w-3 bg-tuto-purple rounded-full ml-4"></div>
                      )}{' '}
                    </div>
                    <div className="font-light">
                      {moment(
                        new Date(record.created_at).toLocaleString()
                      ).format('DD/MM/YY HH:mm')}
                    </div>
                    <div>{record.is_session ? 'session' : 'record'}</div>
                  </Link>
                ))}
              {emptyInvestigation && (
                <h1 className="text-dark-gray text-center">
                  There are currently no matching records or sessions for your
                  investigation
                </h1>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default InvestigationSidebar
