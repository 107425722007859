import { useState } from 'react'
import { unprotectedPostRequest } from '../../../../app/apis/apiGenerics'
import { useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import Toggle from '../../../utils/elements/Toggle'

const Collecting = () => {
  const { user } = useAppSelector(authState)
  const [sessionRecordingEnabled, setSessionRecordingEnabled] = useState(
    user.session_enabled
  )
  const [reportBugWidgetEnabled, setReportBugWidgetEnabled] = useState(
    user.bug_widget_enabled
  )
  const reportBugWidgetText = `When enabled, a ‘Report a Bug’ button appears at the bottom-right of the user interface, allowing users to report issues throughout the system. The result will be a new Record, visible in the inbox or any relevant investigation.`

  const toggleSessionRecording = async () => {
    try {
      setSessionRecordingEnabled(!sessionRecordingEnabled)
      const enabledRes = await unprotectedPostRequest(
        'customers/updateSessionEnabled',
        {
          customer_id: user.customer_id,
          isSessionEnable: !sessionRecordingEnabled
        }
      )
      console.log(enabledRes)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleReportBugWidget = async () => {
    try {
      setReportBugWidgetEnabled(!reportBugWidgetEnabled)
      const enabledRes = await unprotectedPostRequest(
        'customers/updateReportBugWidgetEnabled',
        {
          customer_id: user.customer_id,
          isEnabled: !reportBugWidgetEnabled
        }
      )
      console.log(enabledRes)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="p-4">
      <div className="flex gap-4 flex-col max-w-lg">
        <h1 className="general-title">Session Recording</h1>
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-dark-gray">Collect User Session</h3>
            <p className="text-sm text-dark-gray">
              Collect user sessions on the background, it will enable you to
              investigate and find bugs and errors that have not been reported
              to you in order to take action on them.
            </p>
          </div>
          <div>
            <Toggle
              setEnabled={toggleSessionRecording}
              enabled={sessionRecordingEnabled}
            />
          </div>
        </div>
      </div>
     
      <div className="flex gap-4 flex-col max-w-lg pt-16">
        <div className="flex gap-4 items-end">
          <h1 className="general-title">Report Bug Widget</h1>
          <span className="px-2 bg-tuto-purple text-sm text-white rounded-xl shadow-purple">
            Beta
          </span>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-dark-gray">Report Bug Widget</h3>
            <p className="text-sm text-dark-gray">
              {reportBugWidgetText}
              <br />
            </p>
          </div>
          <div>
            <Toggle
              setEnabled={toggleReportBugWidget}
              enabled={reportBugWidgetEnabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Collecting
