import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import Button from '../../../utils/elements/Button'
import { v4 as uuidv4 } from 'uuid'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import { unprotectedPostRequest } from '../../../../app/apis/apiGenerics'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

interface IFormInputs {
  email: string
}

const Instructions = () => {
  const { user } = useAppSelector(authState)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })
  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setLoading(true)
    const body = {
      email: data.email,
      senderName: user.name || 'Your teammate',
      senderEmail: user.email
    }
    console.log(body)
    const res = await unprotectedPostRequest(
      `users/sendInstallationInstructionsMail`,
      body
    )
    Mixpanel.track('Installation mail sent to developer', {
      user_id: user.id,
      customer_id: user.customer_id,
      email: user.email,
      emailTo: data.email
    })
    
    
    reset()
    if (res.success) {
      dispatch(
        addNewNotification({
          text: `Mail sent to ${data.email}`,
          id: uuidv4(),
          title: 'Mail sent!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    } else {
      addNewNotification({
        text: `Please try again later`,
        id: uuidv4(),
        title: `Error sending mail to ${data.email}`,
        type: 'WARN',
        timer: true,
        button: false
      })
    }
    setLoading(false)
  }

  const watchedEmail = watch("email", "");

  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      className="flex flex-col gap-8 developer-instructions-form"
    >
      <div className="">
        <p className="onBoarding-p mb-8">
          Share the instructions of how to install Record Snippet with your
          engineers
        </p>
        <div className="relative">
          <input
            type="text"
            placeholder="Engineer Mail"
            className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
            {...register('email')}
            // value={searchTerm}
            // onChange={handleSearch}
          />
          {errors.email && (
            <span className="absolute text-sm -bottom-6 text-record-red left-0">
              {errors.email.message}
            </span>
          )}
        </div>
      </div>
      <div className="">
        <div className="border-b py-4">To: {watchedEmail}</div>
        <div className="border-b py-4">
          {user.name} invites you to Record toghther !
        </div>
        <div className="py-4">
          Hey ✋, <br /> <br />
          Record is a tool to help you focus on building, not fixing bugs.{' '}
          {user.name} asked for your help installing the Record Snippet on your
          application. It only takes 5 minutes, and you'll benefit from: <br />{' '}
          <br />
          <ul>
            <li>- No more data missing when tickets escalate to R&D.</li>
            <li>- Saving hours on debugging and follow-ups each week.</li>
            <li>
              - Establishing one source of truth platform for ticket's data
              between support and R&D, enhancing collaboration.
            </li>
          </ul>
          <br /> <br />
          Click <span className="text-tuto-purple">here</span> to collaborate
          with {' '}
          {user.name} Thanks for your assistance in setting up Record! Let us
          know if you have any questions.
        </div>
      </div>
      <div>
        <Button
          inverted={false}
          loading={loading}
          disabled={false}
          text="Send"
          type="submit"
          withIcon={false}
          spacialClass=""
          // clickFunction={() => setCurrentForm(3)}
        />
      </div>
    </form>
  )
}

export default Instructions
