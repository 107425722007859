import React, { FC, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { unprotectedGetRequest } from '../../../app/apis/apiGenerics'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import { investigationsState, setLogsCount } from '../../../app/slices/investigationsSlice'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import {
  problemsState,
  setRecordsArray
} from '../../../app/slices/ProblemsSlice'
import Player from '../../utils/player/Player'
import CogLoader from '../../utils/shared/CogLoader'
import ErrorPage from '../Investigations/ErrorPage'

interface MainInvestigationViewProps {
  setAdditionalData: any
  defaultRecord?: string 
}

const MainRecordView: FC<MainInvestigationViewProps> = ({
  setAdditionalData,
  defaultRecord
}) => {
  const recordLink: any = useMatch('/issues/:record')
  const dispatch = useAppDispatch()
  const { currentSession, loadingDetails } = useAppSelector(investigationsState)
  const { loadingFilters, filteredRecords } = useAppSelector(problemsState)
  const [session, setSession] = useState(null)
  const [loadingSession, setLoadingSession] = useState(false)
  const [errorPage, setErrorPage] = useState(false)
  const [isSession, setIsSession] = useState(false)
  const [audioSource, setAudioSource] = useState('')
  const [sourceId, setSourceId] = useState('')
  const {user} = useAppSelector(authState)

  useEffect(() => {
    if(defaultRecord) {
      getRecord(defaultRecord)
    } else {

      getRecord(recordLink.params.record)
    }
  }, [recordLink])

  useEffect(() => {
    if (filteredRecords.length > 0) {
      dispatch(setRecordsArray(filteredRecords))
    }
  }, [filteredRecords])

  const getRecord = async (record_id: string, is_session: any = undefined) => {
    setLoadingSession(true)
    setSession(null)
    setErrorPage(false)
    try {
      const res = await unprotectedGetRequest(
        `records/getSingleRecord?record_id=${record_id}&customer_id=${user.customer_id}${
          is_session ? `&is_session=${is_session}` : ''
        }`
      )
      console.log(res)
      if (res.success) {
        setAdditionalData({
          data: res.data,
          additionalInfo: res.additionalInfo,
          oneai: res.oneai,
          transcription: res.transcription,
          tags: res.tags,
          backendLogs: res.backendLogs,
          windowObject:res.windowObject,
          feedEvents: res.feedEvents
        })
        dispatch(setLogsCount(res.feedEvents.length))
        setSourceId(res.data.source_id)
        setSession(JSON.parse(res.json))
        if (res.data.is_session) {
          setIsSession(true)
        }
        if(res.data.path) {
          setAudioSource(res.data.path)
        }
        setLoadingSession(false)
      } else {
        setErrorPage(true)
        setLoadingSession(false)
      }
    } catch (err) {
      setErrorPage(true)
      setLoadingSession(false)
    }
  }

  return (
    <div className="">
      {loadingSession || loadingDetails || loadingFilters ? (
        <div className="min-h-75v flex items-center justify-center">
          <CogLoader />
        </div>
      ) : session ? (
        <div className="flex flex-col gap-6">
          <div
            className={`p-2 rounded self-start ${
              isSession ? 'bg-gray-200' : 'bg-tuto-light-blue'
            }`}
          >
            {isSession ? 'User Session' : 'User Record'}
          </div>
          <div>
            {defaultRecord ? 
            <Player session={session} sourceId={sourceId} recordId={defaultRecord} audioSource={audioSource} />
            :
            <Player session={session} sourceId={sourceId} recordId={recordLink.params.record} audioSource={audioSource} />
          }
          </div>
        </div>
      ) : (
        errorPage && (
          <div className="min-h-75v flex items-center justify-center">
            <ErrorPage />
          </div>
        )
      )}
    </div>
  )
}

export default MainRecordView
