import { unprotectedGetRequest, unprotectedPostRequest } from './apiGenerics'

export const GetRecordsByCustomerID = async (customer_id: string) => {
  const records = await unprotectedGetRequest(
    `records/getRecords?customer_id=${customer_id}`
  )
  // console.log(records)
  return records
}

export const GetFilterOptions = async (customer_id: string) => {
  const filters = await unprotectedGetRequest(
    `records/getFilterOptions?customer_id=${customer_id}`
  )
  // console.log(records)
  return filters
}

export const FilterRecords = async (customer_id: string, filterRecordsReq:any, include_sessions: boolean) => {
  const results = await unprotectedPostRequest(
    `records/filterRecords`, {
      customer_id,
      filterRecordsReq,
      include_sessions
    }
  )
  // console.log(results)
  return results
}

// export const UpdateDomainStatus = async (domain_id: string, status_id: string) => {
//   const domains = await unprotectedPostRequest('customers/updateDomainStatus', {
//     domain_id,
//     status_id
//   })
//   return domains
// }

// export const GetStatusTypes = async () => {
//     const res = await unprotectedGetRequest('users/getStatusTypes')
//     return res
//   }

// export const GetUserRoles = async () => {
//   const usersRoles = await unprotectedGetRequest('users/getUsersRoles')
//   return usersRoles
// }

// export const SendInvitation = async (
//   customer_id: string,
//   role_name: string,
//   teammates: string[],
//   campaign_id: string,
//   sender: string
// ) => {
//   const users = await unprotectedPostRequest('users/sendInvitaionMail', {
//     customer_id,
//     role_name,
//     teammates,
//     campaign_id,
//     sender
//   })
//   return users
// }
