import React, { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { useOutsideClick } from '../../../../hooks/useClickOuside'

import {
  GlobeAltIcon,
  TrashIcon,
  UserIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
  ComputerDesktopIcon
} from '@heroicons/react/24/solid'
import FilterCategory from './FilterCategory'
import { GlobeAmericasIcon, MicrophoneIcon } from '@heroicons/react/24/outline'
import FilterValue from './FilterValue'
import FilterValues from './FilterValues'
import { useAppSelector } from '../../../../app/hooks'
import { problemsState } from '../../../../app/slices/ProblemsSlice'

interface AdvancedFilteringProps {
  title: string
  activeFilterTab: string
  categories: any[]
  withCategories: boolean
}

const AdvancedFiltering: React.FC<AdvancedFilteringProps> = ({
  title,
  activeFilterTab,
  categories,
  withCategories
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { filters, filterRecordsReq } = useAppSelector(problemsState)
  const [activeFilter, setActiveFilter] = useState('screen')

  const handleDropDownOut = () => {
    setDropdownOpen(false)
  }

  useEffect(() => {
    setActiveFilter(activeFilterTab)
  }, [])

  const activeFiltersNumber = (title: string) => {
    if (title === 'Domains') {
      return filterRecordsReq.Origins.length > 0
        ? filterRecordsReq.Origins.length
        : ''
    }
    if (title === 'Custom') {
      return filterRecordsReq.CustomData.length > 0
        ? filterRecordsReq.CustomData.length
        : ''
    }
    if (title === 'Investigate') {
      return filterRecordsReq.Console.length + filterRecordsReq.Network.length >
        0
        ? filterRecordsReq.Console.length + filterRecordsReq.Network.length
        : ''
    }
    if (title === 'Additional Info') {
      let count = 0
      for (const [key, value] of Object.entries(filterRecordsReq)) {
        if (key === 'IsWithAudio') {
          if (
            filterRecordsReq.IsWithAudio === true ||
            filterRecordsReq.IsWithAudio === false
          ) {
            count++
          }
        } else if (key !== 'Origins' && key !== 'CustomData' && key !== 'Console' && key !== 'Network') {
          count = count + filterRecordsReq[key].length
        }
      }
      if (count === 0) {
        return ''
      } else {
        return count
      }
    }
  }

  const dropRef = useOutsideClick(() => setDropdownOpen(!handleDropDownOut))

  return (
    <div className="inline-block text-left mr-12">
      <div className="mt-4 flex items-center">
        <span className="rounded-full bg-tuto-light-blue px-2 text-xs">
          {activeFiltersNumber(title)}
        </span>
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="inline-flex w-full justify-center   px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
        >
          {title}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      <Transition
        as={'div'}
        show={dropdownOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform  opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        ref={dropRef}
      >
        <div
          className={`absolute flex left-1 z-50 mt-2  ${
            withCategories ? 'min-w-40' : 'w-80'
          } max-h-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="absolute h-4 w-4 bg-tuto-primary  rotate-45 -top-2 left-6"></div>
          {title !== 'Investigate' && title !== 'Domains' && (
            <div className="relative flex-grow overflow-hidden rounded-l-md min-w-1/2">
              <div className="overflow-scroll max-h-full min-h-10 pb-14  bg-tuto-primary p-2 rounded-b-lg">
                {categories.map((category: any, index: number) => (
                  <FilterCategory
                    key={index}
                    activeFilter={activeFilter}
                    title={category.title}
                    Icon={category.Icon}
                    setActiveFilter={setActiveFilter}
                    type={category.type}
                  />
                ))}
              </div>
            </div>
          )}

          {title === 'Custom' ? (
            categories.map((category: any) => (
              <FilterValues
                custom={true}
                key={category.type}
                filter={'CustomData'}
                values={filters.CustomData.filter(
                  (data: any) => data.type === category.type
                ).map((value: any) => value.value)}
                type={category}
                isActive={activeFilter === category.type ? true : false}
              />
            ))
          ) : title === 'Investigate' ? (
            <FilterValues
              custom={false}
              filter={'Investigate'}
              values={filters.Console.concat(filters.Network)}
              type={'investigate'}
              isActive={true}
            />
          ) : title === 'Domains' ? (
            <FilterValues
              custom={false}
              filter={'Domains'}
              values={filters.Origins}
              type={'domains'}
              isActive={true}
            />
          ) : (
            <div className="flex-grow">
              <FilterValues
                custom={false}
                filter={'ScreenSize'}
                values={filters.ScreenSize}
                type={'screen'}
                isActive={activeFilter === 'screen'}
              />
              <FilterValues
                custom={false}
                filter={'OS'}
                values={filters.OS}
                type={'os'}
                isActive={activeFilter === 'os'}
              />
              <FilterValues
                custom={false}
                filter={'Browser'}
                values={filters.Browser}
                type={'browser'}
                isActive={activeFilter === 'browser'}
              />
              <FilterValues
                custom={false}
                filter={'IsWithAudio'}
                values={filters.IsWithAudio}
                type={'mic'}
                isActive={activeFilter === 'mic'}
              />
              <FilterValues
                custom={false}
                filter={'VisitorName'}
                values={filters.VisitorName}
                type={'user'}
                isActive={activeFilter === 'user'}
              />
              <FilterValues
                custom={false}
                filter={'VisitorEmail'}
                values={filters.VisitorEmail}
                type={'email'}
                isActive={activeFilter === 'email'}
              />
            </div>
          )}
        </div>
      </Transition>
    </div>
  )
}

export default AdvancedFiltering
