import React from 'react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useAppSelector } from '../../../app/hooks'
import { problemsState } from '../../../app/slices/ProblemsSlice'
const people = [
  {
    name: 'e4b21eb0',
    title: 'F-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    duration: '154000'
  },
  {
    name: 'Ldsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    duration: '11000'
  },
  {
    name: 'Lindsay galeddd',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    duration: '130000'
  },
  {
    name: 'e4b21eb0',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    duration: '130000'
  },
  {
    name: 'Lin Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    duration: '130000'
  }
  // More people...
]

const BlockedIssuesTable = () => {
  const {totalRecordsBlocked} = useAppSelector(problemsState)

  const millisToMinutesAndSeconds = (duration: string) => {
    const millis = Number(duration)
    var minutes = Math.floor(millis / 60000)
    var seconds: any = ((millis % 60000) / 1000).toFixed(0)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  return (
    <div className='border border-gray-300 rounded-xl ml-4'>
      <table className="min-w-full divide-y border border-gray-900 rounded-xl overflow-hidden shadow-small divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th className="p-4 " scope="colgroup" colSpan={5}>
           
              <div className="flex justify-center items-center">
                <LockClosedIcon className="w-7 h-7 mr-2" />
                You Have {totalRecordsBlocked} Records Locked
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {people.map((person) => (
            <tr key={person.email} className='blur-sm'>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {person.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {millisToMinutesAndSeconds(person.duration)}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className='py-1 px-2 font-semibold text-white rounded-2xl bg-tuto-light-blue'> 5 days left</span>
              </td>
            </tr>
          ))}
          {totalRecordsBlocked - 5 > 0 &&
          
          <tr className=''>
            <th className="p-2" scope="colgroup" colSpan={5}>
            
              <div className="flex justify-center items-center">
                
                And {totalRecordsBlocked -5} more..
              </div>
            </th>
          </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

export default BlockedIssuesTable
