import { CogIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import { customerIntegrationsState, sendNewEarlyRequest } from '../../../../app/slices/customerIntegrationsSlice'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import { uiState } from '../../../../app/slices/uiSlice'
import Button from '../../../utils/elements/Button'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import IntegrationForm from '../../Knowledge/IntegrationForm'
import { useNavigate } from 'react-router-dom'
import Knowledge from '../../Knowledge/Knowledge'
import { v4 as uuidv4 } from 'uuid'

interface IntegrationProps {
  logo: string
  alreadyExist: boolean
  existForUser: boolean
  integrationId: string
}

const Integration: React.FC<IntegrationProps> = ({
  logo,
  alreadyExist,
  existForUser,
  integrationId
}) => {
  const dispatch = useAppDispatch()
  const [integrationFormOpen, setIntegrationFormOpen] = useState(false)
  const navigate = useNavigate()
  const { integrationSidebarToggle, integration_id } = useAppSelector(uiState)
  const { recordIntegrations, user } = useAppSelector(authState)
  const {onBoardingMode} = useAppSelector(uiState)
  const { earlyRequests } = useAppSelector(customerIntegrationsState)
  const currEarlyRequest: any = earlyRequests?.filter(obj => obj.integration_id === integrationId)
  const currIntegration: any = recordIntegrations.filter(obj => obj.id === integrationId)

  useEffect(() => {
    if(integrationId === integration_id && integrationSidebarToggle){
      setIntegrationFormOpen(true)
    }
  }, [integrationSidebarToggle])

  useEffect(() => {
    if(onBoardingMode) {
      navigate('/onboarding')
    }
  }, [onBoardingMode])

  return (
    <div className="relative flex flex-col items-center justify-between border rounded-lg shadow-lg p-4 max-w-12">
      {!alreadyExist && (
        <div className="absolute top-2 right-2 text-tuto-primary text-5xl">
          *
        </div>
      )}
      <div className="p-4 flex flex-grow  items-center justify-center">
        <img src={logo} className="max-w-9"/>
      </div>
      <div className="flex items-center">
        <div className="p-1">
          {!existForUser ? currEarlyRequest && currEarlyRequest.length > 0 && currIntegration[0].coming_soon ? 'Request Sent' :  <Button
            inverted={false}
            loading={false}
            disabled={currEarlyRequest && currEarlyRequest.length > 0 && currIntegration[0].coming_soon ? true : existForUser}
            text={
              !alreadyExist
                ? 'Request early access'
                : existForUser
                ? 'Connected'
                : 'Connect'
            }
            type="button"
            withIcon={false}
            spacialClass={existForUser ? 'text-sm bg-green-600' : 'text-sm'}
            clickFunction={() => {
              if(alreadyExist){
                setIntegrationFormOpen(true)
                Mixpanel.track(`Clicked on open integration form - ${currIntegration[0].application}`, {
                  user_id: user.id,
                  email: user.email,
                  customer_id: user.customer_id
                })
              }
              else{
                Mixpanel.track(`Clicked on request early access - ${currIntegration[0].application}`, {
                  user_id: user.id,
                  email: user.email,
                  customer_id: user.customer_id
                })
                dispatch(sendNewEarlyRequest({customer_id: user.customer_id, integration_id: currIntegration[0].id, application: currIntegration[0].application}))
                dispatch(
                  addNewNotification({
                    text: `We received your early access request for ${currIntegration[0].application}`,
                    id: uuidv4(),
                    title: 'Thanks',
                    type: 'SUCCESS',
                    timer: true,
                    button: false
                  })
                )
              }
            }}
          /> :
          (<div className='flex flex-row items-center'>
              <div className='h-3 w-3 bg-tuto-green rounded-xl mr-2'></div>
              <div className='text-tuto-green font-semibold'>Connected</div>
            </div>)}
        </div>
        <div className="p-1">
          {existForUser && (
            <Button
              inverted={false}
              loading={false}
              disabled={false}
              text=""
              Icon={CogIcon}
              type="button"
              withIcon={true}
              spacialClass=""
              clickFunction={() => {
                  setIntegrationFormOpen(true)
                  Mixpanel.track(`Clicked on open integration settings form - ${currIntegration[0].application}`, {
                    user_id: user.id,
                    email: user.email,
                    customer_id: user.customer_id
                  })
              }}
            />
          )}
        </div>
      </div>
      <Knowledge
        title={`Configure Your ${currIntegration[0].application} Account`}
        isOpen={integrationFormOpen}
        setSlideOpen={setIntegrationFormOpen}
      >
        {alreadyExist && (<IntegrationForm existForUser={existForUser} integrationId={integrationId}/>)}
      </Knowledge>
    </div>
  )
}

export default Integration
