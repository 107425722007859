import React from 'react'
import {
  CalendarIcon,
  ClockIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  IdentificationIcon,
  MicrophoneIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import ShortUuid from '../../utils/shared/ShortUuid'
import BrowserInfo from './BrowserInfo'
import moment from 'moment'

interface RecordDataTabProps {
  recordData: any
  browserInfo: any
}

const RecordDataTab: React.FC<RecordDataTabProps> = ({
  recordData,
  browserInfo
}) => {
  
  return (
    <div className="px-2">
      <div className="flex items-center mb-2">
        <UserIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold"></span>{' '}
        <span className="ml-2 font-bold">
          {recordData.visitor_name ? (
            recordData.visitor_name
          ) : (
            recordData.vistor_id
          )}
        </span>
      </div>
      {recordData.visitor_email && (
        <div className="flex items-center mb-2">
          <EnvelopeIcon className="w-6 h-6 mr-2" />
          <span className="ml-2 font-bold">
            <span className="font-semibold"></span> {recordData.visitor_email}
          </span>
        </div>
      )}
      <div className="flex items-center mb-2">
        <GlobeAltIcon className="w-6 h-6 mr-2" />
        <span className="ml-2 font-bold">
          <span className="font-semibold"></span>{' '}
          {recordData.origin && recordData.origin}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <IdentificationIcon className="w-6 h-6 mr-2" />
        <span className="ml-2 font-bold">
          <span className="font-semibold"></span>{' '}
          {recordData.record_id && recordData.record_id}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <MicrophoneIcon className="w-6 h-6 mr-2" />
        <span className="ml-2 font-bold">
          <span className="font-semibold"></span>{' '}
          {recordData.path ? 'on' : 'off'}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <CalendarIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold"></span>{' '}
        <span className="ml-2 font-bold">
          {' '}
          {moment(new Date(recordData.created_at).toLocaleString()).format(
            'DD/MM/YY HH:mm'
          )}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <ClockIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold"></span>
        {'   '}
        <span className="ml-2 font-bold">
          {Math.round(Number(recordData.duration) / 1000)}s
        </span>
      </div>
      {Object.keys(browserInfo).length > 0 &&
      
      <BrowserInfo info={browserInfo} />
      } 
    </div>
  )
}

export default RecordDataTab
