import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { unprotectedGetRequest, unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SentryForm = () => {
  const { user } = useAppSelector(authState)

  const [selectedProject, setSelectedProject] = useState('')
  const [projects, setProjects] = useState([])

  useEffect(() => {
    getSentryProjects()
  }, [])

  const getSentryProjects = async () => {
    const projects = await unprotectedGetRequest(
      `integrations/getSentryProjects?customer_id=${user.customer_id}`
    )
    if(projects.success) {

      setProjects(projects.data.projects)
      setSelectedProject(projects.data.chosenProject)
    }
  }

  const setSentryProject = async(sentryProject:string) => {
    setSelectedProject(sentryProject)
    const project = await unprotectedPostRequest('integrations/setSentryProject', {
      customer_id: user.customer_id,
      project_name: sentryProject
    })
    
    
  }

  return (
    <Menu as="div" className="relative inline-block text-left mt-2">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selectedProject === '' ? 'select a project' : selectedProject}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {projects.map((project) => (
              <Menu.Item key={project}>
                {({ active }) => (
                  <div
                   onClick={() => setSentryProject(project)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {project}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SentryForm
