import moment from 'moment'
import { FC } from 'react'
import Avatar from '../Investigations/Avatar'
import LogTagsGenerator from '../Investigations/LogTagsGenerator'
import CountryFlag from 'react-country-flag'
import { Link, useNavigate } from 'react-router-dom'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { useAppDispatch } from '../../../app/hooks'
import { setIsWatched } from '../../../app/slices/ProblemsSlice'

interface InboxTableProps {
  results: any
  location: string
}

const InboxTable: FC<InboxTableProps> = ({ results, location }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleClick = async (
    event: React.MouseEvent,
    recordId: string,
    sourceId: string,
    isWatched: boolean
  ) => {
    unprotectedPostRequest('records/setIsWatched', {
      record_id: recordId,
      source_id: sourceId,
      value: isWatched
    })
    dispatch(setIsWatched({ id: recordId, is_watched: isWatched }))
    event.stopPropagation()
  }

  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className=" pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                ></th>
                <th
                  scope="col"
                  className=" pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  {/* Date */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Type */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Name */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Email */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Feed Events */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Topics */}
                </th>
                <th
                  scope="col"
                  className="px-3  text-left text-sm font-semibold text-gray-900"
                >
                  {/* Pages */}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {results &&
                results.length > 0 &&
                results.map((result: any, index: number) => (
                  <tr
                    onClick={() => {

                      if(location === 'Inbox'){
                        navigate(`/issues/${result.record_id}`)
                      }
                    }
                    }
                    className={`relative group  ${
                      location === 'Inbox'
                        ? 'cursor-pointer hover:bg-tuto-light-blue hover:bg-opacity-30'
                        : ''
                    }`}
                    key={index}
                  >
                    <td className="whitespace-nowrap flex items-center gap-4 px-3 py-4 text-sm text-gray-500">
                      {result.visitor_email && (
                        <Avatar email={result.visitor_email} id={result.record_id} />
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {result.visitor_name ? result.visitor_name : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {result.visitor_email ? result.visitor_email : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {result.window_object?.currentLocationData ? (
                        <CountryFlag
                          countryCode={
                            result.window_object.currentLocationData
                              .country_code2
                          }
                          svg
                          style={{
                            width: '2em',
                            height: '2em'
                          }}
                        />
                      ) : (
                        <span className="w-20">-</span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {result.is_session ? (
                          <span className="inline-flex relative rounded-full bg-tuto-purple shadow-purple px-2 text-xs font-semibold leading-5 text-purple-200">
                            Session
                            {result.is_demo_data && (
                              <span className="demo-tag bg-tuto-light-blue text-tuto-primary ">
                                Demo
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="inline-flex relative shadow-tag rounded-full bg-tuto-primary px-2 text-xs font-semibold leading-5 text-white">
                            Record
                            {result.is_demo_data && (
                              <span className="demo-tag bg-tuto-purple">
                                Demo
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <LogTagsGenerator
                        console_events={result.console_events}
                        network_events={result.network_events}
                      />
                    </td>
                    <td className={`whitespace-nowrap ${location === 'Inbox' ? 'group-hover:opacity-0' : ''} py-4 pl-4 pr-3 text-sm sm:pl-0 text-right`}>
                      <div className="flex items-end justify-end w-full">
                        <div className=" text-gray-900 font-bold">
                          {moment(
                            new Date(result.created_at).toLocaleString()
                          ).format('DD/MM/YY HH:mm')}
                        </div>
                      </div>
                    </td>
                    {location === 'Inbox' && (
                      <td className="whitespace-nowrap px-3 py-4 text-sm absolute top-2 right-24">
                        {result.is_watched ? (
                          <EyeSlashIcon
                            onClick={(event) =>
                              handleClick(
                                event,
                                result.record_id,
                                result.source_id,
                                !result.is_watched
                              )
                            }
                            className="h-6 w-6 opacity-0 text-gray-300 hover:text-gray-500 group-hover:opacity-100 transition-all"
                          />
                        ) : (
                          <EyeIcon
                            onClick={(event) =>
                              handleClick(
                                event,
                                result.record_id,
                                result.source_id,
                                !result.is_watched
                              )
                            }
                            className="h-6 w-6 opacity-0 text-gray-300 hover:text-gray-500 group-hover:opacity-100 transition-all"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default InboxTable
