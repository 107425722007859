import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../elements/Button'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { uiState, closeTiersPopup } from '../../../app/slices/uiSlice'
import GrantAccess from '../../Auth/GrantAccess'
import FeaturesIncluded from '../../pages/Settings/Options/Pricing/featuresIncluded'
import { tiersPopupDetails } from '../enums'
import { authState } from '../../../app/slices/authSlice'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'

const TierPopup = () => {
  const dispatch = useAppDispatch()
  const { tierPopup, tiers } = useAppSelector(uiState)
  const [featureNextTierId, setFeatureNextTierId] = useState('')
  const { user } = useAppSelector(authState)
  const [mailSent, setMailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const tierToShowNext = tiers.find(
      (tier) => tier.name === tierPopup.nextTier
    )

    if (tierToShowNext) {
      setFeatureNextTierId(tierToShowNext.id)
    }
  }, [tierPopup])

  useEffect(() => {
    return () => {
      setMailSent(false)
    }
  }, [])

  const sendSubscriptionMail = async () => {
    setLoading(true)
    try {
      await unprotectedPostRequest('customers/sendUpdateSubsctiptionReq', {
        userName: user.name,
        customer_id: user.customer_id,
        tier_id: user.tier_id,
        user_email: user.email
      })
      setMailSent(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setMailSent(true)
      setLoading(false)
    }
  }

  return (
    <Transition.Root show={tierPopup.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          dispatch(closeTiersPopup())
          setMailSent(false)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full ${
                  tierPopup.specialComponent ? 'sm:max-w-5xl' : 'sm:max-w-4xl'
                } sm:p-6 sm:px-12 sm:py-16`}
              >
                <div className="purple-home"></div>
                <div className="yellow-home"></div>
                <div
                  onClick={() => {
                    dispatch(closeTiersPopup())
                    setMailSent(false)
                  }}
                  className="absolute left-2 top-2 cursor-pointer"
                >
                  <XMarkIcon className="w-6 h-6" />
                </div>
                <div className="flex relative">
                  <div className="max-w-lg pr-4 flex flex-col justify-between">
                    <div className="flex-grow">
                      <div className="flex flex-col justify-between items-stretch">
                        <Dialog.Title
                          as="h3"
                          className="text-4xl font-bold mb-2 text-tuto-primary"
                        >
                          {tierPopup.title}
                        </Dialog.Title>
                        {tierPopup.subTitle && (
                          <Dialog.Title
                            as="h3"
                            className="font-medium leading-6 text-tuto-primary mb-4"
                          >
                            {tierPopup.subTitle}
                          </Dialog.Title>
                        )}
                        <div className="">
                          {tierPopup.paragraph && (
                            <p className="text-md text-gray-500 mb-8">
                              {tierPopup.paragraph}
                            </p>
                          )}
                          {tierPopup.nextTier &&
                          
                          <FeaturesIncluded tier_id={featureNextTierId} />
                          }
                        </div>
                      </div>
                    </div>
                    <GrantAccess grantAccessToRoles={['admin']}>
                      <div className="mt-5 self-center">
                        {mailSent ? (
                          <div className="text-md flex items-center gap-2">
                            <CheckBadgeIcon className="h-12 w-12 text-tuto-green" />
                            <div>
                              Thanks! we got your information. Our team will
                              reach out to you as soon as possible.
                            </div>
                          </div>
                        ) : (
                          <Button
                            type="button"
                            inverted={false}
                            loading={loading}
                            disabled={false}
                            withIcon={false}
                            spacialClass=""
                            text={`${
                              mailSent
                                ? 'Thanks'
                                : tierPopup.nextTier ? `Upgrade to ${tierPopup.nextTier && tierPopup.nextTier } to unlock` :'Notify Our Sales Team'
                            }`}
                            clickFunction={sendSubscriptionMail}
                          />
                        )}
                      </div>
                    </GrantAccess>
                    <GrantAccess grantAccessToRoles={['user']}>
                      <div className="mt-5 sm:mt-12 sm:mb-8">
                        {mailSent ? (
                          <div className="text-md flex items-center gap-2">
                            <CheckBadgeIcon className="h-12 w-12 text-tuto-green" />
                            <div>
                              Thanks! we got your information. Our team will
                              reach out to you as soon as possible.
                            </div>
                          </div>
                        ) : (
                          <Button
                            type="button"
                            inverted={false}
                            loading={loading}
                            disabled={false}
                            withIcon={false}
                            text={`${
                              mailSent
                                ? 'Thanks'
                                : tierPopup.nextTier ? `Ask your Admin to Upgrade to ${tierPopup.nextTier}` :'Notify Our Sales Team'  
                            }`}
                            clickFunction={sendSubscriptionMail}
                          />
                        )}
                      </div>
                    </GrantAccess>
                  </div>
                  <div className="flex-grow">
                    {tierPopup.specialComponent ? (
                      <tierPopup.component />
                    ) : (
                      <img
                        src={tierPopup.img}
                        className={tierPopup.specialImgClass}
                      />
                    )}
                  </div>
                </div>
                {/* ) : (
                  <div className="flex items-center justify-center">
                    <div>
                      <div className="">
                        <div className="mt-3 sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-xl font-medium leading-6 text-gray-900"
                          >
                            You run out of {tierPopup.feature}, Upgrade to
                            unlock the {tierPopup.newMetric} with{' '}
                            {tierPopup.nextTier}
                          </Dialog.Title>
                          <div className="mt-2">
                            {tierPopup.paragraph && (
                              <p className="text-sm text-gray-500">
                                {tierPopup.paragraph}
                              </p>
                            )}
                            <FeaturesIncluded
                              tier_id={activeTier.nextTier.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <GrantAccess grantAccessToRoles={['admin']}>
                          <div className="mt-5 sm:mt-6">
                            <Button
                              type="button"
                              inverted={false}
                              loading={false}
                              disabled={false}
                              withIcon={false}
                              spacialClass='uppercase'
                              text={`Upgrade to ${tierPopup.nextTier} to unlock`}
                              clickFunction={() => console.log('upgrade popup')}
                            >
                              Upgrade
                            </Button>
                          </div>
                        </GrantAccess>
                        <GrantAccess grantAccessToRoles={['user']}>
                          <div className="mt-5 sm:mt-6">
                            <Button
                              type="button"
                              inverted={false}
                              loading={false}
                              disabled={false}
                              withIcon={false}
                              text={`Ask your Admin to Upgrade to ${tierPopup.nextTier}`}
                              clickFunction={() => console.log('upgrade popup')}
                            >
                              Upgrade
                            </Button>
                          </div>
                        </GrantAccess>
                      </div>
                    </div>
                    <div>
                      <img src="https://assets.torecord.it/OnbordingFirst.png" />
                    </div>
                  </div> */}
                {/* )} */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TierPopup
