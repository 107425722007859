import React, { useEffect, useState } from 'react'
import { updateUserRole } from '../../../app/slices/customerUsersSlice'
import { useDispatch } from 'react-redux'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch } from '../../../app/hooks'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'

interface RoleSelectProps {
  userRole: string
  rolesToSelect: any[]
  userId: string
  fromInvitation: boolean
  invitation_id?:string
}

const RoleSelect: React.FC<RoleSelectProps> = ({
  userRole,
  rolesToSelect,
  userId,
  fromInvitation,
  invitation_id
}) => {
  const dispatch = useAppDispatch()
  const [role, setRole] = useState('')

  useEffect(() => {
    setRole(userRole)
  }, [])

  const handleRoleChange = async (e: any) => {
    e.preventDefault()
    setRole(e.target.value)
    if(fromInvitation) {
      await unprotectedPostRequest('users/updateInvitationRole', {
        invitation_id, role_id: e.target.value
      })
    } else {

      await dispatch(updateUserRole({ user_id: userId, role_id: e.target.value }))
    }
    dispatch(
      addNewNotification({
        text: 'User Role Changed Successfully',
        id: uuidv4(),
        title: 'Role Changed',
        type: 'SUCCESS',
        timer: true,
        button: false
      })
    )
  }

  return (
    <select
      value={role}
      onChange={(e) => handleRoleChange(e)}
      id="role"
      name="role"
      className="focus:ring-indigo-500 focus:border-tuto-primary h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
    >
      {rolesToSelect.map((role) => (
        <option key={role.id} value={role.id}>
          {role.name.toUpperCase()}
        </option>
      ))}
    </select>
  )
}

export default RoleSelect
