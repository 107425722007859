import { unprotectedGetRequest, unprotectedPostRequest } from './apiGenerics'

export const GetDomainsByCustomer = async (customer_id: string) => {
  const users = await unprotectedGetRequest(
    `customers/getCustomerDomains?customer_id=${customer_id}`
  )
  
  return users
}

export const UpdateDomainStatus = async (domain_id: string, status_id: string) => {
  const domains = await unprotectedPostRequest('customers/updateDomainStatus', {
    domain_id,
    status_id
  })
  return domains
}

export const GetStatusTypes = async () => {
    const res = await unprotectedGetRequest('users/getStatusTypes')
    return res
  }

  export const GetIntegrationsByCustomer = async (customer_id: string) => {
    const integrations = await unprotectedGetRequest(
      `customers/getCustomerIntegrations?customer_id=${customer_id}`
    )
    return integrations
  }

  export const SendNewEarlyRequest = async (customer_id: string, integration_id: string ,application: string) => {
    const res = await unprotectedPostRequest('integrations/earlyRequest', {
      customer_id,
      integration_id,
      application
    })
    return res
  }

  export const GetCustomerIntegrationEarlyRequests = async (customer_id: string) => {
    const integrations = await unprotectedGetRequest(
      `integrations/getCustomerEarlyRequest?customer_id=${customer_id}`
    )
    return integrations
  }