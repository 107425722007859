import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { problemsSelectors } from '../../../app/slices/ProblemsSlice'
import IssuesSidebar from './IssuesSidebar'

const Issues = () => {

  const records = useAppSelector(problemsSelectors.selectAll)

  return (
    <div className='flex'>
      <IssuesSidebar records={records} />
      <div className='w-full mt-4'>
        <Outlet />
      </div>
    </div>
  )
}

export default Issues