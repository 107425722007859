import React, { FC } from 'react'
import Button from '../../utils/elements/Button'

interface SingleEmptyProps {
  buttonText: string
  text: string
  buttonFunction?: any
}

const SingleEmpty:FC<SingleEmptyProps> = ({buttonText, buttonFunction, text}) => {
  return (
    <div className="flex flex-col gap-6 items-center justify-center col-span-10">
      <h1 className="text-dark-gray text-center">
        {text}
      </h1>
      <Button
        loading={false}
        inverted={false}
        disabled={false}
        text={buttonText}
        type="button"
        withIcon={false}
        spacialClass="white-btn"
        clickFunction={() => buttonFunction()}
      />
    </div>
  )
}

export default SingleEmpty
