import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link, useMatch, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import {
  getRecords,
  problemsSelectors,
  problemsState
} from '../../../app/slices/ProblemsSlice'
import { viewsState } from '../../../app/slices/viewsSlice'
import CogLoader from '../../utils/shared/CogLoader'
import ShortUuid from '../../utils/shared/ShortUuid'
import ViewsDropdown from '../Problems/filtering/ViewsDropdown'

const RecordSidebar = () => {
  const recordLink: any = useMatch('/issues/:record')
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const results = useAppSelector(problemsSelectors.selectAll)

  useEffect(() => {
    dispatch(getRecords({ customer_id: user.customer_id }))
  }, [])

  return (
    <div className="">
      <div className="flex  justify-between mb-4">
        <Link
          className=" text-tuto-primary font-bold text-2xl flex"
          to="/inbox"
        >
          <ChevronLeftIcon className="w-8 h-8 mr-2 stroke-icon" />
        </Link>
        <div className=" text-tuto-primary flex-grow font-bold text-2xl self-start clamp">
          Inbox
        </div>
        <div>{/* <ViewsDropdown /> */}</div>
      </div>
      <div className="flex flex-col max-h-80v overflow-scroll">
        {results ? (
          results.map((record: any) => (
            <Link
              key={record.record_id}
              to={`/issues/${record.record_id}`}
              className={`border-transparent ${
                record.record_id === recordLink.params.record
                  ? 'bg-tuto-light-blue bg-opacity-30'
                  : 'bg-transparent'
              }  font-bold hover:bg-tuto-light-blue  text-tuto-primary items-center px-12 py-2 mb-2 rounded-lg border-b-2 text-sm`}
            >
              <div className="flex items-center">
                <div className="flex-grow">
                  {record.visitor_name ? (
                    record.visitor_name
                  ) : (
                    <ShortUuid text={record.visitor_id} />
                  )}
                </div>
                {record.is_watched ? (
                  <div className="h-3 w-3 bg-transparent rounded-full ml-4"></div>
                ) : (
                  <div className="h-3 w-3 bg-tuto-purple rounded-full ml-4"></div>
                )}{' '}
              </div>
              <div className="font-light">
                {moment(new Date(record.created_at).toLocaleString()).format(
                  'DD/MM/YY HH:mm'
                )}
              </div>
            </Link>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center mt-52">
            <CogLoader />
          </div>
        )}
      </div>
    </div>
  )
}

export default RecordSidebar
