import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit'

import {
  GetRecordsByCustomerID,
  GetFilterOptions,
  FilterRecords
} from '../apis/problemsApi'
import { RootState } from '../store'

export interface problemsType {
  record_id: string
  created_at: string
  visitor_id: string
  user_id: string
  domain_id: string
  customer_id: string
  path?: string
  origin?: string
  is_watched?: boolean
}

export const problemsAdapter = createEntityAdapter<problemsType>({
  selectId: (problem) => problem.record_id,
  sortComparer: (a: any, b: any) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
})

export const problemsSelectors = problemsAdapter.getSelectors<RootState>(
  (state) => state.problems
)

export const { selectAll, selectById, selectEntities, selectTotal } =
  problemsSelectors

export const getRecords = createAsyncThunk(
  'records/getRecords',
  async ({ customer_id }: any) => {
    try {
      const res = await GetRecordsByCustomerID(customer_id)
      console.log(res)

      let totalVisibleRecords: any = []
      const visibleRecords = res.data.filteredByMonth

      for (let i = 0; i < visibleRecords.length; i++) {
        if (visibleRecords[i].visibleRecords.length > 0) {
          totalVisibleRecords = totalVisibleRecords.concat(
            visibleRecords[i].visibleRecords.reverse()
          )
        }
      }
      console.log('totalVisibleRecords', totalVisibleRecords)
      return {
        totalVisibleRecords,
        totalArchived: res.data.totalArchived,
        totalBlocked: res.data.totalBlocked
      }
    } catch (err: any) {
      return err.message
    }
  }
)
export const getFilterOptions = createAsyncThunk(
  'records/getFilterOptions',
  async ({ customer_id }: any) => {
    try {
      const res = await GetFilterOptions(customer_id)

      const customData = res.data.CustomData
      const uniqeValuesSet = new Set(customData.map((item: any) => item.key))
      const uniqeValuesArr = Array.from(uniqeValuesSet).map((value) => {
        return { value, title: value, type: value }
      })
      res.data.customDataKeys = uniqeValuesArr

      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const filterRecords = createAsyncThunk(
  'records/filterRecords',
  async ({ customer_id, filterRecordsReq, include_sessions }: any) => {
    try {
      const res = await FilterRecords(
        customer_id,
        filterRecordsReq,
        include_sessions
      )

      return res.data.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
    } catch (err: any) {
      return err.message
    }
  }
)

const problemsSlice = createSlice({
  name: 'issues',
  initialState: problemsAdapter.getInitialState({
    loading: false,
    loadingFilters: false,
    error: '',
    totalRecordsArchived: 0,
    totalRecordsBlocked: 0,
    recordsArray: <any>[],
    noResults: false,
    filterDone: false,
    filteredRecords: [],
    filters: <any>{
      OS: <any>[],
      Browser: <any>[],
      VisitorName: <any>[],
      VisitorEmail: <any>[],
      ScreenSize: <any>[],
      RecordIds: <any>[],
      Origins: <any>[],
      IsWithAudio: [
        { name: 'On', checked: false },
        { name: 'Off', checked: false }
      ],
      UserIds: <any>[],
      CustomData: <any>[],
      customDataCategories: <any>[],
      Network: <any>[
        { name: 'GET', method: 'GET', checked: false },
        { name: 'POST', method: 'POST', checked: false }
      ],
      Console: <any>[
        { name: 'log', checked: false },
        { name: 'info', checked: false },
        { name: 'warn', checked: false },
        { name: 'error', checked: false }
      ]
    },
    filterRecordsReq: <any>{
      ScreenSize: <any>[],
      OS: <any>[],
      Browser: <any>[],
      VisitorName: <any>[],
      VisitorEmail: <any>[],
      CustomData: <any>[],
      RecordIds: <any>[],
      Origins: <any>[],
      IsWithAudio: <null | boolean>null,
      UserIds: <any>[],
      Network: <any>[],
      Console: <any>[]
    }
  }),
  reducers: {
    setIsWatched(state, action) {
      problemsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { is_watched: action.payload.is_watched }
      })
    },
    setFilterDone(state, action) {
      state.filterDone = action.payload
    },
    setNoResults(state, action) {
      state.noResults = action.payload
    },
    setRecordsArray(state, action) {
      state.recordsArray = [...action.payload]
    },
    setReqestFiltersReqObj(state, action) {
      state.filterRecordsReq = action.payload
      // console.log('state.filterRecordsReq', state.filterRecordsReq)
      problemsSlice.reducer(state, prefilChekedValues())
    },
    resetFilters(state) {
      for (const [key, value] of Object.entries(state.filterRecordsReq)) {
        if (key !== 'Origins' && key !== 'IsWithAudio') {
          state.filterRecordsReq[key] = []
        }
        if (key === 'IsWithAudio') {
          state.filterRecordsReq[key] = null
        }
      }

      for (const [key, value] of Object.entries(state.filters)) {
        state.filters[key] = [
          ...state.filters[key].map((element: any) => {
            if (key === 'customDataCategories') {
              return element
            }
            if (key === 'Origins') {
              return { ...element, checked: true }
            }
            if (key === 'CustomData') {
              return {
                ...element,
                value: {
                  name: element.value.name,
                  checked: false
                },
                checked: false
              }
            }
            return { ...element, checked: false }
          })
        ]
      }

      state.filteredRecords = []
    },
    prefilChekedValues(state) {
      // console.log('entered prefil checked values')
      for (const [key, value] of Object.entries(state.filterRecordsReq)) {
        if (key === 'IsWithAudio') {
          if (state.filterRecordsReq.IsWithAudio) {
            state.filters.IsWithAudio = [
              ...state.filters.IsWithAudio.map((element: any) => {
                if (element.name === 'On') {
                  // console.log('element.name === on')
                  return { ...element, checked: true }
                } else {
                  return element
                }
              })
            ]
          } else if (state.filterRecordsReq.IsWithAudio === false) {
            state.filters.IsWithAudio = [
              ...state.filters.IsWithAudio.map((element: any) => {
                if (element.name === 'Off') {
                  return { ...element, checked: true }
                } else {
                  return element
                }
              })
            ]
          } else {
            state.filters.IsWithAudio.map((element: any) => {
              return { ...element, checked: false }
            })
          }
        } else {
          if (state.filterRecordsReq[key].length > 0) {
            if (key === 'ScreenSize') {
              state.filters.ScreenSize = [
                ...state.filters.ScreenSize.map((filter: any) => {
                  if (
                    state.filterRecordsReq.ScreenSize.some(
                      (filterReq: any) =>
                        filterReq.screenHeight === filter.screenHeight &&
                        filterReq.screenWidth === filter.screenWidth
                    )
                  ) {
                    return { ...filter, checked: true }
                  } else {
                    return filter
                  }
                })
              ]
            }
            if (key === 'CustomData') {
              state.filters.CustomData = [
                ...state.filters.CustomData.map((filter: any) => {
                  if (
                    state.filterRecordsReq.CustomData.some(
                      (filterReq: any) =>
                        filterReq.key === filter.type &&
                        filterReq.value === filter.value.name
                    )
                  ) {
                    // console.log(filter.type, filter.value.name)
                    return {
                      ...filter,
                      value: {
                        name: filter.value.name,
                        checked: true
                      },
                      checked: true
                    }
                  } else {
                    return filter
                  }
                })
              ]
            }
          }

          state.filters[key] = [
            ...state.filters[key].map((filter: any) => {
              if (state.filterRecordsReq[key].includes(filter.name)) {
                return { ...filter, checked: true }
              } else {
                return filter
              }
            })
          ]
        }
      }
    },
    setOrigins(state, action: PayloadAction<any>) {
      if (action.payload.type === 'all') {
        state.filters.Origins = [
          ...action.payload.domains.map((element: any) => ({
            name: element.origin,
            checked: true
          }))
        ]
        state.filterRecordsReq.Origins = [
          ...action.payload.domains.map((element: any) => element.origin)
        ]
      }
      if (action.payload.type === 'view') {
        state.filters.Origins = [
          ...state.filters.Origins.map((element: any) => {
            if (action.payload.domains.includes(element.name)) {
              return {
                name: element.name,
                checked: true
              }
            } else {
              return {
                name: element.name,
                checked: false
              }
            }
          })
        ]
        state.filterRecordsReq.Origins = [
          ...action.payload.domains.map((element: any) => element)
        ]
      }
    },
    setReqestFilters(state, action: PayloadAction<any>) {
      if (action.payload.filter === 'ScreenSize') {
        const foundElement = state.filters.ScreenSize.findIndex(
          (element: any) => {
            return (
              element.screenWidth ===
                action.payload.valueAsObject.screenWidth &&
              element.screenHeight === action.payload.valueAsObject.screenHeight
            )
          }
        )

        state.filters.ScreenSize[foundElement].checked = true

        state.filterRecordsReq.ScreenSize = [
          ...state.filterRecordsReq.ScreenSize,
          action.payload.valueAsObject
        ]
      }
      if (action.payload.filter === 'IsWithAudio') {
        const foundElement = state.filters.IsWithAudio.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )

        state.filters.IsWithAudio[foundElement].checked = true

        const bothChecked = state.filters.IsWithAudio.every((element: any) => {
          return element.checked === true
        })

        if (bothChecked) {
          state.filterRecordsReq.IsWithAudio = null
        } else if (
          state.filters.IsWithAudio[0].checked &&
          !state.filters.IsWithAudio[1].checked
        ) {
          state.filterRecordsReq.IsWithAudio = true
        } else if (
          state.filters.IsWithAudio[1].checked &&
          !state.filters.IsWithAudio[0].checked
        ) {
          state.filterRecordsReq.IsWithAudio = false
        }
      }
      if (action.payload.filter === 'OS') {
        const foundElement = state.filters.OS.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        state.filters.OS[foundElement].checked = true

        state.filterRecordsReq.OS = [
          ...state.filterRecordsReq.OS,
          action.payload.value
        ]
      }
      if (action.payload.filter === 'Browser') {
        const foundElement = state.filters.Browser.findIndex((element: any) => {
          return element.name === action.payload.value
        })
        state.filters.Browser[foundElement].checked = true

        state.filterRecordsReq.Browser = [
          ...state.filterRecordsReq.Browser,
          action.payload.value
        ]
      }

      if (action.payload.filter === 'VisitorName') {
        const foundElement = state.filters.VisitorName.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )
        state.filters.VisitorName[foundElement].checked = true

        state.filterRecordsReq.VisitorName = [
          ...state.filterRecordsReq.VisitorName,
          action.payload.value
        ]
      }

      if (action.payload.filter === 'VisitorEmail') {
        const foundElement = state.filters.VisitorEmail.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )
        state.filters.VisitorEmail[foundElement].checked = true

        state.filterRecordsReq.VisitorEmail = [
          ...state.filterRecordsReq.VisitorEmail,
          action.payload.value
        ]
      }

      if (action.payload.filter === 'CustomData') {
        const foundElement = state.filters.CustomData.findIndex(
          (element: any) => {
            return element.value.name === action.payload.valueAsObject.name
          }
        )
        // console.log(foundElement)
        state.filters.CustomData[foundElement].checked = true
        state.filters.CustomData[foundElement].value.checked = true

        state.filterRecordsReq.CustomData = [
          ...state.filterRecordsReq.CustomData,
          { key: action.payload.key, value: action.payload.value }
        ]
      }

      if (action.payload.filter === 'Investigate') {
        let foundElement = state.filters.Console.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        if (foundElement === -1) {
          foundElement = state.filters.Network.findIndex((element: any) => {
            return element.name === action.payload.value
          })

          state.filters.Network[foundElement].checked = true
          state.filterRecordsReq.Network = [
            ...state.filterRecordsReq.Network,
            { method: action.payload.value }
          ]
        } else {
          state.filters.Console[foundElement].checked = true
          state.filterRecordsReq.Console = [
            ...state.filterRecordsReq.Console,
            action.payload.value
          ]
        }
      }
      if (action.payload.filter === 'Domains') {
        let foundElement = state.filters.Origins.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        state.filters.Origins[foundElement].checked = true
        state.filterRecordsReq.Origins = [
          ...state.filterRecordsReq.Origins,
          action.payload.value
        ]
      }
    },
    removeRequestFilter(state, action: PayloadAction<any>) {
      if (action.payload.filter === 'ScreenSize') {
        const foundElement = state.filters.ScreenSize.findIndex(
          (element: any) => {
            return (
              element.screenWidth ===
                action.payload.valueAsObject.screenWidth &&
              element.screenHeight === action.payload.valueAsObject.screenHeight
            )
          }
        )

        state.filters.ScreenSize[foundElement].checked = false

        state.filterRecordsReq.ScreenSize = [
          ...state.filterRecordsReq.ScreenSize.filter(
            (filter: any) =>
              filter.screenWidth !==
                Number(action.payload.value.split(' ')[0]) &&
              filter.screenHeigth !== Number(action.payload.value.split(' ')[2])
          )
        ]
      }
      if (action.payload.filter === 'IsWithAudio') {
        const foundElement = state.filters.IsWithAudio.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )

        state.filters.IsWithAudio[foundElement].checked = false

        const bothUnChecked = state.filters.IsWithAudio.every(
          (element: any) => {
            return element.checked === false
          }
        )

        if (bothUnChecked) {
          state.filterRecordsReq.IsWithAudio = null
        } else if (
          state.filters.IsWithAudio[0].checked &&
          !state.filters.IsWithAudio[1].checked
        ) {
          state.filterRecordsReq.IsWithAudio = true
        } else if (
          state.filters.IsWithAudio[1].checked &&
          !state.filters.IsWithAudio[0].checked
        ) {
          state.filterRecordsReq.IsWithAudio = false
        }
      }
      if (action.payload.filter === 'OS') {
        const foundElement = state.filters.OS.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        state.filters.OS[foundElement].checked = false

        state.filterRecordsReq.OS = [
          ...state.filterRecordsReq.OS.filter(
            (filter: any) => filter !== action.payload.value
          )
        ]
      }
      if (action.payload.filter === 'Browser') {
        const foundElement = state.filters.Browser.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        state.filters.Browser[foundElement].checked = false

        state.filterRecordsReq.Browser = [
          ...state.filterRecordsReq.Browser.filter(
            (filter: any) => filter !== action.payload.value
          )
        ]
      }

      if (action.payload.filter === 'VisitorName') {
        const foundElement = state.filters.VisitorName.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )

        state.filters.VisitorName[foundElement].checked = false

        state.filterRecordsReq.VisitorName = [
          ...state.filterRecordsReq.VisitorName.filter(
            (filter: any) => filter !== action.payload.value
          )
        ]
      }

      if (action.payload.filter === 'VisitorEmail') {
        const foundElement = state.filters.VisitorEmail.findIndex(
          (element: any) => {
            return element.name === action.payload.value
          }
        )

        state.filters.VisitorEmail[foundElement].checked = false

        state.filterRecordsReq.VisitorEmail = [
          ...state.filterRecordsReq.VisitorEmail.filter(
            (filter: any) => filter !== action.payload.value
          )
        ]
      }

      if (action.payload.filter === 'CustomData') {
        const foundElement = state.filters.CustomData.findIndex(
          (element: any) => {
            return element.value.name === action.payload.valueAsObject.name
          }
        )
        state.filters.CustomData[foundElement].checked = false
        state.filters.CustomData[foundElement].value.checked = false

        state.filterRecordsReq.CustomData = [
          ...state.filterRecordsReq.CustomData.filter(
            (filter: any) => filter.value !== action.payload.value
          )
        ]
      }
      if (action.payload.filter === 'Investigate') {
        let foundElement = state.filters.Console.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        if (foundElement === -1) {
          foundElement = state.filters.Network.findIndex((element: any) => {
            return element.name === action.payload.value
          })

          state.filters.Network[foundElement].checked = false
          state.filterRecordsReq.Network = [
            ...state.filterRecordsReq.Network.filter(
              (element: any) => element.method !== action.payload.value
            )
          ]
        } else {
          state.filters.Console[foundElement].checked = false
          state.filterRecordsReq.Console = [
            ...state.filterRecordsReq.Console.filter(
              (element: any) => element !== action.payload.value
            )
          ]
        }
      }
      if (action.payload.filter === 'Domains') {
        let foundElement = state.filters.Origins.findIndex((element: any) => {
          return element.name === action.payload.value
        })

        state.filters.Origins[foundElement].checked = false
        state.filterRecordsReq.Origins = [
          ...state.filterRecordsReq.Origins.filter(
            (element: any) => element !== action.payload.value
          )
        ]
      }
    },
    chooseAllFilters(state, action: PayloadAction<any>) {
      if (action.payload.filter === 'Browser') {
        state.filters.Browser = state.filters.Browser.map((element: any) => ({
          ...element,
          checked: action.payload.checked
        }))
        if (action.payload.checked) {
          state.filterRecordsReq.Browser = [
            ...state.filters.Browser.map((element: any) => element.name)
          ]
        } else {
          state.filterRecordsReq.Browser = []
        }
      }
      if (action.payload.filter === 'IsWithAudio') {
        state.filters.IsWithAudio = state.filters.IsWithAudio.map(
          (element: any) => ({
            ...element,
            checked: action.payload.checked
          })
        )

        state.filterRecordsReq.IsWithAudio = null
      }
      if (action.payload.filter === 'OS') {
        state.filters.OS = state.filters.OS.map((element: any) => ({
          ...element,
          checked: action.payload.checked
        }))

        if (action.payload.checked) {
          state.filterRecordsReq.OS = [
            ...state.filters.OS.map((element: any) => element.name)
          ]
        } else {
          state.filterRecordsReq.OS = []
        }
      }
      if (action.payload.filter === 'VisitorName') {
        state.filters.VisitorName = state.filters.VisitorName.map(
          (element: any) => ({ ...element, checked: action.payload.checked })
        )

        if (action.payload.checked) {
          state.filterRecordsReq.VisitorName = [
            ...state.filters.VisitorName.map((element: any) => element.name)
          ]
        } else {
          state.filterRecordsReq.VisitorName = []
        }
      }
      if (action.payload.filter === 'VisitorEmail') {
        state.filters.VisitorEmail = state.filters.VisitorEmail.map(
          (element: any) => ({ ...element, checked: action.payload.checked })
        )

        if (action.payload.checked) {
          state.filterRecordsReq.VisitorEmail = [
            ...state.filters.VisitorEmail.map((element: any) => element.name)
          ]
        } else {
          state.filterRecordsReq.VisitorEmail = []
        }
      }
      if (action.payload.filter === 'ScreenSize') {
        state.filters.ScreenSize = state.filters.ScreenSize.map(
          (element: any) => ({ ...element, checked: action.payload.checked })
        )

        if (action.payload.checked) {
          state.filterRecordsReq.ScreenSize = [...state.filters.ScreenSize]
        } else {
          state.filterRecordsReq.ScreenSize = []
        }
      }
      if (action.payload.filter === 'CustomData') {
        state.filters.CustomData = state.filters.CustomData.map(
          (element: any) => {
            if (element.type === action.payload.type) {
              return {
                ...element,
                value: {
                  name: element.value.name,
                  checked: action.payload.checked
                },
                checked: action.payload.checked
                // value:element.value.name,
              }
            } else {
              return element
            }
          }
        )

        if (action.payload.checked) {
          state.filterRecordsReq.CustomData = [
            ...state.filterRecordsReq.CustomData.concat(
              state.filters.CustomData.filter(
                (element: any) => element.type === action.payload.type
              ).map((element: any) => ({
                key: action.payload.key,
                value: element.value.name
              }))
            )
          ]
        } else {
          state.filterRecordsReq.CustomData = [
            ...state.filterRecordsReq.CustomData.filter(
              (element: any) => element.key !== action.payload.type
            )
          ]
        }
      }
      if (action.payload.filter === 'Investigate') {
        state.filters.Console = state.filters.Console.map((element: any) => ({
          ...element,
          checked: action.payload.checked
        }))
        state.filters.Network = state.filters.Network.map((element: any) => ({
          ...element,
          checked: action.payload.checked
        }))

        if (action.payload.checked) {
          state.filterRecordsReq.Console = [
            ...state.filters.Console.map((element: any) => element.name)
          ]
          state.filterRecordsReq.Network = [
            ...state.filters.Network.map((element: any) => ({
              method: element.method
            }))
          ]
        } else {
          state.filterRecordsReq.Console = []
          state.filterRecordsReq.Network = []
        }
      }
      if (action.payload.filter === 'Domains') {
        state.filters.Origins = state.filters.Origins.map((element: any) => ({
          ...element,
          checked: action.payload.checked
        }))

        if (action.payload.checked) {
          state.filterRecordsReq.Origins = [
            ...state.filters.Origins.map((element: any) => element.name)
          ]
        } else {
          state.filterRecordsReq.Origins = []
        }
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRecords.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getRecords.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.totalRecordsBlocked = action.payload.totalBlocked
        state.totalRecordsArchived = action.payload.totalArchived
        problemsAdapter.setAll(state, action.payload.totalVisibleRecords)
      })
      .addCase(getRecords.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getFilterOptions.pending, (state, action) => {
        // state.loadingFilters = true
      })
      .addCase(
        getFilterOptions.rejected,
        (state, action: PayloadAction<any>) => {
          // state.loadingFilters = false
          state.error = action.payload
        }
      )
      .addCase(
        getFilterOptions.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.filters.ScreenSize = action.payload.ScreenSize.map(
            (el: any) => ({
              ...el,
              checked: false
            })
          )
          state.filters.OS = action.payload.OS.map((el: any) => ({
            name: el,
            checked: false
          }))
          state.filters.Browser = action.payload.Browser.map((el: any) => ({
            name: el,
            checked: false
          }))
          state.filters.VisitorName = action.payload.VisitorName.map(
            (el: any) => ({
              name: el,
              checked: false
            })
          )
          state.filters.VisitorEmail = action.payload.VisitorEmail.map(
            (el: any) => ({
              name: el,
              checked: false
            })
          )

          state.filters.customDataCategories = action.payload.customDataKeys
          state.filters.CustomData = action.payload.CustomData.map(
            (customData: any) => {
              return {
                type: customData.key,
                title: customData.key,
                value: { name: customData.value.toString(), checked: false },
                checked: false
              }
            }
          )
          // state.lodaingFilters = false
          problemsSlice.reducer(state, prefilChekedValues())
        }
      )
      .addCase(filterRecords.pending, (state, action) => {
        state.loadingFilters = true
        state.filteredRecords = []
      })
      .addCase(filterRecords.fulfilled, (state, action: PayloadAction<any>) => {
        state.loadingFilters = false
        state.filteredRecords = action.payload
        // state.totalRecordsArchived = action.payload.totalArchived
        // problemsAdapter.setAll(state, action.payload.totalVisibleRecords)
      })
      .addCase(filterRecords.rejected, (state, action: PayloadAction<any>) => {
        state.loadingFilters = false
        state.error = action.payload
      })
  }
})

export const problemsState = (state: RootState) => state.problems
export const {
  setReqestFilters,
  removeRequestFilter,
  chooseAllFilters,
  setOrigins,
  resetFilters,
  prefilChekedValues,
  setReqestFiltersReqObj,
  setFilterDone,
  setNoResults,
  setRecordsArray,
  setIsWatched
} = problemsSlice.actions
export default problemsSlice.reducer
