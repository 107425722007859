import React, { FC, useEffect, useState } from 'react'

// Define the type for a single tab
interface Tab {
  title: string
  icon: JSX.Element // This assumes your icon is some form of JSX, such as from react-icons
  content: JSX.Element // The content of the tab, which can be any React component
}

interface TabsProps {
  tabs: Tab[]
}

const Tabs: FC<TabsProps> = ({ tabs }) => {
  const searchParams = new URLSearchParams(window.location.search)
  const initialActiveTab = searchParams.get('activeTab')
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if(initialActiveTab) {
      setActiveTab(Number(initialActiveTab))
    }
  }, [])

  return (
    <div className='w-full'>
      <div className="flex border-b">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`flex items-center py-2 px-4 gap-2 text-lg relative bg-white    ${activeTab === index ? 'border-t border-l border-r rounded-t-md border-b-white tab-down font-semibold' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.icon}
            {tab.title}
          </button>
        ))}
      </div>
      <div className="pt-12">{tabs[activeTab].content}</div>
    </div>
  )
}

export default Tabs
