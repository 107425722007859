import React, { FC } from 'react'

interface StickyLeftProps {
  children: React.ReactNode
  gridCols:number
}

const StickyLeft: FC<StickyLeftProps> = ({ children, gridCols }) => {
  return (
    <div className={`col-span-4 sticky top-20 h-85v p-4 flex gap-4 flex-col`}>
      {children}
    </div>
  )
}

export default StickyLeft
