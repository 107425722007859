import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import {
  getCustomerDomains,
  customerDomainsSelectors
} from '../../../app/slices/customerDomainsSlice'
import {
  getFilterOptions,
  getRecords,
  problemsSelectors,
  setOrigins
} from '../../../app/slices/ProblemsSlice'
import { viewsState } from '../../../app/slices/viewsSlice'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import ProblemsTable from '../../utils/tables/ProblemsTable'

const Problems = () => {
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()

  const records = useAppSelector(problemsSelectors.selectAll)
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const { currentView } = useAppSelector(viewsState)

  useEffect(() => {
    if (user) {
      Mixpanel.track('Records Page Viewed', {})
      Mixpanel.register({
        Page: 'Records',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      dispatch(getCustomerDomains({ customer_id: user.customer_id }))
      dispatch(getRecords({ customer_id: user.customer_id }))
      dispatch(getFilterOptions({ customer_id: user.customer_id }))
    }
  }, [user])

  useEffect(() => {
    if (domains.length > 0) {
      dispatch(setOrigins({ domains, type: 'all' }))
    }
    if (currentView.id) {
      const domainsOfView = JSON.parse(currentView.query_json).Origins
      dispatch(setOrigins({ domains:domainsOfView, type: 'view' }))
    }
  }, [domains])

  return (
    <div className="flex relative flex-grow justufy-center">
      <div className="p-4 pr-6 mb-20 flex-grow">
        <div className="flex">
          {records.length > 0 ? (
            <div className="w-full">
              <ProblemsTable />
            </div>
          ) : (
            <div className="p-6 flex items-center justify-center border rounded-lg shadow-sm">
              <ExclamationCircleIcon className="h-10 w-10 text-blue-500 mr-4" />
              <div>There is no records in the system yet</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Problems
