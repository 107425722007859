import React, { FC, Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../../utils/elements/Button'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { closeTiersPopup } from '../../../app/slices/uiSlice'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { authState, updateUserData } from '../../../app/slices/authSlice'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { ArrowRightIcon } from '@heroicons/react/20/solid'

interface InvitationPopupProps {
  open: boolean
  setOpen: Function
}

const InvitationPopup:FC<InvitationPopupProps> = ({open, setOpen}) => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector(authState)

  const [loadingChange, setLoadingChange] = useState(false)
  const [name, setName] = useState('')

  const [errors, setErrors] = useState({
    name: ''
  })
  const [validations, setValidations] = useState({
    name: false
  })

  const nameSubmitHandler = async (event: any) => {
    event.preventDefault()
    if (validations.name) {
      setLoadingChange(true)
      Mixpanel.track('User Name Filled', {
        name: name,
        'invitationpopup': 2
      })
      await dispatch(
        updateUserData({
          data: name,
          userId: user.id,
          type: 'name',
          customerId: user.customer_id
        })
      )
      const welcomeMail = await unprotectedPostRequest(
        'users/sendWelcomeEmail',
        {
          email: user.email,
          isFromInvite: true
        }
      )
      setOpen(false)
      event.target.value = ''
    }
  }

  const handleFirstNameInputChange = (event: any) => {
    setName(event.target.value)
    if (event.target.value.length < 2) {
      setErrors((errors) => ({
        ...errors,
        name: 'Name must be at least 2 characters'
      }))
      setValidations((validations) => ({
        ...validations,
        name: false
      }))
    } else {
      setErrors((errors) => ({
        ...errors,
        name: ''
      }))
      setValidations((validations) => ({
        ...validations,
        name: true
      }))
    }
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => setOpen(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 sm:px-12 sm:py-16`}
              >
                <div className="purple-home"></div>
                <div className="yellow-home"></div>
                <div className="flex items-center justify-center relative">
                  <div className="max-w-lg pr-4 flex flex-col justify-between">
                    <div className="flex-grow">
                      <div className="flex flex-col justify-between items-stretch">
                        <Dialog.Title
                          as="h3"
                          className="text-4xl text-center font-bold mb-2 text-tuto-primary"
                        >
                          Welcome!
                        </Dialog.Title>

                        <div className="mt-10 flex flex-col items-center justify-center">
                          <form
                          id="invitation_popup"
                            className="flex flex-col items-center"
                            onSubmit={nameSubmitHandler}
                          >
                            <div className="relative mb-12">
                              <input
                                name="name"
                                value={name}
                                onChange={handleFirstNameInputChange}
                                className="p-4 text-center font-light bg-transparent text-4xl border-b border-gray-400 focus:outline-none"
                                placeholder="What is your name?"
                              />
                              <span className="text-red-400 absolute -bottom-6 right-0 left-0 text-center">
                                {errors.name && errors.name}
                              </span>
                            </div>
                            <Button
                              inverted={false}
                              loading={loadingChange}
                              disabled={false}
                              text="Start"
                              type="submit"
                              withIcon={false}
                              Icon={ArrowRightIcon}
                              spacialClass=""
                              // clickFunction={() => setCurrentForm(2)}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default InvitationPopup
