import { FC, useState } from 'react'
import Layout from '../../utils/layout/Layout'
import RightInfoSection from '../Investigations/RightInfoSection'
import StickyRight from '../Investigations/StickyRight'
import StickyLeft from '../Investigations/StickeyLeft'
import MainView from '../Investigations/MainView'
import Transcript from './Transcript'
import SinglePageGrid from '../Investigations/SinglePageGrid'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentSession } from '../../../app/slices/investigationsSlice'
import { problemsState } from '../../../app/slices/ProblemsSlice'
import RecordSidebar from './RecordSidebar'
import MainRecordView from './MainRecordView'
import SlideOver from '../Knowledge/Knowledge'
import JsonSearch from '../Investigations/JsonSearch'

const SingleRecord = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const { loadingFilters } = useAppSelector(problemsState)
  const [networkInfoOpen, setNetworkInfoOpen] = useState(false)
  const [networkData, setNetworkData] = useState({})
  const [additionalData, setAdditionalData] = useState<any>({
    data: {},
    oneai: null,
    transcription: '',
    additionalInfo: {},
    tags: [],
    backendLogs: [],
    windowObject: '',
    feedEvents: []
  })

  useEffect(() => {
    return () => {
      setAdditionalData({
        data: {},
        oneai: null,
        transcription: '',
        additionalInfo: {},
        tags: [],
        backendLogs: [],
        windowObject: '',
        feedEvents: []
      })
    }
  }, [])

  useEffect(() => {
    if (params.investigationId && params.sessionId) {
      dispatch(setCurrentSession({ id: params.sessionId }))
    }
  }, [params])

  return (
    <Layout withCircles={false}>
      <SinglePageGrid gridCols={12}>
        <StickyRight gridCols={2}>
          <RecordSidebar />
        </StickyRight>

        <MainView gridCols={6}>
          <MainRecordView setAdditionalData={setAdditionalData} />
          <Transcript
            oneAi={additionalData.oneai}
            transcript={additionalData.transcription}
          />
        </MainView>

        <StickyLeft gridCols={4}>
          <RightInfoSection additionalData={additionalData} setNetworkData={setNetworkData} openNetwork={() => setNetworkInfoOpen(true)}/>
        </StickyLeft>
      </SinglePageGrid>
      
      
      <SlideOver  
      title={`Network Info`}
        isOpen={networkInfoOpen}
        setSlideOpen={setNetworkInfoOpen}
        >
          <JsonSearch data={networkData} />
      </SlideOver>
      
    </Layout>
  )
}

export default SingleRecord
