import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CreateOrUpdateContent from './CreateOrUpdateContent'
import DeleteContent from './DeleteContent'

interface ViewsPopupProps {
  open: boolean
  setOpen: Function
  type: string
}

const ViewsPopup: React.FC<ViewsPopupProps> = ({ setOpen, open, type }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        {type !== 'delete' ? (
          <CreateOrUpdateContent setOpen={setOpen} type={type} />
        ) : (
          <DeleteContent setOpen={setOpen} />
        )}
      </Dialog>
    </Transition.Root>
  )
}

export default ViewsPopup
