import React, { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useAppSelector } from '../../../../../app/hooks'
import { uiState } from '../../../../../app/slices/uiSlice'

interface FeaturesIncludedProps {
  tier_id: string
}
const FeaturesIncluded: React.FC<FeaturesIncludedProps> = ({ tier_id }) => {
  const { tiers } = useAppSelector(uiState)
  const [ features, setFeatures] = useState<any>(null)

  useEffect(() => {
    console.log('component mounted')
    console.log(tiers)
    if(tiers) {

      const tierFeatures = tiers.find((tier) => tier.id == tier_id)
      if(tierFeatures) {
        setFeatures(tierFeatures)
      }
    }

  }, [tiers])
  return ( features &&
    <ul role="list" className="my-12 space-y-4 text-md">
      <li className="flex space-x-3">
        <CheckIcon
          className="h-5 w-5 flex-shrink-0 text-green-500"
          aria-hidden="true"
        />
        <span className=" text-gray-500">
          {features.max_seats} seats
        </span>
      </li>
      <li className="flex space-x-3">
        <CheckIcon
          className="h-5 w-5 flex-shrink-0 text-green-500"
          aria-hidden="true"
        />
        <span className=" text-gray-500">
          {features.max_records_per_months} Records per month
        </span>
      </li>
      <li className="flex space-x-3">
        <CheckIcon
          className="h-5 w-5 flex-shrink-0 text-green-500"
          aria-hidden="true"
        />
        <span className=" text-gray-500">
          {features.data_storage_limit_period} days Data storage limit
        </span>
      </li>
      {features.record_capture_enabled && (
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm text-gray-500">Record capture</span>
        </li>
      )}
      {features.record_ai_enabled && (
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className=" text-gray-500">Record AI</span>
        </li>
      )}
      {features.record_analytics_enabled && (
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className=" text-gray-500">Record Analytics</span>
        </li>
      )}
    </ul>
  )
}

export default FeaturesIncluded
