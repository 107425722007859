import { FC, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { EnvelopeIcon, TrashIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  deleteInvitation,
  sendInvitation,
  deleteUser
} from '../../../app/slices/customerUsersSlice'
import { authState } from '../../../app/slices/authSlice'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { v4 as uuidv4 } from 'uuid'
import { addNewNotification } from '../../../app/slices/notificationsSlice'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface TeammatesDropdownProps {
  email: string
  role: string
  invitation_id: string
  invitation: boolean
}

const TeammatesDropdown: FC<TeammatesDropdownProps> = ({
  email,
  role,
  invitation_id,
  invitation
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)

  const sendInvitationAgain = () => {
    dispatch(
      sendInvitation({
        customer_id: user.customer_id,
        role_id: role,
        teammates: [email],
        campaign_id: '799188',
        sender: user.email,
        senderName: user.name,
        inviter_id: user.id,
        name: '',
        is_re_invite: true,
        invitation_id
      })
    )
    dispatch(
      addNewNotification({
        text: 'Invitation Sent Again Successfully',
        id: uuidv4(),
        title: 'Invitation Sent!',
        type: 'SUCCESS',
        timer: true,
        button: false
      })
    )
  }

  const deleteExistingUser = async() => {
    dispatch(deleteUser({email, id:invitation_id}))
  }

  const deleteInvitationFromTable = async () => {
    const deleted = await unprotectedPostRequest(
      'users/updateInvitationStatus',
      {
        invitation_id,
        isCompleted: false
      }
    )

    if (deleted.success) {
      dispatch(deleteInvitation(invitation_id))
      dispatch(
        addNewNotification({
          text: 'Invitation Deleted Successfully',
          id: uuidv4(),
          title: 'Invitation Deleted',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    }
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700  hover:bg-gray-50 focus:outline-none">
          <EllipsisHorizontalIcon className="w-6 h-6" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {invitation && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={sendInvitationAgain}
                    className={classNames(
                      active ? 'bg-gray-100 ' : '',
                      'group flex items-center px-4 py-2 text-sm text-tuto-primary'
                    )}
                  >
                    <EnvelopeIcon
                      className="mr-3 h-5 w-5 text-tuto-primary"
                      aria-hidden="true"
                    />
                    Send Again
                  </div>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={invitation ? deleteInvitationFromTable : deleteExistingUser}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'group flex items-center px-4 py-2 text-sm text-record-red'
                  )}
                >
                  <TrashIcon className="mr-3 h-5 w-5 " aria-hidden="true" />
                  Delete {invitation ? 'invitation' : 'user'}
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default TeammatesDropdown
