// investigationParametersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { boolean } from 'yup/lib/locale'
import { RootState } from '../store'

export interface Parameter {
  group: string
  value: string
  displayName: string
  type: string
  hasDropdown: boolean
  hasTextField: boolean
  parent: string
  apiParams?: { param: string; table: string }
  customOptions?: { displayName: string; value: string }[]
}

export interface InvestigationParameter {
  id: string
  parameter: Parameter | null
  selectedCondition: string | null
  selectedValues: string | string[] | null | Object
}

interface InvestigationParametersState {
  parameters: InvestigationParameter[]
  lastParameterValid: boolean
  elasticObj: any
}

const initialState: InvestigationParametersState = {
  parameters: [
    {
      id: uuidv4(),
      parameter: null,
      selectedCondition: null,
      selectedValues: null
    }
  ],
  lastParameterValid: true,
  elasticObj: {}
}

const investigationParametersSlice = createSlice({
  name: 'investigationParameters',
  initialState,
  reducers: {
    addParameter: (state) => {
      state.parameters.push({
        id: uuidv4(),
        parameter: null,
        selectedCondition: null,
        selectedValues: null
      })
    },
    removeParameter: (state, action: PayloadAction<string>) => {
      if (state.parameters.length === 1) {
        state.parameters[0].parameter = null
        state.parameters[0].selectedCondition = null
        state.parameters[0].selectedValues = null
      } else {
        state.parameters = state.parameters.filter(
          (param) => param.id !== action.payload
        )
      }
    },
    setSelectedParameter: (
      state,
      action: PayloadAction<{ id: string; parameter: Parameter | null }>
    ) => {
      const { id, parameter } = action.payload
      const param = state.parameters.find((p) => p.id === id)
      if (param) {
        param.parameter = parameter
      }
    },
    setSelectedCondition: (
      state,
      action: PayloadAction<{ id: string; condition: string | null }>
    ) => {
      const { id, condition } = action.payload
      const param = state.parameters.find((p) => p.id === id)
      if (param) {
        param.selectedCondition = condition
      }
    },
    setSelectedValues: (
      state,
      action: PayloadAction<{ id: string; values: string | string[] | null }>
    ) => {
      const { id, values } = action.payload
      const param = state.parameters.find((p) => p.id === id)
      
      if (param) {
        param.selectedValues = values
      }
    },
    setLastParameterValid: (
      state,
      action: PayloadAction<{ isValid: boolean }>
    ) => {
      // console.log(action.payload.isValid)
      state.lastParameterValid = action.payload.isValid
    },
    setDefaultParameters: (
      state,
      action: PayloadAction<{ parameters: string }>
    ) => {
      const deafultParams = JSON.parse(action.payload.parameters)
      state.parameters = [...deafultParams]
    },
    resetParameters: (state) => {
      state.parameters = [
        {
          id: uuidv4(),
          parameter: null,
          selectedCondition: null,
          selectedValues: null
        }
      ]
    },
    mapForElastic: (state) => {
      const result: any = {
        match: {},
        not_match: {},
        contains: {},
        not_contain: {}
      }

      state.parameters.forEach((item: any) => {
        const { id, parameter, selectedCondition, selectedValues } = item

        if (!parameter) {
          return
        }

        const { value } = parameter

        const handleIsCondition = (value: string, selectedValues: any) => {
          console.log('selectedValues ***', selectedValues)
          if (value === 'is_with_audio') {
            if (selectedValues.includes('On')) {
              return true
            } else {
              return false
            }
          } else if (value === 'is_session') {
            if (selectedValues.includes('Record')) {
              return false
            } else {
              return true
            }
          } else if (selectedValues.length > 1) {
            return selectedValues
          } else {
            return selectedValues[0]
          }
        }
        switch (selectedCondition) {
          case 'is':
            result.match[value] = handleIsCondition(value, selectedValues)
            break
          case 'is not':
            result.match[value] = handleIsCondition(value, selectedValues)
            break
          case 'contains':
            result.contains[value] = selectedValues
            break
          case 'not contains':
            result.not_contain[value] = selectedValues
            break
          default:
            break
        }
      })

      state.elasticObj = result
    }
  }
})

export const {
  addParameter,
  removeParameter,
  setSelectedParameter,
  setSelectedCondition,
  setSelectedValues,
  setLastParameterValid,
  mapForElastic,
  setDefaultParameters,
  resetParameters
} = investigationParametersSlice.actions
export const investigationParametersState = (state: RootState) =>
  state.investigationParameters
export default investigationParametersSlice.reducer
