import React, { useEffect } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { problemsState } from '../../../../app/slices/ProblemsSlice'

interface FilterCategoryProps {
  title: string
  Icon?: any
  setActiveFilter: any
  type: string
  activeFilter: string
}

const FilterCategory: React.FC<FilterCategoryProps> = ({
  title,
  Icon,
  setActiveFilter,
  type,
  activeFilter
}) => {
  const { filterRecordsReq, filters, } = useAppSelector(problemsState)

  return (
    <div
      onClick={() => setActiveFilter(type)}
      className={`flex justify-between items-center text-white mb-2 ${
        activeFilter === type ? 'bg-tuto-light-blue bg-opacity-30' : ''
      } hover:bg-tuto-light-blue hover:bg-opacity-30 px-1 py-2 rounded-md font-light cursor-pointer`}
    >
      <div className="flex items-center">
        {Icon && <Icon className="h-5 w-5 mr-2" />}
        <span>{title}</span>
      </div>
      <div className="bg-tuto-light-blue px-1 rounded text-tuto-primary">
        {type === 'screen' && filterRecordsReq.ScreenSize.length > 0 ? filterRecordsReq.ScreenSize.length: 
        type === 'os' && filterRecordsReq.OS.length > 0 ? filterRecordsReq.OS.length:
        type === 'browser' && filterRecordsReq.Browser.length > 0 ? filterRecordsReq.Browser.length:
        type === 'user' && filterRecordsReq.VisitorName.length > 0 ? filterRecordsReq.VisitorName.length:
        type === 'email' && filterRecordsReq.VisitorEmail.length > 0 ? filterRecordsReq.VisitorEmail.length:
        type === 'mic' && filters.IsWithAudio.filter((element:any) => element.checked === true).length > 0 ? filters.IsWithAudio.filter((element:any) => element.checked === true).length: ''}
      </div>
    </div>
  )
}

export default FilterCategory
