import Notification from './Notification'
import { useSelector } from 'react-redux'
import { NotificationType, notificationsSelectors } from '../../../app/slices/notificationsSlice'

const NotificationsProvider = () => {
  const notifications = useSelector(notificationsSelectors.selectAll)

  const renderNotifications = () => {
    return notifications.map((notification: NotificationType) => (
      <Notification
        key={notification.id}
        id={notification.id}
        message={notification.text}
        title={notification.title}
        type={notification.type}
        withTimer={notification.timer}
        withButton={notification.button}
        buttonText={notification.buttonText}
        buttonFunction={notification.buttonFunction}
        withSecondButton={notification.secondButton}
        secondButtonText={notification.secondButtonText}
        secondButtonFunction={notification.secondButtonFunction}
      />
    ))
  }

  return (
    <div
      aria-live='assertive'
      className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30'
    >
      <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
        {renderNotifications()}
      </div>
    </div>
  )
}

export default NotificationsProvider
