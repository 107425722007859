import React, { FC } from 'react'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid'

interface IFormInputState {
  error: any
  touched?: any
  type: string
  label: string
  placeholder: string
  registerFunction?: any
  onChangeFunction?: any
  name: string
  validMessage?: string
  watch?: any
  customClass?: any
  isCustomClass?: boolean
  children?: React.ReactNode
  defaultValue?: string
  readOnly?: boolean
}

const FormInput: FC<IFormInputState> = ({
  error,
  touched,
  type,
  label,
  placeholder,
  registerFunction,
  name,
  validMessage,
  watch,
  customClass,
  isCustomClass,
  children,
  defaultValue,
  onChangeFunction,
  readOnly
}) => {
  return (
    <div className={isCustomClass ? customClass : 'mb-6 relative'}>
      <div
        className={`relative border border-gray-300 rounded-md px-3 py-2 mb-1 shadow-sm focus:boder-tuto-primary dark:bg-dark-secondary
            ${
              error
                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                : touched &&
                  !error &&
                  'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
            }`}
      >
        <label
          htmlFor="name"
          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
        >
          {label}
        </label>
        {onChangeFunction ? (
          <input
            defaultValue={defaultValue}
            type={type}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={onChangeFunction}
            className="block w-full border-0 p-0 text-gray-900  focus:ring-0 placeholder-gray-500 sm:text-sm dark:bg-dark-secondary dark:text-dark-text dark:placeholder-dark-text"
          />
        ) : (
          <input
            defaultValue={defaultValue}
            // value={defaultValue ? defaultValue : ''}
            readOnly={readOnly}
            type={type}
            placeholder={placeholder}
            {...registerFunction(name)}
            className="block w-full border-0 p-0 text-gray-900  focus:ring-0 placeholder-gray-500 sm:text-sm dark:bg-dark-secondary dark:text-dark-text dark:placeholder-dark-text"
          />
        )}
        {error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          touched &&
          !error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <CheckCircleIcon
                className="h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            </div>
          )
        )}
      </div>
      {error ? (
        <p className="absolute top-10  text-xs text-red-600" id="email-error">
          {error}
        </p>
      ) : (
        touched &&
        !error && (
          <p
            className="absolute top-10 text-xs text-green-600"
            id="email-error"
          >
            {validMessage}
          </p>
        )
      )}
    </div>
  )
}

export default FormInput
