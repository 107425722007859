import React, { useState, useEffect } from 'react'
import '../pages.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import {
  getRecords,
  problemsSelectors,
  problemsState
} from '../../../app/slices/ProblemsSlice'
import InboxTable from './InboxTable'
import SetupBanner from './SetupBanner'
import { getCustomerDomains } from '../../../app/slices/customerDomainsSlice'
import RecordRequest from './RecordRequest'
import OnboardingPopup from './OnBoardingPopup'
import { setOnboardingMode, uiState } from '../../../app/slices/uiSlice'
import { Link, useNavigate } from 'react-router-dom'
import Collapsible from './Collapsible' // Import the new component
import CogLoader from '../../utils/shared/CogLoader'
import {
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/solid'
import Button from '../../utils/elements/Button'

const Inbox = () => {
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()
  const results = useAppSelector(problemsSelectors.selectAll)
  const [showPopup, setShowPopup] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredResults, setFilteredResults] = useState(results)
  const [watchedFilteredResults, setWatchedFilteredResults] = useState<any>([])
  const [unwatchedFilteredResults, setUnwatchedFilteredResults] = useState<any>(
    []
  )
  const { onBoardingMode } = useAppSelector(uiState)
  const { loading } = useAppSelector(problemsState)
  const navigate = useNavigate()

  useEffect(() => {
    if (onBoardingMode) {
      localStorage.removeItem('Inbox')
      navigate('/onboarding')
    }
  }, [onBoardingMode])

  useEffect(() => {
    // Check if the page has been visited
    if (user.is_onboarding_mode) {
      // console.log('homepage changes it back to true')
      dispatch(setOnboardingMode(true))
    }
    const pageVisited = localStorage.getItem('Inbox')
    if (!pageVisited) {
      setShowPopup(true) // Show the popup if the page hasn't been visited
      localStorage.setItem('Inbox', 'visited') // Set the page as visited
    }
  }, [])

  useEffect(() => {
    dispatch(getRecords({ customer_id: user.customer_id }))
    dispatch(getCustomerDomains({ customer_id: user.customer_id }))
  }, [])

  useEffect(() => {
    console.log(results)
    const watched = results.filter((result: any) => result.is_watched)
    console.log(watched)
    const unwatched = results.filter((result: any) => !result.is_watched)
    console.log(unwatched)

    if (search !== '') {
      setWatchedFilteredResults(
        watched.filter(
          (result: any) =>
            result.visitor_name?.toLowerCase().includes(search.toLowerCase()) ||
            result.visitor_email?.toLowerCase().includes(search.toLowerCase())
        )
      )

      setUnwatchedFilteredResults(
        unwatched.filter(
          (result: any) =>
            result.visitor_name?.toLowerCase().includes(search.toLowerCase()) ||
            result.visitor_email?.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setWatchedFilteredResults(watched)
      setUnwatchedFilteredResults(unwatched)
    }
  }, [search, results])

  // Function to close the popup and mark the user as no longer new
  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <div>
      <SetupBanner />
      <div className="px-4 relative bg-white">
        <div className="grid grid-cols-8 mb-8 sticky top-16 py-4 bg-white z-50 right-0">
          <RecordRequest />
          <div className="flex gap-4 relative col-start-7 col-span-2 ">
            <input
              type="text"
              value={search}
              className="border border-gray-300 bg-gray-100 rounded px-4 pl-12 py-2 flex-grow  text-sm"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />
            <MagnifyingGlassIcon className="w-6 h-6 absolute top-3 left-2" />
            <button
              className="bg-gray-200 text-xl font-bold p-1 px-4 rounded border border-gray-300 hover:bg-tuto-primary hover:text-white transition-all"
              type="button"
              onClick={() => window.open('https://torecord.it/docs', 'blank')}
            >
              ?
            </button>
          </div>
        </div>

        {loading ? (
          <CogLoader />
        ) : (
          <div className="flex flex-col gap-8">
            <Collapsible
              stickyClass="top-36"
              title={`Unwatched (${unwatchedFilteredResults.length})`}
              defaultOpen={true}
            >
              <InboxTable location="Inbox" results={unwatchedFilteredResults} />
            </Collapsible>

            <Collapsible
              stickyClass="top-36"
              title={`Watched (${watchedFilteredResults.length})`}
              defaultOpen={true}
            >
              <InboxTable location="Inbox" results={watchedFilteredResults} />
            </Collapsible>
          </div>
        )}
      </div>

      {showPopup && (
        <OnboardingPopup
          videoSource="https://assets.torecord.it/InboxOnborading.mp4"
          buttonText="Show me my inbox"
          onClose={closePopup}
          title={`Hi ${user.name}`}
          text={
            <h1 className="font-semibold">
              WELCOME TO YOUR NEW{' '}
              <span className="text-tuto-light-blue">INBOX</span>{' '}
            </h1>
          }
        />
      )}
    </div>
  )
}

export default Inbox
