import React, { useEffect } from 'react'
import ParameterDropdown from './ParameterDropdown'
import Conditions from './Conditions'
import '../Investigations.css'
import Button from '../../../utils/elements/Button'
import { TrashIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { investigationParametersState, setSelectedCondition, setSelectedValues } from '../../../../app/slices/investigationParametersSlice'

interface ParameterBoxProps {
  id: string
  onRemove: (id: string) => void
  onUpdate: (id: string, parameter: any) => void
}

// ParameterBox component
const ParameterBox: React.FC<ParameterBoxProps> = ({
  id,
  onRemove,
  onUpdate,
}) => {
  const dispatch = useAppDispatch()
  const {parameters} = useAppSelector(investigationParametersState)
  const selectedParameter = parameters.find((param) => param.id === id)
  

  const handleChange = (parameter: any) => {
    dispatch(setSelectedValues({id:id ,values: null}))
    onUpdate(id, { id, parameter: parameter })
  }

  const handleSelectCondition = (condition: string) => {
    
    dispatch(setSelectedCondition({ id, condition }))
  }

  return (
    <div className="parameter-box">
      <div className='absolute right-3 z-10'>
        <Button
          inverted={true}
          loading={false}
          disabled={false}
          text={''}
          type="button"
          withIcon={true}
          Icon={TrashIcon}
          spacialClass="remove-btn"
          clickFunction={() => onRemove(id)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <ParameterDropdown
          id={selectedParameter?.id}
          onSelect={handleChange}
          
        />
        {selectedParameter?.parameter && (
          <Conditions
            onSelect={handleSelectCondition}
            selectedParameter={selectedParameter}
            parameterId={selectedParameter?.id}
          />
        )}
      </div>
    </div>
  )
}

export default ParameterBox
