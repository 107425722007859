import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/24/solid'
import '../Investigations.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, ChangeEvent, useEffect, useRef } from 'react'
import ParameterBox from './ParameterBox'
import Button from '../../../utils/elements/Button'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import {
  addParameter,
  removeParameter,
  setSelectedParameter,
  Parameter,
  investigationParametersState,
  setDefaultParameters,
  resetParameters
} from '../../../../app/slices/investigationParametersSlice'
import {
  createInvestigation,
  getInvestigationDetails,
  investigationElastic,
  investigationsState,
  resetElaticResults,
  setCurrentInvestigation,
  updateInvestigation
} from '../../../../app/slices/investigationsSlice'
import { authState } from '../../../../app/slices/authSlice'

const CreationSidebar = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { currentInvestigation, elasticResults } =
    useAppSelector(investigationsState)
  const dispatch = useAppDispatch()
  const { parameters, lastParameterValid } = useAppSelector(
    investigationParametersState
  )
  const { user } = useAppSelector(authState)
  const prevParametersLengthRef = useRef(parameters.length)

  const handleGoBack = () => {
    navigate(-1)
  }
  const [investigationName, setInvestigationName] = useState<string>('')
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const [error, setError] = useState('')
  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    if (params.investigationId) {
      getSingleInvestigation(params.investigationId)
      setFormValid(true)
      // dispatch(setDefaultParameters())
    } else {
      dispatch(
        investigationElastic({
          customer_id: user.customer_id,
          filters: parameters
        })
      )
    }

    return () => {
      dispatch(setCurrentInvestigation({}))
      dispatch(resetParameters())
      dispatch(resetElaticResults())
    }
  }, [])

  const handleNameChange = (event: any) => {
    setInvestigationName(event.target.value)
    if (event.target.value.length < 2) {
      setError('Investigation name must be at least 2 charachters')
      setFormValid(false)
    } else {
      setError('')
      setFormValid(true)
    }
  }

  useEffect(() => {
    if (currentInvestigation.id) {
      dispatch(
        setDefaultParameters({ parameters: currentInvestigation.query_json })
      )
      setInvestigationName(currentInvestigation.name)
    }
  }, [currentInvestigation])

  useEffect(() => {
    if (parameters.length < prevParametersLengthRef.current) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      // Add a new timeout to invoke the investigationElastic function after 3 seconds of inactivity
      const newTimeoutId = setTimeout(() => {
        console.log('parameters', parameters)
        dispatch(
          investigationElastic({
            customer_id: user.customer_id,
            filters: parameters
          })
        )
      }, 1000) as unknown as number

      // Update the timeoutId state
      setTimeoutId(newTimeoutId)
    }
    prevParametersLengthRef.current = parameters.length
  }, [parameters.length])

  const getSingleInvestigation = async (investigationId: string) => {
    const investigation = await dispatch(
      getInvestigationDetails({ investigation_id: investigationId })
    )
  }

  const handleAddParameter = () => {
    if (lastParameterValid) {
      dispatch(addParameter())
      // dispatch(setLastParameterValid({ isValid: false }))
    }
  }

  const handleRemoveParameter = (id: string) => {
    dispatch(removeParameter(id))
  }

  const updateParameter = (id: string, parameter: Parameter) => {
    dispatch(setSelectedParameter({ id, parameter }))
  }

  const createNewInvestigation = async () => {
    if (formValid) {
      const investigationData: any = {
        name: investigationName,
        query_json: JSON.stringify(parameters),
        customer_id: user.customer_id,
        user_id: user.id
      }

      if (params.investigationId) {
        const updatedRes = await dispatch(
          updateInvestigation({
            investigation_id: currentInvestigation.id,
            query_json: parameters,
            name: investigationName
          })
        )

        if (elasticResults.length > 0) {
          navigate(`/investigate/${updatedRes.payload[0].id}`)
        } else {
          navigate(`/investigate`)
        }
      } else {
        const createdInvestigation = await dispatch(
          createInvestigation({ investigationData })
        )
        if (elasticResults.length > 0) {
          navigate(`/investigate/${createdInvestigation.payload[0].id}`)
        } else {
          navigate(`/investigate`)
        }
      }
    } else {
      setError('Investigation name must be at least 2 charachters')
    }
  }

  return (
    <div className="text-tuto-primary flex flex-col  gap-4 sticky bottom-0 top-0">
      <div
        onClick={handleGoBack}
        className="flex items-center gap-2 cursor-pointer  font-semibold border-b py-4 sticky top-20"
      >
        <ArrowLeftIcon className="w-5 h-5 stroke-icon" />
        <span className="text-sm">Back</span>
        <div>
          <h1 className="text-lg">Create Investigation</h1>
        </div>
      </div>
      <div className="creation-sidebar min-h-70v pb-8">
        <div className="flex flex-col gap-4 items-start">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Investigation Name"
              value={investigationName}
              onChange={handleNameChange}
              className="border-0 border-b-2 w-full  focus:outline-none focus:ring-0"
            />
            <span className="text-red-400 text-xs absolute -bottom-4 left-0 text-center">
              {error && error}
            </span>
          </div>

          <div className="parameters">
            {parameters.map((parameter) => (
              <ParameterBox
                key={parameter.id}
                id={parameter.id} // Make sure to pass the id prop
                onRemove={handleRemoveParameter}
                onUpdate={updateParameter}
              />
            ))}
          </div>
          <Button
            inverted={true}
            loading={false}
            disabled={!lastParameterValid} // Update this based on your validation logic
            text={'Add Parameter'}
            type="button"
            withIcon={true}
            Icon={PlusIcon}
            spacialClass="parameter-btn"
            clickFunction={handleAddParameter}
          />
        </div>
      </div>
      <div className="">
        <Button
          inverted={false}
          loading={false}
          disabled={false} // Update this based on your validation logic
          text={`${params.investigationId ? 'Update' : 'Create'}`}
          type="button"
          withIcon={false}
          Icon={PlusIcon}
          spacialClass="w-full relative top-10"
          clickFunction={createNewInvestigation}
        />
      </div>
    </div>
  )
}

export default CreationSidebar
