import React, { useEffect } from 'react'
import JsonSearch from '../Investigations/JsonSearch'

interface WindowObjectTabProps {
  data: any
}

const WindowObjectTab:React.FC<WindowObjectTabProps> = ({data}) => {

  useEffect(() => {
    console.log('WindowObjectTab', data)
  }, [])
  return (
    <div>
      {data ? 
      
      <JsonSearch data={JSON.parse(data)} /> : <div className='font-semibold text-center'>
        Sorry, we haven’t been able to locate additional information regarding this recording. If the problem continues, please <a className='text-tuto-purple hover:underline' href='mailto:support@torecord.it'>contact us</a> for further assistance.
      </div>
      
    }
    </div>
  )
}

export default WindowObjectTab