import { useEffect, useRef, useState } from 'react'
import { unpack } from 'rrweb'
import rrwebPlayer from 'rrweb-player'
import { eventWithTime } from '@rrweb/types'
import 'rrweb-player/dist/style.css'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { investigationsState, setPlayFrom } from '../../../app/slices/investigationsSlice'
import { setIsWatched } from '../../../app/slices/ProblemsSlice'

interface SessionProps {
  session?: eventWithTime[]
  audioSource?: string
  sourceId?: string
  playerStartTimer?: any
  isWatched?: boolean
  recordId?: string
  autoPlay?: boolean
}

const Player: React.FunctionComponent<SessionProps> = ({
  session,
  audioSource,
  playerStartTimer,
  sourceId,
  isWatched,
  recordId,
  autoPlay
}) => {
  const [playerWidth, setPlayerWidth] = useState(0)
  const [playerHeight, setPlayerHeight] = useState(0)
  const sessionDiv = useRef<HTMLDivElement>(null)
  const [playerRef, setPlayer] = useState<any>(null)
  const {playFrom} = useAppSelector(investigationsState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (sessionDiv.current) {
      setPlayerWidth(sessionDiv.current.offsetWidth)
      setPlayerHeight(sessionDiv.current.offsetWidth * 0.45)
    }
  }, [])

  useEffect(() => {
    if (session && playerWidth !== 0) {
      generatePlayer(session)
    }
  }, [playerWidth])

  useEffect(() => {
    if(playFrom > 0 && playerRef) {
      console.log('playerref need to play')
      playerRef.getReplayer().play(playFrom)
      dispatch(setPlayFrom(0))
    } 
  }, [playFrom])

  

  const generatePlayer = (session: eventWithTime[]) => {
    const player = new rrwebPlayer({
      target: document.getElementById('session_player') as HTMLElement,
      props: {
        events: session,
        autoPlay: autoPlay || false,
        width: playerWidth,
        height: playerHeight,
        unpackFn: unpack,
        skipInactive:false
      }
    })
    setPlayer(player)
    // player.play()
    // player.pause()
    const iframe = document.getElementsByTagName(
      'iframe'
    )[0] as HTMLIFrameElement

    const audio = document.getElementById('audio') as HTMLVideoElement

    let playerWithCanvas: any
    if (iframe !== null && iframe.contentWindow) {
      // console.log('iframe !== null && iframe.contentWindow')
      playerWithCanvas =
      iframe.contentWindow.document.querySelectorAll('[record_canvas_id]')[0]

    }

    player.addEventListener('pause', () => {
      if (audio) {
        audio.pause()
      }
      if (iframe !== null && iframe.contentWindow && playerWithCanvas) {
        playerWithCanvas.pause()
      }
    })

    player.addEventListener('start', (event) => {
      // console.log('addEventListener(start')
      if (!isWatched) {
        unprotectedPostRequest('records/setIsWatched', {
          record_id: recordId,
          source_id: sourceId,
          value: true
        })
        dispatch(setIsWatched({ id: recordId, is_watched: true }))
        
      }
      if (audio) {
        audio.play()
      }
      if (iframe !== null && iframe.contentWindow && playerWithCanvas) {
        // console.log('playerWithCanvas.play()')
        playerWithCanvas.play()
      }
    })

    player.addEventListener('ui-update-player-state', (event) => {
      // console.log('ui-update-player-state')
      if (iframe !== null && iframe.contentWindow) {
       
        let time = player.getReplayer().getCurrentTime()
        if (playerWithCanvas) {
          playerWithCanvas.currentTime = time / 1000
        }
        if (audio) {(audio.currentTime = time / 1000)}
      }
    })

   
  }

  return (
    <div className="rr-block" id="session_player" ref={sessionDiv}>
      {audioSource && <audio id="audio" autoPlay={autoPlay} src={audioSource} />}
    </div>
  )
}
export default Player
