import { CognitoUserPool } from 'amazon-cognito-identity-js'

const UserPoolId: any = process.env.REACT_APP_USER_POOL_ID
const ClientId: any = process.env.REACT_APP_COGNITO_CLIENT_ID

const poolData = {
  UserPoolId,
  ClientId
}

export default new CognitoUserPool(poolData)
