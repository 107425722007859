import Button from '../../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  customerUsersState,
  sendInvitation
} from '../../../app/slices/customerUsersSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { authState } from '../../../app/slices/authSlice'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import FormInput from '../../utils/forms/FormInput'
import config from '../../../config/config'
import { useState } from 'react'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

interface IFormInputs {
  email: string
}

interface InstructionsFormProps {
  setSlideOpen: any
}

const InstructionsForm: React.FC<InstructionsFormProps> = ({
  setSlideOpen
}) => {
  const { user } = useAppSelector(authState)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })
  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setLoading(true)
    const body = {
      email: data.email,
      senderName: user.name || 'Your teammate',
      senderEmail: user.email
    }
    console.log(body)
    const res = await fetch(
      `${config.api}/api/v1/users/sendInstallationInstructionsMail`,
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    Mixpanel.track('Installation mail sent to developer', {user_id: user.id, customer_id: user.customer_id, email: user.email, emailTo: data.email})
    const parsedRes = await res.json()
    console.log(parsedRes)
    reset()
    if (parsedRes.success) {
      dispatch(
        addNewNotification({
          text: `Mail sent to ${data.email}`,
          id: uuidv4(),
          title: 'Mail sent!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    } else {
      addNewNotification({
        text: `Please try again later`,
        id: uuidv4(),
        title: `Error sending mail to ${data.email}`,
        type: 'WARN',
        timer: true,
        button: false
      })
    }
    setLoading(false)
    setSlideOpen(false)
  }

  const trackEvent = (event: string) => {
    Mixpanel.track(event, {user_id: user.id, customer_id: user.customer_id, email: user.email})
  }

  return (
    <form id='installation instructions' onSubmit={handleSubmit(formSubmitHandler)}>
      <div className="mb-6">
        Send installation instructions to your developer
      </div>
      <FormInput
        name="email"
        type="email"
        label="Developer Email"
        placeholder="Your Developer Email..."
        error={errors.email?.message}
        registerFunction={register}
        isCustomClass={false}
        customClass={undefined}
      />
      <Button
        type={'submit'}
        disabled={false}
        loading={loading}
        withIcon={true}
        Icon={ArrowRightIcon}
        text={'Send Instructions'}
        inverted={false}
        clickFunction={trackEvent('Clicked on Send Instructions Btn')}
      />
    </form>
  )
}

export default InstructionsForm
