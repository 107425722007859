import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { RootState } from '../store'
import { CreateView, GetViews, DeleteView, UpdateView } from '../apis/viewsApi'

export const viewsAdapter = createEntityAdapter<any>({
  selectId: (view) => view.id
})

export const viewsSelectors = viewsAdapter.getSelectors<RootState>(
  (state) => state.views
)

export const { selectAll, selectById, selectEntities, selectTotal } =
  viewsSelectors

export const createView = createAsyncThunk(
  'views/createView',
  async ({ viewData }: any) => {
    try {
      const res = await CreateView(viewData)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getViews = createAsyncThunk(
  'views/getViews',
  async ({ user_id, customer_id }: any) => {
    try {
      const res = await GetViews(user_id, customer_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const deleteView = createAsyncThunk(
  'views/deleteView',
  async ({ view_id }: any) => {
    try {
      const res = await DeleteView(view_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const updateView = createAsyncThunk(
  'views/updateView',
  async ({ view_id, query_json, name, is_public }: any) => {
    try {
      const filtersToUpdate = JSON.stringify(query_json)
      const res = await UpdateView(view_id, filtersToUpdate, name,is_public )
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

const viewsSlice = createSlice({
  name: 'views',
  initialState: viewsAdapter.getInitialState({
    loading: false,
    loadingCreation: false,
    currentView:<any> {},
    error: ''
  }),
  reducers: {
    setCurrentView(state, action) {
      state.currentView = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createView.pending, (state, action) => {
        state.loadingCreation = true
      })
      .addCase(createView.fulfilled, (state, action: PayloadAction<any>) => {
        state.loadingCreation = false
        viewsAdapter.setOne(state, action.payload[0])
      })
      .addCase(createView.rejected, (state, action: PayloadAction<any>) => {
        state.loadingCreation = false
        state.error = action.payload
      })
      .addCase(getViews.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getViews.fulfilled, (state, action: PayloadAction<any>) => {
        
        state.loading = false
        viewsAdapter.setAll(state, action.payload)
      })
      .addCase(getViews.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(deleteView.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log(action.payload)

        viewsAdapter.removeOne(state, action.payload[0].id)
      })
      .addCase(deleteView.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
      })
      .addCase(updateView.pending, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(updateView.fulfilled, (state, action: PayloadAction<any>) => {
        localStorage.setItem('record_cur_view', JSON.stringify(action.payload[0]))
        viewsAdapter.updateOne(state, {id: action.payload[0].id, changes: action.payload[0]})
        viewsSlice.reducer(state, setCurrentView(action.payload[0]))
      })
      .addCase(updateView.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
      })
  }
})

export const viewsState = (state: RootState) => state.views
export const { setCurrentView } = viewsSlice.actions
export default viewsSlice.reducer
