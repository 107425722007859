import { XCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import Button from '../utils/elements/Button'

interface IAuthErrorProps {
  title: string
  description: string
  dataCy?: string
  withButton?: boolean
  buttonFunction? :any
  buttonText?: string
}

const AuthError: React.FC<IAuthErrorProps> = ({
  title,
  description,
  dataCy,
  withButton,
  buttonFunction,
  buttonText
}) => {
  return (
    <div
      className="rounded-md bg-red-50 p-4"
      {...(dataCy ? { 'data-cy': `${dataCy}` } : {})}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>{description}</li>
            </ul>
          </div>
        </div>
        {withButton && (
          <Button
            inverted={true}
            loading={false}
            disabled={false}
            text={buttonText}
            type="button"
            withIcon={false}
            spacialClass="transparent-button"
            clickFunction={buttonFunction}
          />
        )}
      </div>
    </div>
  )
}

export default AuthError
