import { useState } from 'react'
import Layout from '../../utils/layout/Layout'
import InvestigationSidebar from './InvestigationSidebar'
import RightInfoSection from './RightInfoSection'
import StickyRight from './StickyRight'
import StickyLeft from './StickeyLeft'
import MainView from './MainView'
import MainInvestigationView from './MainInvestigationView'
import SinglePageGrid from './SinglePageGrid'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  getInvestigationDetails,
  investigationsState,
  setCurrentInvestigation,
  setCurrentSession
} from '../../../app/slices/investigationsSlice'
import { problemsState } from '../../../app/slices/ProblemsSlice'
import SingleEmpty from './SingleEmpty'
import Knowledge from '../Knowledge/Knowledge'
import InvestigationCreate from './InvestigationCreate'
import Transcript from '../Record/Transcript'
import SlideOver from '../Knowledge/Knowledge'
import JsonSearch from './JsonSearch'
import { demoVars } from '../../utils/enums'


const SingleInvestigation = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const { loadingFilters } = useAppSelector(problemsState)
  const [emptyInvestigation, setEmptyInvestigation] = useState(false)
  const {currentInvestigation} = useAppSelector(investigationsState)
  const [formOpen, setFormOpen] = useState(false)
  const [networkInfoOpen, setNetworkInfoOpen] = useState(false)
  const [networkData, setNetworkData] = useState({})
  const [additionalData, setAdditionalData] = useState<any>({
    data: {},
    oneai: null,
    transcription: '',
    additionalInfo: {},
    tags: [],
    backendLogs: [],
    windowObject: '',
    feedEvents: []
  })

  useEffect(() => {
    if (params.investigationId && !params.sessionId) {
      if(params.investigationId == demoVars.INVESTIGATION_ID) {
        dispatch(
          getInvestigationDetails({ investigation_id: null })
        )
      } else {

        dispatch(
          getInvestigationDetails({ investigation_id: params.investigationId })
        )
      }
    } else if (params.investigationId && params.sessionId) {
      if(params.investigationId == demoVars.INVESTIGATION_ID) {
        dispatch(
          getInvestigationDetails({ investigation_id: null })
        )
      } else {

        dispatch(
          getInvestigationDetails({ investigation_id: params.investigationId })
        )
      }
      dispatch(setCurrentSession({ id: params.sessionId }))
    }

    return () => {
      dispatch(setCurrentInvestigation({}))
      setAdditionalData({
        data: {},
        oneai: null,
        transcription: '',
        additionalInfo: {},
        tags: [],
        backendLogs: [],
        windowObject: '',
        feedEvents: []
      })
    }
  }, [])

  // useEffect(() => {
  //   console.log('currentInvestigation***********', currentInvestigation)
  // }, [currentInvestigation])

  useEffect(() => {
    if (params.investigationId && params.sessionId) {
      dispatch(setCurrentSession({ id: params.sessionId }))
    }
  }, [params])

  return (
    <Layout withCircles={false} >
      <SinglePageGrid gridCols={12}>
        <StickyRight gridCols={2}>
          <InvestigationSidebar emptyInvestigation={emptyInvestigation} setEmptyInvestigation={setEmptyInvestigation} />
        </StickyRight>
        {emptyInvestigation && !loadingFilters && (
          <SingleEmpty buttonFunction={() => setFormOpen(true)} buttonText='Edit' text='We couldn’t find any records or sessions matching your investigation. Please modify the search parameters to find the information you’re looking for.' />
        )}
        {!emptyInvestigation && !loadingFilters && (
          <MainView gridCols={6}>
            <MainInvestigationView setAdditionalData={setAdditionalData} />
            <Transcript oneAi={additionalData.oneai} transcript={additionalData.transcription} />
          </MainView>
        )}
        {!emptyInvestigation && !loadingFilters && (
          <StickyLeft gridCols={4}>
            <RightInfoSection additionalData={additionalData} setNetworkData={setNetworkData} openNetwork={() => setNetworkInfoOpen(true)}/>
          </StickyLeft>
        )}
      </SinglePageGrid>
      <Knowledge
        title={`Edit Investigation`}
        isOpen={formOpen}
        setSlideOpen={setFormOpen}
      >
        <InvestigationCreate investigation={currentInvestigation} setFormOpen={setFormOpen}  type={'update'} />
      </Knowledge>
     
      
      <SlideOver  
      title={`Network Info`}
        isOpen={networkInfoOpen}
        setSlideOpen={setNetworkInfoOpen}
        >
          <JsonSearch data={networkData} />
      </SlideOver>
   
    </Layout>
  )
}

export default SingleInvestigation
