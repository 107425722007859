import React, { FC } from 'react'

interface StickyRightProps {
  children: React.ReactNode
  gridCols: number
}

const StickyRight:FC<StickyRightProps> = ({children, gridCols}) => {
  return (
    <div className={`col-span-2 sticky top-20 rounded-xl h-85v p-4 z-10`}>{children}</div>
  )
}

export default StickyRight