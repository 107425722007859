import { unprotectedGetRequest, unprotectedPostRequest } from "./apiGenerics"

export const GetViews = async (user_id: string, customer_id: string) => {
  const views = await unprotectedGetRequest(
    `filterViews/getViews?customer_id=${customer_id}&user_id=${user_id}`
  )
  // console.log(records)
  return views
}

export const CreateView = async (viewData: any) => {
  const newView = await unprotectedPostRequest(
    `filterViews/createView`, 
    {...viewData}
  )
  // console.log(newView)
  return newView
}

export const DeleteView = async (view_id: string) => {
  const deletedView = await unprotectedPostRequest(
    `filterViews/deleteView`, 
    {view_id}
  )
  
  return deletedView
}

export const UpdateView = async (view_id: string, query_json:string, name: string, is_public: boolean) => {
  // console.log(query_json)
  const updatedView = await unprotectedPostRequest(
    `filterViews/updateView`, 
    {view_id, query_json, name, is_public}
  )
  
  return updatedView
}