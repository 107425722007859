import React, { useEffect, useState } from 'react'
import TeammatesInput from './TeammatesInput'
import TeammatesTable from './TeammatesTable'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  getCustomerUsers,
  customerUsersSelectors,
  getUserRoles,
  customerUsersState
} from '../../../app/slices/customerUsersSlice'
import { authState } from '../../../app/slices/authSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import GrantAccess from '../../Auth/GrantAccess'

interface TeammatesProps {
  children: React.ReactNode
  // grantAccessToRoles: string[]
}

const Teammates: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const { roles } = useAppSelector(customerUsersState)
  const [searchQuery, setSearchQuery] = useState('')
  const teammates = useAppSelector(customerUsersSelectors.selectAll)

  useEffect(() => {
    if (roles.length === 0) {
      dispatch(getUserRoles())
    }
    if (user && user.customer_id) {
      dispatch(getCustomerUsers(user.customer_id))
    }
    if (user) {
      Mixpanel.register({
        Page: 'Teammates',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      Mixpanel.track('Teammates Page Viewed', {})
    }
  }, [])

  const handleSearch = (searchQuery: string) => {
    if (!searchQuery && teammates) {
      return teammates
    }

    return (
      teammates &&
      teammates.filter((teammate) => {
        const postName = teammate.email.toLowerCase()
        return postName.includes(searchQuery)
      })
    )
  }

  const filtered = handleSearch(searchQuery)

  return (
    <div className="p-4">
      <GrantAccess grantAccessToRoles={['admin']}>
        <div>
          <h1 className="mb-2 general-title">Invite People</h1>
          <p className="tuto__title mb-6 block text-lg">
            Invite teammates to your workspace
          </p>
          <div className="flex flex-col md:grid gap-2 grid-cols-3">
            <div className="col-span-2">
              <TeammatesInput />
            </div>
            <div className="relative border border-gray-300 rounded-md shadow-sm col-span-1 w-full text-gray-400 focus-within:text-gray-600">
              <input
                data-cy="search-box"
                value={searchQuery}
                onInput={(e: any) => setSearchQuery(e.target.value)}
                id="search_field"
                name="search_field"
                className="block w-full h-full pl-2 pr-3 border-transparent rounded-md text-gray-900 dark:text-dark-text placeholder-gray-500 focus:outline-none focus:border-tuto-primary focus:ring-0 focus:border-transparent sm:text-sm dark:bg-dark-secondary dark:border-dark-text"
                placeholder={'Search...'}
                type="search"
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pointer-events-none"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 mr-1"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </GrantAccess>
      <div>
        <TeammatesTable teammates={filtered} />
      </div>
    </div>
  )
}

export default Teammates
