const PageLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen jello-horizontal">
      <img
        className="max-w-xs md:max-w-md"
        src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/logolongrecord.png"
      />
    </div>
  )
}

export default PageLoader
