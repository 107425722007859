import React, { FC, Fragment, useEffect, useState } from 'react'
import Button from '../../utils/elements/Button'
import RightTabs from './RightTabs'
import InvestigateTab from '../Problems/InvestigateTab'
import InfoTab from '../Problems/InfoTab'
import AdditionalInfoTab from './AdditionalInfoTab'
import { ShareIcon } from '@heroicons/react/24/outline'
import AnalyticsTab from '../Problems/AnalyticsTab'
import { useMatch } from 'react-router-dom'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import FeedData from './sideComponents/EventsFeed.json'
import { investigationsState } from '../../../app/slices/investigationsSlice'
import EventsFeed from './EventsFeed'

interface RightInfoSectionProps {
  additionalData: any,
  openNetwork?:any,
  setNetworkData?:any
}

const RightInfoSection: FC<RightInfoSectionProps> = ({ additionalData, openNetwork, setNetworkData }) => {
  const [dataFetched, setDataFetched] = useState(false)
  const [tabs, setTabs] = useState<any>([])
  const recordLink: any = useMatch('/issues/:record')
  const {currentSession} = useAppSelector(investigationsState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    
    if (additionalData.additionalInfo && Object.keys(additionalData.additionalInfo).length > 0) {
      setTabs([
        {
          title: 'INFO',
          component: AdditionalInfoTab,
          props: {
            regularData: additionalData.data,
            browserInfo: additionalData.additionalInfo.browserInfo,
            customData: additionalData.additionalInfo.customData,
            windowObject: additionalData.windowObject && additionalData.windowObject !== ''  ? JSON.parse(additionalData.windowObject) : null
          }
        },
        {
          title: 'FEED',
          component: EventsFeed,
          props: {
            // logs: additionalData.additionalInfo.console,
            // pages: JSON.parse(additionalData.additionalInfo.analytics),
            // network: additionalData.additionalInfo.network,
            // blockFilters: false,
            startTimestamp: additionalData.data.start_timestamp,
            data: additionalData.feedEvents.sort((a:any,b:any) => a.timestamp-b.timestamp) ,
            openNetwork: openNetwork,
            setNetworkData: setNetworkData,
            // backendLogs: additionalData.backendLogs.map((log: any) => ({...log, timestamp: Date.parse(log.lastSeen) }))
          }
        },
        {
          title: 'ANALYTICS',
          component: AnalyticsTab,
          props: {
            emotions: [],
            ai: additionalData.oneai,
            // pages: JSON.parse(additionalData.additionalInfo.analytics),
            //  pages: ['https://sandbox-playground.torecord.it/'],
            record_id: additionalData.data.record_id,
            tags: additionalData.tags,
            source_id: additionalData.data.source_id
          }
        }
      ])
      setDataFetched(true)
    }

  }, [additionalData])

  const copyShare = () => {
    if(currentSession.id) {

      navigator.clipboard.writeText(
        `${window.location.origin}/sharedrecord/${currentSession.id}?session=true`
      )
    } else if(recordLink.params.record) {
      navigator.clipboard.writeText(
        `${window.location.origin}/sharedrecord/${recordLink.params.record}`
      )
    }
    dispatch(setCoppiedTooltip(true))
  }

  return (
    <Fragment>
      <div className="flex justify-end">
        <Button
          spacialClass="white-btn"
          text="Share"
          loading={false}
          inverted={false}
          disabled={false}
          type="button"
          withIcon={true}
          Icon={ShareIcon}
          clickFunction={copyShare}
        />
      </div>
      <div className="bg-gray-100 border rounded-xl flex-grow overflow-scroll">
        {dataFetched && <RightTabs tabs={tabs} />}
      </div>
    </Fragment>
  )
}

export default RightInfoSection
