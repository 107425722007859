import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import React, { FC } from 'react'
import './inbox.css'


interface SetupAlertButtonProps {
  setIsVisible: any
}
const SetupAlertButton: FC<SetupAlertButtonProps> = ({setIsVisible}) => {
  return (
    <div className='exclamation-icon' onClick={() => setIsVisible(true)}>!</div>
  )
}

export default SetupAlertButton