import { FC } from 'react';
import BoringAvatar from 'boring-avatars';

interface AvatarProps {
  email:string
  id: string
}

const Avatar:FC<AvatarProps> = ({ email, id }) => {
  return <BoringAvatar variant="beam" colors={['#14172E', '#95D5FC', '#8E68CB']} size="35" name={email || id} />;
};

export default Avatar;
