import { Outlet } from 'react-router-dom'
import SideBar from './Options/SideBar'

const Settings = () => {
  return (
    <div className="flex">
      <SideBar />
      <div className='p-2 md:p-4 flex-grow overflow-y-auto ml-14 md:ml-64'>
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
