import React, { useState } from 'react'
import { JSONTree } from 'react-json-tree'

interface DataItem {
  [key: string]: {
    value: string | number | boolean | object
    percentage: number
  }
}

interface SearchResult {
  key: string
  value: DataItem[keyof DataItem]
}

interface JsonSearchProps {
  data: any
}

const JsonSearch: React.FC<JsonSearchProps> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<any[]>([])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm) {
      const results: any[] = Object.entries(data)
        .filter(([key, value]) => {
          const itemJson = JSON.stringify({ key, value }).toLowerCase()
          return itemJson.includes(searchTerm)
        })
        .map(([key, value]) => ({ key, value }))

      setSearchResults(results)
    } else {
      setSearchResults([])
    }
  }

  const theme = {
    scheme: 'bright',
    base00: 'transparent',
    base0B: '#14172E',
    base0D: '#8641EF'
    // Add more theme colors here if needed
  }

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
        value={searchTerm}
        onChange={handleSearch}
      />
      {searchResults.length > 0 ? (
        searchResults.map(({ key, value }, index) => (
          <JSONTree
            key={index}
            data={{ [key]: value }}
            theme={theme}
            hideRoot={true}
          />
        ))
      ) : searchTerm ? (
        <div>No results found.</div>
      ) : (
        <JSONTree hideRoot={true} data={data} theme={theme} shouldExpandNodeInitially={() => true}/>
      )}
      {/* {searchResults.length > 0 ? (
        searchResults.map(({ key, value }, index) => (
          <JSONTree hideRoot={true} key={index} data={{ [key]: value }} theme={treeTheme} />
        ))
      ) : (
        <JSONTree hideRoot={true} data={data} theme={treeTheme} />
      )} */}
    </div>
  )
}

export default JsonSearch
