import { XCircleIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice';
import Button from '../../utils/elements/Button';
import './inbox.css';
import SetupAlertButton from './SetupAlertButton';

const SetupBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const customerDomains = useAppSelector(customerDomainsSelectors.selectAll)

  if(customerDomains.length > 0) {
    return null
  }

  if (!isVisible) {
    return <SetupAlertButton setIsVisible={setIsVisible} />
  }

  return (
    <div className="px-12 py-16 inbox bg-gray-300 flex flex-col gap-4 items-start relative">
      <button 
        onClick={() => setIsVisible(false)} 
        className="absolute top-0 right-0 m-4 focus:outline-none"
      >
        <XCircleIcon className='h-10 w-10 text-gray-300' />
      </button>
      <h1 className='text-5xl font-semibold text-tuto-purple'>Let's wrap this up! </h1>
      <p className='font-light text-xl'>You're currently browsing Demo data.  <br/>Don't waste another minute troubleshooting tickets without Record's capabilities. </p>
      <div className=' flex gap-4'>

      <Link to={'/settings/installation?activeTab=1'}>
        <Button
          inverted={false}
          loading={false}
          disabled={false}
          text="Install the code"
          type="button"
          withIcon={false}
          spacialClass=""
        />
      </Link>
      <Link to={'/settings/installation?activeTab=2'}>
        <Button
          inverted={true}
          loading={false}
          disabled={false}
          text="Send to engineer"
          type="button"
          withIcon={false}
          spacialClass=""
        />
      </Link>
      </div>
    </div>
  );
};

export default SetupBanner;
