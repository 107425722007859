import React from 'react'
import { useNavigate } from 'react-router-dom'


const Sessions = () => {
  const navigate = useNavigate()
  return (
    <div className='relative'>
      <div className='absolute flex flex-col items-center text-center top-1/3 p-4 sm:px-96'>
        <h1 className='text-xl sm:text-2xl font-bold mb-2.5'>Coming Soon</h1>
        <p className='text-sm sm:text-xl'>Want to be the first to understand sentiments, Metrics and more? <span onClick={() => navigate('/settings')} className='text-tuto-purple cursor-pointer'>Become a beta tester</span></p>
      </div>
      <img className='max-w-full' src='https://assets.torecord.it/sessionsblurb.png' />
    </div>
  )
}

export default Sessions