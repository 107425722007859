import { FC } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';



interface BarChartComponentProps {
  data: any
}

const BarChartComponent:FC<BarChartComponentProps> = ({data}) => {
  return (
    <BarChart width={400} height={100} data={data}>
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis fontSize={10} dataKey="x" />
      {/* <YAxis /> */}
      <Tooltip formatter={(value, name, props) => [value, "New Entries"]} />
      {/* <Legend /> */}
      <Bar dataKey="y" fill="#8884d8" />
      {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
    </BarChart>
  );
};

export default BarChartComponent;