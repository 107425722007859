import React, { useEffect, useState } from 'react'
import { authState, logOutUser } from '../../../app/slices/authSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import NavBar from './NavBar'
import CopiedState from './CopiedState'
import {
  getCustomerUsers
} from '../../../app/slices/customerUsersSlice'
import Knowledge from '../../pages/Knowledge/Knowledge'
import Videos from '../../pages/Knowledge/Videos'
import {
  toggleSlider,
  uiState,
  setActiveTier
} from '../../../app/slices/uiSlice'
import Congrats from './Confetti'
import PGWidget from '../shared/PGWidget'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import Button from '../elements/Button'
import IntegrationPopup from '../../pages/Integrations/IntegrationPopup'
import TierPopup from '../shared/TierPopup'
import InvitationPopup from '../../pages/onboarding/InvitationPopup'
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice'

interface ILayoutProps {
  children: React.ReactNode
  withCircles: boolean
}

declare global {
  interface Window {
    recordSettings: any
    recordLogs: any
  }
}

const Layout: React.FunctionComponent<ILayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { user, isPlayground } = useAppSelector(authState)
  const { slidoverOpen, isFromIntegration, activeTier } =
    useAppSelector(uiState)
  const [invitationActive, setInvitationActive] = useState(false)
  const customerUsers = useAppSelector(customerDomainsSelectors.selectAll)

  // const isToOpenFirstPopup = localStorage.getItem('isFirstPopupOpened')
  useEffect(() => {
    if (user && user.customer_id) {
      if (customerUsers.length === 0) {
        dispatch(getCustomerUsers(user.customer_id))
      }
      window.recordSettings = {
        name: user.name,
        email: user.email,
        customData: {
          customer_id: user.customer_id,
          env: process.env.REACT_APP_STAGE
        }
      }
      if (user.tier_id !== null) {
        dispatch(setActiveTier(user.tier_id))
      } else {
        dispatch(setActiveTier('438187b9-5f43-444c-aca9-dfa6e2d7e3fd'))
      }
    }
    if (sessionStorage.getItem('invitationActive') === 'true') {
      setInvitationActive(true)
      sessionStorage.setItem('invitationActive', 'false')
    }
  }, [])

  return (
    <div className="">
      {/* <div className="ob-purple"></div>
      <div className="ob-yellow"></div> */}

      {isFromIntegration && <IntegrationPopup />}
      {/* {user &&
        user.email === user.company_created_by &&
        !isToOpenFirstPopup &&
        !isFromIntegration && <FirstPopup />} */}
      <NavBar />
      {/* {!isPlayground && <Banner/>} */}
      {isPlayground && (
        <>
          <PGWidget />
          <div className="fixed px-2 bottom-0 w-full bg-tuto-primary z-50 h-20 flex items-center justify-between">
            <div className="mx-40 xl:text-2xl text-white">
              Notice: this environment is not a real environment
            </div>
            <div>
              <Button
                loading={false}
                inverted={true}
                disabled={false}
                type={'button'}
                withIcon={true}
                Icon={ArrowLeftOnRectangleIcon}
                clickFunction={() => {
                  dispatch(logOutUser())
                }}
                spacialClass={
                  'bg-tuto-yellow text-light-black rounded-3xl hover:bg-tuto-yellow border-2 border-light-black'
                }
                text="Signup / Login"
              />
            </div>
          </div>
        </>
      )}
      <main className=" mt-16 relative">
        <div className="">{children}</div>
      </main>
      <CopiedState />
      <Knowledge
        title={'Getting Started'}
        isOpen={slidoverOpen}
        setSlideOpen={() => dispatch(toggleSlider())}
      >
        <Videos />
      </Knowledge>
      <Congrats />

      {activeTier && <TierPopup />}
      {invitationActive && (
        <InvitationPopup
          open={invitationActive}
          setOpen={setInvitationActive}
        />
      )}
    </div>
  )
}

export default Layout
