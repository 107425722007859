import React, { FC } from 'react'

interface MainViewProps {
  children: React.ReactNode
  gridCols: number
}

const MainView:FC<MainViewProps> = ({children}) => {
  return (
    <div className={`col-span-6 grid grid-rows-2 gap-4 px-4`}>{children}</div>
  )
}

export default MainView