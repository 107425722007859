import React, { FC } from 'react'

interface SinglePageGridProps {
  children: React.ReactNode
  gridCols:number
}

const SinglePageGrid:FC<SinglePageGridProps> = ({children, gridCols}) => {
  return (
    <div className={`grid grid-cols-12 p-4 relative`}>{children}</div>
  )
}

export default SinglePageGrid