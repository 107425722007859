import { useState, useEffect } from 'react'
import { authState, updateUserData } from '../../../../app/slices/authSlice'
import * as yup from 'yup'
import Button from '../../../utils/elements/Button'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import SingleInputChange from '../../../utils/forms/SingleInputChange'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm } from 'react-hook-form'
import Toggle from '../../../utils/elements/Toggle'
import { getCustomerStepsDone, uiState } from '../../../../app/slices/uiSlice'
import Modal from '../../../utils/layout/modal'
import Unsubscribe from '../../../utils/layout/modal-contents/Unsubscribe'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useOutsideClick } from '../../../../hooks/useClickOuside'

const nameSchema = yup.object().shape({
  name: yup.string().required().min(2)
})

const customerSchema = yup.object().shape({
  customer: yup.string().required().min(2)
})

const Profile = () => {
  const { user, isPlayground } = useAppSelector(authState)
  const [changeName, setChangeName] = useState(false)
  const [changeOrganiztionName, setChangeOrganiztionName] = useState(false)
  const [phone, setPhone] = useState('')
  const [isBetta, setIsBetta] = useState(false)
  const [activeWeeklyReport, setActiveWeeklyReport] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const {activeTier} = useAppSelector(uiState)

  const { register } = useForm()
  const dispatch = useAppDispatch()

  // const handleNameOutside = () => {
  //   if(changeName) {
  //     setChangeName(false)
  //   }
  // }

  // const handleOrgOutside = () => {
  //   console.log('clicked outside')
  //   if(changeOrganiztionName) {
  //     setChangeOrganiztionName(false)
  //   }
  // }
  // const nameRef = useOutsideClick(handleNameOutside)
  // const orgRef = useOutsideClick(handleOrgOutside)


  useEffect(() => {
    if (!user.name) {
      setChangeName(true)
    }
    if (user) {
      setIsBetta(user.is_beta)
      setActiveWeeklyReport(user.weekly_report)
      Mixpanel.register({
        Page: 'Profile',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      Mixpanel.track('Profile Page Viewed', {})
    }
  }, [])

  const updatePhone = () => {
    dispatch(
      updateUserData({
        data: phone,
        userId: user.id,
        type: 'phone',
        customerId: user.customer_id
      })
    )
    if (user && user.customer_id) {
      dispatch(getCustomerStepsDone(user.customer_id))
    }
    Mixpanel.track('Clicked On Change Phone name', {})
  }

  const toggleBeta = () => {
    if (!isPlayground) {
      setIsBetta(!isBetta)
      dispatch(
        updateUserData({
          data: !isBetta,
          userId: user.id,
          type: 'beta',
          customerId: user.customer_id
        })
      )
      dispatch(
        addNewNotification({
          text: !isBetta ? 'You became a beta tester' : 'Beta tester is off',
          id: uuidv4(),
          title: 'Beta status updated!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    }
  }

  const toggleWeeklyReport = () => {
    dispatch(
      updateUserData({
        data: !activeWeeklyReport,
        userId: user.id,
        type: 'weekly_report',
        customerId: user.customer_id
      })
    )
    setActiveWeeklyReport(!activeWeeklyReport)
    setPopupOpen(false)
  }

  const openModal = () => {
    if (!isPlayground) {
      if (user.weekly_report) {
        setPopupOpen(true)
        Mixpanel.track('user try to unsubscribe weekly report', {})
      } else {
        toggleWeeklyReport()
        Mixpanel.track('user subscribed to weekly report', {})
      }
    }
  }

  return (
    <div>
      <div className="sm:p-4">
        <div className="mx-auto flex flex-col md:px-8 xl:px-0">
          <main className="flex-1">
            <div className="relative  mx-auto md:px-8 xl:px-0">
              <div className="pb-16">
                <div className="px-4 sm:px-6 md:px-0">
                  <div className="">
                    <div className="space-y-1">
                      <h3 className="general-title">Account Information</h3>
                    </div>
                    <div className="mt-6">
                      <dl className="">
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="paragraph">Name</dt>
                          <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                            <span className="flex-grow flex">
                              {user && user.name && changeName === false && (
                                <div
                                  className="flex items-center"
                                  {...{ 'data-cy': 'name-text-box' }}
                                >
                                  <div className="mr-2 text-gray-400">{user.name}</div>
                                </div>
                              )}
                              <div
                                className={
                                  isPlayground
                                    ? 'flex flex-grow pointer-events-none opacity-50'
                                    : ''
                                }
                                // ref={nameRef}
                              >
                                <SingleInputChange
                                  dataCySingle="change-or-add-name"
                                  schema={nameSchema}
                                  changeValueFunction={setChangeName}
                                  updateApiFunction={updateUserData}
                                  buttonText={'Add'}
                                  toggleViewsState={changeName}
                                  isPhone={false}
                                  name="name"
                                  notify={true}
                                  registerFunction={register}
                                  placeHolder="Your Name"
                                  // defaultValue={user.name}
                                  
                                />
                              </div>
                            </span>
                          </dd>
                        </div>
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="paragraph">Organization Name</dt>
                          <dd className="mt-1 flex text-gray-400 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow flex">
                              {user &&
                                user.company_name &&
                                changeOrganiztionName === false && (
                                  <div className="flex items-center">
                                    <div
                                      className="mr-2"
                                      {...{
                                        'data-cy': 'organization-text-box'
                                      }}
                                    >
                                      {user.company_name}
                                    </div>
                                  </div>
                                )}
                              <div
                              // ref={orgRef}
                                className={
                                  isPlayground
                                    ? 'flex flex-grow pointer-events-none opacity-50'
                                    : ''
                                }
                              >
                                <SingleInputChange
                                  dataCySingle="change-organization-button"
                                  schema={customerSchema}
                                  changeValueFunction={setChangeOrganiztionName}
                                  updateApiFunction={updateUserData}
                                  buttonText={'Add'}
                                  toggleViewsState={changeOrganiztionName}
                                  isPhone={false}
                                  name="customer"
                                  registerFunction={register}
                                  notify={true}
                                  placeHolder="Org Name"
                                  // defaultValue={user.company_name}
                                />
                              </div>
                            </span>
                          </dd>
                        </div>

                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                          <dt className="paragraph">Email</dt>
                          <dd className="mt-1 flex text-gray-400 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{user?.email}</span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div>
                        {user.email === user.company_created_by ? (
                          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <dt className="paragraph">Phone</dt>
                            <dd className="mt-1 flex text-gray-400 sm:mt-0 sm:col-span-2">
                              <div className="flex">
                                <PhoneInput
                                  country={'us'}
                                  value={user.company_phone}
                                  onChange={(phone) => setPhone(phone)}
                                  // isValid={false}
                                />
                                <Button
                                  dataCy="add-number-button"
                                  inverted={true}
                                  loading={false}
                                  disabled={isPlayground}
                                  text={'Add'}
                                  type="submit"
                                  withIcon={false}
                                  spacialClass="text-xs mx-2"
                                  clickFunction={updatePhone}
                                />
                              </div>
                            </dd>
                          </div>
                        ) : null}
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
                          <dt className="paragraph">Role</dt>
                          <dd className="mt-1 flex text-gray-400 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{user?.role_name}</span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div>
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
                          <dt className="paragraph">Tier</dt>
                          <dd className="mt-1 flex text-gray-400 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{activeTier && activeTier.name}</span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div>
                        {/* <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="paragraph">
                            Key
                            {isPlayground && (
                              <div className="text-sm text-gray-500">
                                This environment is a playground environment. in
                                your dedicated environment you will recieve a
                                specific api key (example:
                                d3da7yc5d-8t1c-4c9s-9d32-bc44ac1bafe9)
                              </div>
                            )}
                          </dt>
                          <dd className="mt-1 flex paragraph-small sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {isPlayground ? '{API_KEY}' : user?.customer_id}
                            </span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div> */}
                        <div className="space-y-1 my-4 2xl:mt-20">
                          <h3 className="general-title">Account Settings</h3>
                        </div>
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="paragraph">
                            Weekly Report
                            <div className="text-sm text-gray-500">
                              once a week, we’ll send you everything you need to
                              know about your Record account activity right to
                              your mailbox.
                            </div>
                          </dt>
                          <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              <Toggle
                                enabled={activeWeeklyReport}
                                setEnabled={openModal}
                                dataCy={'weekly-report-toggle'}
                              />
                            </span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div>
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="paragraph">Become a beta tester
                          
                          <div className="text-sm text-gray-500">
                          Preview early versions of new features before anyone else.
                            </div>
                          </dt>
                          <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              <Toggle
                                dataCy="beta-toggle"
                                enabled={isBetta}
                                setEnabled={toggleBeta}
                              />
                            </span>
                            <span className="ml-4 flex-shrink-0"></span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="space-y-1 my-4 2xl:mt-20">
                    <h3 className="general-title">Account Key</h3>
                  </div>
                    <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">
                        {isPlayground ? '{API_KEY}' : user?.customer_id}
                      </span>
                      <span className="ml-4 flex-shrink-0"></span>
                    </dd>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Modal
        type="danger"
        buttonText={'Remove Me'}
        cancelButtonText={'keep me in the loop'}
        cancelButtonFunction={() =>
          Mixpanel.track('user clicked on keep me in the loop', {})
        }
        title={'Are You Sure?'}
        buttonAction={() => {
          Mixpanel.track('user clicked on unsubscribe from weekly report', {})
          toggleWeeklyReport()
        }}
        openFunction={setPopupOpen}
        isOpen={popupOpen}
      >
        <Unsubscribe />
      </Modal>
    </div>
  )
}

export default Profile
