import './layout.css'
import {
  NotificationsState,
  setCoppiedTooltip
} from '../../../app/slices/notificationsSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useEffect } from 'react'

const CopiedState = () => {
  const { urlCoppied } = useAppSelector(NotificationsState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if(urlCoppied) {
      setTimeout(() => {
        dispatch(setCoppiedTooltip(false))
      }, 2000)
    }
  },[urlCoppied])
  return (
    urlCoppied ? <div className="copied z-50">
      The link copied to your clipboard!
      <div className="tip"></div>
    </div> : null
  )
}

export default CopiedState
