import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    createEntityAdapter
  } from '@reduxjs/toolkit'
import { GetDomainsByCustomer, GetStatusTypes, UpdateDomainStatus } from '../apis/customerDomainsApi'
  import { RootState } from '../store'

  export interface customerDomainsType {
    id: string
    created_at: string
    origin: string
    status_id: string
    customer_id: string
    domainFilters: any[]
  }

  export const customerDomainsAdapter = createEntityAdapter<customerDomainsType>({
    selectId: (domain) => domain.id
  })
  
  export const customerDomainsSelectors =
  customerDomainsAdapter.getSelectors<RootState>((state) => state.customerDomains)
  
  export const { selectAll, selectById, selectEntities, selectTotal } =
  customerDomainsSelectors

  export const updateDomainStatus = createAsyncThunk(
        'customerDomains/updateDomainStatus',
        async ({ domain_id, status_id }: any) => {
          try {
            const res = await UpdateDomainStatus(domain_id, status_id)
            return res.data
          } catch (err: any) {
            return err.message
          }
        }
    )
  export const getCustomerDomains = createAsyncThunk(
      'customerDomains/getCustomerDomains',
      async({customer_id}: any) => {
          try{
              const res = await GetDomainsByCustomer(customer_id)
              return res.data
          } catch (err: any) {
            return err.message
          }
      }
    )
  export const getStatusTypes = createAsyncThunk(
      'customerDomains/getStatusTypes',
      async() => {
          try{
              const res: any = await GetStatusTypes()
              return res.data
          } catch (err: any) {
              return err.message
          }
      }   
    )


    const customerDomainsSlice = createSlice({
        name: 'customerDomains',
        initialState: customerDomainsAdapter.getInitialState({
          loading: false,
          error: '',
          statusTypes: [],
          copiedLink: '',
          success: false,
          verified: 0,
          pending: 0,
          domainFilters: []
        }),
        reducers: {
          setCopiedLink(state, action){
            state.copiedLink = action.payload
          },
          setDomainFilters(state, action) {
            state.domainFilters = action.payload
          }
        },
        extraReducers(builder) {
          builder
            .addCase(getCustomerDomains.pending, (state, action) => {
              state.loading = true
            })
            .addCase(
                getCustomerDomains.fulfilled,
              (state, action: PayloadAction<any>) => {
                state.loading = false
                customerDomainsAdapter.setAll(state, action.payload)
                state.verified = action.payload.filter((domain:any) => {
                  const statusId = domain.status_id
                  return statusId.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
                }).length
                state.pending = action.payload.filter((domain:any) => {
                  const statusId = domain.status_id
                  return !statusId.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
                }).length
                state.success = true
              }
            )
            .addCase(
                getCustomerDomains.rejected,
              (state, action: PayloadAction<any>) => {
                state.loading = false
                state.error = action.payload
              }
            )
            .addCase(getStatusTypes.pending, (state, action) => {
              state.loading = true
            })
            .addCase(getStatusTypes.fulfilled, (state, action: PayloadAction<any>) => {
              state.loading = false
              state.statusTypes = action.payload
            })
            .addCase(getStatusTypes.rejected, (state, action: PayloadAction<any>) => {
              state.loading = false
              state.error = action.payload
            })
            .addCase(updateDomainStatus.pending, (state, action) => {
              state.loading = true
            })
            .addCase(
                updateDomainStatus.fulfilled,
              (state, action: PayloadAction<any>) => {
                state.loading = false
                // customerUsersAdapter.updateOne() = action.payload
              }
            )
            .addCase(updateDomainStatus.rejected, (state, action: PayloadAction<any>) => {
              state.loading = false
              state.error = action.payload
            })
        }
      })

export const { setCopiedLink, setDomainFilters } = customerDomainsSlice.actions
export const customerDomainsState = (state: RootState) => state.customerDomains
export default customerDomainsSlice.reducer
