import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { Mixpanel } from './components/utils/mixPanel/mixPanel'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KQ23GP6',
  
}

TagManager.initialize(tagManagerArgs)

if (process.env.REACT_APP_STAGE === 'production') {
  console.log = () => {}
}
const searchParams = new URLSearchParams(window.location.search)
const utm_email = searchParams.get('utm_email')
if (utm_email) {
  Mixpanel.track('Click From Mail', {'mail': utm_email})
}

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
