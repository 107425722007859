import {
  createSlice,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from '../store'

export type NotificationType = {
  id: string
  title: string
  text: string
  type: string
  timer: boolean
  button: boolean
  buttonText?: string
  buttonFunction?: Function
  secondButton?: boolean
  secondButtonText?: string
  secondButtonFunction?: Function
}

export const notificationsAdapter = createEntityAdapter<NotificationType>({
  selectId: (notification) => notification.id
  // sortComparer: (a, b) => a.title.localeCompare(b.title)
})

export const notificationsSelectors =
  notificationsAdapter.getSelectors<RootState>(
    (state) => state.notifications
  )

export const { selectAll, selectById, selectEntities, selectTotal } =
  notificationsSelectors

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState({
    urlCoppied: false
  }),
  reducers: {
    addNewNotification(state, action) {
      notificationsAdapter.addOne(state, action.payload)
    },
    removeNotification(state, action) {
      notificationsAdapter.removeOne(state, action.payload)
    },
    removeAllNotifications(state, action) {
      notificationsAdapter.removeAll(state)
    },
    setCoppiedTooltip(state, action: PayloadAction<any>) {
      state.urlCoppied = action.payload
    }
  }
})

export const NotificationsState = (state: RootState) => state.notifications

export const { addNewNotification, removeNotification, setCoppiedTooltip, removeAllNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer
