import { Transition, Dialog } from '@headlessui/react'
import { CheckIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState, updatePGUserData } from '../../../app/slices/authSlice'
import {
  integrationSidebarToggleStatus,
  openDiscoverMode,
  uiState
} from '../../../app/slices/uiSlice'
import Button from '../../utils/elements/Button'
import { IntegrationsTypes } from '../../utils/enums'
import Domain from '../../utils/layout/FirstPopup/Steps/Domain'
import JobTitle from '../../utils/layout/FirstPopup/Steps/JobTitle'
import OrgName from '../../utils/layout/FirstPopup/Steps/OrgName'
import UserName from '../../utils/layout/FirstPopup/Steps/UserName'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'

interface IntegrationPopupProps {}

const IntegrationPopup: React.FC<IntegrationPopupProps> = ({}) => {
  const dispatch = useAppDispatch()
  const isMobile = useWindowSize()
  const { user } = useAppSelector(authState)
  const { integration_id, onBoardingMode } = useAppSelector(uiState)
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const [leftTitle, setLeftTitle] = useState('')
  const navigate = useNavigate()
  const [integration, setIntegrationName] = useState('')

  useEffect(() => {
    switch (integration_id) {
      case IntegrationsTypes.ZENDESK:
        setLeftTitle('Record + Zendesk Integration')
        setIntegrationName('Zendesk')
        break;
      case IntegrationsTypes.INTERCOM:
        setLeftTitle('Record + Intercom Integration')
        setIntegrationName('Intercom')
        break;
      case IntegrationsTypes.SENTRY:
        setLeftTitle('Record + Sentry Integration')
        setIntegrationName('Sentry')
        break;
    }
  }, [integration_id])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {
          Mixpanel.track('Integration Popup Closed', {
            email: user.email,
            user_id: user.id,
            customer_id: user.customer_id
          })
          setOpen(false)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto sm:overflow-y-hidden">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={
                  'relative bg-white rounded-lg min-h-95v w-full pb-4 text-left overflow-hidden md:overflow-y-hidden md:overflow-x-hidden md:max-h-maxPopup shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:h-full md:min-h-full'
                }
              >
                <div className="purple"></div>
                <div className="yellow"></div>
                <div className="ml-8 mt-8 flex flex-row">
                  <img
                    className="h-8 w-auto sm:h-10 z-50"
                    src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/logoicon.png"
                    alt="record"
                  />
                  <span className="ml-4 mt-2 text-tuto-blue font-semibold">
                    {leftTitle}
                  </span>
                </div>
                <div className="ml-8 mt-8 flex justify-center">
                  <img
                    className="sm:h-80 sm:w-80 z-50"
                    src="https://assets.torecord.it/First+.png"
                    alt="record"
                  />
                </div>
                <div className="ml-8 mt-8 text-center text-3xl font-semibold">
                  You’re now connected to {integration}
                </div>
                <div className="ml-8 mt-8 text-center text-m text-dark-gray">
                  Now your Record experience will be even faster.
                  <br /> Let’s see how to use it
                </div>
                <div className="text-center pb-4 pt-8 flex flex-col items-center md:mx-64">
                  <Button
                    loading={false}
                    dataCy="first-popup-button"
                    inverted={false}
                    disabled={false}
                    type={'button'}
                    text="Done"
                    withIcon={false}
                    clickFunction={() => {
                      dispatch(integrationSidebarToggleStatus(true))
                      if (onBoardingMode) {
                        setOpen(false)
                      } else {
                        navigate('/settings/integrations')
                      }
                      setOpen(false)
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default IntegrationPopup
