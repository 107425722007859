import React from 'react'

interface ShortUuidProps {
  text: any
}

const ShortUuid: React.FunctionComponent<ShortUuidProps> = ({ text }) => {
  return text &&  text.split('-')[0]
}

export default ShortUuid