import React from 'react'

const EmptyRow = () => {
  return (
    <tr className=" relative transition duration-300">
      <td></td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 w-4/12">
        <div className="flex items-center text-white hover:">.</div>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="flex items-center">
          <div className="text-gray-500"></div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-gray-500"></div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-gray-500"></div>
          </div>
        </div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center cursor-pointer">
                          <EllipsisHorizontalIcon className="w-6 h-6" />
                        </td> */}
    </tr>
  )
}

export default EmptyRow
