import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Button from '../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import FormInput from '../utils/forms/FormInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  authState,
  loginUserMenual
} from '../../app/slices/authSlice'
import UserPool from './UserPool'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import AuthError from './AuthError'
import { useAppDispatch } from '../../app/hooks'
import { Mixpanel } from '../utils/mixPanel/mixPanel'
import { unprotectedPostRequest } from '../../app/apis/apiGenerics'

interface IFormConfirmationInputs {
  code: string
}

const schema = yup.object().shape({
  code: yup.string().required()
})

interface IConfirmationFormProps {
  email: string
  newUserPassword: string
  invitationDetails?: any
}
const ConfirmationForm: React.FC<IConfirmationFormProps> = ({
  email,
  newUserPassword,
  invitationDetails
}) => {
  const dispatch = useAppDispatch()
  const { isLoggedIn, codeStep } = useSelector(authState)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const role = searchParams.get('role')
  let customer_id = searchParams.get('state')
  const stateArr = customer_id?.split('_')
  if (stateArr && stateArr.length > 1) {
    customer_id = null
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormConfirmationInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const formConfirmationSubmitHandler: SubmitHandler<
    IFormConfirmationInputs
  > = async (formData: IFormConfirmationInputs) => {
    Mixpanel.track('Confirmation Code Submit', { email: email })
    confirmUserRegistration(email, formData.code)
  }
  const confirmUserRegistration = (email: string, verificationCode: string) => {
    // console.log('entered confirmUserRegistration')
    setLoading(true)
    const newUser = new CognitoUser({
      Username: email,
      Pool: UserPool
    })

    newUser.confirmRegistration(verificationCode, true, function (err, result) {
      if (err) {
        console.log(err)
        setError(err.message)
        setLoading(false)
      }

      if (result === 'SUCCESS') {
        
        Mixpanel.alias(newUser.getUsername())
       

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: newUserPassword
        })

        newUser.authenticateUser(authDetails, {
          onSuccess: async (data: any) => {
            
            setLoading(false)
            if (invitationDetails.active) {
             
              await unprotectedPostRequest('users/updateInvitationStatus', {
                invitation_id: invitationDetails.invitation.id,
                isCompleted: true
              })
              dispatch(
                loginUserMenual({
                  token: data.idToken.jwtToken,
                  invitation_id: invitationDetails.invitation.id
                })
              )
            } else {
              
              dispatch(loginUserMenual({
                token: data.idToken.jwtToken,
                invitation_id: ''
              }))
            }
          },
          onFailure: (err) => {
            
            setLoading(false)
            setError(err.message)
            // console.log(err.message)
          }
        })
      }
    })
  }

  const redirectToHomepage = () => {
    if (isLoggedIn) {
      return <Navigate to="/" />
    }
  }

  return (
    <div>
      {redirectToHomepage()}
      <div className="flex flex-col items-start">
        <h2 className="general-title onboarding login-title">
          Verify your account
        </h2>
        <div className="paragraph-small mb-4">
          Check your email for a confirmation code
        </div>
      </div>
      <form id='code_confirm' onSubmit={handleSubmit(formConfirmationSubmitHandler)}>
        <FormInput
          name="code"
          type="text"
          label="Code Confirmation"
          placeholder=""
          touched={touchedFields.code}
          error={errors.code?.message}
          watch={watch}
          registerFunction={register}
          isCustomClass={false}
          customClass={undefined}
        />
        <Button
          type={'submit'}
          disabled={false}
          loading={loading}
          withIcon={true}
          Icon={ArrowRightIcon}
          text={'Signup'}
          inverted={false}
        />
      </form>
      <div className="mt-4">
        {error && <AuthError title="error" description={error} />}
      </div>
    </div>
  )
}

export default ConfirmationForm
