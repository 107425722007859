import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice'
import { getCustomerStepsDone, uiState, getCustomerSteps } from '../../../app/slices/uiSlice'
import { toggleSlider } from '../../../app/slices/uiSlice'
import { Mixpanel } from '../mixPanel/mixPanel'

const Steps = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const { remainingSteps } = useAppSelector(uiState)
  const domains = useSelector(customerDomainsSelectors.selectAll)
  const verifiedDomains: any =
    domains &&
    domains.length > 0 &&
    domains.filter((domain) => {
      return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
    })

  useEffect(() => {
    if(user && user.customer_id){
      dispatch(getCustomerStepsDone(user.customer_id))
      dispatch(getCustomerSteps(user.customer_id))
    }
  }, [user])

  return (
    <div onClick={() => {
      Mixpanel.track('Clicked on Quick Start', {
        email: user.email,
        user_id: user.id,
        customer_id: user.customer_id
      })
      dispatch(toggleSlider())}} className={verifiedDomains && verifiedDomains.length > 0 ? "box mr-12 px-2 py-1 flex cursor-pointer rounded-md hover:bg-secondary transition duration-300" : "box mr-12 px-2 py-1 flex cursor-pointer rounded-md bg-white text-tuto-primary transition duration-300"} {...({'data-cy':'quick start'})}>
      <div className="precent">
        <svg className='w-20 h-16'>
          <circle cx="18" cy="18" r="18"></circle>
          <circle
            cx="18"
            cy="18"
            r="18"
            style={{
              strokeDashoffset: `calc(115 - (115 * ${
                remainingSteps === 4 ? 0 : remainingSteps === 3 ? 25 : remainingSteps === 2 ? 50 : remainingSteps === 1 ? 75 : 100
              }) / 100)`
            }}
          ></circle>
        </svg>
        <div className="number">
          <p className={`${remainingSteps === 0 ? 'bg-green-400' : 'bg-tuto-primary'} rounded-full font-bold flex items-center justify-center`}>
            {remainingSteps === 0 ? <CheckIcon className='w-4 h-4' /> : remainingSteps }
          </p>
        </div>
      </div>
      <div className='ml-2' >
        <div className='font-bold'>Quick Start</div>
        <div className='text-xs'>
          {remainingSteps === 0 ? 'Youre all set!' : remainingSteps + ' remaining tasks '} 
          </div>
      </div>
    </div>
  )
}

export default Steps
