import { FC, useEffect, useState } from 'react'
import ButtonTooltip from '../../utils/elements/ButtonTooltip'
import Tags from './Tags'
import Badge from './Badge'
import { unprotectedGetRequest } from '../../../app/apis/apiGenerics'
import { useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'

interface AnalyticsTabProps {
  emotions: any
  ai: any
  pages: any
  record_id: string,
  tags:any,
  source_id: string
}
const AnalyticsTab: FC<AnalyticsTabProps> = ({ emotions, ai, record_id, tags, source_id }) => {
  const [sentimentrank, setSentimentRank] = useState(0)
  const [customerTags, setCustomerTags] = useState([])
  const {user} = useAppSelector(authState)
  const [sessionTags, setSessionTags] = useState([])

  useEffect(() => {
    if (ai && ai.sentiments !== undefined) {
      if (ai.sentiments.length > 0) {
        const pos = ai.sentiments.filter(
          (sentiment: any) => sentiment.value === 'POS'
        )
        if (pos.length > 0) {
          setSentimentRank((pos.length / ai.sentiments.length) * 100)
        } else {
          setSentimentRank(0)
        }
      }
    }
  }, [ai])

  useEffect(() => {
    getTags()
    setSessionTags(tags)
    return () => {
      setCustomerTags([])
      setSessionTags([])
    }
  }, [record_id])

  useEffect(() => {
    return () => {

    }
  },[])

  

  const getTags = async () => {
    const tags = await unprotectedGetRequest(`customers/getTags?customer_id=${user.customer_id}`)
    
    setCustomerTags(tags.data)
  }

  return (
    <div className="relative px-2">
      <div>
        <div className="mb-4">
          <h3 className="font-semibold text-sm mb-2">Topics</h3>
          <p className="text-xs text-dark-gray">
            What we understand based on our AI model
          </p>
        </div>
        {ai && ai.topics && ai.topics.length > 0 ? (
          <div className="flex items-center flex-wrap">
            {ai.topics.map((topic: any) => (
              <div className="mr-2">
                <Badge text={topic}  close={true}/>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-xs text-dark-gray font-semibold">
            No topics found for this record
          </p>
        )}
      </div>
      <div>
        <div className="my-4">
          <h3 className="font-semibold text-sm mb-2">Sentiment</h3>
          <p className="text-xs text-dark-gray">
            Using our AI model we find sentences with negative or positive
            sentiments. Note that the location is relative to the specific
            recordings and not all of them.
          </p>
          
        </div>
        {ai && ai.sentiments && ai.sentiments.length > 0 ? (
          <div className="flex items-center">
            <div className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-5-5h2a3 3 0 0 1 6 0h2a5 5 0 0 0-10 0zm1-6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm8 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
            </div>
            <input
              className="sentiment"
              min="0"
              max="100"
              value={sentimentrank}
              type="range"
              readOnly
            />
            <div className="ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 9c-2 0-3.667.333-5 1a5 5 0 0 0 10 0c-1.333-.667-3-1-5-1zM8.5 7c-1.152 0-2.122.78-2.412 1.84L6.05 9h4.9A2.5 2.5 0 0 0 8.5 7zm7 0c-1.152 0-2.122.78-2.412 1.84L13.05 9h4.9a2.5 2.5 0 0 0-2.45-2z" />
              </svg>
            </div>
          </div>
        ) : (
          <p className="text-xs font-semibold text-dark-gray">
            No sentiments found for this record
          </p>
        )}
      </div>
      {/* {pages && pages.length > 0 && (
        <div>
          <div className="my-4">
            <h3 className="font-semibold text-sm mb-2">Pages</h3>
            <p className="text-xs text-dark-gray">
              We automatically tag pages that your user visits during the
              recording.
            </p>
          </div>
          <div className="flex items-center flex-wrap relative ">
            {pages.map((page: any, index: number) => (
              <div key={page+index}  style={{zIndex:  `${pages.length - index}`}}>

              <ButtonTooltip text={page}>
                <div
                  key={page + index}
                  className="mr-2 py-2  px-4 break-all rounded-md bg-gray-400 text-white text-sm font-semibold mb-2"
                >
                  {new URL(page).pathname}
                </div>
              </ButtonTooltip>
              </div>
            ))}
          </div>
        </div>
      )} */}
      <div >
        <div className="my-4">
          <h3 className="font-semibold text-sm mb-2">Emotions</h3>
          <p className="text-xs text-dark-gray">
            We automatically tag for you emotion the user express during the
            recording, you can Use this for qualetive research
          </p>
          {!emotions && (
            <p className="text-xs font-semibold text-dark-gray mt-2">
              No emotions found for thie record
            </p>
          )}
        </div>
        {emotions && (
          <div className="flex items-center">
            {emotions &&
              emotions.map((emoition: any, index: number) => (
                <ButtonTooltip key={emoition.name + index} text={emoition.name}>
                  <div className="p-1 px-2 text-xl rounded bg-gray-400 mr-2 flex items-center justify-center">
                    {emoition.emoji}
                  </div>
                </ButtonTooltip>
              ))}
          </div>
        )}
      </div>
      <div className='mb-36'>
        <div className="my-4">
          <h3 className="font-semibold text-sm mb-2">Manual Tags</h3>
          <p className="text-xs text-dark-gray">
          You can add your manual tag for each recording to get a better understanding of the suit your specific needs
          </p>
        </div>
        
          <div className="flex items-center">
          
          
             <Tags source_id={source_id} customer_tags={customerTags} record_id={record_id} record_tags={sessionTags} />
          
          </div>
       
      </div>
    </div>
  )
}

export default AnalyticsTab
