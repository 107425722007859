import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import AdvancedFiltering from '../Problems/filtering/AdvancedFiltering'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { v4 as uuidv4 } from 'uuid'
import {
  ComputerDesktopIcon,
  CpuChipIcon,
  GlobeAmericasIcon,
  MicrophoneIcon,
  UserIcon,
  EnvelopeIcon
} from '@heroicons/react/24/solid'
import { problemsState, resetFilters, setOrigins, setReqestFiltersReqObj } from '../../../app/slices/ProblemsSlice'
import { authState } from '../../../app/slices/authSlice'
import Button from '../../utils/elements/Button'
import {
  createInvestigation,
  setCurrentInvestigation,
  updateInvestigation
} from '../../../app/slices/investigationsSlice'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { useNavigate } from 'react-router-dom'

const adavancedFilteringData = [
  {
    type: 'screen',
    title: 'Screen Size',
    Icon: ComputerDesktopIcon
  },
  {
    type: 'os',
    title: 'Operating System',
    Icon: CpuChipIcon
  },
  {
    type: 'browser',
    title: 'Browser',
    Icon: GlobeAmericasIcon
  },
  {
    type: 'mic',
    title: 'Mic',
    Icon: MicrophoneIcon
  },
  {
    type: 'user',
    title: 'User',
    Icon: UserIcon
  },
  {
    type: 'email',
    title: 'Email',
    Icon: EnvelopeIcon
  }
]

interface InvestigationCreateProps {
  type: string
  investigation?: any
  setFormOpen?: any
}

const InvestigationCreate: FC<InvestigationCreateProps> = ({
  type,
  investigation,
  setFormOpen
}) => {
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(problemsState)
  const [investigationName, setInvestigationName] = useState('')
  const [error, setError] = useState('')
  
  const [formValid, setFormValid] = useState(false)
  const { filterRecordsReq } = useAppSelector(problemsState)
  const navigate = useNavigate()

  useEffect(() => {
    if(type === 'update') {
      setFormValid(true)
      setInvestigation(investigation)
      setInvestigationName(investigation.name)
      // dispatch(resetFilters())
    } else {
      dispatch(resetFilters())
    }
    
  }, [])

  const setInvestigation = (investigation:any) => {
    // dispatch(resetFilters())
    const invetigationFilters = JSON.parse(investigation.query_json)
    dispatch(setReqestFiltersReqObj(invetigationFilters))
    dispatch(setCurrentInvestigation(investigation))
    
    const domainsOfView = JSON.parse(investigation.query_json).Origins
      dispatch(setOrigins({ domains:domainsOfView, type: 'view' }))
    // setFormOpen(true)
  }

  const handleViewNameChange = (event: any) => {
    setInvestigationName(event.target.value)
    if (event.target.value.length < 2) {
      setError('View name must be at least 2 charachters')
      setFormValid(false)
    } else {
      setError('')
      setFormValid(true)
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    // console.log('entered the submit')
    if (investigationName.length < 2) {
      setError('Investigation name must be at least 2 charachters')
    }
    if (formValid) {
      const investigationData: any = {
        name: investigationName,
        query_json: filterRecordsReq,
        customer_id: user.customer_id,
        user_id: user.id
      }
      if (type === 'create') {
        const createdInvestigation = await dispatch(
          createInvestigation({ investigationData })
        )
        if (createdInvestigation.payload[0].id) {
          dispatch(
            addNewNotification({
              text: `Investigation ${investigationName} Created successfully`,
              id: uuidv4(),
              title: 'Investigation Created!',
              type: 'SUCCESS',
              timer: true,
              button: false
            })
          )
          navigate(`/investigate/${createdInvestigation.payload[0].id}`)
          
        }
      }
      if (type === 'update') {
        console.log('updated ')
        const updatedRes = await dispatch(
          updateInvestigation({
            investigation_id: investigation.id,
            query_json: filterRecordsReq,
            name: investigationName
          })
        )
        if (updatedRes.payload[0].id) {
          dispatch(
            addNewNotification({
              text: `Investigation Settings ${investigationName} updated successfully`,
              id: uuidv4(),
              title: 'Investigation Settings Updated!',
              type: 'SUCCESS',
              timer: true,
              button: false
            })
          )
          setFormOpen(false)
          navigate(`/investigate/${updatedRes.payload[0].id}`)
        }
      }
    }
  }

  return (
    <div className="flex flex-col justify-between min-h-70v">
      <div className="flex flex-col items-start gap-6 z-10">
        <div className='relative'>
          <input
            name="customer"
            value={investigationName}
            onChange={handleViewNameChange}
            className="p-4 min-w-full text-center font-light bg-transparent text-2xl border-b border-gray-400 focus:outline-none"
            placeholder={`${user.name}'s investigation`}
          />
          <span className="text-red-400 text-xs absolute -bottom-6 right-0 left-0 text-center">
            {error && error}
          </span>
        </div>
        <AdvancedFiltering
          title={'Domains'}
          categories={filters.Origins}
          activeFilterTab={''}
          withCategories={false}
        />

        <AdvancedFiltering
          title={'Additional Info'}
          categories={adavancedFilteringData}
          activeFilterTab={adavancedFilteringData[0].type}
          withCategories={true}
        />
        {filters.CustomData && filters.CustomData.length > 0 && (
          <AdvancedFiltering
            title={'Custom'}
            categories={filters.customDataCategories}
            activeFilterTab={filters.customDataCategories[0].type}
            withCategories={true}
          />
        )}
        <AdvancedFiltering
          title={'Investigate'}
          categories={filters.Network.concat(filters.Console)}
          activeFilterTab={''}
          withCategories={false}
        />
      </div>
      <div>
        <Button
          loading={false}
          inverted={false}
          disabled={false}
          text={type === 'update' ?  'Update': "Create New"}
          type="submit"
          withIcon={false}
          clickFunction={handleSubmit}
        />
      </div>
     
    </div>
  )
}

export default InvestigationCreate
