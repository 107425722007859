export const customerSteps = {
  CHANGE_SETTINGS: '41dd6dfc-feb0-4e54-ac6c-2ef43e47b7c1',
  CHANGE_USER_NAME: '2ae4852a-b86a-4379-b84d-cebf52790eb4',
  CDN_INSTALLED: '305cc9df-d5a7-4032-8183-113741b472ed',
  VERIFY_DOMAIN: '66493608-0278-4ea1-a388-1636ad6799ea',
  RECORD_CREATED: 'ffba2553-25e7-4715-a0fd-67e92e404365',
  ADDED_TEAMMATES: '3dc68770-39c0-42a0-8223-5ea73a598c13',
  PHONE_UPDATED: 'b8c5b3b9-c4c1-4b32-9f57-cbead970b37f'
}

export const IntegrationsTypes = {
  ZENDESK: '12ba17dd-d488-4f0d-85ce-3f12634f819e',
  INTERCOM: '35d81a3b-ecab-439d-a982-f270f5e928e0',
  SENTRY: '1bda7395-da29-4311-89ee-5eaa7a4fd7f5'
}

export const OnBoardingStepsE = {
  CHANGE_USER_NAME: '9e51ceb7-642c-4dbd-a6cd-2cf377146238',
  CHANGE_ORG_NAME: 'e7d57cef-423d-4825-96f7-abef93f0a22c',
  VALUE: 'd59a2aaa-f8f8-45c3-8c91-d9bb161c0312',
  INSTALLATION: 'da3a7dc4-0113-4943-91a9-25f5d852074b'
}

export const AdditionalInfo = {
  CONSOLE: '497b71a7-1cd7-48b4-b09b-3626e25cada0',
  BROWSER_INFO: '6a2d71e2-a2ab-4326-ae3c-c2aa3ef929e5',
  NETWORK: 'b69cd5dd-f52c-4b5b-b2f7-e738eb8ecc0e',
  ANALYTICS: '8bceaf6e-72de-4092-bff3-06292ff69cc8'
}

export const tiersPopupDetails = {
  CONSOLE: '497b71a7-1cd7-48b4-b09b-3626e25cada0',
  BROWSER_INFO: '6a2d71e2-a2ab-4326-ae3c-c2aa3ef929e5',
  NETWORK: 'b69cd5dd-f52c-4b5b-b2f7-e738eb8ecc0e',
  ANALYTICS: '8bceaf6e-72de-4092-bff3-06292ff69cc8'
}

export const demoVars = {
  INVESTIGATION_ID: '1234'
}

