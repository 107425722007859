import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { investigationsState } from '../../../app/slices/investigationsSlice';

type Tab = {
  title: string;
  component: React.ComponentType<any>;
  props: any;
};

interface TabsProps {
  tabs: Tab[];
}

const RightTabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {logsCount} = useAppSelector(investigationsState)
  

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className='flex flex-col gap-4 relative'>
      <div className='flex items-center w-full justify-around sticky top-0 bg-gray-100 z-10 pt-4'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={` ${activeTabIndex === index ? 'bg-tuto-light-blue text-white':'' } rounded-2xl px-4 py-1`}
            onClick={() => handleTabClick(index)}
            style={{ cursor: 'pointer', fontWeight: activeTabIndex === index ? 'bold' : 'normal' }}
          >
            {tab.title}
            {tab.title === 'FEED' ? <span className='rounded-full ml-1 bg-gray-300 px-2 py-1'>{logsCount}</span>  : ''}
          </div>
        ))}
      </div>
      <div className='p-4'>
        {tabs.map((tab, index) => (
          <div key={index} style={{ display: activeTabIndex === index ? 'block' : 'none' }}>
            <tab.component {...tab.props } />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RightTabs;
