import Button from '../elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  uiState,
  nextDiscoverStep,
  closeDiscoverMode,
  nextPGDiscoverStep,
  nextPGRecordDiscoverStep,
  prevDiscoverStep
} from '../../../app/slices/uiSlice'
import { Mixpanel } from './../../utils/mixPanel/mixPanel'
import { useEffect, useState } from 'react'
import { authState } from '../../../app/slices/authSlice'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import { customerDomainsState } from '../../../app/slices/customerDomainsSlice'

interface StepByStepProps {
  title: string
  number: number
  description: string
  isRecordPage?: boolean
}

const StepByStep: React.FC<StepByStepProps> = ({
  title,
  number,
  description,
  isRecordPage
}) => {
  const dispatch = useAppDispatch()
  const { isPlayground } = useAppSelector(authState)
  const {
    discoverSteps,
    activeDiscoverStep,
    discoverMode,
    pgDiscoverSteps,
    pgRecordDiscoverSteps,
    recordDiscoverMode
  } = useAppSelector(uiState)
  const steps = isPlayground
    ? isRecordPage
      ? pgRecordDiscoverSteps
      : pgDiscoverSteps
    : discoverSteps
  const [showStep, setShowStep] = useState(true)
  const { copiedLink } = useAppSelector(customerDomainsState)

  const getNextBtnText = () => {
    let btnText = ''
    if(isPlayground){
      if(!isRecordPage && number === 5){
        btnText = 'Copy!'
      }
      else{
        btnText = number ===
        (isPlayground
          ? isRecordPage
            ? pgRecordDiscoverSteps.length
            : pgDiscoverSteps.length
          : 5)
          ? isRecordPage
            ? 'Finish!'
            : 'Paste!'
          : 'Next'
      }
    }
    else{
      btnText = number === discoverSteps.length ? 'Got it!' : 'Next'
    }
    return btnText
  }

  useEffect(() => {
    if (window.innerWidth < 678) {
      setShowStep(false)
    }
  }, [])

  const position: any = {
    1: 'left-52 top-32',
    2: 'right-48 top-16',
    3: 'left-52 top-24',
    4: 'left-12 top-16',
    5: 'left-12 top-16'
  }

  const dotPosition: any = {
    1: 'top-14 -left-2',
    2: 'right-36 -top-2',
    3: 'top-14 -left-2',
    4: 'right-64 -top-2',
    5: 'right-40 -top-2'
  }

  const pgPosition: any = {
    1: 'right-20 top-1/3',
    2: 'left-4 top-16',
    3: 'left-1/3 top-20',
    4: 'right-16 bottom-20',
    5: 'right-0 bottom-20',
    6: 'left-1/3 top-2'
  }

  const pgDotPosition: any = {
    1: 'bottom-8 -right-2',
    2: 'right-36 -top-2',
    3: '-top-2 left-1/2',
    4: 'right-40 -bottom-2',
    5: 'right-14 -bottom-2',
    6: 'right-40 -top-2'
  }

  const pgRecordPosition: any = {
    1: 'left-96 bottom-52',
    2: 'right-1/3 top-28',
    3: 'right-60 top-28',
    4: 'left-20 top-20'
  }

  const pgRecordDottPosition: any = {
    1: '-bottom-2 right-24',
    2: '-right-2 top-10',
    3: 'right-40 -bottom-2',
    4: 'right-52 -top-2'
  }

  return showStep &&
    (discoverMode || recordDiscoverMode) &&
    number === activeDiscoverStep ? (
    <div
      data-cy='verified-domains-text-box'
      className={`absolute shadow-lg ${
        isPlayground
          ? isRecordPage
            ? pgRecordPosition[number]
            : pgPosition[number]
          : position[number]
      } bg-white z-20 max-w-xs border border-tuto-primary rounded-lg px-4 py-2 scale-in-center`}
    >
      <div
        className={`bg-white absolute z-30 ${
          isPlayground
            ? isRecordPage
              ? pgRecordDottPosition[number]
              : pgDotPosition[number]
            : dotPosition[number]
        } rounded-full w-4 h-4 shadow-2xl border border-tuto-primary overflow-hidden pulsate-fwd`}
      >
        <div className="border-tuto-primary bg-tuto-secondary absolute inset-0"></div>
      </div>
      <h1 className="font-semibold mb-2">
        {number}/
        {isPlayground
          ? isRecordPage
            ? pgRecordDiscoverSteps.length
            : pgDiscoverSteps.length
          : discoverSteps.length}{' '}
        {title}
      </h1>
      <p className="font-light">{description}</p>
      <div className="flex items-center justify-between mt-4">
        <div>
        <button
            data-cy='back-button'
            className="p-2 text-sm text-gray-600"
            onClick={() => {
              Mixpanel.track('Exit discover mode', {
                step: title,
                playground_mode: isPlayground
              })
              dispatch(prevDiscoverStep())
            }}
          >
            Back
          </button>
        </div>
        <div className='flex items-center'  data-cy = 'exit-button'>
          <button
            className="p-2 text-sm"
            onClick={() => {
              Mixpanel.track('Exit discover mode', {
                step: title,
                playground_mode: isPlayground
              })
              dispatch(closeDiscoverMode())
            }}
          >
            Exit
          </button>
          <Button
            dataCy='finish-got-it-next-button'
            inverted={false}
            loading={false}
            disabled={false}
            text={getNextBtnText()}
            type="button"
            spacialClass="text-sm"
            withIcon={true}
            Icon={ArrowRightIcon}
            clickFunction={() => {
              Mixpanel.track('Clicked on next discover step', {
                step: title,
                playground_mode: isPlayground
              })
              if (isPlayground) {
                if(!isRecordPage && number === 5){
                  navigator.clipboard.writeText(
                        copiedLink
                      )
                  dispatch(setCoppiedTooltip(true))
                }
                if(!isRecordPage && number === 6 && copiedLink !== ''){
                  window.location.href = copiedLink
                }
                dispatch(
                  isRecordPage
                    ? nextPGRecordDiscoverStep()
                    : nextPGDiscoverStep()
                )
              } else {
                dispatch(nextDiscoverStep())
              }
            }}
          />
        </div>
      </div>
    </div>
  ) : null
}

export default StepByStep
