import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  stickyClass?: string
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children,stickyClass, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className='relative'>
      <button
        className={`text-tuto-primary bg-white z-10 font-semibold text-xl p-2 rounded w-full text-left flex items-center sticky ${stickyClass}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ChevronRightIcon
          className={`w-5 h-5 mr-2 transform ${isOpen ? 'rotate-90' : ''}`} 
          // rotate-90 will rotate the icon to point downwards when the collapsible is open
          aria-hidden="true"
        />
        {title}
      </button>
      {isOpen && (
        <div 
          className="mt-2" 
          // style={{ maxHeight: maxHeight }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Collapsible;
