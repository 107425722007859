export function getColor(category: string, subCategory: string): string {
  const categoryColorMap: { [key: string]: string } = {
    network: 'bg-slate-500 text-slate-300',
    console: 'bg-yellow-300 text-yellow-600',
    page: 'bg-teal-500 text-teal-700',
    'backend log': 'bg-blue-800 text-blue-600',
    mouse_interaction: 'bg-purple-300 text-purple-500 ',
    warning: 'bg-amber-500 text-amber-700 shadow-yellow',
    log: 'bg-indigo-500 text-indigo-200 shadow-blue',
    error: 'bg-red-500 text-red-200 shadow-red',
    info: 'bg-sky-500 text-sky-700 shadow-sky',
    GET: 'bg-slate-500 text-slate-300 shadow-slate',
    POST: 'bg-slate-500 text-slate-300 shadow-slate'
    // add more mappings as needed
  };

  return categoryColorMap[subCategory] || categoryColorMap[category] || 'bg-gray-300'; // default color if not found in map
}
