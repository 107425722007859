import { useEffect, useState } from 'react'
import { useMatch, useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import { unprotectedGetRequest } from '../../../app/apis/apiGenerics'
import Player from '../../utils/player/Player'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Button from '../../utils/elements/Button'
import {
  ArrowLeftIcon,
  ClipboardIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import NavBar from '../../utils/layout/NavBar'
import { authState } from '../../../app/slices/authSlice'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import FormInput from '../../utils/forms/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import config from '../../../config/config'
import CopiedState from '../../utils/layout/CopiedState'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import PageLoader from '../../utils/shared/PageLoader'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

interface IFormInputs {
  email: string
}

const SharedRecord = () => {
  const record: any = useMatch('/sharedRecord/:record')
  const params = useParams()
  const [recordSession, setRecordSession] = useState()
  const [recordData, setRecordData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const {isLoggedIn} = useAppSelector(authState)
  const navigate = useNavigate()
  
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })
  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setLoading(true)
    const body = {
      email: data.email,
      url: window.location.href,
      domain_id: recordData.domain_id
    }
    
    const res = await fetch(`${config.api}/api/v1/records/sendShareRecord`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const parsedRes = await res.json()
    reset()
    if (parsedRes.success) {
      Mixpanel.track("Share Record By Email", {
        "record_id": recordData.id,
        "customer_id": recordData.customer_id,
        "shared_by_email": recordData.shared_by_email,
        "visitor_id": recordData.visitor_id,
        "sent to": data.email
      })
      dispatch(
        addNewNotification({
          text: `Mail sent to ${data.email}`,
          id: uuidv4(),
          title: 'Mail sent!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    } else {
      Mixpanel.track("Share Record By Email Failed", {
        "record_id": recordData.id,
        "customer_id": recordData.customer_id,
        "shared_by_email": recordData.shared_by_email,
        "visitor_id": recordData.visitor_id,
        "sent to": data.email
      })
      addNewNotification({
        text: `Please try again later`,
        id: uuidv4(),
        title: `Error sending mail to ${data.email}`,
        type: 'WARN',
        timer: true,
        button: false
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if(isLoggedIn) {
      
      
      setLoading(false)
      navigate(`/issues/${record.params.record}`)
    }
    setLoading(false)
  }, [isLoggedIn])

  useEffect(() => {
    
    const fetchData = async () => {
      setLoading(true)
      const res = await unprotectedGetRequest(
        `records/getSharedRecord?record_id=${record.params.record}`
      )
      console.log(res)
      setRecordSession(JSON.parse(res.json))
      setRecordData(res.data)
      Mixpanel.register({
        'Page': 'Public Record',
        "record_id": recordData.id,
        "customer_id": recordData.customer_id,
        "shared_by_email": recordData.shared_by_email,
        "visitor_id": recordData.visitor_id  
      })
      Mixpanel.track('Public Record Page View', {})
      setLoading(false)
    }
    fetchData()
    
  
  }, [])

  const copyShare = () => {
    navigator.clipboard.writeText(window.location.href)
    dispatch(setCoppiedTooltip(true))
    Mixpanel.track("Copy Link of Record", {})
  }

  return (
    loading ? <PageLoader /> : <div>
      <NavBar />
      <div className="p-2 text-tuto-blue mt-16">
        {/* <div className="flex items-center">
          <h1 className="general-title">
            {recordData.visitor_id}{' '}
            {moment(new Date(recordData.created_at).toLocaleString()).format(
              'DD/MM/YY'
            )}
          </h1>
        </div> */}
        <div className="grid grid-cols-3 p-4 gap-2">
          <div className="col-span-2">
            {loading ? (
              <div className="flex justify-center items-center min-h-screen">
                <div className="loader"></div>
              </div>
            ) : (
              <div>
                {recordSession && (
                  <Player
                    audioSource={recordData.path}
                    session={recordSession}
                  />
                )}
              </div>
            )}
          </div>
          <div className="col-soan-1">
            <div className="w-full px-4">
              <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                <div className="mb-4">
                  <button
                    onClick={copyShare}
                    className="flex items-center border border-tuto-blue rounded-lg p-2 hover:bg-tuto-blue hover:text-white transition duration-300"
                  >
                    <ClipboardIcon className="w-5 h-5 mr-2" />
                    Copy The Link
                  </button>
                </div>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button 
                        className="flex w-full border-b border-tuto-blue px-4 py-2 text-left text-sm font-medium focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                        onClick={() => Mixpanel.track('Click on Additional Info',{
                          "Page": "Public Record",
                          "record_id": recordData.id,
                          "customer_id": recordData.customer_id,
                          "shared_by_email": recordData.shared_by_email,
                          "visitor_id": recordData.visitor_id,
                          "is_open": open
                        })}
                        >
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-tuto-blue mr-4`}
                        />
                        <span>Additional Information</span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                        <div>
                          <span className="font-semibold">Date:</span>{' '}
                          <span>
                            {' '}
                            {moment(
                              new Date(recordData.created_at).toLocaleString()
                            ).format('DD/MM/YY')}
                          </span>
                        </div>
                        <div>
                          <span className="font-semibold">Duration:</span>{' '}
                          <span>
                            {Math.round(Number(recordData.duration) / 1000)}s
                          </span>
                        </div>
                        {/* <div>
                          <span className="font-semibold">Shared by:</span>{' '}
                          <span>{recordData.shared_by_email}</span>
                        </div> */}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="mt-10" defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button onClick={() => Mixpanel.track('Click on Share Record', {
                          "record_id": recordData.id,
                          "customer_id": recordData.customer_id,
                          "shared_by_email": recordData.shared_by_email,
                          "visitor_id": recordData.visitor_id,
                          "is_open": open
                        })} className="flex w-full border-b border-black px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-tuto-blue mr-4`}
                        />
                        <span>Share Record</span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <div className="mb-4">
                          <p className="mb-4">
                            Want people to know about the Record? Share it.
                          </p>
                          {/* <img
                            className="w-10"
                            src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/whatsapp.png"
                          /> */}
                        </div>
                        <form
                          className="relative rounded-md py-2"
                          onSubmit={handleSubmit(formSubmitHandler)}
                          id="shared_record_page"
                        >
                          <FormInput
                            name="email"
                            type="email"
                            label="Share it using Email"
                            placeholder="Email..."
                            error={errors.email?.message}
                            registerFunction={register}
                            isCustomClass={false}
                            customClass={undefined}
                          />
                          <Button
                            type={'submit'}
                            disabled={false}
                            loading={false}
                            withIcon={true}
                            Icon={PaperAirplaneIcon}
                            text={''}
                            inverted={false}
                            spacialClass="absolute top-2 right-0"
                          />
                        </form>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="mt-10">
                  {({ open }) => (
                    <>
                      <Disclosure.Button 
                        className="flex w-full border-b border-black px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                        onClick={() => Mixpanel.track('Click on About Record', {
                          "record_id": recordData.id,
                          "customer_id": recordData.customer_id,
                          "shared_by_email": recordData.shared_by_email,
                          "visitor_id": recordData.visitor_id,
                          "is_open": open
                        })}
                        >
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-tuto-blue mr-4`}
                        />
                        <span>About Record</span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <h1 className="text-lg text-tuto-blue font-semibold">
                          Bye, Bye Darkness. Hello Clarity.
                        </h1>
                        <p className="mb-4">
                          Let users share their problems visually and share them
                          with your team to knock down support cycle times.
                        </p>
                        <video
                          controls
                          className="rounded-xl"
                          src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/Record_how_it_works_short.mp4"
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link 
                  to='/signup' 
                  className="flex bg-tuto-blue rounded-lg flex-col items-center justify-center p-4 mt-20"
                  onClick={() => Mixpanel.track('Go to Sign Up From Public Record',{ 
                    "location": "Footer",
                    "record_id": recordData.id,
                    "customer_id": recordData.customer_id,
                    "shared_by_email": recordData.shared_by_email,
                    "visitor_id": recordData.visitor_id
                  })}
                >
                  <span className='text-tuto-yellow'>Not a Record Member?</span>
                  <span className='text-white font-semibold'>Get Record For Free</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopiedState />
    </div>
  )
}

export default SharedRecord
