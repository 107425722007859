import { useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { authState } from "../../../app/slices/authSlice"
import Button from "../../utils/elements/Button"
import { Mixpanel } from "../../utils/mixPanel/mixPanel"

interface IntegrationVideosProps {
    videos:any[]
  }

const IntegrationVideos:React.FC<IntegrationVideosProps> = ({
    videos
}) => {
    const { user } = useAppSelector(authState)
    const [selectedVideo, setSelectVideoSrc] = useState<any>({
        src: '',
        btnText: '',
        id: 0
      })
    const [showVideo, setShowVideo] = useState(false)

    const setVideoToShow = (src: string, btnText: string, id: string) => {
        setSelectVideoSrc({ ...selectedVideo, btnText, src, id })
        Mixpanel.track(`Clicked on ${btnText}`, {
          user_id: user.id,
          email: user.email,
          customer_id: user.customer_id
        })
        setShowVideo(true)
        //navigate('/settings/installation')
    }

    useEffect(()=> {
        setSelectVideoSrc({...selectedVideo, btnText: videos[0].btnText, src: videos[0].videoSrc, id: videos[0].id})
        setShowVideo(true)
    },[videos])
    return (
        <div className="flex flex-col">
            {showVideo && (<><div className="text-xl font-semibold mb-2 pt-8">
                {selectedVideo.btnText}
            </div>
            <div className="rounded-xl overflow-hidden">
                    <video width={'100%'} controls src={selectedVideo.src}></video>
                </div></>)}
            <div className="pt-8 flex items-center justify-between">
                {videos.map((video:any) => (
                    <div key={video.btnText}>
                        <Button
                        inverted={false}
                        loading={false}
                        disabled={false}
                        text={video.btnText}
                        type="button"
                        withIcon={false}
                        bgColor="bg-white"
                        textColor={video.id === selectedVideo.id ? "text-tuto-primary" : "text-dark-gray"}
                        spacialClass={video.id === selectedVideo.id ? "border-tuto-primary" : "opacity-80"}
                        clickFunction={() => {
                            setVideoToShow(
                                video.videoSrc,
                                video.btnText,
                                video.id
                                ) 
                        }}
                    />
                </div>
                ))}
            </div>
        </div>
    )
}

export default IntegrationVideos