import Button from '../../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  customerUsersSelectors,
  customerUsersState,
  sendInvitation
} from '../../../app/slices/customerUsersSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { authState } from '../../../app/slices/authSlice'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'


interface IFormInputs {
  email: string
  role: string
}

const TeammatesInput = () => {
  const { roles, invitationLoading } = useSelector(customerUsersState)
  const { user, isPlayground } = useAppSelector(authState)
  const customerUsers = useAppSelector(customerUsersSelectors.selectAll)
  const customerUsersEmail = customerUsers.map((user) => (user.email))
  const dispatch = useAppDispatch()
  const schema = yup.object().shape({
    email: yup.string().email().required().notOneOf(customerUsersEmail, 'Already an active user or has a pending invitation'),
    role: yup.string().required()
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    dispatch(
      sendInvitation({
        customer_id: user.customer_id,
        role_id: data.role,
        teammates: [data.email],
        campaign_id: '799188',
        sender: user.email,
        senderName: user.name,
        inviter_id: user.id,
        name:'',
        is_re_invite: false,
        invitation_id: ''
      })
    )
    Mixpanel.track('User Intives User', {
      user_id: user.id,
      sender_email: user.email,
      invited_email: data.email,
      invited_role: data.role,
      customer_id: user.customer_id
    })
    reset()
    dispatch(
      addNewNotification({
        text: `Invitation sent to ${data.email}`,
        id: uuidv4(),
        title: 'Invitation Sent',
        type: 'SUCCESS',
        timer: true,
        button: false
      })
    )
  }

  return (
    <div className="relative rounded-md shadow-sm">
      <form
        className="relative flex flex-col"
        onSubmit={handleSubmit(formSubmitHandler)}
      >
        <div className='mb-6 md:mb-0'>
          <input
            data-cy = "email-adress-text-box"
            {...register('email')}
            type="email"
            name="email"
            id="email"
            className="focus:ring-indigo-500 focus:border-tuto-primary block md:w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 rounded-md dark:bg-dark-secondary dark:border-dark-text dark:text-dark-text"
            placeholder="Your Teammate Email"
          />
          {errors.email ? (
            <p
              className="absolute top-14  text-xs text-red-600"
              id="email-error"
            >
              {errors.email?.message}
            </p>
          ) : null}
        </div>
        <div className="md:absolute inset-y-0 right-0 flex items-center border-l">
          <label htmlFor="currency" className="sr-only">
            choose
          </label>
          <select
            data-cy ='admin-or-user'
            {...register('role')}
            id="role"
            name="role"
            className="focus:ring-indigo-500 focus:border-tuto-primary h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            {roles.map((role: any) => (
              <option key={role.id} value={role.id}>
                {role.name.toUpperCase()}
              </option>
            ))}
          </select>
          <Button
            dataCy='send-invite-button'
            inverted={true}
            loading={invitationLoading}
            disabled={isPlayground}
            text="Send invite"
            type="submit"
            withIcon={true}
            Icon={ArrowRightIcon}
            spacialClass="text-xs mr-2"
          />
        </div>
      </form>
    </div>
  )
}

export default TeammatesInput
