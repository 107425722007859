import React from 'react'
import { Link } from 'react-router-dom'
import './Auth.css'

interface SelfServedStepProps {
  withSteps: boolean
  imageAdditionalClass?: string
  imageUrl: string
  withLoginLink?: boolean
  withTopPurpleCircle?: boolean
  children: React.ReactNode
  isIntegration?: boolean
}

const AuthWrapper: React.FC<SelfServedStepProps> = ({
  children,
  withSteps,
  imageAdditionalClass,
  imageUrl,
  withLoginLink,
  withTopPurpleCircle,
  isIntegration
}) => {
  return (
    <div className="relative flex flex-col items-center justify-center md:flex-row h-screen overflow-hidden">
      <div className="flex flex-row items-center paragraph-small bottom-2 absolute md:left-16 md:bottom-4 z-10">
        <a href="https://torecord.it/" className=" text-gray-500">
          Back to Record website
        </a>
        {withLoginLink && (
          <div className="flex flex-row items-center">
            <div className="rounded-full h-2 w-2 bg-light-black mx-2"></div>
            <Link to="/login">Login</Link>
          </div>
        )}
      </div>

      <div className="tuto-gradient top md:hidden"></div>
      {withTopPurpleCircle && (
        <div className="tuto-gradient login-circle"></div>
      )}
      <div className="absolute top-4 right-6 md:hidden"></div>
      {/* <img
        className="absolute w-32 xl:w-40 top-6 left-4 md:left-8"
        src="https://assets.torecord.it/recordlogoblack.png"
      /> */}
      <div className="hidden md:block absolute top-4"></div>
      <div className="relative flex mt-auto md:mt-0 items-center sm:px-8 sm:py-6 px-4 py-2 rounded-2xl form-area">
        {children}
      </div>
      <div className="relative mt-auto mb-4 md:mt-0 md:mb-0 z-10">
        <img
          className={`hidden md:block md:w-full md:h-full ${imageAdditionalClass}`}
          src={imageUrl}
        />
        {isIntegration && <div className='mt-16 text-4xl text-light-gray font-bold flex'>
          You are about to save a lot of time
        </div>}
      </div>
      <div className="tuto-gradient bottom md:hidden"></div>
    </div>
  )
}

export default AuthWrapper
