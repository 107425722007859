import React, { useEffect } from 'react';
import './onboarding.css';

import Button from '../../utils/elements/Button';
import { useAppDispatch } from '../../../app/hooks';
import { setOnboardingMode } from '../../../app/slices/uiSlice';
import { setUserOnboardingMode } from '../../../app/slices/authSlice';
import { useNavigate } from 'react-router-dom';

interface IOnboardingInstallationProps {
  setCurrentPage: any
}

const OnBoardingInstallation: React.FC<IOnboardingInstallationProps> = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
  },[]);

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const finshOnboarding = () => {
    dispatch(setOnboardingMode(false))
    dispatch(setUserOnboardingMode(false))
    navigate('/settings/installation')
  }

  return (
    <div className="flex flex-col ">
      <div className="ob-purple"></div>
      <div className="ob-yellow"></div>
      <h1 className="text-4xl text-center">Before we finish the setup we highly recommend <br/> To book an onboarding session with us to make sure</h1>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings-eu1.hubspot.com/ohad-ronen/record-onboarding-session?embed=true"
        dangerouslySetInnerHTML={{ __html: '' }}
      />

      <div className='self-center relative bottom-16'>
        <Button
          inverted={false}
          loading={false}
          disabled={false}
          text="Take me to my dashboard"
          type="button"
          withIcon={false}
          spacialClass=""
          clickFunction={finshOnboarding}
        />
      </div>
    </div>
  );
}

export default OnBoardingInstallation;
