import React from 'react'
import {JSONTree} from 'react-json-tree';

interface InvestigationSimilaritiesProps {
  similarities: any
  title: string
}

const InvestigationSimilarities:React.FC<InvestigationSimilaritiesProps> = ({similarities, title}) => {

  

  const treeTheme = {
    scheme: 'bright',
    base00: 'white',
    base0B: '#14172E',
    base0D: '#8641EF'
    // Add more theme colors here if needed
  };
  return (
    <div className='mb-2'>  
      <h1>{title}</h1>
    <JSONTree hideRoot={true} data={similarities} theme={treeTheme} />
    </div>
  )
}

export default InvestigationSimilarities