import { FC, ReactNode, useRef } from 'react'

interface ButtonTooltip {
  text?: string
  children: ReactNode
  helpText?: boolean
}

const ButtonTooltip: FC<ButtonTooltip> = ({ text, children, helpText }) => {
  const tooltipRef = useRef<HTMLSpanElement>(null)
  const container = useRef<HTMLDivElement>(null)
  return (
    <div
    //  onMouseEnter={({clientX}) => {
    //   if(!tooltipRef.current || !container.current) return
    //   const {left} = container.current.getBoundingClientRect()
    //   tooltipRef.current.style.left = clientX - left + 'px'
    // }} 
    ref={container} className="group relative inline-block ">
      {children}
      <span ref={tooltipRef} className={`invisible z-50  group-hover:visible opacity-0 group-hover:opacity-100 transition bg-tuto-primary text-white left-1/2 -translate-x-1/2 text-sm ${helpText ? 'text-xs' : 'text-sm break-all'} p-1 rounded absolute  ${text && text.length < 50  ? 'whitespace-nowrap' : 'w-64'}  top-full mt-2`}>
        {text}{' '}
      </span>
    </div>
  )
}

export default ButtonTooltip
