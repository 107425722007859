import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './components/pages/Home/Home'
import Settings from './components/pages/Settings/Settings'
import Problems from './components/pages/Problems/Problems'
import Domains from './components/pages/Settings/Options/Domains'
import Installation from './components/pages/Settings/Options/Installation'
import Teammates from './components/pages/Teammates/Teammates'
import Profile from './components/pages/Settings/Options/Profile'
import Login from './components/Auth/Login'
import ProtectedRoutes from './components/Auth/ProtectedRoutes'
import SignUp from './components/Auth/SignUp'
import ResetPassword from './components/Auth/ResetPassword'
import NotificationsProvider from './components/utils/notifiers/NotificationsProvider'
import SingleProblem from './components/pages/Problems/SingleProblem'
import SharedRecord from './components/pages/Problems/SharedRecord'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from './app/hooks'
import {
  authState,
  getCurrentUser,
  getCurrentUserGoogle,
  getCurrentUserGoogleCypress,
  loginUser,
  logOutUser,
  noToken
} from './app/slices/authSlice'
import Docs from './components/pages/Settings/Options/Docs'
import Integrations from './components/pages/Settings/Options/Integrations'
import Zendesk from 'react-zendesk'
import OnBoarding from './components/pages/onboarding/OnBoarding'
import Issues from './components/pages/Problems/Issues'
import Sessions from './components/pages/Problems/Sessions'
import Solutions from './components/pages/Problems/Solutions'
import Pricing from './components/pages/Settings/Options/Pricing/Pricing'
import { getTiers, setPlans} from './app/slices/uiSlice'
import { unprotectedGetRequest } from './app/apis/apiGenerics'

import Investigations from './components/pages/Investigations/Investigations'
import SingleInvestigation from './components/pages/Investigations/SingleInvestigation'
import Links from './redirect/Links'
import SingleRecord from './components/pages/Record/SingleRecord'
import Collecting from './components/pages/Settings/Options/Collecting'
import InvestigationCreatePage from './components/pages/Investigations/InvestigationCreatePage'
import Inbox from './components/pages/Inbox/Inbox'


// const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || ''

// const zeSetting = {
//   color: {
//     theme: '#9fdaff'
//   },
//   launcher: {
//     chatLabel: {
//       'en-US': 'Need Help'
//     }
//   },
//   contactForm: {
//     fields: [
//       { id: 'description', prefill: { '*': 'My pre-filled description' } }
//     ]
//   }
// }

function App() {
  const dispatch = useAppDispatch()
  const { isPlayground } = useAppSelector(authState)
  useEffect(() => {
    const lastAuthUser = localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`
    )
    const refreshToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${lastAuthUser}.refreshToken`
    )
    const idToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${lastAuthUser}.idToken`
    )
    const searchParams = new URLSearchParams(window.location.search)
    let authCode = searchParams.get('code')
    let invitation_id = searchParams.get('state')
    const googleRefreshToken = localStorage.getItem('refresh_token')
    const googleCypress = localStorage.getItem('googleCypress')

    if (refreshToken && idToken) {
      dispatch(getCurrentUser({ idToken }))
    } else if (googleRefreshToken) {
      dispatch(getCurrentUserGoogle({ refreshToken: googleRefreshToken }))
    } else if (googleCypress) {
      dispatch(getCurrentUserGoogleCypress({ googleCypress: googleCypress }))
    } else {
      if (authCode && !refreshToken) {
        if (invitation_id?.includes('_')) {
          invitation_id = ''
        }
        dispatch(loginUser({ authCode, invitation_id }))
      } else {
        dispatch(noToken())
      }
    }
  }, [])

  window.addEventListener('beforeunload', () => {
    if (isPlayground) {
      dispatch(logOutUser())
    }
  })

  useEffect(() => {
    dispatch(getTiers())
    getPlans()
  }, [])

  const getPlans = async () => {
    const res = await unprotectedGetRequest('paddle/getPlans')
   
    const plans = res.data.filter((plan:any) => plan.billing_type !== 'day')
    
    dispatch(setPlans(plans))
  }
  
  return (
    <div className="transition duration-500">
      
      <Router>
        <Routes>
          <Route path="/links" element={<Links />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/sharedRecord/:id" element={<SharedRecord />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Inbox />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/onboarding" element={<OnBoarding />} />
            <Route path="/investigate" element={<Investigations />} />
            <Route path="/investigate-create" element={<InvestigationCreatePage />} />
            <Route path="/investigate-create/:investigationId" element={<InvestigationCreatePage />} />
            <Route path="/investigate/:investigationId" element={<SingleInvestigation />} />
            <Route path="/investigate/:investigationId/:sessionId" element={<SingleInvestigation />} />
            <Route path="*" element={<Inbox />} />
            <Route path="/issues" element={<Issues />}>
              <Route index element={<Problems />} />
              
              <Route path="sessions" element={<Sessions />} />
              <Route path="solutions" element={<Solutions />} />
            </Route>
            
            <Route path="/issues/:id" element={<SingleRecord />} />
            <Route path="settings" element={<Settings />}>
              <Route index element={<Profile />} />
              <Route path="domains" element={<Domains />} />
              <Route path="installation" element={<Installation />} />
              <Route path="teammates" element={<Teammates />} />
              <Route path="docs" element={<Docs />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="collecting" element={<Collecting />} />

              {/* <Route path="Billing" element={<Pricing />} /> */}
            </Route>
          </Route>
        </Routes>
      </Router>
      <NotificationsProvider />
    </div>
  )
}

export default App
