import React, { useState } from 'react'
import { EllipsisHorizontalIcon, GlobeAltIcon } from '@heroicons/react/24/solid'
import GrantAccess from '../../Auth/GrantAccess'
import { useSelector } from 'react-redux'
import { authState } from '../../../app/slices/authSlice'
import moment from 'moment'
import {
  customerDomainsState,
  updateDomainStatus,
  getCustomerDomains
} from '../../../app/slices/customerDomainsSlice'
import StatusSelect from '../shared/StatusSelect'
import { useAppDispatch } from '../../../app/hooks'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import Button from '../elements/Button'

interface DomainsTableProps {
  domains?: any
  activateBtn: boolean
}

const approvedCss = 'border-l-4 border-green-500 w-0 rounded-lg'
const waitingForApproveCss = 'border-l-4 border-yellow-300 w-0 rounded-lg'

const DomainsTable: React.FC<DomainsTableProps> = ({
  domains,
  activateBtn
}) => {
  const dispatch = useAppDispatch()
  const { user } = useSelector(authState)
  const isPlayground = user?.role_name === 'playground'
  const { statusTypes } = useSelector(customerDomainsState)
  const [loading, setLoading] = useState(false)

  const activateDomain = async (domain_id: string, status_id: string) => {
    setLoading(true)
    await dispatch(updateDomainStatus({ domain_id, status_id }))
    await dispatch(getCustomerDomains({ customer_id: user.customer_id }))
    dispatch(
      addNewNotification({
        text: 'Domain Status Changed Successfully',
        id: uuidv4(),
        title: 'Domain Status Changed',
        type: 'SUCCESS',
        timer: true,
        button: false
      })
    )
    setLoading(false)
  }

  return (
    <div className="overflow-x-auto">
      <div className="mt-4 flex flex-col">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle overflow-x-auto">
            <div className="table-wrapper">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="sticky top-0 border-b bg-super-light-gray dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-light-black dark:text-dark-text sm:pl-6"
                    >
                      Origin
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text w-3/12"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                    >
                      Add On
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                    >
                      Settings
                    </th>
                  </tr>
                </thead>
                <tbody className="teammates-table">
                  {domains &&
                    domains.length > 0 &&
                    domains.map((domain: any) => (
                      <tr
                        key={domain.id}
                        className="dark:bg-dark-primary dark:divide-gray-600"
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 w-4/12">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <GlobeAltIcon className="w-8 h-8 p-1 bg-blue-100 rounded-full" />
                            </div>
                            <div className="ml-4">
                              <div className="text-gray-500">
                                {domain.origin}
                              </div>
                            </div>
                          </div>
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Badge text={'Host'} type={'info'} closeIcon={false} />
                      </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <GrantAccess grantAccessToRoles={['user', 'playground']}>
                            <div
                              className={
                                domain &&
                                domain.status_name.toLowerCase() === 'activated'
                                  ? approvedCss
                                  : waitingForApproveCss
                              }
                            >
                              <span className="pl-2">{domain.status_name}</span>
                            </div>
                          </GrantAccess>
                          <GrantAccess grantAccessToRoles={['admin']}>
                            {domains && domains.length > 0 && (
                              <StatusSelect
                                statusId={domain.status_id}
                                statusesToSelect={statusTypes}
                                id={domain.id}
                              />
                            )}
                          </GrantAccess>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-gray-500">
                                {moment(
                                  new Date(domain.created_at).toLocaleString()
                                ).format('DD/MM/YY')}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center cursor-pointer">
                          {activateBtn ? (
                            <Button
                              type={'button'}
                              disabled={isPlayground}
                              loading={loading}
                              withIcon={false}
                              //  Icon={ArrowRightIcon}
                              text={'Activate'}
                              inverted={false}
                              clickFunction={() =>
                                activateDomain(
                                  domain.id,
                                  '7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1'
                                )
                              }
                            />
                          ) : (
                            <EllipsisHorizontalIcon className="w-6 h-6" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomainsTable
