import React from 'react'
import { useSelector } from 'react-redux'
import { authState } from '../../app/slices/authSlice'

interface GrantAccessProps {
  children: React.ReactNode
  grantAccessToRoles: string[]
}

const GrantAccess: React.FunctionComponent<GrantAccessProps> = ({
  children,
  grantAccessToRoles
}) => {
  const { user } = useSelector(authState)
  const checkAccess = (roles: any) => {
    return grantAccessToRoles.includes(user.role_name)
  }

  return <>{checkAccess(grantAccessToRoles) ? children : null}</>
}

export default GrantAccess
