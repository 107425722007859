import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { RootState } from '../store'
import {
  CreateInvestigation,
  GetInvestigations,
  DeleteInvestigation,
  UpdateInvestigation,
  GetInvestigationDetails,
  GetElasticFilterOptions,
  InvestigationElastic,
  GetSimilarities
} from '../apis/investigationsApi'

export const investigationsAdapter = createEntityAdapter<any>({
  selectId: (view) => view.id
})

export const investigationsSelectors =
  investigationsAdapter.getSelectors<RootState>((state) => state.investigations)

export const { selectAll, selectById, selectEntities, selectTotal } =
  investigationsSelectors

export const createInvestigation = createAsyncThunk(
  'investigations/createInvestigation',
  async ({ investigationData }: any) => {
    try {
      const res = await CreateInvestigation(investigationData)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getInvestigations = createAsyncThunk(
  'investigations/getInvestigation',
  async ({ customer_id }: any) => {
    try {
      const res = await GetInvestigations(customer_id)

      const concatData = res.data.map((investigation: any) => {
        return {
          aggregatedData: res.response.filter(
            (data: any) => data.investigation_id === investigation.id
          ),
          ...investigation
        }
      })

      // console.log(concatData)

      return concatData
    } catch (err: any) {
      return err.message
    }
  }
)

export const getSimilarities = createAsyncThunk(
  'investigations/getSimilarities',
  async ({ customer_id, investigation_id, user_id }: any) => {
    try {
      const res = await GetSimilarities(customer_id, investigation_id, user_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getInvestigationDetails = createAsyncThunk(
  'investigations/getInvestigationDetails',
  async ({ investigation_id }: any) => {
    try {
      const res = await GetInvestigationDetails(investigation_id)
      // console.log('getInvestigationDetails', res)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const deleteInvestigation = createAsyncThunk(
  'investigations/deleteInvestigation',
  async ({ investigation_id }: any) => {
    try {
      const res = await DeleteInvestigation(investigation_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const updateInvestigation = createAsyncThunk(
  'investigations/updateInvestigation',
  async ({ investigation_id, query_json, name }: any) => {
    try {
      const filtersToUpdate = JSON.stringify(query_json)
      const res = await UpdateInvestigation(
        investigation_id,
        filtersToUpdate,
        name
      )

      // console.log(res.data)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const investigationElastic = createAsyncThunk(
  'investigations/investigationElastic',
  async ({ filters, customer_id }: any) => {
    

    console.log(filters)

    try {
      const res = await InvestigationElastic(filters, customer_id)
      console.log(res)
      const records = res.data
      return records
    } catch (err: any) {
      return err.message
    }
  }
)

const investigationsSlice = createSlice({
  name: 'investigations',
  initialState: investigationsAdapter.getInitialState({
    loading: false,
    loadingCreation: false,
    loadingDetails: false,
    currentInvestigation: <any>{},
    currentSession: { id: '', is_session: null },
    error: '',
    logsCount: 0,
    elasticResults: <any>[],
    playFrom: 0,
    investigationDropdownData: [],
    investigationParameters: <any>[],
    investigationFilters: <any>{
      customer_id: '',
      match: <any>{},
      not_match: <any>{},
      contains: <any>{},
      not_contain: <any>{}
    },
    lastParameterValid: false,
    selectedValues: null,
    investigationSimilarities: <any>{}
  }),
  reducers: {
    setSelectedValues: (state, action) => {
      state.selectedValues = action.payload
    },
    setLastParameterValid: (state, action) => {
      state.lastParameterValid = action.payload
    },
    setCurrentInvestigation(state, action) {
      state.currentInvestigation = action.payload
    },
    setCurrentSession(state, action) {
      state.currentSession.id = action.payload.id
      state.currentSession.is_session = action.payload.is_session
    },
    setLogsCount(state, action) {
      state.logsCount = action.payload
    },
    setPlayFrom(state, action) {
      state.playFrom = action.payload
    },
    addParameter(state, action) {
      state.investigationParameters = [
        ...state.investigationParameters,
        action.payload
      ]
    },
    addInvestigationFilter(state, action) {
      state.investigationFilters[action.payload.selector] = {
        ...state.investigationFilters[action.payload.selector],
        [action.payload.field]: action.payload.value
      }
    },
    removeInvestigationFilter(state, action) {
      state.investigationParameters = [
        ...state.investigationParameters.splice(action.payload, 1)
      ]
    },
    resetElaticResults(state) {
      state.elasticResults = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createInvestigation.pending, (state, action) => {
        state.loadingCreation = true
      })
      .addCase(
        createInvestigation.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loadingCreation = false
          investigationsAdapter.setOne(state, action.payload[0])
        }
      )
      .addCase(
        createInvestigation.rejected,
        (state, action: PayloadAction<any>) => {
          state.loadingCreation = false
          state.error = action.payload
        }
      )
      .addCase(getInvestigations.pending, (state, action) => {
        state.loading = true
      })
      .addCase(
        getInvestigations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          investigationsAdapter.setAll(state, action.payload)
        }
      )
      .addCase(
        getInvestigations.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        }
      )
      .addCase(
        deleteInvestigation.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log(action.payload)

          investigationsAdapter.removeOne(state, action.payload[0].id)
        }
      )
      .addCase(
        deleteInvestigation.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addCase(
        updateInvestigation.pending,
        (state, action: PayloadAction<any>) => {
          state.loading = false
        }
      )
      .addCase(
        updateInvestigation.fulfilled,
        (state, action: PayloadAction<any>) => {
          localStorage.setItem(
            'record_cur_investigation',
            JSON.stringify(action.payload[0])
          )
          investigationsAdapter.updateOne(state, {
            id: action.payload[0].id,
            changes: action.payload[0]
          })
          investigationsSlice.reducer(
            state,
            setCurrentInvestigation(action.payload[0])
          )
        }
      )
      .addCase(
        updateInvestigation.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addCase(
        getInvestigationDetails.pending,
        (state, action: PayloadAction<any>) => {
          state.loadingDetails = true
        }
      )
      .addCase(
        getInvestigationDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log(action.payload)
          state.currentInvestigation = action.payload[0]
          state.loadingDetails = false
        }
      )
      .addCase(
        getInvestigationDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
          state.loadingDetails = false
        }
      )
      // .addCase(
      //   getElasticFilterOptions.pending,
      //   (state, action: PayloadAction<any>) => {
      //     state.loadingDetails = true
      //   }
      // )
      // .addCase(
      //   getElasticFilterOptions.fulfilled,
      //   (state, action: PayloadAction<any>) => {
      //     state.investigationDropdownData = action.payload
      //   }
      // )
      // .addCase(
      //   getElasticFilterOptions.rejected,
      //   (state, action: PayloadAction<any>) => {
      //     state.error = action.payload
      //     state.loadingDetails = false
      //   }
      // )
      .addCase(
        investigationElastic.pending,
        (state, action: PayloadAction<any>) => {
          state.loadingDetails = true
        }
      )
      .addCase(
        investigationElastic.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.elasticResults = action.payload
          state.loadingDetails = false
        }
      )
      .addCase(
        investigationElastic.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
          state.loadingDetails = false
        }
      )
      // .addCase(getSimilarities.pending, (state, action) => {
      //   state.loading = true
      // })
      .addCase(
        getSimilarities.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.investigationSimilarities = action.payload
          //investigationsAdapter.setAll(state, action.payload)
        }
      )
      .addCase(
        getSimilarities.rejected,
        (state, action: PayloadAction<any>) => {
          // state.loading = false
          // state.error = action.payload
        }
      )
  }
})

export const investigationsState = (state: RootState) => state.investigations
export const {
  setCurrentInvestigation,
  setCurrentSession,
  setLogsCount,
  addParameter,
  addInvestigationFilter,
  removeInvestigationFilter,
  setPlayFrom,
  setLastParameterValid,
  setSelectedValues,
  resetElaticResults
} = investigationsSlice.actions
export default investigationsSlice.reducer
