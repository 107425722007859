// RadioValues.tsx

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import {
  investigationParametersState,
  setLastParameterValid,
  setSelectedValues
} from '../../../../app/slices/investigationParametersSlice'
import {
  investigationElastic,
  investigationsState
} from '../../../../app/slices/investigationsSlice'

interface RadioValuesProps {
  onSelect: (value: string) => void
  selectedParameter: any
  parameterId: string
}

const RadioValues = ({
  onSelect,
  selectedParameter,
  parameterId
}: RadioValuesProps) => {
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const { user } = useAppSelector(authState)
  const { parameters } = useAppSelector(investigationParametersState)
  const { currentInvestigation } = useAppSelector(investigationsState)
  const dispatch = useAppDispatch()
  const params = useParams()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
    onSelect(event.target.value)
    dispatch(
      setSelectedValues({ id: parameterId, values: [event.target.value] })
    )
    
  }

  useEffect(() => {
    setSelectedValue(selectedParameter.parameter.options[0])
    dispatch(
      setSelectedValues({
        id: parameterId,
        values: [selectedParameter.parameter.options[0]]
      })
    )

    dispatch(setLastParameterValid({ isValid: true }))
    
  }, [])

  useEffect(() => {
    if (currentInvestigation.id && params.investigationId) {
      if(selectedParameter?.selectedValues !== null) {

        setSelectedValue(selectedParameter?.selectedValues[0])
        dispatch(
          setSelectedValues({
            id: parameterId,
            values: [selectedParameter?.selectedValues[0]]
          })
        )
      }
    }
  }, [currentInvestigation])

  useEffect(() => {
    if (selectedValue) {
      
      console.log('selectedValue investigationElastic')
      dispatch(
        investigationElastic({
          customer_id: user.customer_id,
          filters: parameters
        })
      )
    }
  }, [selectedValue])

  const renderOptions = () => {
    return selectedParameter.parameter.options.map((option: string) => (
      <div key={option} className="flex items-center gap-1">
        <input
          type="radio"
          id={option}
          name={selectedParameter.name}
          value={option}
          checked={selectedValue === option}
          onChange={handleChange}
          className="checked:bg-tuto-light-blue"
        />
        <label htmlFor={option} className="mr-4">
          {option}
        </label>
      </div>
    ))
  }

  return <div className="flex items-center ml-4">{renderOptions()}</div>
}

export default RadioValues
