import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  investigationsState,
  resetElaticResults
} from '../../../app/slices/investigationsSlice'
import LogTagsGenerator from './LogTagsGenerator'
import moment from 'moment'
import TopicsGenerator from './TopicsGenerator'
import PagesTags from './PagesTags'
import { useEffect } from 'react'
import { NoSymbolIcon } from '@heroicons/react/24/outline'
import Avatar from './Avatar'
import CountryFlag from 'react-country-flag'
import InboxTable from '../Inbox/InboxTable'

const ElasticTable = () => {
  const { elasticResults } = useAppSelector(investigationsState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(resetElaticResults())
    }
  }, [])
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {elasticResults && elasticResults.length > 0 ? (
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <InboxTable location='Investigation' results={elasticResults} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-70v text-center text-xl text-dark-gray">
          <NoSymbolIcon className="h-16 w-16" />
          No results match your search.
          <br /> Feel free to save the investigation to track potential future
          events.
        </div>
      )}
    </div>
  )
}

export default ElasticTable
