/* eslint no-unneeded-ternary: 0 */
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './Auth.css'
import AuthWrapper from './AuthWrapper'
import { authState } from '../../app/slices/authSlice'
import RegistrationForm from './RegistrationForm'
import ConfirmationForm from './ConfirmationForm'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Mixpanel } from '../utils/mixPanel/mixPanel'
import { IntegrationsTypes } from '../utils/enums'
import { unprotectedGetRequest } from '../../app/apis/apiGenerics'

const SignUp = () => {
  const { codeStep, mailToConfirm, isLoggedIn } = useAppSelector(authState)
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  let integrationId = localStorage.getItem('integrationId')
  const searchParams = new URLSearchParams(window.location.search)
  const appSumo = searchParams.get('appsumo')
  const rocketHub = searchParams.get('rockethub')
  const specialCode = searchParams.get('specialCode')
  const redirect = searchParams.get('redirect')
  
  const invitation_id = searchParams.get('state')

  const [isIntegration, setIsIntegration] = useState(false)
  const [loginImage, setLoginImage] = useState('')
  const [invitationDetails, setInvitationDetails] = useState({
    active: false,
    invitation: null,
    customer: null,
    inviter: null,
    error: false
  })

  const getInvitationDetails = async (id: string) => {
    const details = await unprotectedGetRequest(
      `users/getInvitationDetails?invitation_id=${id}`
    )

    setInvitationDetails((prevState) => ({
      ...prevState,
      invitation: details.data[0],
      customer: details.customerDetails[0],
      inviter: details.inviterDetails[0],
      active: true
    }))
  }

  useEffect(() => {
    Mixpanel.register({ Page: 'Signup' })
    Mixpanel.track('Signup Page Viewed', {})
    switch (integrationId) {
      case IntegrationsTypes.ZENDESK:
        setLoginImage('https://assets.torecord.it/Zendesk%2BRecord.png')
        setIsIntegration(true)
        break
      case IntegrationsTypes.INTERCOM:
        setLoginImage('https://assets.torecord.it/Intercom%2Brecord.png')
        setIsIntegration(true)
        break;
        case IntegrationsTypes.SENTRY:
          setLoginImage("https://assets.torecord.it/record-sentry.png")
          setIsIntegration(true)
          break;
      default:
        setLoginImage('https://assets.torecord.it/Pepole-photo.png')
        break
    }
    if (appSumo) {
      setLoginImage('https://assets.torecord.it/Appsumo_Login.png')
      localStorage.setItem('appsumo', appSumo)
    }
    if (rocketHub) {
      setLoginImage('https://assets.torecord.it/Rockethub_Login.png')
      localStorage.setItem('rockethub', rocketHub)
    }
    if (specialCode) {
      setLoginImage('https://assets.torecord.it/SpecialCode_Login.png')
      localStorage.setItem('specialCode', specialCode)
    }
    if (invitation_id) {
      if (invitation_id !== 'invitationError') {
        getInvitationDetails(invitation_id)
      } else {
        setInvitationDetails((prevState) => ({
          ...prevState,
          error: true
        }))
      }
    }
  }, [])

  useEffect(() => {
    if (invitationDetails.active) {
      sessionStorage.setItem('invitationActive', 'true')
    }
  }, [invitationDetails])

  useEffect(() => {
    if (isLoggedIn) {
      if(redirect) {
        let redirectTo = '/' + redirect.split('%2F').join('/') + '?activeTab=1'
        console.log(redirectTo)
        navigate(redirectTo)
      } else {

        navigate('/')
      }
    }
  }, [isLoggedIn])

  return (
    <AuthWrapper
      withSteps={false}
      imageUrl={loginImage}
      imageAdditionalClass={
        isIntegration || appSumo || rocketHub || specialCode ? 'h-4 w-4 -translate-x-10' : 'login-image'
      }
      withLoginLink={true}
      isIntegration={isIntegration}
    >
      <div className="flex-1 flex flex-col justify-start">
        <div className="w-full max-w-sm">
          <div className="">
            <div className="">
              {codeStep === 'register' ? (
                <RegistrationForm
                  setPassword={setPassword}
                  invitationDetails={invitationDetails}
                />
              ) : codeStep === 'confirmation' ? (
                <ConfirmationForm
                  email={mailToConfirm}
                  newUserPassword={password}
                  invitationDetails={invitationDetails}
                />
              ) : codeStep === 'success' ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/signup" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1 overflow-hidden">
        <div className="decoration-1"></div>
        <div className="decoration-2"></div>
        <a
          className="absolute bottom-2 right-2 text-white"
          href="https://tutoit.io/"
          target="blank"
        >
          Visit our website!
        </a>
      </div>
    </AuthWrapper>
  )
}

export default SignUp
