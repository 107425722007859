import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import CONFIG from '../../config/config'
import {
  AuthorizeUserFromCognito,
  GetUserDataFromApi,
  GetRefreshToken,
  ChangeUserData,
  LoginUserFromCognito,
  UpdatePGUserData,
  InsertNewIntegrationDetails,
  GetIntegrations
} from '../apis/authApi'
import { Mixpanel } from '../../components/utils/mixPanel/mixPanel'
import StatusSelect from '../../components/utils/shared/StatusSelect'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from '../../components/Auth/UserPool'
import { getCustomerDomains } from './customerDomainsSlice'
import { setFromIntegration } from './uiSlice'
import { getCustomerIntegrations } from './customerIntegrationsSlice'


export interface AuthState {
  user: any
  loading: 'idle' | 'loading' | 'failed' | 'success'
  error: string
  isLoggedIn: boolean
  actionLoading: boolean
  codeStep: 'register' | 'confirmation' | 'reset' | 'failed' | 'success'
  mailToConfirm: string
  isPlayground: boolean
  optDomain: string
  referrer: string
  recordIntegrations: any[]
  leadGenFilled: boolean
}

const initialState: AuthState = {
  user: '',
  error: '',
  loading: 'idle',
  isLoggedIn: false,
  actionLoading: false,
  codeStep: 'register',
  mailToConfirm: '',
  isPlayground: false,
  leadGenFilled: false,
  optDomain: '',
  referrer: '',
  recordIntegrations: []
}

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ authCode, invitation_id }: any, thunkApi) => {
    try {
      const res: any = await AuthorizeUserFromCognito(
        authCode,
        invitation_id
      )

      // if (res.status === 400) {
      //   return { isLoggedIn: false }
      // }

      if (!res.error) {
        if (res.refresh_token) {
          localStorage.setItem('refresh_token', res.refresh_token)
        }
        localStorage.setItem('access_token', res.access_token)
        localStorage.setItem('id_token', res.id_token)

        const currentUser = await GetUserDataFromApi(
          res.id_token,
          invitation_id
        )
        if(currentUser && currentUser.data){
          const subDomain = localStorage.getItem('integrationSubDomain')
          const referrer = localStorage.getItem('integrationId')
          const authCode = localStorage.getItem('integrationAuthCode')
          const installationId = localStorage.getItem('integrationInstallationId')
          if(subDomain && referrer){
            await InsertNewIntegrationDetails(subDomain, currentUser.data.customer_id, referrer, authCode, currentUser.data.email, currentUser.data.name, installationId).then(() => {
              thunkApi.dispatch(setFromIntegration(referrer))
              localStorage.removeItem('integrationAuthCode')
              localStorage.removeItem('integrationSubDomain')
              localStorage.removeItem('integrationId')
              })
              .catch(() => {
                localStorage.removeItem('integrationAuthCode')
                localStorage.removeItem('integrationSubDomain')
                localStorage.removeItem('integrationId')
              })
          }
          thunkApi.dispatch(getCustomerDomains({customer_id: currentUser.data.customer_id}))
          thunkApi.dispatch(getCustomerIntegrations({customer_id: currentUser.data.customer_id}))
        }

        return {
          isLoggedIn: true,
          currentUser: currentUser.data
        }
      } else {
        return thunkApi.rejectWithValue(res.error)
      }
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.message)
    }
  }
)

export const loginPlayground = createAsyncThunk(
  'auth/loginPlayground',
  async(_,thunkApi) => {
    try{
      const user = new CognitoUser({
        Username: process.env.REACT_APP_PLAYGROUND_USERNAME || '',
        Pool: UserPool
      })
  
      const authDetails = new AuthenticationDetails({
        Username: process.env.REACT_APP_PLAYGROUND_USERNAME || '',
        Password: process.env.REACT_APP_PLAYGROUND_PASS || ''
      })
      Mixpanel.track('Signin Clicked', { email: process.env.REACT_APP_PLAYGROUND_USERNAME })
      user.authenticateUser(authDetails, {
        onSuccess: async (data: any) => {
          thunkApi.dispatch(
            loginUserMenual({  token: data.idToken.jwtToken, invitation_id :''  })
          )
        },
        onFailure: (err) => {
          setError(err.message)
        }
      })
    }
    catch(err: any){
      console.log(err.message)
      return err.message
    }
  }
)

export const loginUserMenual = createAsyncThunk(
  'auth/loginUserMenual',
  async ({ token, invitation_id }: any, thunkApi) => {
    try {
      // console.log('loginUserMenual')
      const res = await GetUserDataFromApi(token, invitation_id)
      if(res && res.data){
        const subDomain = localStorage.getItem('integrationSubDomain')
        const referrer = localStorage.getItem('integrationId')
        const authCode = localStorage.getItem('integrationAuthCode')
        const installationId = localStorage.getItem('integrationInstallationId')
        if(subDomain && referrer && res.data.email !== 'playground@torecord.it'){
          await InsertNewIntegrationDetails(subDomain, res.data.customer_id, referrer, authCode, res.data.email, res.data.name, installationId).then(() => {
          thunkApi.dispatch(setFromIntegration(referrer))
          localStorage.removeItem('integrationAuthCode')
          localStorage.removeItem('integrationSubDomain')
          localStorage.removeItem('integrationId')
          })
          .catch(() => {
            localStorage.removeItem('integrationAuthCode')
            localStorage.removeItem('integrationSubDomain')
            localStorage.removeItem('integrationId')
          })
        }
        else{
            localStorage.removeItem('integrationAuthCode')
            localStorage.removeItem('integrationSubDomain')
            localStorage.removeItem('integrationId')
        }
        thunkApi.dispatch(getCustomerDomains({customer_id: res.data.customer_id}))
        thunkApi.dispatch(getCustomerIntegrations({customer_id: res.data.customer_id}))
      }
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getCurrentUser = createAsyncThunk(
  'auth/currentUser',
  async ({ idToken, invitation_id }: any, thunkApi) => {
    try {
      // const refreshedUserTokens: any = await GetRefreshToken(
      //   refreshToken,
      //   role,
      //   customer_id
      // )
      // console.log('getCurrentUser')
      const res = await GetUserDataFromApi(idToken, invitation_id)
      
      if(res && res.data && res.data.email !== 'playground@torecord.it'){
        const subDomain = localStorage.getItem('integrationSubDomain')
        const referrer = localStorage.getItem('integrationId')
        const authCode = localStorage.getItem('integrationAuthCode')
        const installationId = localStorage.getItem('integrationInstallationId')
        if(subDomain && referrer){
          await InsertNewIntegrationDetails(subDomain, res.data.customer_id, referrer, authCode, res.data.email, res.data.name, installationId).then(() => {
            thunkApi.dispatch(setFromIntegration(referrer))
            localStorage.removeItem('integrationAuthCode')
            localStorage.removeItem('integrationSubDomain')
            localStorage.removeItem('integrationId')
            })
            .catch(() => {
              localStorage.removeItem('integrationAuthCode')
              localStorage.removeItem('integrationSubDomain')
              localStorage.removeItem('integrationId')
            })
        }
        Mixpanel.identify(res.data.email)
        // console.log("cognito reload")
        Mixpanel.track('Reload Page', {
          "Method": "Cognito",
          user_id: res.data.id,
          customer_id: res.data.customer_id,
          email: res.data.email
        })
        thunkApi.dispatch(getCustomerDomains({customer_id: res.data.customer_id}))
        thunkApi.dispatch(getCustomerIntegrations({customer_id: res.data.customer_id}))
      }
      localStorage.removeItem('integrationAuthCode')
      localStorage.removeItem('integrationSubDomain')
      localStorage.removeItem('integrationId')
      // if (refreshedUserTokens.refresh_token) {
      //   localStorage.setItem('refresh_token', refreshedUserTokens.refresh_token)
      // }
      // localStorage.setItem('access_token', refreshedUserTokens.access_token)
      // localStorage.setItem('id_token', refreshedUserTokens.id_token)
      return res.data
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.message)
    }
  }
)

export const getCurrentUserGoogleCypress = createAsyncThunk(
  'auth/currentUser',
  async ({ googleCypress }: any) => {
    try {
      const { id_token, access_token } = JSON.parse(googleCypress) 
      // console.log(access_token)
      // console.log(id_token)
      const res = await GetUserDataFromApi(id_token)
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('id_token', id_token)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)
export const getCurrentUserGoogle = createAsyncThunk(
  'auth/currentUser',
  async ({ refreshToken }: any, thunkApi) => {
    try {
      const refreshedUserTokens: any = await GetRefreshToken(
        refreshToken
        // role,
        // customer_id
      )
      
      const res = await GetUserDataFromApi(refreshedUserTokens.id_token)
      if (refreshedUserTokens.refresh_token) {
        localStorage.setItem('refresh_token', refreshedUserTokens.refresh_token)
      }
      localStorage.setItem('access_token', refreshedUserTokens.access_token)
      localStorage.setItem('id_token', refreshedUserTokens.id_token)
      // console.log("google relaod")
      if(res && res.data && res.data.email !== 'playground@torecord.it'){
        const subDomain = localStorage.getItem('integrationSubDomain')
        const referrer = localStorage.getItem('integrationId')
        const authCode = localStorage.getItem('integrationAuthCode')
        const installationId = localStorage.getItem('integrationInstallationId')
        if(subDomain && referrer){
          await InsertNewIntegrationDetails(subDomain, res.data.customer_id, referrer, authCode, res.data.email, res.data.name, installationId).then(() => {
            thunkApi.dispatch(setFromIntegration(referrer))
            localStorage.removeItem('integrationAuthCode')
            localStorage.removeItem('integrationSubDomain')
            localStorage.removeItem('integrationId')
            })
            .catch(() => {
              localStorage.removeItem('integrationAuthCode')
              localStorage.removeItem('integrationSubDomain')
              localStorage.removeItem('integrationId')
            })
        }
        Mixpanel.identify(res.data.email)
        Mixpanel.track('Reload Page', {
          "Method": "Google",
          user_id: res.data.id,
          customer_id: res.data.customer_id,
          email: res.data.email
        })
        thunkApi.dispatch(getCustomerDomains({customer_id: res.data.customer_id}))
        thunkApi.dispatch(getCustomerIntegrations({customer_id: res.data.customer_id}))
      }
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const updateUserData = createAsyncThunk(
  'auth/changeUserName',
  async ({ data, userId, type, customerId }: any) => {
    try {
      const res: any = await ChangeUserData(data, userId, type, customerId)

      return { data: res.data, type }
    } catch (err: any) {
      return err.message
    }
  }
)

export const updatePGUserData = createAsyncThunk(
  'auth/updatePGUserData',
  async ({ name, email, job_title }: any) => {
    try {
      const res: any = await UpdatePGUserData(name, email, job_title)

      return { data: res.data }
    } catch (err: any) {
      return err.message
    }
  }
)

export const getIntegrations = createAsyncThunk(
  'auth/getIntegrations',
  async () => {
    try {
      const res: any = await GetIntegrations()

      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOutUser: (state) => {
      const userToRemove = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${userToRemove}.refreshToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${userToRemove}.idToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${userToRemove}.accessToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${userToRemove}.clockDrift`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`
      )
      localStorage.removeItem('id_token')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('record_cur_view')
      window.location.href = `${CONFIG.auth}logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&logout_uri=${CONFIG.dashboard}login&redirect_uri=${CONFIG.dashboard}login`
      state.isLoggedIn = false
      state.isPlayground = false
      state.user = null
      state.loading = 'failed'
      Mixpanel.track('Logout', {})
      Mixpanel.reset()
    },
    logOutFromGoogle: (state) => {
      state.isLoggedIn = false
      state.user = null
      state.loading = 'failed'
      window.location.href = `${CONFIG.auth}logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&logout_uri=${CONFIG.dashboard}`
    },
    noToken: (state) => {
      state.loading = 'failed'
    },
    continueToCodeStep: (state, action: PayloadAction<any>) => {
      state.codeStep = action.payload
    },
    setMailToConfirm: (state, action: PayloadAction<any>) => {
      state.mailToConfirm = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setLoader: (state) => {
      state.loading = 'loading'
    },
    setLeadgenFilled: (state, action) => {
      state.leadGenFilled = action.payload
    },
    setUserOnboardingMode: (state, action) => {
      state.user.is_onboarding_mode = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = 'success'
        state.user = action.payload.currentUser
        state.isPlayground = action.payload.currentUser.role_name === 'playground'
        state.isLoggedIn = action.payload.isLoggedIn
        // console.log("Log in Google")
        if (action.payload.currentUser.email !== 'playground@torecord.it') {
          Mixpanel.identify(action.payload.currentUser.email)
        }
        Mixpanel.track('Log In', {
          "Method": "Google",
          user_id: action.payload.currentUser.id,
          customer_id: action.payload.currentUser.customer_id,
          email: action.payload.currentUser.email
        })
      })
      .addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = 'failed'
        state.error = action.payload
        state.isLoggedIn = false
      })
      .addCase(getCurrentUser.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(
        getCurrentUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = 'success'
          state.user = action.payload
          state.isPlayground = action.payload.role_name === 'playground'
          state.isLoggedIn = true
        }
      )
      .addCase(getCurrentUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = 'failed'
        state.error = action.payload
        state.isLoggedIn = false
      })
      .addCase(updateUserData.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(
        updateUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log(action.payload)
          if (action.payload.type == 'name') {
            state.user.name = action.payload.data[0].name
          }
          if (action.payload.type == 'customer') {
            state.user.company_name = action.payload.data[0].name
          }
          if (action.payload.type == 'beta') {
            state.user.is_beta = action.payload.data[0].is_beta
          }
          if (action.payload.type == 'weekly_report') {
            state.user.weekly_report = action.payload.data[0].weekly_report
          }
          if (action.payload.type == 'jobTitle') {
            state.user.job_title = action.payload.data[0].job_title
          }
          if (action.payload.type == 'domain') {
            state.optDomain = action.payload.data[0].domain
          }
          state.actionLoading = false
        }
      )
      .addCase(updateUserData.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
        state.actionLoading = false
      })
      .addCase(loginUserMenual.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(
        loginUserMenual.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log("Log in Cognito")
          if (action.payload.email !== 'playground@torecord.it') {
            Mixpanel.identify(action.payload.email)
          }
          Mixpanel.track('Log In', {
            "Method": "Cognito",
            user_id: action.payload.id,
            customer_id: action.payload.customer_id,
            email: action.payload.email
          })
          state.loading = 'success'
          state.user = action.payload
          state.isLoggedIn = true
        }
      )
      .addCase(
        loginUserMenual.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = 'failed'
          state.error = action.payload
          state.isLoggedIn = false
        }
      )
      .addCase(loginPlayground.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(loginPlayground.fulfilled, (state, action) => {
        state.loading = 'success'
        state.isPlayground = true
      })
      .addCase(loginPlayground.rejected, (state, action) => {
        state.loading = 'failed'
      })
      .addCase(getIntegrations.fulfilled, (state, action) => {
        state.recordIntegrations = action.payload
      })
  }
})

export const authState = (state: RootState) => state.auth
export const {
  logOutUser,
  logOutFromGoogle,
  continueToCodeStep,
  setMailToConfirm,
  setError,
  noToken,
  setLoader,
  setUserOnboardingMode,
  setLeadgenFilled
} = authSlice.actions

export default authSlice.reducer
