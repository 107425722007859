import React, { useState, useEffect } from 'react'
import Button from '../../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import './onboarding.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState, updateUserData } from '../../../app/slices/authSlice'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import {
  uiState,
  getCustomerOnboardingSteps
} from '../../../app/slices/uiSlice'
import { OnBoardingStepsE } from '../../utils/enums'
import './onboarding.css'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'

interface IOnboardingFormProps {
  setCurrentPage: any
  currentPage: number
}

const OnboardingForm: React.FC<IOnboardingFormProps> = ({ setCurrentPage, currentPage }) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const { onBoardingSteps } = useAppSelector(uiState)
  const [currentForm, setCurrentForm] = useState(1)
  const [loadingChange, setLoadingChange] = useState(false)
  const [name, setName] = useState('')
  const [customer, setCustomer] = useState('')
  const [errors, setErrors] = useState({
    name: '',
    customer: ''
  })
  const [validations, setValidations] = useState({
    name: false,
    customer: false
  })

  useEffect(() => {
    if(onBoardingSteps) {

      if (
        onBoardingSteps.lastStepDone && OnBoardingStepsE.CHANGE_USER_NAME === onBoardingSteps.lastStepDone.step_id
      ) {
        Mixpanel.track('Add User Name Page View', {
          Page: 'Add User Name',
          'Onboarding Step': 2
        })
        setCurrentForm(2)
      } else if (
        onBoardingSteps.lastStepDone && OnBoardingStepsE.CHANGE_ORG_NAME === onBoardingSteps.lastStepDone.step_id
      ) {
        Mixpanel.track('Add Org Name Page View', {
          Page: 'Add Org Name',
          'Onboarding Step': 3
        })
        setCurrentForm(3)
      }
    }
  }, [onBoardingSteps])

  const nameSubmitHandler = async (event: any) => {
    event.preventDefault()
    if (validations.name) {
      setLoadingChange(true)
      Mixpanel.track('User Name Filled', {
        name: name,
        'Onboarding Step': 2
      })
      await dispatch(
        updateUserData({
          data: name,
          userId: user.id,
          type: 'name',
          customerId: user.customer_id
        })
      )
      const welcomeMail = await unprotectedPostRequest(
        'users/sendWelcomeEmail',
        {
          email: user.email,
          isFromInvite: false
        }
      )
      // console.log(welcomeMail)
      setLoadingChange(false)
      setCurrentForm(2)
      setCurrentPage(2)
      event.target.value = ''
    }
  }

  const customerSubmitHandler = async (event: any) => {
    event.preventDefault()
    if (validations.customer) {
      setLoadingChange(true)
      Mixpanel.track('Organization Name Filled', {
        'customer name': customer,
        'Onboarding Step': 3
      })
      dispatch(
        updateUserData({
          data: customer,
          userId: user.id,
          type: 'customer',
          customerId: user.customer_id
        })
      )
      await unprotectedPostRequest(
        'onboarding/insertCustomerOBStep',
        {
          customer_id: user.customer_id,
          user_id: user.id,
          step_id: OnBoardingStepsE.VALUE
        }
      )
       await unprotectedPostRequest(
        'onboarding/insertCustomerOBStep',
        {
          customer_id: user.customer_id,
          user_id: user.id,
          step_id: OnBoardingStepsE.INSTALLATION
        }
      )
      setLoadingChange(false)
      setCurrentPage(3)
    }
  }

  const handleFirstNameInputChange = (event: any) => {
    setName(event.target.value)
    if (event.target.value.length < 2) {
      setErrors((errors) => ({
        ...errors,
        name: 'Name must be at least 2 characters'
      }))
      setValidations((validations) => ({
        ...validations,
        name: false
      }))
    } else {
      setErrors((errors) => ({
        ...errors,
        name: ''
      }))
      setValidations((validations) => ({
        ...validations,
        name: true
      }))
    }
  }

  const handleCompanyInputChange = (event: any) => {
    setCustomer(event.target.value)
    if (event.target.value.length < 2) {
      setErrors((errors) => ({
        ...errors,
        customer: 'Company name must be at least 2 characters'
      }))
      setValidations((validations) => ({
        ...validations,
        customer: false
      }))
    } else {
      setErrors((errors) => ({
        ...errors,
        customer: ''
      }))
      setValidations((validations) => ({
        ...validations,
        customer: true
      }))
    }
  }

  

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="ob-purple"></div>
      <div className="ob-yellow"></div>
      {currentForm === 1 ? (
        <div>
          <div>
            <h1 className="text-center onBoarding-title mb-4">
              Lets set things up
            </h1>
            <p className="text-center text-xl onBoarding-p">
              Please fill your full name
            </p>
          </div>
          <div className="mt-10">
            <form
              className="flex flex-col items-center"
              onSubmit={nameSubmitHandler}
              id="onboarding_form_user"
            >
              <div className="relative mb-12">
                <input
                  name="name"
                  value={name}
                  onChange={handleFirstNameInputChange}
                  className="p-4 text-center font-light bg-transparent text-4xl border-b border-gray-400 focus:outline-none"
                  placeholder="What is your name?"
                />
                <span className="text-red-400 absolute -bottom-6 right-0 left-0 text-center">
                  {errors.name && errors.name}
                </span>
              </div>
              <Button
                inverted={false}
                loading={loadingChange}
                disabled={false}
                text={`Next (${currentPage}/3)`}
                type="submit"
                withIcon={true}
                Icon={ArrowRightIcon}
                spacialClass=""
                // clickFunction={() => setCurrentForm(2)}
              />
            </form>
          </div>
        </div>
      ) : currentForm === 2 && (
        <div>
          <div>
            <h1 className="text-center onBoarding-title mb-4">
              Hi {user.name},
            </h1>
            <p className="text-center text-xl onBoarding-p">
              Please fill your company name
            </p>
          </div>
          <div className="mt-10 min-w-40">
            <form
              className="flex flex-col items-center min-w-40"
              onSubmit={customerSubmitHandler}
              id="onboarding_form_company"
            >
              <div className="relative mb-12 min-w-full">
                <input
                  name="customer"
                  value={customer}
                  onChange={handleCompanyInputChange}
                  className="p-4 min-w-full text-center font-light bg-transparent text-4xl border-b border-gray-400 focus:outline-none"
                  placeholder="What is your company name?"
                />
                <span className="text-red-400 absolute -bottom-6 right-0 left-0 text-center">
                  {errors.customer && errors.customer}
                </span>
              </div>
              <Button
                inverted={false}
                loading={loadingChange}
                disabled={false}
                text={`Next (${currentPage}/3)`}
                type="submit"
                withIcon={true}
                Icon={ArrowRightIcon}
                spacialClass=""
                // clickFunction={() => setCurrentForm(3)}
              />
            </form>
          </div>
        </div>
      ) 
      // : (
      //   <div className="max-w-3xl flex flex-col items-center">
      //     <div>
      //       <h1 className="text-center onBoarding-title mb-4">
      //         Now lets see how it looks from your customers’ eyes.
      //       </h1>
      //     </div>
      //     <div className="mt-10">
      //       <Button
      //         inverted={false}
      //         loading={false}
      //         disabled={false}
      //         text="Show me how to Record"
      //         type="button"
      //         withIcon={true}
      //         Icon={ArrowRightIcon}
      //         spacialClass="p-8"
      //         clickFunction={() => {
      //           document.body.dispatchEvent(
      //             new CustomEvent('recordStartVisibleFlow', { detail: 'start' })
      //           )
      //           document.body.dispatchEvent(
      //             new CustomEvent('tutoFromDashboard', {
      //               detail: {
      //                 fromDashboard: true,
      //                 customer_id: user.customer_id,
      //                 user_id: user.id
      //               }
      //             })
      //           )
      //           Mixpanel.track('Onboarding Demo Clicked', {
      //             'Onboarding Step': 4
      //           })
      //           setCurrentPage(4)
      //         }}
      //         id="record_activate_btn"
      //       />
      //     </div>
      //     <div
      //       className="mt-10 cursor-pointer onBoarding-p"
      //       onClick={() => {
      //         Mixpanel.track('Skip Onboarding Demo Clicked', {
      //           'Onboarding Step': 4
      //         })
      //         insertOnboardingStep()
      //       }}
      //     >
      //       Skip this step to installation
      //     </div>
      //   </div>
      // )
      }
    </div>
  )
}

export default OnboardingForm
