import React, { useEffect, useRef, Fragment, useState } from 'react'
import { Dialog, Transition, Switch } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import Button from '../../../utils/elements/Button'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import GrantAccess from '../../../Auth/GrantAccess'
import { problemsState } from '../../../../app/slices/ProblemsSlice'
import {
  createView,
  viewsState,
  updateView
} from '../../../../app/slices/viewsSlice'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'

interface CreateOrUpdateContentProps {
  setOpen: Function
  type: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CreateOrUpdateContent: React.FC<CreateOrUpdateContentProps> = ({
  setOpen,
  type
}) => {
  const dispatch = useAppDispatch()
  const [viewName, setViewName] = useState('')
  const [error, setError] = useState('')
  const { loadingCreation, currentView } = useAppSelector(viewsState)
  const [formValid, setFormValid] = useState(false)
  const { filterRecordsReq } = useAppSelector(problemsState)
  const [publicEnabled, setPublicEnabled] = useState(false)
  const { user } = useAppSelector(authState)

  useEffect(() => {
    if (type === 'update') {
      console.log('currentView.is_public', currentView.is_public)
      setViewName(currentView.name)
      setPublicEnabled(currentView.is_public)
      setFormValid(true)
    }
  }, [currentView])

  const handleViewNameChange = (event: any) => {
    setViewName(event.target.value)
    if (event.target.value.length < 2) {
      setError('View name must be at least 2 charachters')
      setFormValid(false)
    } else {
      setError('')
      setFormValid(true)
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (viewName.length < 2) {
      setError('View name must be at least 2 charachters')
    }
    if (formValid) {
      const viewData: any = {
        name: viewName,
        query_json: filterRecordsReq,
        is_public: publicEnabled,
        customer_id: user.customer_id,
        user_id: user.id,
        icon_path: null
      }
      if (type === 'create') {
        const createdView = await dispatch(createView({ viewData }))
        if (createdView.payload[0].id) {
          dispatch(
            addNewNotification({
              text: `View ${viewName} Created successfully`,
              id: uuidv4(),
              title: 'View Created!',
              type: 'SUCCESS',
              timer: true,
              button: false
            })
          )
        }
      }
      if (type === 'update') {
        const updatedRes = await dispatch(
          updateView({
            view_id: currentView.id,
            query_json: filterRecordsReq,
            name: viewName,
            is_public: publicEnabled
          })
        )
        if (updatedRes.payload[0].id) {
          dispatch(
            addNewNotification({
              text: `View Settings ${viewName} updated successfully`,
              id: uuidv4(),
              title: 'View Settings Updated!',
              type: 'SUCCESS',
              timer: true,
              button: false
            })
          )
        }
      }

      setOpen(false)
    }
  }

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <form
        onSubmit={handleSubmit}
        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-16">
            <div>
              <div className="my-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-3xl font-semibold leading-6 text-gray-900"
                >
                  {type === 'create' ? 'Add View' : 'Update View'}
                </Dialog.Title>
                <div className="mt-8">
                  <div className="relative mb-8 min-w-full">
                    <input
                      name="customer"
                      value={viewName}
                      onChange={handleViewNameChange}
                      className="p-4 min-w-full text-center font-light bg-transparent text-2xl border-b border-gray-400 focus:outline-none"
                      placeholder={`${user.name}'s view`}
                    />
                    <span className="text-red-400 absolute -bottom-6 right-0 left-0 text-center">
                      {error && error}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <GrantAccess grantAccessToRoles={['admin']}>
                    <Switch
                      checked={publicEnabled}
                      onChange={setPublicEnabled}
                      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none "
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                      />
                      <span
                        aria-hidden="true"
                        className={classNames(
                          publicEnabled ? 'bg-tuto-primary' : 'bg-gray-200',
                          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                        )}
                      />
                      <span
                        aria-hidden="true"
                        className={classNames(
                          publicEnabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                        )}
                      />
                    </Switch>

                    <span className="ml-2 font-semibold">Public</span>
                  </GrantAccess>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-6 flex items-center justify-center">
              <Button
                type="submit"
                inverted={false}
                loading={loadingCreation}
                disabled={loadingCreation}
                withIcon={false}
                text={type === 'create' ? 'Create' : 'Update'}

                // clickFunction={handleSubmit}
              />
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </form>
    </div>
  )
}

export default CreateOrUpdateContent
