import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import {
  customerDomainsSelectors,
  customerDomainsState,
  getCustomerDomains,
  getStatusTypes
} from '../../../../app/slices/customerDomainsSlice'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import DomainsTable from '../../../utils/tables/DomainsTable'
import DomainsEmpty from './Domains/DomainsEmpty'

const Domains = () => {
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()
  const domains = useSelector(customerDomainsSelectors.selectAll)
  const { pending, statusTypes } = useAppSelector(customerDomainsState)

  useEffect(() => {
    if (user) {
      Mixpanel.register({
        Page: 'Domains',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      Mixpanel.track('Domains Page Viewed', {})
      if (statusTypes.length === 0) {
        dispatch(getStatusTypes())
      }
      dispatch(getCustomerDomains({ customer_id: user.customer_id }))
    }
  }, [])
  const getWaitingToVerified = () => {
    return (
      domains &&
      domains.filter((domain) => {
        const statusId = domain.status_id
        return !statusId.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
      })
    )
  }
  const getVerified = () => {
    return (
      domains &&
      domains.filter((domain) => {
        const statusId = domain.status_id
        return statusId.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
      })
    )
  }
  return (
    <div className="flex flex-col flex-grow justufy-center">
      {domains.length > 0 ? (
        <div className="p-4">
          <div className="mb-16">
            <h1 className="general-title">Verified domains</h1>
            <p>
              Here you can approve all of the domains and envirionemnts that
              your record snippet can run on
            </p>
          </div>
          <div className="mb-4">
            <h1 className="general-title">Waiting to verify</h1>
            {domains && pending > 0 ? (
              <DomainsTable
                domains={getWaitingToVerified()}
                activateBtn={true}
              />
            ) : (
              <div className="p-6 flex items-center justify-center border rounded-lg shadow-sm">
                <CheckCircleIcon className="h-10 w-10 text-green-500 mr-4" />
                <div>No Domains To Verify</div>
              </div>
            )}
          </div>
          <div>
            <h1 className="general-title">Verified</h1>
            {domains && domains.length > 0 && (
              <DomainsTable domains={getVerified()} activateBtn={false} />
            )}
          </div>
        </div>
      ) : (
        <DomainsEmpty />
      )}
    </div>
  )
}

export default Domains
