import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  problemsState,
  filterRecords,
  setFilterDone
} from '../../../../app/slices/ProblemsSlice'
import AdvancedFiltering from './AdvancedFiltering'
import Button from '../../../utils/elements/Button'
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  ComputerDesktopIcon,
  CpuChipIcon,
  GlobeAmericasIcon,
  MicrophoneIcon,
  UserIcon,
  EnvelopeIcon,
  PlusIcon,
  TrashIcon,
  PencilSquareIcon,
  DocumentTextIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/solid'
import { authState } from '../../../../app/slices/authSlice'
import ViewsPopup from './ViewsPopup'
import { viewsState, updateView } from '../../../../app/slices/viewsSlice'
import ButtonTooltip from '../../../utils/elements/ButtonTooltip'
import _ from 'lodash'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'

const adavancedFilteringData = [
  {
    type: 'screen',
    title: 'Screen Size',
    Icon: ComputerDesktopIcon
  },
  {
    type: 'os',
    title: 'Operating System',
    Icon: CpuChipIcon
  },
  {
    type: 'browser',
    title: 'Browser',
    Icon: GlobeAmericasIcon
  },
  {
    type: 'mic',
    title: 'Mic',
    Icon: MicrophoneIcon
  },
  {
    type: 'user',
    title: 'User',
    Icon: UserIcon
  },
  {
    type: 'email',
    title: 'Email',
    Icon: EnvelopeIcon
  }
]

interface FilteringBarProps {
  clearFilters: Function
}

const FilteringBar: React.FC<FilteringBarProps> = ({ clearFilters }) => {
  const dispatch = useAppDispatch()
  const { filters, filterRecordsReq, filterDone } =
    useAppSelector(problemsState)
  const [open, setOpen] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false)
  const [popupType, setPopupType] = useState('create')
  const { currentView } = useAppSelector(viewsState)
  const { user } = useAppSelector(authState)

  const openPopup = (type: string) => {
    setPopupType(type)
    setOpen(true)
  }

  useEffect(() => {
    if (currentView.query_json) {
      if (_.isEqual(JSON.parse(currentView.query_json), filterRecordsReq)) {
        setCanUpdate(false)
      } else {
        setCanUpdate(true)
      }
    }
  }, [currentView, filterRecordsReq])

  return (
    <div className="z-20 relative flex items-center justify-between">
      <div className="flex">
        <AdvancedFiltering
          title={'Domains'}
          categories={filters.Origins}
          activeFilterTab={''}
          withCategories={false}
        />

        <AdvancedFiltering
          title={'Additional Info'}
          categories={adavancedFilteringData}
          activeFilterTab={adavancedFilteringData[0].type}
          withCategories={true}
        />
        {filters.CustomData && filters.CustomData.length > 0 && (
          <AdvancedFiltering
            title={'Custom'}
            categories={filters.customDataCategories}
            activeFilterTab={filters.customDataCategories[0].type}
            withCategories={true}
          />
        )}
        <AdvancedFiltering
          title={'Investigate'}
          categories={filters.Network.concat(filters.Console)}
          activeFilterTab={''}
          withCategories={false}
        />
      </div>
      <div className="flex">
        {currentView.id ? (
          <div className="flex items-center border border-tuto-primary divide-x divide-tuto-primary rounded-lg mr-2">
            <ButtonTooltip text="Edit View Settings">
              <div
                className={`flex items-center m-1 py-1 px-2 ${
                  currentView.id
                    ? 'cursor-pointer hover:bg-tuto-light-blue text-tuto-primary'
                    : 'text-gray-400'
                } rounded-lg transition duration-300`}
              >
                <button
                  disabled={currentView.id ? false : true}
                  className=""
                  onClick={() => openPopup('update')}
                >
                  <Cog6ToothIcon className="w-6 h-6" />
                </button>
              </div>
            </ButtonTooltip>
            <ButtonTooltip text="Add View">
              <div
                className={`flex items-center m-1 py-1 px-2 ${
                  canUpdate
                    ? 'cursor-pointer hover:bg-tuto-light-blue text-tuto-primary'
                    : 'text-gray-400'
                } rounded-lg transition duration-300`}
              >
                <button
                  className=""
                  disabled={!canUpdate}
                  onClick={() => openPopup('create')}
                >
                  <PlusIcon className="w-6 h-6" />
                </button>
              </div>
            </ButtonTooltip>
            <ButtonTooltip text="Update View">
              <div
                className={`flex items-center m-1 py-1 px-2 ${
                  canUpdate
                    ? 'cursor-pointer hover:bg-tuto-light-blue text-tuto-primary'
                    : 'text-gray-400'
                } rounded-lg transition duration-300`}
              >
                <button
                  className=""
                  disabled={!canUpdate}
                  onClick={async () => {
                    const updatedRes = await dispatch(
                      updateView({
                        view_id: currentView.id,
                        query_json: filterRecordsReq,
                        name: currentView.name,
                        is_public: currentView.is_public
                      })
                    )
                    if (updatedRes.payload[0].id) {
                      dispatch(
                        addNewNotification({
                          text: `View Settings ${currentView.name} updated successfully`,
                          id: uuidv4(),
                          title: 'View Settings Updated!',
                          type: 'SUCCESS',
                          timer: true,
                          button: false
                        })
                      )
                    }
                  }}
                >
                  <svg
                    className={`h-7 w-7 ${
                      canUpdate ? 'fill-tuto-primary' : 'fill-gray-400'
                    } transition duration-300`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M5 5v14h14V7.828L16.172 5H5zM4 3h13l3.707 3.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm8 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM6 6h9v4H6V6z" />
                  </svg>
                </button>
              </div>
            </ButtonTooltip>
            <ButtonTooltip text="Delete View">
              <div
                className={`flex items-center m-1 py-1 px-2 ${
                  user.id === currentView.user_id || user.role_name === 'admin'
                    ? 'cursor-pointer hover:bg-tuto-light-blue text-tuto-primary'
                    : 'text-gray-400'
                } rounded-lg transition duration-300`}
              >
                <button
                  disabled={
                    user.id === currentView.user_id ||
                    user.role_name === 'admin'
                      ? false
                      : true
                  }
                  className=""
                  onClick={() => openPopup('delete')}
                >
                  <TrashIcon className="w-6 h-6" />
                </button>
              </div>
            </ButtonTooltip>
          </div>
        ) : (
          <div className="flex">
            <div className="mr-2">
              <Button
                loading={false}
                disabled={false}
                Icon={PlusIcon}
                inverted={false}
                withIcon={true}
                type="button"
                text="Add As View"
                spacialClass="white-btn"
                clickFunction={() => openPopup('create')}
              />
            </div>

            <div className="mr-2">
              <Button
                loading={false}
                disabled={false}
                Icon={ArrowPathIcon}
                inverted={false}
                withIcon={true}
                type="button"
                text="Clear All"
                spacialClass="white-btn"
                clickFunction={() => clearFilters()}
              />
            </div>
          </div>
        )}
        <Button
          loading={false}
          disabled={false}
          Icon={MagnifyingGlassIcon}
          inverted={false}
          withIcon={false}
          type="button"
          text="APPLY"
          spacialClass="px-8"
          clickFunction={() => {
            dispatch(
              filterRecords({
                customer_id: user.customer_id,
                filterRecordsReq
              })
            )
            dispatch(setFilterDone(true))
          }}
        />
        <ViewsPopup setOpen={setOpen} open={open} type={popupType} />
      </div>
    </div>
  )
}

export default FilteringBar
