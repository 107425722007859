import React, { useEffect, useRef, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { deleteView, viewsState, setCurrentView } from '../../../../app/slices/viewsSlice'
import { resetFilters, setOrigins } from '../../../../app/slices/ProblemsSlice'
import { customerDomainsSelectors } from '../../../../app/slices/customerDomainsSlice'
import { addNewNotification } from '../../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'


interface DeleteContentProps {
  setOpen:Function
}
const DeleteContent:React.FC<DeleteContentProps> = ({setOpen}) => {
  const dispatch = useAppDispatch()
  const cancelButtonRef = useRef(null)
  const {currentView} = useAppSelector(viewsState)
  const domains = useAppSelector(customerDomainsSelectors.selectAll)

  const removeCurrentView = () => {
    localStorage.removeItem('record_cur_view')
    // setActiveNav('')
    dispatch(setCurrentView({}))
    dispatch(resetFilters())
    dispatch(setOrigins({domains, type: 'all'}))
  }
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Delete view
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete {currentView.name}?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={async() => {
                        const deletedView = await dispatch(deleteView({view_id:currentView.id}))
                        
                        removeCurrentView()
                        setOpen(false)
                        if (deletedView.payload[0].id) {
                          dispatch(
                            addNewNotification({
                              text: `View ${deletedView.payload[0].name} Deleted successfully`,
                              id: uuidv4(),
                              title: 'View Deleted!',
                              type: 'SUCCESS',
                              timer: true,
                              button: false
                            })
                          )
                        }

                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
  )
}

export default DeleteContent