import { CpuChipIcon, ComputerDesktopIcon, GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline'
import ShortUuid from '../../utils/shared/ShortUuid'
import React, { useEffect } from 'react'

interface BrowserInfoProps {
  info: any
}

const BrowserInfo:React.FC<BrowserInfoProps> = ({info}) => {

  
  return (
    <div className='border-t border-t-tuto-primary pt-4'>
      <div className="flex items-center mb-2">
        <ComputerDesktopIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold"></span>{' '}
        <span className="ml-2 font-bold">
        {info.screenWidth} X {info.screenHeight}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <CpuChipIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold"></span>{' '}
        <span className="ml-2 font-bold">
        {info.os}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <GlobeEuropeAfricaIcon className="w-6 h-6 mr-2" />
        <span className="font-bold"></span>{' '}
        <span className="ml-2 font-bold">
        {info.browserAgent}
        </span>
        <span className="ml-2 font-bold">
        {info.browserAgentVersion}
        </span>
      </div>
    </div>
  )
}

export default BrowserInfo