import Button from '../elements/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { useRef } from 'react'
import { PencilIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid'

interface SingleInputChangeProps {
  schema: any
  changeValueFunction: any
  updateApiFunction: any
  buttonText: string
  toggleViewsState: boolean
  isPhone: boolean
  name : string
  registerFunction: any
  isPlayground? : boolean
  dataCySingle?: string
  placeHolder: string
  notify: boolean
  errorCB?: any
  defaultValue?:string
}

interface IFormInputs {
  data: string
}

const schema = yup.object().shape({
  data: yup.string().required().min(2)
})

const SingleInputChange: React.FC<SingleInputChangeProps> = ({
  changeValueFunction,
  updateApiFunction,
  buttonText,
  toggleViewsState,
  defaultValue,
  name,
  registerFunction,
  dataCySingle,
  isPlayground,
  placeHolder,
  notify,
  errorCB
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })


  
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const [domainPrefix, setDomainPrefix] = useState('https://')


  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const userId = user.id
    const customerId = user.customer_id
    if(!isPlayground){
      if(name === 'domain'){
        const regex = RegExp(
          /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,=.]+$/gm
        )
        const fullDomainName = domainPrefix.concat(data.data)
        const isRealURL = regex.test(fullDomainName)
        if(isRealURL){
          errorCB(false)
          dispatch(
            updateApiFunction({
              data: data.data,
              userId: userId,
              type: name,
              customerId: customerId
            })
          )
        }
        else{
          errorCB(true)
        }
      }
      else{
        dispatch(
          updateApiFunction({
            data: data.data,
            userId: userId,
            type: name,
            customerId: customerId
          })
        )
      }
    }
    else{
      if(name === 'workEmail'){
        const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        const regex = RegExp(
          /(?=^((?!@gmail.com).)*$)(?=^((?!@yahoo.com).)*$)/
        )
        const isEmail = mailRegex.test(data.data)
        const isWorkEmail = regex.test(data.data)
        if(isEmail && isWorkEmail){
          errorCB(false)
          updateApiFunction(data)
        }
        else{
          errorCB(true)
        }
      }
      else{
        updateApiFunction(data)
      }
    }
    reset()
    changeValueFunction(false)
    const notifTitle = name === 'customer' ? 'Organization Name' : 'User Name'
    const notifContent = name === 'customer' ? 'Organization name' : 'User name'
    if(!isPlayground && notify){
      dispatch(
        addNewNotification({
          text: `Updated ${notifContent} to ${data.data}`,
          id: uuidv4(),
          title: `${notifTitle} Updated!`,
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    }
  }

  return toggleViewsState ? (
    <form
      className="relative flex-grow"
      onSubmit={handleSubmit(formSubmitHandler)}
    >
      <input
        {...register('data')}
        type="text"
        id={name}
        className="w-full focus:outline-none sm:text-sm border-transparent border-b border-b-gray-400 "
        placeholder={placeHolder}
        // value={defaultValue? defaultValue: ''}
      />
      {errors.data && (
        <p className="absolute -bottom-4  text-xs text-red-600" id="email-error">
          {errors.data?.message}
        </p>
      )}

      <div className="absolute inset-y-0 right-0 flex items-center">
        {/* <Button
          inverted={true}
          loading={false}
          disabled={false}
          text={''}
          type="submit"
          withIcon={true}
          Icon={PaperAirplaneIcon}
          spacialClass="text-xs mx-2"
          dataCy={dataCySingle}
        /> */}
      </div>
    </form>
  ) : (
    // <Button
    //   inverted={true}
    //   loading={false}
    //   disabled={false}
    //   clickFunction={() => changeValueFunction(true)}
    //   text="Change"
    //   type="button"
    //   withIcon={false}
    //   spacialClass="text-xs mr-2"
    //   dataCy={dataCySingle}
    // />
    <button className='bg-gray-200 text-tuto-primary rounded-full p-2' onClick={() => changeValueFunction(true)}>
      <PencilIcon className='h-3 w-3' />
    </button>
  )
}

export default SingleInputChange
