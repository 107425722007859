import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { unprotectedGetRequest } from '../../../../app/apis/apiGenerics'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import { investigationParametersState } from '../../../../app/slices/investigationParametersSlice'
import { investigationElastic } from '../../../../app/slices/investigationsSlice'

interface ValuesDropdownProps {
  onSelect: (values: string[]) => void
  isRadio?: boolean
  parameterId: string
  selectedParameter: any
  defaultKeys: string[]
  setCustomDataKey: any
}

const KeysDropdown = ({
  onSelect,
  isRadio = false,
  parameterId,
  selectedParameter,
  defaultKeys,
  setCustomDataKey
}: ValuesDropdownProps) => {
  const [selectedKey, setSelectedKey] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAppSelector(authState)

  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const { parameters } = useAppSelector(investigationParametersState)
  const params = useParams()
  // const seletedParameter = parameters.find((param) => param.id === parameterId)

  useEffect(() => {
    if (!params.investigationId) {
    } else {
      if (selectedParameter?.selectedValues) {
        let keys = Object.keys(selectedParameter?.selectedValues)
          console.log(keys)
          setSelectedKey(keys[0])

        // setValues([...values])
      }
    }
  }, [selectedParameter?.parameter])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
        // dispatch(investigationElastic({customer_id:user.customer_id, filters:parameters}))
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOptionToggle = (key: string) => {
    setSelectedKey(key)
    setCustomDataKey(key)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredValues = defaultKeys.filter(
    (value) =>
      value &&
      value.length > 0 &&
      value.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleAdd = () => {
    // dispatch(mapForElastic())
    setIsOpen(false)
    dispatch(
      investigationElastic({
        customer_id: user.customer_id,
        filters: parameters
      })
    )
  }

  // const selectedValuesString = () => {
  //   if (values.length === 0) return ''
  //   if (values.length === 1) return values[0]
  //   return `${values[0]} (and ${values.length - 1} more...)`
  // }

  return (
    <div className="relative">
      <button className="parameter-tag" onClick={() => setIsOpen(!isOpen)}>
        {selectedKey || 'Select Key'}
      </button>
      {isOpen && (
        <div
          ref={ref}
          className="absolute top-12  min-w-22 z-10 left-0  w-full bg-white border border-gray-300 rounded-lg  shadow-lg"
        >
          <div className="absolute h-2 w-2 bg-tuto-primary  rotate-45 -top-1 left-6"></div>
          <div className="px-4 py-2 bg-tuto-primary rounded-t-lg">
            <input
              type="text"
              className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
              placeholder="Search values..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <MagnifyingGlassIcon className="h-6 w-6 text-tuto-primary absolute right-6 top-4" />
          </div>
          {/* <div className="flex items-center just0fy-center px-4 py-1">
            <button className="text-gray-500 text-sm" onClick={handleCheckAll}>
              All
            </button>
            <div className="mx-1 text-gray-500"> | </div>
            <button className="text-gray-500 text-sm" onClick={handleClearAll}>
              None
            </button>
          </div> */}
          <hr />
          <ul className="text-gray-800 h-52 overflow-scroll">
            {filteredValues.length > 0 ? (
              filteredValues.map((value) => (
                <li
                  key={value}
                  className="flex text-xs items-center px-4 py-2 cursor-pointer hover:bg-light-gray"
                  onClick={() => handleOptionToggle(value)}
                >
                  <input
                    type="radio"
                    checked={value === selectedKey}
                    readOnly
                    className="rounded-full checked:bg-tuto-light-blue"
                  />

                  <span className="ml-2 font-semibold">{value}</span>
                </li>
              ))
            ) : (
              <div className="flex w-full h-full items-center p-4 justify-center text-gray-400 text-center">
                <NoSymbolIcon className="w-6 h-5 mr-2" />
                No Results Found
              </div>
            )}
          </ul>
          <hr />
          <div
            onClick={handleAdd}
            className="px-4 py-2 bg-tuto-primary rounded-b-lg text-white font-semibold cursor-pointer text-center"
          >
            Add
          </div>
        </div>
      )}
    </div>
  )
}

export default KeysDropdown
