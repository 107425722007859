import React, { useState, ChangeEvent, useEffect } from 'react'
import FeedElement from './FeedElement'
import EventsFilterDropdown from './EventsFilterDropdown'

interface IDataItem {
  category: string
  sub_category: string
  title: string
  content: string
  timestamp: number
  more_info: { gili: boolean }
  banner: { content: string; is_unhandled: boolean }
}

interface EventsFeedProps {
  data: IDataItem[]
  startTimestamp: number
  openNetwork: any
  setNetworkData: any
}

const EventsFeed: React.FC<EventsFeedProps> = ({
  data,
  startTimestamp,
  openNetwork,
  setNetworkData
}) => {
  const [search, setSearch] = useState<string>('')
  const [filters, setFilters] = useState<Record<string, boolean>>({})

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
   
      setFilters({})
      setSearch('')
  }, [startTimestamp])

  let categoriesMap: { [category: string]: Set<string> } = {}

  data.forEach((item) => {
    if (!categoriesMap[item.category]) {
      categoriesMap[item.category] = new Set()
    }
    if (item.sub_category) {
      categoriesMap[item.category].add(item.sub_category)
    }
  })

  let categories = Object.keys(categoriesMap).map((category) => ({
    name: category,
    subCategories: Array.from(categoriesMap[category])
  }))

  // Remove any category which doesn't have a name.
  categories = categories.filter((category) => category.name !== '')

  const isNoFilterApplied = Object.values(filters).every((value) => !value)

  const filteredData = data
    .filter(
      (item) =>
        isNoFilterApplied ||
        filters[item.category] ||
        filters[item.sub_category]
    )
    .filter((item) => item.content?.toLowerCase().includes(search.toLowerCase()))

  const handleMainCategoryChange = (
    category: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }
      newFilters[category] = isChecked
      categories
        .find((cat) => cat.name === category)
        ?.subCategories.forEach((subCat) => {
          newFilters[subCat] = isChecked
        })
      return newFilters
    })
  }

  const handleSubCategoryChange = (
    mainCategory: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked
    const subCategory = event.target.name

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }
      newFilters[subCategory] = isChecked

      const allSubCategoriesChecked = categories
        .find((cat) => cat.name === mainCategory)
        ?.subCategories.every((subCat) => newFilters[subCat] === true)

      if (allSubCategoriesChecked) {
        newFilters[mainCategory] = true
      } else {
        newFilters[mainCategory] = false
      }

      return newFilters
    })
  }

  return (
    <div className="flex flex-col  items-start relative">
      <div className="sticky top-12 bg-gray-100 flex  gap-2 w-full py-2">
        <input
          type="text"
          className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search..."
        />

        <EventsFilterDropdown
          categories={categories}
          handleMainCategoryChange={handleMainCategoryChange}
          handleSubCategoryChange={handleSubCategoryChange}
          filters={filters}
        />
      </div>

      <div className="flex flex-col gap-2 w-full">
        {filteredData.map((item, index) => (
          <FeedElement
            key={index}
            item={item}
            startTimestamp={startTimestamp}
            openNetwork={openNetwork}
            setNetworkData={setNetworkData}
            search={search}
          />
        ))}
      </div>
    </div>
  )
}

export default EventsFeed
