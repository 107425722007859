import { protectedPostRequest, unprotectedGetRequest, unprotectedPostRequest } from './apiGenerics'

export const GetUsersByCustomer = async (customer_id: string) => {
  const users = await unprotectedGetRequest(
    `users/getCompanyUsers?customer_id=${customer_id}`
  )
  
  return users
}

export const UpdateUsersRole = async (user_id: string, role_id: string) => {
  const users = await unprotectedPostRequest('users/updateUserRole', {
    user_id,
    role_id
  })
  return users
}

export const DeleteUser = async (email:string, id:string) => {
  const deleteUser = await protectedPostRequest('users/deleteUser' ,{
    email
  })
  return {deleteUser, id}
}

export const GetUserRoles = async () => {
  const usersRoles = await unprotectedGetRequest('users/getUsersRoles')
  return usersRoles
}

export const SendInvitation = async (
  customer_id: string,
  role_id: string,
  teammates: string[],
  campaign_id: string,
  sender: string,
  senderName: string,
  inviter_id: string,
  name: string,
  is_re_invite: boolean,
  invitation_id: string
) => {
  const users = await unprotectedPostRequest('users/sendInvitaionMail', {
    customer_id,
    role_id,
    teammates,
    campaign_id,
    sender,
    senderName,
    inviter_id,
    name,
    is_re_invite,
    invitation_id
  })
  return users
}

export const UpdateCustomerHasDomain = async (
  customer_id: string,
  hasDomain: boolean
) => {
  const users = await unprotectedPostRequest('customers/updateCustomerHasDomain', {
    customer_id,
    hasDomain
  })
  return users
}

export const IsUserExist = async(
  userName: string
) => {
  const isExist = unprotectedPostRequest('auth/isUserExist', {
    email: userName
  })
  return isExist
}