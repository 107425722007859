import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState, getIntegrations } from '../../../../app/slices/authSlice'
import { customerIntegrationsState } from '../../../../app/slices/customerIntegrationsSlice'
import Button from '../../../utils/elements/Button'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import Integration from './Integration'

const Integrations = () => {
  const dispatch = useAppDispatch()
  const { integrations } = useAppSelector(customerIntegrationsState)
  const { recordIntegrations, user } = useAppSelector(authState)

  const isExistForUser = (integrationId: string) => {
    let isExist = false
    if (integrations.find((i) => i.integration_id === integrationId)) {
      isExist = true
    }
    return isExist
  }

  useEffect(() => {
    if (user) {
      Mixpanel.register({
        Page: 'Integrations',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      Mixpanel.track('Integrations Page Viewed', {})
    }
    if (recordIntegrations.length === 0) {
      dispatch(getIntegrations())
    }
  }, [])

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-12 gap-4">
      <div>
        <div className="mt-2 mb-4 text-lg md:text-2xl text-dark-primary font-semibold">
          Ready to use
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-1 mb-4 md:gap-4">
          {recordIntegrations.map(
            (integration: any) =>
              !integration.coming_soon && (
                <Integration
                  existForUser={isExistForUser(integration.id)}
                  logo={integration.image_src}
                  alreadyExist={!integration.coming_soon}
                  integrationId={integration.id}
                />
              )
          )}
        </div>
        <div className="mt-8 md:mt-12 mb-2 text-lg md:text-2xl text-dark-primary font-semibold">
          Coming soon
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-4">
          {recordIntegrations.map(
            (integration: any) =>
              integration.coming_soon && (
                <Integration
                  existForUser={isExistForUser(integration.id)}
                  logo={integration.image_src}
                  alreadyExist={!integration.coming_soon}
                  integrationId={integration.id}
                />
              )
          )}
        </div>
      </div>
      <div className="p-4 md:pl-32">
        <div>
          <h1 className="text-3xl text-light-black font-semibold mb-4">
            Installation steps
          </h1>
          <ol>
            <li>1. Please install the code on any web environment </li>
            <li>2. Record will Identify your domain and notify you.</li>
            <li>3. Approve your domain under settings/domains.</li>
            <li>
              4. Share your unique link with your users to enable recording
            </li>
            <li> 5. Watch the record on your dashboard</li>
          </ol>
          <div className="mt-4">
            {/* <div className="mb-4">
              <Button
                inverted={true}
                loading={false}
                disabled={false}
                text="Send installation instructions"
                type="button"
                withIcon={false}
                spacialClass="text-xs mr-2"
                clickFunction={() => {
                  
                }}
              />
            </div> */}
            <div>
              <a target={'_blank'} href='https://torecord.it/docs' >
                <Button
                  inverted={true}
                  loading={false}
                  disabled={false}
                  text="Installation guide"
                  type="button"
                  withIcon={false}
                  spacialClass="text-xs mr-2"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h1 className="text-xl text-light-black font-semibold mb-4">
            Organizations who trust us
          </h1>
          <div className="flex flex-wrap">
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/paypal.png"
              alt="record"
            />
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/buildium.png"
              alt="record"
            />
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/pingboard.png"
              alt="record"
            />
          </div>
          <div className="flex flex-wrap">
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/yellow-ai.png"
              alt="record"
            />
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/dataloop.png"
              alt="record"
            />
            <img
              className="h-8 w-auto sm:h-7 mr-2 md:mr-4 my-2 grayscale opacity-70"
              src="https://sandbox-records-tuto.s3.eu-west-1.amazonaws.com/images/syte.png"
              alt="record"
            />
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default Integrations
