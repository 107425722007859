import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    createEntityAdapter
  } from '@reduxjs/toolkit'
import { GetCustomerIntegrationEarlyRequests, GetIntegrationsByCustomer, SendNewEarlyRequest } from '../apis/customerDomainsApi'
  import { RootState } from '../store'
  
  export interface IntegrationState {
    integrations: any[]
    loading: boolean
    error: string
    actionLoading: boolean
    earlyRequests: any[]
  }
  
  const initialState: IntegrationState = {
    integrations: [],
    error: '',
    loading: true,
    actionLoading: false,
    earlyRequests: []
  }

  export const getCustomerIntegrations = createAsyncThunk(
    'customerDomains/getCustomerIntegrations',
    async({customer_id}: any, thunkApi) => {
        try{
            const res = await GetIntegrationsByCustomer(customer_id)
            thunkApi.dispatch(getCustomerIntegrationsEarlyRequests({customer_id}))
            return res.data
        } catch (err: any) {
          return err.message
        }
    }
  )

  export const getCustomerIntegrationsEarlyRequests = createAsyncThunk(
    'customerDomains/getCustomerIntegrationsEarlyRequests',
    async({customer_id}: any) => {
        try{
            const res = await GetCustomerIntegrationEarlyRequests(customer_id)
            return res.data
        } catch (err: any) {
          return err.message
        }
    }
  )

  export const sendNewEarlyRequest = createAsyncThunk(
    'customerDomains/sendNewEarlyRequest',
    async({customer_id, integration_id, application}: any, thunkApi) => {
        try{
            const res = await SendNewEarlyRequest(customer_id, integration_id, application)
            thunkApi.dispatch(getCustomerIntegrationsEarlyRequests({customer_id}))
            return res.data
        } catch (err: any) {
          return err.message
        }
    }
  )
  
  const customerIntegrationsSlice = createSlice({
    name: 'customerIntegrations',
        initialState,
        reducers: {
        },
        extraReducers(builder) {
          builder
            .addCase(getCustomerIntegrations.pending, (state, action) => {
              state.loading = true
            })
            .addCase(
                getCustomerIntegrations.fulfilled,
              (state, action: PayloadAction<any>) => {
                state.loading = false
                state.integrations = action.payload
              }
            )
            .addCase(
                getCustomerIntegrations.rejected,
              (state, action: PayloadAction<any>) => {
                state.loading = false
                state.error = action.payload
              }
            )
            .addCase(
                getCustomerIntegrationsEarlyRequests.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.earlyRequests = action.payload
                }
            )
            .addCase(
                getCustomerIntegrationsEarlyRequests.rejected,
                (state, action: PayloadAction<any>) => {
                    state.error = action.payload
                }
            )
        }
  })
  
  export const customerIntegrationsState = (state: RootState) => state.customerIntegrations
  export const {

    } = customerIntegrationsSlice.actions
  
  export default customerIntegrationsSlice.reducer
  