// Conditions.tsx

import { useState, useEffect, useRef } from 'react'
import ValuesDropdown from './ValuesDropdown'
import ValuesTextField from './ValuesTextField'
import RadioValues from './RadioValues'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import {
  InvestigationParameter,
  investigationParametersState,
  setLastParameterValid,
  setSelectedValues
} from '../../../../app/slices/investigationParametersSlice'
import { authState } from '../../../../app/slices/authSlice'
import KeysDropdown from './KeysDropdown'
import { unprotectedGetRequest } from '../../../../app/apis/apiGenerics'

interface ConditionsProps {
  onSelect: (condition: string) => void
  selectedParameter: InvestigationParameter | null
  parameterId: string
}

const Conditions = ({
  onSelect,
  selectedParameter,
  parameterId
}: ConditionsProps) => {
  const { user } = useAppSelector(authState)
  const { parameters } = useAppSelector(investigationParametersState)
  const [conditions, setConditions]=useState<string[]>([]) 

  
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCondition, setSelectedCondition] = useState<string>()
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const [defaultKeys , setDefaultKeys] = useState<string[]>([])
  const [customDataValues , setCustomDataValues] = useState<any>()
  const [customDataKey , setCustomDatakey] = useState<any>('')

  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   onSelect(selectedCondition)
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedParameter?.parameter) {
      if (selectedParameter.parameter.hasDropdown) {
        setConditions(prevConditions => {
          // Filter out conditions related to dropdowns.
          const filtered = prevConditions.filter(condition => condition !== 'is' && condition !== 'is not');
          // Add dropdown conditions.
          return [...filtered, 'is', 'is not'];
        });
      } else {
        setConditions(prevConditions => {
          // Filter out conditions related to dropdowns.
          return prevConditions.filter(condition => condition !== 'is' && condition !== 'is not');
        });
      }
  
      if (selectedParameter.parameter.hasTextField) {
        setConditions(prevConditions => {
          // Filter out conditions related to text fields.
          const filtered = prevConditions.filter(condition => condition !== 'contains' && condition !== 'not contains');
          // Add text field conditions.
          return [...filtered, 'contains', 'not contains'];
        });
        
      } else {
        setConditions(prevConditions => {
          // Filter out conditions related to text fields.
          return prevConditions.filter(condition => condition !== 'contains' && condition !== 'not contains');
        });
      }
      
    }
  }, [selectedParameter?.parameter?.value]);

  useEffect(() => {
    console.log(parameters)
    console.log(selectedParameter?.selectedCondition)
    if(selectedParameter?.selectedCondition) {

      setSelectedCondition(selectedParameter?.selectedCondition)
      onSelect(selectedParameter?.selectedCondition)
    } else {
      setSelectedCondition(conditions[0])
      onSelect(conditions[0])
    }
  }, [conditions])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    // if (selectedParameter?.parameter?.value === 'custom_data') {
    //   getCustomValues()
    // }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    // Reset the selected values when the selectedParameter prop changes
    // dispatch(setSelectedValues(selectedParameter, null))
    setSelectedCondition(conditions[0])
    // Reset the isValid state
    if (selectedParameter?.parameter?.type === 'bool') {
      dispatch(setLastParameterValid({ isValid: true }))
      
    } 
  }, [])

  useEffect(() => {
    if (selectedParameter?.parameter?.value === 'custom_data') {
      getCustomValues()
    }
  }, [selectedParameter])

  const getCustomValues = async () => {

    if (selectedParameter?.parameter) {
      let res = await unprotectedGetRequest(
        `records/getFilterOptionsByParam?customer_id=${user.customer_id}&param=${selectedParameter?.parameter?.value}`
      )
     
      const keys = Object.keys(res.data.values[0])
     
      setCustomDataValues(res.data.values[0])
      
      setDefaultKeys(keys)
    }
  }

  const handleSelect = (condition: string) => {
    setSelectedCondition(condition)
    onSelect(condition)
    setIsOpen(false)
    // dispatch(setLastParameterValid({ isValid: false }))
    
  }

  const handleValueChange = (value: string | string[]) => {
   
    const inputValueIsValid =
      value !== '' && (!Array.isArray(value) || value.length > 0)
    if (selectedParameter?.parameter?.type === 'bool') {
      dispatch(setLastParameterValid({ isValid: true }))
    } else {
      dispatch(setLastParameterValid({ isValid: inputValueIsValid }))
    }
    
  }

  const handleTextFieldChange = (value: string) => {
    
      dispatch(setSelectedValues({ id: parameterId, values: value }))
      dispatch(setLastParameterValid({ isValid: true }))
    
  }

  return (
    <div className="relative flex gap-2" ref={ref}>
      {selectedParameter?.parameter?.type === 'dynamic' && (
        <KeysDropdown onSelect={handleValueChange} setCustomDataKey={setCustomDatakey} defaultKeys={defaultKeys} parameterId={parameterId} selectedParameter={selectedParameter} />
      )}

      <button className="parameter-tag" onClick={() => setIsOpen(!isOpen)}>
        {selectedCondition || 'Select Condition'}
      </button>
      {isOpen && (
        <div className="absolute z-10 left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg">
          <ul className="text-gray-800">
            {conditions.map((condition) => (
              <li
                key={condition}
                className="cursor-pointer px-4 py-2 hover:bg-tuto-light-blue hover:bg-opacity-30"
                onClick={() => handleSelect(condition)}
              >
                {condition}
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedCondition &&
      (selectedCondition === 'is' || selectedCondition === 'is not') ? (
        selectedParameter?.parameter?.type === 'bool' ? (
          <RadioValues
            selectedParameter={selectedParameter}
            onSelect={handleValueChange}
            parameterId={parameterId}
          />
        ) : (
          <ValuesDropdown
            onSelect={handleValueChange}
            parameterId={parameterId}
            customDataValues={customDataValues}
            customKey={customDataKey}
          />
        )
      ) : (
        <ValuesTextField selectedParameter={selectedParameter} onChange={handleTextFieldChange} />
      )}
    </div>
  )
}

export default Conditions
