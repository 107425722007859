// ParameterDropdown.tsx

import { useState, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { investigationParametersState, setSelectedParameter, setSelectedValues } from '../../../../app/slices/investigationParametersSlice'
import parametersData from './ParametersData.json'

interface Parameter {
  group: string
  value: string
  displayName: string
  type: string
  hasDropdown: boolean
  hasTextField: boolean
  parent: string
}

interface SelectedParameterInfo {
  parent: string | null
  parameter: Parameter
}

interface ParameterDropdownProps {
  id: string | undefined
  onSelect: (selectedParameterInfo: SelectedParameterInfo) => void;
}

const ParameterDropdown = ({
  onSelect,
  id
}: ParameterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filterGroup, setFilterGroup] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  const {parameters} = useAppSelector(investigationParametersState);
  const selectedParameter = parameters.find((param) => param.id === id) || parameters[0]
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const handleSelect = ( parameter: Parameter) => {
    // onSelect({ parent: parameter.parent, parameter })
    
    const parameterSet = new Set(parameters.map(p => p.parameter?.value));
    let isExist = parameterSet.has(parameter.value)
    
    if(!isExist) {

      dispatch(setSelectedValues({id:selectedParameter.id ,values: null}))
      dispatch(setSelectedParameter({id:selectedParameter.id, parameter} ))
      setIsOpen(false)
    }
  }

  const handleFilterSelect = (group: string | null) => {
    setFilterGroup(group)
    setIsFilterOpen(false)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredParameters = filterGroup
    ? parametersData.filter((param) => param.group === filterGroup)
    : parametersData

  const searchedParameters = filteredParameters.filter((parameter) =>
    parameter.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const uniqueGroups = Array.from(
    new Set(
      parametersData
        .filter((param) => param.group !== '')
        .map((param) => param.group)
    )
  )

  const groupParametersByParent = (params: Parameter[]) => {
    const groupedParameters: { [key: string]: Parameter[] } = {}

    params.forEach((param) => {
      const parent = param.parent
      if (!groupedParameters[parent]) {
        groupedParameters[parent] = []
      }
      groupedParameters[parent].push(param)
    })

    return groupedParameters
  }

  const groupedParameters = groupParametersByParent(searchedParameters)

  const renderParentWithSubParameters = (
    parent: string,
    subParameters: Parameter[]
  ) => (
    <>
      <li key={parent} className="px-4 py-2 font-bold border-b">
        {parent}
      </li>
      {subParameters.map((parameter) => (
        <li
          key={parameter.value}
          className="cursor-pointer pl-8 px-4 py-2 hover:bg-tuto-light-blue hover:bg-opacity-30"
          onClick={() => handleSelect(parameter)}
        >
          {parameter.displayName}
        </li>
      ))}
    </>
  )

  return (
    <div className="relative" ref={ref}>
      <button
        className="parameter-tag"
        onClick={toggleDropdown}
      >
        {selectedParameter.parameter
          ?  selectedParameter.parameter.parent ? selectedParameter.parameter.parent + ' > ' +  selectedParameter.parameter.displayName :
            selectedParameter.parameter.displayName
          : 'Select a parameter'}
      </button>
      {isOpen && (
        <div className="absolute min-w-22 h-80 overflow-scroll z-10  left-0 mt-2 w-full bg-white border border-gray-300 rounded-t-lg shadow-lg">
          <div className='sticky rounded-t-lg top-0'>
          <div className="px-4 py-2 bg-tuto-primary  rounded-t-lg">
            <input
              type="text"
              className="border border-gray-300 rounded px-4 py-2  w-full text-sm"
              placeholder="Search parameters..."
              value={searchQuery}
              onChange={handleSearch}
            />
            </div>
            <div className="flex border-b gap-1 justify-between bg-white p-1">
              {/* <button
                className="bg-white text-gray-800 border-b border-gray-300 w-full text-left py-2 px-4"
                onClick={toggleFilterDropdown}
              >
                {filterGroup ? `Group: ${filterGroup}` : 'Filter by group'}
              </button> */}
              <button
                className={`text-gray-800  rounded text-xs ${filterGroup === null ? 'bg-tuto-light-blue bg-opacity-30': ''} font-semibold px-4 py-2 hover:bg-tuto-light-blue hover:bg-opacity-30`}
                onClick={() => handleFilterSelect(null)}
              >
                All
              </button>
              {uniqueGroups.map((group) => (
                    <button
                      key={group}
                      className={`cursor-pointer rounded text-xs px-1 py-2 border-l text-center ${filterGroup === group ? 'bg-tuto-light-blue bg-opacity-30': ''} hover:bg-tuto-light-blue hover:bg-opacity-30`}
                      onClick={() => handleFilterSelect(group)}
                    >
                      {group}
                    </button>
                  ))}
            </div>
          </div>
          <div className="relative">
            {isFilterOpen && (
              <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg">
                <ul className="text-gray-800">
                  {uniqueGroups.map((group) => (
                    <li
                      key={group}
                      className="cursor-pointer px-4 py-2 hover:bg-tuto-light-blue hover:bg-opacity-30"
                      onClick={() => handleFilterSelect(group)}
                    >
                      {group}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <ul className="text-gray-800">
            {Object.entries(groupedParameters).map(
              ([parent, subParameters]) => {
                if (parent === '') {
                  return subParameters
                    .filter((param) => param.type !== 'nested')
                    .map((parameter) => (
                      <li
                        key={parameter.displayName}
                        className="cursor-pointer px-4 py-2 hover:bg-tuto-light-blue hover:bg-opacity-30"
                        onClick={() => handleSelect(parameter)}
                      >
                        {parameter.displayName}
                      </li>
                    ))
                } else {
                  return renderParentWithSubParameters(parent, subParameters)
                }
              }
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ParameterDropdown
