import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'
import { getColor } from './colorMapping'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

interface FilterDropdownProps {
  categories: { name: string; subCategories: string[] }[]
  handleSubCategoryChange: (
    category: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  handleMainCategoryChange: (
    category: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  filters: Record<string, boolean> // Add filters prop
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  categories,
  handleSubCategoryChange,
  handleMainCategoryChange,
  filters
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false)
    }
  }

  const isCategoryChecked = (category: string, subCategories: string[]) => {
    return [category, ...subCategories].every((cat) => filters[cat])
  }

  const areAnyFiltersChecked = () => {
    return Object.values(filters).some(value => value);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div
      className="relative inline-block text-left self-start"
      ref={dropdownRef}
    >
      <div className="flex items-center">
        <button
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Filters
          {areAnyFiltersChecked() && <span className="absolute -top-1 -left-1 h-3 w-3 rounded-full bg-tuto-light-blue"></span>}
          <AdjustmentsHorizontalIcon className="h-5 w-5" />
        </button>
      </div>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {categories.map(({ name, subCategories }) => (
              <React.Fragment key={name}>
                <label
                  className=" px-4 py-2 flex cursor-pointer items-center gap-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <input
                    type="checkbox"
                    name={name}
                    checked={isCategoryChecked(name, subCategories)}
                    onChange={(e) => handleMainCategoryChange(name, e)}
                    className="checked:text-gray-400 checked:outline-none"
                  />
                  <span
                    className={` px-1 rounded-md text-white ${getColor(
                      name,
                      ''
                    )}`}
                  >
                    {name}
                  </span>
                </label>
                {subCategories.map((subCategory) => (
                  <label
                    key={subCategory}
                    className="flex items-center cursor-pointer gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 pl-8"
                    role="menuitem"
                  >
                    <input
                      type="checkbox"
                      name={subCategory}
                      checked={filters[subCategory] || false}
                      onChange={(e) => handleSubCategoryChange(name, e)}
                      className="checked:text-gray-400 checked:outline-none"
                    />
                    <span
                      className={` px-1 rounded-md text-white ${getColor(
                        subCategory,
                        ''
                      )}`}
                    >
                      {subCategory}
                    </span>
                  </label>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterDropdown
