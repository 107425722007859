import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { getCustomerDomains, updateDomainStatus } from '../../../app/slices/customerDomainsSlice'
import { authState } from '../../../app/slices/authSlice'

interface StatusSelectProps {
  statusId: string
  statusesToSelect: any[]
  id: string
}

const approvedCss = 'border-l-4 border-green-500 w-0 rounded-lg'
const waitingForApproveCss = 'border-l-4 border-yellow-300 w-0 rounded-lg'

const StatusSelect: React.FC<StatusSelectProps> = ({
    statusId,
  statusesToSelect,
  id
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const [status, setRole] = useState('')
  const activeStatus: any = statusesToSelect.filter((status) => {
    return status.name.match('Activated')})

  useEffect(() => {
    setRole(statusId)
  }, [])

  const handleStatusChange = async (e: any) => {
    e.preventDefault()
    setRole(e.target.value)
    
    await dispatch(updateDomainStatus({ domain_id: id, status_id: e.target.value }))
    await dispatch(getCustomerDomains({customer_id: user.customer_id}))
    dispatch(
      addNewNotification({
        text: 'Domain Status Changed Successfully',
        id: uuidv4(),
        title: 'Domain Status Changed',
        type: 'SUCCESS',
        timer: true,
        button: false
      })
    )
  }

  return (
    <div className={statusId && activeStatus && activeStatus.length > 0 && statusId === activeStatus[0].id ? approvedCss : waitingForApproveCss}>
    <select
      value={statusId}
      onChange={(e) => handleStatusChange(e)}
      id="status"
      name="status"
      className="focus:ring-indigo-500 focus:border-tuto-primary h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
    >
      {statusesToSelect.map((status) => (
        <option key={status.id} value={status.id}>
          {status.name.toUpperCase()}
        </option>
      ))}
    </select>
    </div>
  )
}

export default StatusSelect
