import { XCircleIcon } from '@heroicons/react/24/solid'
import React, { useState, useEffect } from 'react'

const hashCode = (str: string): number => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

interface BadgeProps {
  text: string
  close: boolean
  closeFunction?: any
}

const BadgeColor: React.FC<BadgeProps> = ({ text, close, closeFunction }) => {
  const [color, setColor] = useState<string>('hsl(0, 100%, 50%)')
  useEffect(() => {
    if(text) {
      
      const hue = Math.abs(hashCode(text)) % 360
      setColor(`hsl(${hue}, 60%, 50%)`)
    }
  }, [text])

  const styles = {
    backgroundColor: color,
    color: 'white',
  }

  return (
    <div
      style={styles}
      className={`px-2 font-semibold text-white rounded-sm mr-1 flex items-center mb-1`}
    >
      {text}
      {close && <div onClick={closeFunction}><XCircleIcon  className="ml-1 h-4 w-4 cursor-pointer" /></div>}
    </div>
  )
}



export default BadgeColor