import CONFIG from '../../config/config'
import { unprotectedGetRequest, unprotectedPostRequest } from './apiGenerics'
import UserPool from '../../components/Auth/UserPool'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { Mixpanel } from '../../components/utils/mixPanel/mixPanel'

export const AuthorizeUserFromCognito = async (
  authCode: string,
  invitation_id: string | undefined = undefined
) => {
  // console.log(
  //   'from AuthorizeUserFromCognito in auth api ',
  //   authCode,
  //   invitation_id
  // )
  let redirectUri = CONFIG.dashboard
  if (invitation_id) {
    redirectUri = `${CONFIG.dashboard}`
  }

  const res = await fetch(`${CONFIG.auth}oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      client_id: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
      code: `${authCode}`,
      grant_type: 'authorization_code',
      redirect_uri: `${redirectUri}`
    })
  })

  return res.json()
}

export const GetUserDataFromApi = async (
  idToken: string,
  invitation_id: string | undefined = undefined
) => {

  try {
    // console.log('GetUserDataFromApi')
    const distinct_id = Mixpanel.get_distinct_id()
    const res = await fetch(
      `${CONFIG.api}/api/v1/auth/currentuser?&invitation_id=${invitation_id}&distinct_id=${distinct_id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    )
  
    return res.json()
  } catch (error) {
    console.log(error)
    return error
  }
}

export const GetRefreshToken = async (
  refreshToken: string,
  role: string | undefined = undefined,
  customer_id: string | undefined = undefined
) => {
  let redirectUri = CONFIG.dashboard
  if (role && customer_id) {
    redirectUri = `${CONFIG.dashboard}?role=${role}`
  }

  const res = await fetch(`${CONFIG.auth}oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      client_id: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
      refresh_token: `${refreshToken}`,
      grant_type: 'refresh_token',
      redirect_uri: `${redirectUri}`
    })
  })

  return res.json()
}

export const AddToCampaign = async (email: string, campaign_id: string) => {

  return await unprotectedPostRequest('campaigns/pushToCampaign', {
    campaign_id,
    email
  }) 
}

export const LoginUserFromCognito = async (data: any) => {
  const user = new CognitoUser({
    Username: data.email,
    Pool: UserPool
  })

  const authDetails = new AuthenticationDetails({
    Username: data.email,
    Password: data.password
  })

  return await user.authenticateUser(authDetails, {
    onSuccess: async (data: any) => {
      const currentUserSuccess = await GetUserDataFromApi(data.idToken.jwtToken)
      return currentUserSuccess.data
    },
    onFailure: (err) => {
      console.log('on error: ', err)
      return err.message
    }
  })
}

export const ChangeUserData = async (data: string, id: string, type: string, customerId: string) => {
  
  let updatedUserData
  if(type == 'name') {

    updatedUserData = await unprotectedPostRequest('users/updateUserName', {
      name: data,
      user_id: id,
      customer_id: customerId
    })
  }

  if( type == 'customer') {
    updatedUserData = await unprotectedPostRequest('customers/updateCustomerName', {
      name: data,
      customer_id: customerId
    })
  }

  if( type == 'phone') {
    updatedUserData = await unprotectedPostRequest('customers/updateCustomerPhone', {
      phone: data,
      customer_id: customerId
    })
  }

  if( type == 'beta') {
    updatedUserData = await unprotectedPostRequest('customers/updateCustomerBetaStatus', {
      isBeta: data,
      customer_id: customerId
    })
  }

  if( type == 'weekly_report') {
    updatedUserData = await unprotectedPostRequest('customers/updateCustomerWeeklyReportStatus', {
      subscribe: data,
      customer_id: customerId
    })
  }

  if( type == 'jobTitle'){
    updatedUserData = await unprotectedPostRequest('users/updateUserJobTitle', {
      name: data,
      user_id: id,
      customer_id: customerId
    })
  }

  if( type == 'domain'){
    updatedUserData = await unprotectedPostRequest('customers/updateCustomerDomain', {
      domain: data,
      customer_id: customerId
    })
  }

  return updatedUserData
}

export const UpdatePGUserData = async(name: string, email: string, job_title: string) => {
  return await unprotectedPostRequest('auth/updatePlaygroundUserDetails', {
    name: name,
    email: email,
    job_title: job_title
  })
}

export const InsertNewIntegrationDetails = async(subDomain: string, customerId: string, integrationId: string, authCode: any, email: string, name: string, installationId: string | null) => {
  return await unprotectedPostRequest('integrations/insertNewZenIntegrationDetails', {
    sub_domain: subDomain,
    customer_id: customerId,
    integration_id: integrationId,
    code: authCode,
    email: email,
    name: name,
    installation_id: installationId
  })
}

export const GetIntegrations = async() => {
  const integrations = await unprotectedGetRequest(
    `integrations/getIntegrations`
  )
  
  return integrations
}
