import React, { useEffect, useState, useCallback } from 'react'
import { MagnifyingGlassIcon, NoSymbolIcon } from '@heroicons/react/24/solid'
import { chooseAllFilters } from '../../../../app/slices/ProblemsSlice'
import FilterValue from './FilterValue'
import { useAppDispatch } from '../../../../app/hooks'

interface FilterValuesProps {
  values: any[]
  type: any
  isActive: boolean
  filter?: string
  custom: boolean
}

const FilterValues: React.FC<FilterValuesProps> = ({
  values,
  type,
  isActive,
  filter,
  custom
}) => {
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState('')

  const getSearchResults = () => {
    const filtered = values.filter((value: any) => {
      if (searchQuery === '') {
        return value
      } else if(value.screenWidth) {
        const size = `${value.screenWidth} X ${value.screenHeight}`
        if(size.toLowerCase().includes(searchQuery.toLowerCase())) {
          return value
        }
      } else if (value.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        return value
      }
    })

    if (!filtered.length)
      return (
        <div className="flex w-full items-center p-4 justify-center text-gray-400 text-center">
          <NoSymbolIcon className="w-6 h-5 mr-2" />
          No Results Found
        </div>
      )

    return filtered.map((value: any, index: number) =>
      type === 'screen' ? (
        <FilterValue
          custom={custom}
          key={index}
          valueAsObject={value}
          text={`${value.screenWidth} X ${value.screenHeight}`}
          type={type}
          filter={filter}
        />
      ) : (
        <FilterValue
          custom={custom}
          key={index}
          valueAsObject={value}
          text={value.name}
          type={type}
          filter={filter}
        />
      )
    )
  }

  return (
    <div
      className={`${
        isActive ? 'block' : 'hidden'
      } relative flex-grow overflow-hidden bg-white`}
    >
      <div className={` p-2 sticky rounded-t-lg ${filter === 'Domains' || filter === 'Investigate' ? 'bg-tuto-primary' : 'bg-white'}`}>
        <input
          className={`${filter === 'Domains' || filter === 'Investigate' ? 'bg-white' : 'bg-gray-300'} p-1 w-full focus:ring-0 focus:outline-none rounded-md text-tuto-primary placeholder:text-gray-600 placeholder:text-sm placeholder:pl-2 `}
          placeholder="Search by name..."
          onChange={(event) => setSearchQuery(event.target.value)}
          value={searchQuery}
        />

        <MagnifyingGlassIcon className="h-6 w-6 text-tuto-primary absolute right-4 top-3" />
      </div>
      <div className="p-1 flex items-center  text-gray-400 cursor-pointer ">
        <span
          className="hover:text-tuto-primary p-1"
          onClick={() =>
            dispatch(
              chooseAllFilters({
                filter,
                checked: true,
                type: type.type,
                key: type.type
              })
            )
          }
        >
          All
        </span>{' '}
        <span className="text-xl mb-1">|</span>
        <span
          onClick={() =>
            dispatch(
              chooseAllFilters({
                filter,
                checked: false,
                type: type.type,
                key: type.type
              })
            )
          }
          className="hover:text-tuto-primary p-1"
        >
          None
        </span>
      </div>
      <div className="overflow-scroll max-h-56   p-2 rounded-b-lg text-tuto-primary font-semibold">
        {/* {values &&
          values.length > 0 &&
          values.map((value: any, index: number) => (
            type === 'screen' ? 
            <FilterValue custom={custom} key={index} valueAsObject={value} text={`${value.screenWidth} X ${value.screenHeight}`} type={type} filter={filter} />
            : 
            <FilterValue custom={custom} key={index} valueAsObject={value} text={value.name} type={type} filter={filter}/>
          ))} */}
        {getSearchResults()}
      </div>
    </div>
  )
}

export default FilterValues
