import { useEffect, useState } from "react"
import { useMatch } from "react-router-dom"
import { useAppSelector } from "../../../../app/hooks"
import { authState } from "../../../../app/slices/authSlice"
import { IntegrationsTypes } from "../../../utils/enums"
import { Mixpanel } from "../../../utils/mixPanel/mixPanel"

const Docs = () => {
    const [src, setSrc] = useState("https://v2-embednotion.com/Record-Knowledge-base-ee67cd6aa6c14fb5bb861d2a546262d5?pvs=4")
    const { user } = useAppSelector(authState)

    useEffect(() => {
        if(user){
            Mixpanel.track('Docs Page Viewed', {email: user.email, user_id: user.id, customer_id: user.customer_id})
        }
        const urlParams = new URLSearchParams(window.location.search)
        const integrationType = urlParams.get('integration')
        switch(integrationType){
            case IntegrationsTypes.ZENDESK:
                setSrc("https://doc.clickup.com/3623824/d/h/3ejwg-4482/fdb961764526b21/3ejwg-8082")
                break
            default:
                setSrc("https://v2-embednotion.com/Record-Knowledge-base-ee67cd6aa6c14fb5bb861d2a546262d5?pvs=4")
                break
        }
    })

    return(
        <div className="h-screen flex w-full">
            <iframe className="w-full" src={src} />
        </div>
    )
}

export default Docs