import React, { FC, useEffect, useState } from 'react'
import { unprotectedGetRequest } from '../../../app/apis/apiGenerics'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import investigationsSlice, {
  investigationsState
} from '../../../app/slices/investigationsSlice'
import { problemsState } from '../../../app/slices/ProblemsSlice'
import Player from '../../utils/player/Player'
import CogLoader from '../../utils/shared/CogLoader'
import { setLogsCount } from '../../../app/slices/investigationsSlice'
import ErrorPage from './ErrorPage'

interface MainInvestigationViewProps {
  setAdditionalData: any
}

const MainInvestigationView: FC<MainInvestigationViewProps> = ({
  setAdditionalData
}) => {
  const { currentSession, loadingDetails } = useAppSelector(investigationsState)
  const { loadingFilters } = useAppSelector(problemsState)
  const [session, setSession] = useState(null)
  const [loadingSession, setLoadingSession] = useState(false)
  const [errorPage, setErrorPage] = useState(false)
  const [isSession, setIsSession] = useState(false)
  const [audioSource, setAudioSource] = useState('')
  const [sourceId, setSourceId] = useState('')
  const {user} = useAppSelector(authState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (currentSession.id) {
      getRecord(currentSession.id)
    }

    return () => {
      setAudioSource('')
    }
  }, [currentSession])

  const getRecord = async (record_id: string, is_session: any = undefined) => {
    setLoadingSession(true)
    setSession(null)
    setErrorPage(false)
    try {
      const res = await unprotectedGetRequest(
        `records/getSingleRecord?customer_id=${user.customer_id}&record_id=${record_id}${
          is_session ? `&is_session=${is_session}` : ''
        }`
      )
      console.log(res)
      if (res.success) {
        setAdditionalData({
          data: res.data,
          additionalInfo: res.additionalInfo,
          oneai: res.oneai,
          transcription: res.transcription,
          tags: res.tags,
          backendLogs: res.backendLogs,
          windowObject:res.windowObject,
          feedEvents: res.feedEvents
        })
        setSourceId(res.data.source_id)
        setSession(JSON.parse(res.json))
        dispatch(setLogsCount(res.feedEvents.length))
        setIsSession(res.data.is_session)
        
        if (res.data.path) {
          setAudioSource(res.data.path)
        }
        setLoadingSession(false)
      } else {

        setErrorPage(true)
        setLoadingSession(false)
      }
    } catch (err) {
      setLoadingSession(false)
    }
  }

  return (
    <div className="">
      {loadingSession || loadingDetails || loadingFilters ? (
        <div className="min-h-75v flex items-center justify-center">
          <CogLoader />
        </div>
      ) : session ? (
        <div className="flex flex-col gap-6">
          <div
            className={`p-2 rounded self-start ${
              isSession ? 'bg-gray-200' : 'bg-tuto-light-blue'
            }`}
          >
            {isSession ? 'User Session' : 'User Record'}
          </div>
          <div>
            <Player sourceId={sourceId} audioSource={audioSource} session={session} recordId={currentSession.id} />
          </div>
        </div>
      ) : (
        errorPage && (
          <div className="min-h-75v flex items-center justify-center">
            <ErrorPage />
          </div>
        )
      )}
    </div>
  )
}

export default MainInvestigationView
