import React, { useEffect, useState } from 'react'
import Button from '../../utils/elements/Button'
import IntegrationVideos from './IntegrationVideos'
import './IntegrationForm.css'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice'
import { setCopiedLink } from '../../../app/slices/customerDomainsSlice'
import { authState } from '../../../app/slices/authSlice'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import { ClipboardIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { IntegrationsTypes } from '../../utils/enums'
import SentryForm from './SentryForm'

interface IntegrationFormProps {
  existForUser: boolean
  integrationId: string
}

const videosZendesk = [
  {
    videoSrc:
      'https://assets.torecord.it/HowtoinstallRecordZendeskIntegration.mp4',
    btnText: 'How to install it?',
    id: 1
  },
  {
    videoSrc: 'https://assets.torecord.it/UseRecordInsideaTicket.mp4',
    btnText: 'How to use it?',
    id: 2
  },
  {
    videoSrc: 'https://assets.torecord.it/CreatingNewTicketUsingRecord.mp4',
    btnText: 'Open tickets using record',
    id: 3
  }
]

const videosIntercom = [
  {
    videoSrc: 'https://assets.torecord.it/IntercomHowToUse.mp4',
    btnText: 'How to use it?',
    id: 1
  }
]

const IntegrationForm: React.FC<IntegrationFormProps> = ({
  existForUser,
  integrationId
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const { user } = useAppSelector(authState)
  const verifiedDomains: any =
    domains &&
    domains.length > 0 &&
    domains.filter((domain) => {
      return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
    })

  const [selectedDomain, setSelectedDomain] = useState('')
  const [domainsOpen, setDomainsOpen] = useState(false)
  const [integration, setIntegrationName] = useState('')
  const [appStoreLink, setAppStoreLink] = useState('')
  const [videos, setVideos] = useState([{}])

  useEffect(() => {
    if (verifiedDomains && verifiedDomains.length > 0) {
      setSelectedDomain(verifiedDomains[0].origin)
    }
  }, [])

  useEffect(() => {
    switch (integrationId) {
      case IntegrationsTypes.ZENDESK:
        setIntegrationName('Zendesk')
        setAppStoreLink(process.env.REACT_APP_ZENDESK_MARKETPLACE || '')
        setVideos(videosZendesk)
        break
      case IntegrationsTypes.INTERCOM:
        setIntegrationName('Intercom')
        setAppStoreLink(process.env.REACT_APP_INTERCOM_MARKETPLACE || '')
        setVideos(videosIntercom)
        break
      case IntegrationsTypes.SENTRY:
        setIntegrationName('Sentry')
        setAppStoreLink(process.env.REACT_APP_ZENDESK_MARKETPLACE || '')
        setVideos(videosZendesk)
        break
    }
  }, [integrationId])

  return (
    <div className="flex flex-col ">
      {/* <div className="purple"></div> */}
      <div className="yellow"></div>
      <div className="blue"></div>
      {integrationId === IntegrationsTypes.SENTRY ? (
        <div className="text-xl font-semibold">
          Choose your {integration} Project
        </div>
      ) : (
        <div className="text-xl font-semibold">
          Add Your {integration} Domain
        </div>
      )}
      <div className="flex items-center">
        <div className="pt-4">
          {!existForUser ? (
            <Button
              inverted={false}
              loading={false}
              disabled={!existForUser ? false : true}
              text="Install Integration"
              type="button"
              withIcon={false}
              spacialClass={!existForUser ? '' : 'bg-green-500'}
              clickFunction={() => {
                if (!existForUser) {
                  window.open(appStoreLink, '_blank')
                }
              }}
            />
          ) : (
            <div>
              <div className="flex flex-row items-center">
                <div className="h-3 w-3 bg-tuto-green rounded-xl mr-2"></div>
                <div className="text-tuto-green font-semibold">Connected</div>
              </div>
              {integrationId !== IntegrationsTypes.SENTRY ? 
              <div className="mt-4">
                <h1 className="text-xl text-tuto-primary">
                  Create Ticket Using Sharable Link
                </h1>
                <p className="text-gray-500">
                  This link will open a new ticket on your support OS
                </p>
                <div className="relative">
                  <div className="flex items-center">
                    <div
                      onClick={() => setDomainsOpen(true)}
                      className="text-sm relative whitespace-nowrap max-w-lg mr-2 cursor-pointer border p-2 border-tuto-primary overflow-scroll flex-grow  focus:border-tuto-primary text-tuto-primary block rounded-md"
                    >
                      {domains && domains.length > 0
                        ? `${selectedDomain}/?isrecording=true&userId=${user.id}&ticketCreation=true`
                        : ''}
                    </div>
                    <div
                      className="mr-2 text-tuto-primary cursor-pointer"
                      onClick={() => setDomainsOpen(!domainsOpen)}
                    >
                      <ChevronDownIcon className="h-5 w-5" />
                    </div>

                    <div
                      className="p-2 cursor-pointer bg-tuto-primary text-white rounded-md hover:bg-tuto-secondary hover:text-tuto-primary transition duration-300"
                      onClick={() => {
                        dispatch(
                          setCopiedLink(
                            `${selectedDomain}/?isrecording=true&userId=${user.id}&ticketCreation=true`
                          )
                        )
                        navigator.clipboard.writeText(
                          `${selectedDomain}/?isrecording=true&userId=${user.id}&ticketCreation=true`
                        )
                        dispatch(setCoppiedTooltip(true))
                      }}
                    >
                      <ClipboardIcon className="h-5 w-5" />
                    </div>
                  </div>

                  {domainsOpen && (
                    <div
                      id="domain"
                      className="absolute z-10 border bg-white  p-2 text-gray-500 text-xs sm:text-sm rounded-md"
                    >
                      {verifiedDomains &&
                        verifiedDomains.map((domain: any) => (
                          <div
                            key={domain.id}
                            className="hover:bg-gray-400 cursor-pointer"
                            onClick={() => {
                              setSelectedDomain(domain.origin)
                              dispatch(
                                setCopiedLink(
                                  `${domain.origin}/?isrecording=true&userId=${user.id}&ticketCreation=true`
                                )
                              )
                              navigator.clipboard.writeText(
                                `${domain.origin}/?isrecording=true&userId=${user.id}&ticketCreation=true`
                              )
                              dispatch(setCoppiedTooltip(true))
                              setDomainsOpen(false)
                            }}
                          >
                            {domain.origin}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              
            : <SentryForm />}
            </div>
          )}
        </div>
      </div>
      <IntegrationVideos videos={videos} />
      <div className="flex items-center mt-20">
        <div className="absolute bottom-4">
          <Button
            inverted={true}
            loading={false}
            disabled={false}
            text={'Documentation'}
            type="button"
            withIcon={false}
            clickFunction={() => {
              Mixpanel.track('Clicked on Documentation - Integration Form', {
                email: user.email,
                user_id: user.id,
                customer_id: user.customer_id
              })
              navigate(
                `/settings/docs/?integration=${IntegrationsTypes.ZENDESK}`
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default IntegrationForm
