import React, { useEffect, useState } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import BadgeColor from './Badge'
import { Combobox } from '@headlessui/react'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'

interface Props {
  customer_tags: any[]
  record_id: string
  record_tags: any[]
  source_id: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CustomTags: React.FC<Props> = ({
  customer_tags,
  record_id,
  record_tags,
  source_id
}) => {
  const [customTag, setCustomTag] = useState('')
  const [newTags, setNewTags] = useState<any>([])
  const [visibleTags, setVisibleTags] = useState(false)
  const { user } = useAppSelector(authState)

  const handleCustomTagChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomTag(event.target.value)
  }

  useEffect(() => {
    console.log('customer_tags' , customer_tags)
    setTimeout(() => {
      setVisibleTags(true)
    }, 500)
    // return () => {
    //   setNewTags([])
    // }
  }, [])

  useEffect(() => {
    
    
    
      setNewTags([])
  
  }, [record_id])

  useEffect(() => {
    if(record_tags) {

      setNewTags(record_tags)
    }

    return () => {
      setNewTags([])
    }
  }, [record_tags])


  const deleteTag = async (tag: any) => {
    setNewTags(
      newTags.filter((existingTag: any) => existingTag.name !== tag.name)
    )
    const deleted = await unprotectedPostRequest('records/deleteRecordTag', {
      record_id,
      tag_id: tag.id,
      elastic_id: source_id,
      tag_name: tag.name
    })
  }

  const handleAddTag = async (event: any) => {
    event.persist()
    if (event.key === 'Enter') {
      // if (!newTags.includes(customTag) && customTag.trim().length > 0) {
        if(newTags && newTags.length > 0) {
         
          if (
            !newTags.some((newTag: any) => newTag.name === customTag) &&
            customTag.trim().length > 0
          ) {
           
            if(customer_tags && customer_tags.length > 0) {
    
              const includeInCustoemrTags: any = customer_tags.filter(
                (customerTag: any) =>
                  customerTag.name.toLowerCase() === customTag.trim().toLowerCase()
              )

              console.log(includeInCustoemrTags)
    
              if (includeInCustoemrTags.length > 0) {
                const mapExistingToRecord = await unprotectedPostRequest(
                  'customers/createTag',
                  {
                    customer_id: user.customer_id,
                    tag_id: includeInCustoemrTags[0].id,
                    record_id: record_id,
                    user_id: user.id,
                    elastic_id: source_id
                  }
                )
               
                setNewTags([...newTags, {
                  customer_id: user.customer_id,
                  id: includeInCustoemrTags[0].id,
                  record_id: record_id,
                  name: customTag.trim(),
                  user_id: user.id
                }])
                setCustomTag('')
              } else {
                const newTag = await unprotectedPostRequest('customers/createTag', {
                  customer_id: user.customer_id,
                  tag_name: customTag.trim(),
                  record_id: record_id,
                  user_id: user.id,
                  elastic_id: source_id
                })
                if(newTags && newTags.length < 1){

                  setNewTags([newTag.data])
                 
                } else {
                  setNewTags([...newTags ,newTag.data])
                 
                }
                
                setCustomTag('')
              }
            } 
        }
        
        
      }
      else {
        const newTag = await unprotectedPostRequest('customers/createTag', {
          customer_id: user.customer_id,
          tag_name: customTag.trim(),
          record_id: record_id,
          user_id: user.id,
          elastic_id: source_id
        })
        if(newTags && newTags.length < 1){
     
          setNewTags([...newTags ,newTag.data])
        } else {
        
          setNewTags([...newTags ,newTag.data])
        }
       
        setCustomTag('')
      }
    }
  }

  const handleOptionClick = (tag: string) => {
    setCustomTag(tag)
  }

  return (
    <div className="mt-4">
      <Combobox as="div" value={customTag} onChange={setCustomTag}>
        <div className="relative ">
          <Combobox.Input
            value={customTag}
            onKeyUp={handleAddTag}
            onChange={handleCustomTagChange}
            placeholder="Enter Manual tag"
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {customer_tags &&
              customer_tags.length > 0 &&
              customer_tags
                .filter((tag: any) =>
                  tag.name.toLowerCase().includes(customTag.toLowerCase())
                )
                .map((tag: any) => (
                  <Combobox.Option
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                    value={tag.name}
                    key={tag.id}
                    onClick={() => handleOptionClick(tag)}
                  >
                    {tag.name}
                  </Combobox.Option>
                ))}
          </Combobox.Options>
        </div>
      </Combobox>

      <div className={`mt-2 flex flex-wrap ${visibleTags ? 'visible' : 'hidden'}`}>
        {newTags &&
          newTags.map((tag: any) => (
            <BadgeColor
              key={tag.id}
              text={tag.name}
              close={true}
              closeFunction={() => deleteTag(tag)}
            />
          ))}
      </div>
    </div>
  )
}

export default CustomTags
