import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import * as yup from 'yup'
import Button from '../../utils/elements/Button'
import { getCustomerDomains } from '../../../app/slices/customerDomainsSlice'
import {
  CheckCircleIcon,
  ArrowRightIcon,
  CogIcon,
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'

interface VerificationPopupProps {
  open: boolean
  setOpen: any
  location: string
}

interface IFormInputs {
  domain: string
}

const schema = yup.object().shape({
  domain: yup
    .string()
    .matches(
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i,
      { message: 'must be a valid domain(ex:https://example.com)' }
    )
    .required()
})

const VerificationPopup: React.FC<VerificationPopupProps> = ({
  open,
  setOpen,
  location
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [verificationFail, setVerificationFail] = useState(false)
  const [verificationSuccess, setVerificationSuccess] = useState(false)
  const [domain, setDomain] = useState('')
  const { user } = useAppSelector(authState)

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setDomain(data.domain)
    verifyInstallation(data.domain)
  }

  const verifyInstallation = (domain: string) => {
    window.open(domain, 'blank')
    setLoading(true)
    let numCalls = 0
    const interval = setInterval(async () => {
      if (numCalls < 4) {
        let res = await dispatch(
          getCustomerDomains({ customer_id: user.customer_id })
        )
        console.log(res)
        if (res.payload.length > 0) {
          clearInterval(interval)
          setVerificationSuccess(true)
          setLoading(false)
        }
        numCalls++
      } else {
        clearInterval(interval)
        setVerificationFail(true)
        setLoading(false)
      }
    }, 15000)
  }

  const goToDocs = () => {
    setOpen(false)
    if (location === 'onboarding') {
      window.open('https://torecord.it/docs', 'blank')
    } else {
      navigate('/settings/docs')
    }
  }

  const goToDomains = () => {
      navigate('/settings/domains')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg shadow-small bg-white px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:py-6 sm:px-12">
                <div>
                  <div className="flex items-center justify-center font-bold">
                    <div className="flex mr-4 items-center justify-center rounded-full ">
                      <CheckCircleIcon
                        className="h-12 w-12 text-tuto-primary "
                        aria-hidden="true"
                      />
                    </div>
                    <h1 className="text-3xl">Verify Your Installation</h1>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    {/* <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Payment successful
                    </Dialog.Title> */}
                    {loading ? (
                      <div className="flex flex-col items-center justify-center space-x-2">
                        <div aria-label="Loading..." role="status">
                          <svg
                            className="h-20 w-20 animate-spin stroke-tuto-primary"
                            viewBox="0 0 256 256"
                          >
                            <line
                              x1="128"
                              y1="32"
                              x2="128"
                              y2="64"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="195.9"
                              y1="60.1"
                              x2="173.3"
                              y2="82.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="224"
                              y1="128"
                              x2="192"
                              y2="128"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="195.9"
                              y1="195.9"
                              x2="173.3"
                              y2="173.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="128"
                              y1="224"
                              x2="128"
                              y2="192"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="60.1"
                              y1="195.9"
                              x2="82.7"
                              y2="173.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="32"
                              y1="128"
                              x2="64"
                              y2="128"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                            <line
                              x1="60.1"
                              y1="60.1"
                              x2="82.7"
                              y2="82.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="24"
                            ></line>
                          </svg>
                        </div>
                        <div className="mt-4">
                          It may take up to 60 seconds...
                        </div>
                      </div>
                    ) : verificationFail ? (
                      <div className="flex flex-col px-16 items-center justify-center">
                        <ExclamationCircleIcon className="h-24 w-24 text-record-red" />
                        <p className="text-record-red">
                          Please check you’ve correctly pasted the Record
                          snippet code into your site’s header.
                        </p>
                        <div className="mt-4">
                          <Button
                            inverted={false}
                            loading={loading}
                            disabled={loading}
                            text="Try Again"
                            type="button"
                            withIcon={false}
                            clickFunction={() => verifyInstallation(domain)}
                          />
                        </div>
                        <div
                          onClick={goToDocs}
                          className="text-tuto-purple mt-4 flex items-center cursor-pointer"
                        >
                          Need help with your tracking code?
                          <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-1" />
                        </div>
                      </div>
                    ) : verificationSuccess ? (
                      <div className="flex flex-col px-16 items-center justify-center">
                        <CheckCircleIcon className="h-24 w-24 text-green-500" />
                        <p className="text-green-500">
                          Domain {domain} successfully verified!
                        </p>
                        <div className="mt-4">
                          <Button
                            inverted={false}
                            loading={loading}
                            disabled={loading}
                            text={location === 'onboarding' ?  'Continue' : "Activate"}
                            Icon={ArrowRightIcon}
                            type="button"
                            withIcon={false}
                            clickFunction={goToDomains}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mt-2">
                        <p className="text-sm text-dark-gray font-semibold">
                          Enter the URL of the site you installed Record into in
                          the field below.
                        </p>
                        <p className="text-sm text-dark-gray">
                          e.g. https://yoursite.com or http://localhost:3000
                        </p>
                        <form
                          className="flex relative flex-col items-center"
                          onSubmit={handleSubmit(formSubmitHandler)}
                        >
                          <div className="relative w-full px-16">
                            <input
                              {...register('domain')}
                              className="p-4 w-full relative text-center mb-8 font-light bg-transparent text-2xl border-b border-gray-400 focus:outline-none"
                              placeholder="https://yoursite.com"
                            />
                            <span className="text-red-400 text-xs absolute bottom-2 right-0 left-0 text-center">
                              {errors.domain && errors.domain.message}
                            </span>
                          </div>
                          <Button
                            inverted={false}
                            loading={loading}
                            disabled={loading}
                            text="Verify installation"
                            type="submit"
                            withIcon={false}
                          />
                        </form>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center flex-col items-center">
                  <div className="border-t w-full pt-4 border-t-gray-200 mt-4 flex flex-col items-center justify-center">
                    <a className="text-sm text-dark-gray font-semibold mb-4">
                      Need help installing Record?
                    </a>
                    <Button
                      inverted={false}
                      loading={false}
                      disabled={false}
                      text="Book time to setup with us"
                      type="button"
                      withIcon={true}
                      Icon={CogIcon}
                      spacialClass="grey-button"
                      // className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"

                      clickFunction={() => {
                        Mixpanel.track(
                          'Clicked on Book a Demo - Verification Popup',
                          {}
                        )
                        window.open('https://booking.akiflow.com/ohad-r-0c48')
                      }}
                    >
                      Go back to dashboard
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default VerificationPopup
