import { useState, useEffect } from 'react'
import Explenation from './Explenation'
import OnboardingForm from './OnboardingForm'
import Demonstration from './Demonstration'
import OnBoardingInstallation from './OnBoardingInstallation'
import {
  getCustomerOnboardingSteps,
  setOnboardingMode,
  uiState
} from '../../../app/slices/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import { OnBoardingStepsE } from '../../utils/enums'
import { useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics'
import { addNewNotification } from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'


const OnBoarding = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const { onBoardingSteps, loadingOnboardingSteps } = useAppSelector(uiState)
  const [appSumoCode, setAppSumoCode] = useState('')
  const [rocketHubCode, setrocketHubCode] = useState('')
  const [specialCode, setSpecialCode] = useState('')
  
  const [cuponRedeemed, setCuponRedeemed] = useState(false)

  useEffect(() => {
    if (user) {
      dispatch(getCustomerOnboardingSteps(user.customer_id))
      if (process.env.REACT_APP_STAGE === 'production') {
        if (TagManager.dataLayer) {
          TagManager.dataLayer({ dataLayer: { event: 'first_time_user' } })
        }
      }
    }
    const appSumo = localStorage.getItem('appsumo')
    const rocketHub = localStorage.getItem('rockethub')
    const specialCodeLS = localStorage.getItem('specialCode')
    if (appSumo) {
      setAppSumoCode(appSumo)
    }
    if (rocketHub) {
      setrocketHubCode(rocketHub)
    }
    if (specialCodeLS) {
      setSpecialCode(specialCodeLS)
    }
  }, [])

  useEffect(() => {
    if(appSumoCode !== '' || rocketHubCode !== '' || specialCode !== '') {
      redeemCupon()
    }
  }, [appSumoCode, rocketHubCode, specialCode])

  const redeemCupon = async () => {
    const redeemed = await unprotectedPostRequest('paddle/redeemCoupon', {
      code: appSumoCode || rocketHubCode || specialCode,
      customer_id: user.customer_id
    })
    // console.log('redeemed' , redeemed)
    if(redeemed.success) {
      setCuponRedeemed(true)
      Mixpanel.track(`Success connecting with ${appSumoCode ? 'appsumo' : specialCode ? 'specialCode' : 'rocketHub'} code`, {code: appSumoCode || rocketHubCode || specialCode})
    } else {
      Mixpanel.track(`Error connecting with ${appSumoCode ? 'appsumo' : specialCode ? 'specialCode' : 'rocketHub'} code`, {code: appSumoCode || rocketHubCode || specialCode})
      dispatch(addNewNotification({
        text: `We’re sorry, contact support at support@torecord.it`,
          id: uuidv4(),
          title: `${appSumoCode ? `AppSumo's` : specialCode ? 'Special Code' : `RocketHub's`} coupon is invalid`,
          type: 'WARN',
          timer: false,
          button: true,
          buttonText: 'Contact Us',
          buttonFunction: () => {
            window.location.href = "mailto:support@torecord.it";
          }
      }))
    }
    localStorage.removeItem('appsumo')
    localStorage.removeItem('rockethub')
    localStorage.removeItem('specialCode')
    
  }

  useEffect(() => {
    if(cuponRedeemed) {
      dispatch(addNewNotification({
        text: `Your account has been upgraded to a lifetime ${appSumoCode ? `AppSumo's` : specialCode ? `Special's` : `RocketHub's`} plan`,
          id: uuidv4(),
          title: 'You’re all set! 🎉',
          type: 'SUCCESS',
          timer: false,
          button: false
      }))
    }
  }, [cuponRedeemed])

  useEffect(() => {
    if (onBoardingSteps.lastStepDone) {
      console.log(onBoardingSteps.lastStepDone)
      if (
        OnBoardingStepsE.CHANGE_USER_NAME ===
          onBoardingSteps.lastStepDone.step_id ||
        OnBoardingStepsE.CHANGE_ORG_NAME ===
          onBoardingSteps.lastStepDone.step_id
      ) {
        console.log('OnBoardingStepsE.VALUE if CHANGE_ORG_NAME', onBoardingSteps.lastStepDone.step_id)
        setCurrentPage(2)
      }
      if (OnBoardingStepsE.VALUE === onBoardingSteps.lastStepDone.step_id) {
        console.log('OnBoardingStepsE.VALUE if', onBoardingSteps.lastStepDone.step_id)
        setCurrentPage(4)
      }
      if (
        OnBoardingStepsE.INSTALLATION === onBoardingSteps.lastStepDone.step_id
      ) {
        console.log('OnBoardingStepsE.INSTALLATION if' , onBoardingSteps.lastStepDone.step_id)
        dispatch(setOnboardingMode(false))
        navigate('/settings/installation')
      }
    }
  }, [onBoardingSteps])

  useEffect(() => {
    console.log(currentPage)
  }, [currentPage])

  return (
    <div className="px-16 py-8 min-h-90v flex items-center justify-center">
      {!loadingOnboardingSteps ? (
        currentPage === 1 ? (
          <OnboardingForm currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : currentPage === 2 ? (
          <OnboardingForm currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : currentPage === 3 ? (
          <Demonstration currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : currentPage === 4 ? (
          <OnBoardingInstallation setCurrentPage={setCurrentPage} />
        ) : null
      ) : (
        'loading'
      )}
    </div>
  )
}

export default OnBoarding
