import React, { useEffect, useRef } from 'react'
import { useAppDispatch } from '../../../../app/hooks'
import { setReqestFilters, removeRequestFilter } from '../../../../app/slices/ProblemsSlice'
import ShortUuid from '../../../utils/shared/ShortUuid'

interface FilterValueProps {
  text: any
  type: any
  filter?:string
  custom: boolean
  valueAsObject?:any
  handleFilterChange?: any
  checked?: boolean
}

const FilterValue: React.FC<FilterValueProps> = ({text, type, filter, custom, valueAsObject}) => {
  const dispatch = useAppDispatch()
  const checkboxRef = useRef<any>(null)
  
  const handleValueCheck = (event:any) => {
    
    if(event.target.checked) {
      if(custom) {
        
      dispatch(setReqestFilters({filter: filter, key: type.value, value: event.target.value, valueAsObject}))

      } else {
        if(filter === 'ScreenSize' && valueAsObject) {
          dispatch(setReqestFilters({filter: filter, value: event.target.value, valueAsObject}))
        } else {

          dispatch(setReqestFilters({filter: filter, value: event.target.value}))
        }
      }
    } else {
      
      dispatch(removeRequestFilter({filter: filter, value: event.target.value, valueAsObject}))
    }
    
  }

 

  return (
    <div  className="flex items-center mb-2 hover:bg-tuto-light-blue hover:bg-opacity-30 px-1 py-2 rounded-md cursor-pointer">
      <input
        aria-describedby="comments-description"
        name="comments"
        onChange={handleValueCheck}
        checked={valueAsObject.checked}
        // defaultChecked={valueAsObject.checked}
        // onClick={}
        type="checkbox"
        ref={checkboxRef}
        value={text}
        className="h-4 w-4 p-1 rounded border-gray-300 text-tuto-light-blue focus:ring-tuto-primary filter-inputs"
      />
      <div className='flex-grow pl-4' onClick={() => checkboxRef.current && checkboxRef.current.click()}>

      {type === 'user' ? 
      <ShortUuid text={text} />: <span>{text}</span>

      }
      </div>
    
    </div>
  )
}

export default FilterValue
