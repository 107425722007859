import React, { useEffect, useRef, useState } from 'react'
import Button from '../../utils/elements/Button'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { ArrowRightIcon, PlayIcon } from '@heroicons/react/24/solid'
import './onboarding.css'


interface IOnboardingDemonstrationProps {
  setCurrentPage: any
  currentPage: number
}

const Demonstration: React.FC<IOnboardingDemonstrationProps> = ({
  setCurrentPage,
  currentPage
}) => {

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  
  return (
    <div className="flex flex-col relative items-center max-w-60 gap-8">
      <div className="flex flex-col text-tuto-primary text-center">
        <h1 className="text-3xl">Are you ready to reduce your handle time?</h1>
        <h1 className="text-6xl font-bold">WHAT RECORD IS ALL ABOUT?</h1>
      </div>
      <div className="rounded-md overflow-hidden shadow-md relative">
        <video
          // ref={videoRef}
          controls
          className='w-full'
          src='https://assets.torecord.it/OnboardingStarrt.mp4'
          // onClick={handlePlayPause}
        />
        {/* {!isPlaying && 
          <div className="absolute cursor-pointer top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 py-12 px-20 glass-btn flex items-center justify-center">
            <PlayIcon className="h-20 w-20 text-white" onClick={handlePlayPause} />
          </div>
        } */}
      </div>
      <div className=''>
        <Button
          inverted={false}
          loading={false}
          disabled={false}
          text={`Next (${currentPage}/3)`}
          type="button"
          withIcon={true}
          Icon={ArrowRightIcon}
          spacialClass=""
          clickFunction={() => setCurrentPage(4)}
        />
      </div>
    </div>
  )
}

export default Demonstration
