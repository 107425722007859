import React from 'react'

interface IFeedBannerProps {
  banner: {
    content: number | string
    is_unhandled: boolean
  }
  sub_category: string
}

const FeedBanner: React.FC<IFeedBannerProps> = ({ banner, sub_category }) => {
  if (!banner) {
    return null
  }

  let bannerColor: string

  if (banner.content === 'Double Click') {
    bannerColor = 'bg-blue-400'
  } else
  if (banner.content >= 100 && banner.content < 200) {
    bannerColor = 'bg-orange-400'
  } else if (banner.content >= 200 && banner.content < 300) {
    bannerColor = 'bg-green-400'
  }else if (banner.content >= 300 && banner.content < 400) {
    bannerColor = 'bg-amber-500'
  } else if (banner.content >= 400) {
    bannerColor = 'bg-red-400'
  } else {
    return null
  }

  

  return (
    <div
      className={`px-2 rounded-md text-white text-sm ${bannerColor}`}
    >
      {banner.content}
      {banner.is_unhandled && ' Unhandled'}
    </div>
  )
}

export default FeedBanner
