import { TableCellsIcon } from '@heroicons/react/24/solid'
import config from '../../../config/config'
import StepByStep from '../layout/StepByStep'
import { useAppSelector } from '../../../app/hooks'
import { uiState } from '../../../app/slices/uiSlice'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const PGWidget = () => {
  const { pgDiscoverSteps, recordDiscoverMode } = useAppSelector(uiState)
  const location = useLocation()
  const [openDiscover , setOpenDiscover] = useState(true)
  useEffect (() => {
    if(location.pathname.startsWith('/records')) {
        setOpenDiscover(false)
    }
  }, [])
  return (
    <div className="">
      {!recordDiscoverMode && openDiscover && (
        <StepByStep
          title={pgDiscoverSteps[0].title}
          description={pgDiscoverSteps[0].description}
          number={pgDiscoverSteps[0].number}
        />
      )}
      <div
        className="fixed shadow-md z-10 cursor-pointer right-0 border rounded-l-full bg-tuto-blue text-white top-1/2 h-20 flex flex-row-reverse justify-end items-center
        w-20 hover:w-64 hover:before:content-['Go_To_Playground'] hover:text-xl hover:font-semibold"
        onClick={() => {
          window.location.href = `${config.playground}`
        }}
      >
        <TableCellsIcon className="h-10 w-10 mx-4" />
      </div>
    </div>
  )
}

export default PGWidget
