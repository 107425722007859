import React, { useEffect, useRef, useState } from 'react'
import {
  GlobeAltIcon,
  PencilIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  BookmarkIcon,
  CursorArrowRippleIcon,
  ServerIcon,
  DocumentIcon,
  CommandLineIcon,
  DocumentTextIcon,
  ArrowLeftOnRectangleIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/solid'
import { setPlayFrom } from '../../../app/slices/investigationsSlice'
import { useAppDispatch } from '../../../app/hooks'
import { getColor } from './colorMapping'
import FeedBanner from './FeedBanner'
import Highlighter from 'react-highlight-words'

interface IDataItem {
  category: string
  sub_category: string
  title: string
  content: string
  timestamp: number
  more_info: { gili: boolean }
  banner: { content: string | number; is_unhandled: boolean }
}

interface FeedElementProps {
  item: IDataItem
  startTimestamp: number
  setNetworkData: any
  openNetwork: any
  search: string
}

const FeedElement: React.FC<FeedElementProps> = ({
  item,
  startTimestamp,
  setNetworkData,
  openNetwork,
  search
}) => {
  const [isTextExpanded, setTextExpanded] = useState<boolean>(false)
  const [isButtonVisible, setButtonVisible] = useState<boolean>(false)
  const textRef = useRef<HTMLDivElement | null>(null)
  
  useEffect(() => {
    if (textRef.current) {
      const scrollHeight = textRef.current.scrollHeight;
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight);
      const lines = Math.floor(scrollHeight / lineHeight);

      setButtonVisible(lines > 2);
    }
  }, [item.content]);
  const dispatch = useAppDispatch()
  const Icon = () => {
    switch (item.category) {
      case 'network':
        return <GlobeAltIcon className="h-6 w-6 text-white" />
      case 'console':
        return <CommandLineIcon className="h-6 w-6 text-white" />
      case 'page':
        return <DocumentTextIcon className="h-6 w-6 text-white" />
      case 'backend log':
        return <ArrowLeftOnRectangleIcon className="h-6 w-6 text-white" />
      case 'mouse_interaction':
        return <CursorArrowRaysIcon className="h-6 w-6 text-white" />
      default:
        return <DocumentIcon className="h-6 w-6" />
    }
  }

  const getTimeDifference = () => {
    const differenceInSeconds = (item.timestamp - startTimestamp) / 1000

    // If difference is negative, return '00:00'
    if (differenceInSeconds < 0) {
      return '0:00'
    }

    const minutes = Math.floor(differenceInSeconds / 60)
    const seconds = Math.floor(differenceInSeconds % 60)

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  const setStartTimerMilSec = (logTimeStamp: number) => {
    if (startTimestamp) {
      var difference = logTimeStamp - startTimestamp

      if (difference < 0) {
        return 0
      } else {
        var secondsDifference = Math.floor(difference / 1000)

        if (secondsDifference < 1) {
          return secondsDifference
        } else {
          var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
          difference -= daysDifference * 1000 * 60 * 60 * 24

          var hoursDifference = Math.floor(difference / 1000 / 60 / 60)
          difference -= hoursDifference * 1000 * 60 * 60

          // var minutesDifference = Math.floor(difference / 1000 / 60)
          // difference -= minutesDifference * 1000 * 60

          secondsDifference = Math.floor(difference / 1000)
          return secondsDifference
        }
      }
    }
  }

  const playFromSecond = (logTimeStamp: number) => {
    const timeToPlay = setStartTimerMilSec(logTimeStamp)
    if (timeToPlay !== undefined && timeToPlay >= 0) {
      dispatch(
        setPlayFrom(
          Math.max(timeToPlay * 1000 + Math.floor(Math.random() * 12 - 1000), 1)
        )
      )
    }
  }

  const colorClass = getColor(item.category, item.sub_category)

  return (
    <div
      onClick={() => playFromSecond(item.timestamp)}
      className="flex  space-x-4 bg-gray-50 p-4 rounded-lg cursor-pointer hover:bg-gray-200"
    >
      
      <div
        className={`p-2 self-start rounded-md ${colorClass} flex items-center justify-center`}
      >
        <Icon />
      </div>
      <div className="flex-grow overflow-scroll">
        <div className="flex items-center gap-4">
          <h4 className="text-lg font-bold max-w-full whitespace-nowrap">
            {item.title}
          </h4>
          <FeedBanner banner={item.banner} sub_category={item.sub_category} />
        </div>
        <div ref={textRef} className="hidden">
          <p className="text-gray-500 text-sm max-w-full break-words">
            {item.content}
          </p>
        </div>
        <div className={`text-gray-500 text-sm max-w-full break-words ${isTextExpanded ? '' : ''}`}>
        <Highlighter
        highlightClassName="bg-yellow-400"
        searchWords={[search]} // this is your search term
        autoEscape={true}
        textToHighlight={item.content}
      />
        </div>
       {item.more_info && 
       
          <button
          onClick={(event) => {
            event.stopPropagation()
            setNetworkData(item.more_info)
            openNetwork()
          }}
            className="mt-2 text-blue-500 hover:text-blue-600 underline text-sm"
          >
            {isTextExpanded ? 'Show Less' : 'Show More'}
          </button>
       }
        
      </div>
      <div className="flex flex-col justify-between">
        <p className="text-gray-400 text-sm">{getTimeDifference()}</p>
        {/* {item.more_info && (
          <div
            onClick={(event) => {
              event.stopPropagation()
              setNetworkData(item.more_info)
              openNetwork()
            }}
            className="border cursor-pointer rounded-md px-1 flex items-center hover:bg-gray-300"
          >
            <InformationCircleIcon className="h-6 w-6" />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default FeedElement
