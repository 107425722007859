import {
  ArrowPathIcon,
  CheckBadgeIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import ShortUuid from '../../utils/shared/ShortUuid'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  viewsSelectors,
  getViews,
  setCurrentView,
  viewsState
} from '../../../app/slices/viewsSlice'
import { authState } from '../../../app/slices/authSlice'
import {
  filterRecords,
  setReqestFiltersReqObj,
  resetFilters,
  setOrigins,
  setRecordsArray,
  problemsSelectors,
  setNoResults,
  setFilterDone
} from '../../../app/slices/ProblemsSlice'
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice'

const navigation = [
  { name: 'All', href: '', icon: VideoCameraIcon, current: false }
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface IssuesSidebarProps {
  records: any
}

const IssuesSidebar: React.FC<IssuesSidebarProps> = ({ records }) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const totalRecords = useAppSelector(problemsSelectors.selectAll)
  const { currentView } = useAppSelector(viewsState)
  const [activeNav, setActiveNav] = useState('All')
  const views = useAppSelector(viewsSelectors.selectAll)
  const domains = useAppSelector(customerDomainsSelectors.selectAll)

  useEffect(() => {
    dispatch(getViews({ user_id: user.id, customer_id: user.customer_id }))

    if (localStorage.getItem('record_cur_view') !== null) {
      let viewToParse: any = localStorage.getItem('record_cur_view')
      let currentView = JSON.parse(viewToParse)
      setView(currentView)
    }
  }, [])

  useEffect(() => {
    if (currentView.id) {
      const domains = JSON.parse(currentView.query_json).Origins
      dispatch(setOrigins({ domains, type: 'view' }))
    }
  }, [currentView])

  const setView = (view: any) => {
    dispatch(resetFilters())
    const viewFilters = JSON.parse(view.query_json)
    localStorage.setItem('record_cur_view', JSON.stringify(view))
    setActiveNav(view.name)
    dispatch(setCurrentView(view))

    dispatch(setReqestFiltersReqObj(viewFilters))
    dispatch(
      filterRecords({
        customer_id: user.customer_id,
        filterRecordsReq: viewFilters
      })
    )
  }

  const removeCurrentView = () => {
    localStorage.removeItem('record_cur_view')
    setActiveNav('')
    dispatch(setCurrentView({}))
    dispatch(resetFilters())
    dispatch(setOrigins({ domains, type: 'all' }))
    dispatch(setRecordsArray(totalRecords))
    dispatch(setNoResults(false))
    dispatch(setFilterDone(false))
  }

  return (
    <div className="pt-24 w-40 mt-2 flex flex-col pl-4 ">
      {/* <button
        className="text-tuto-primary flex items-center mb-2 px-6 "
        onClick={() => navigate(-1)}
      >
        <ArrowLeftIcon className="w-4 h-4" /> Go back
      </button> */}

      {navigation.map((item) => (
        <div
          {...{ 'data-cy': 'your-account' }}
          key={item.name}
          onClick={removeCurrentView}
          className={classNames(
            !currentView.id
              ? 'bg-tuto-light-blue bg-opacity-30'
              : 'border-transparent hover:border-gray-300 hover:bg-tuto-light-blue',
            'flex text-tuto-primary items-start  px-2 py-4 mb-2 rounded-lg text-sm font-medium'
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-gray-700'
                : 'text-gray-600 group-hover:text-gray-500',
              'md:mr-3 flex-shrink-0 h-6 w-6'
            )}
            aria-hidden="true"
          />
          <span className="hidden sm:block">{item.name}</span>
        </div>
      ))}
      {views &&
        views
          .filter(
            (filterdView: any) =>
              filterdView.is_public === true || filterdView.user_id === user.id
          )
          .map((view: any) => (
            <div
              {...{ 'data-cy': 'your-account' }}
              onClick={() => setView(view)}
              key={view.id}
              className={classNames(
                activeNav === view.name
                  ? 'bg-tuto-light-blue bg-opacity-30'
                  : 'border-transparent hover:border-gray-300 hover:bg-tuto-light-blue',
                ' text-tuto-primary cursor-pointer px-2 py-4 mb-2  rounded-lg text-sm font-medium clamp'
              )}
            >
             
                {view.name}
              
            </div>
          ))}
    </div>
  )
}

export default IssuesSidebar
