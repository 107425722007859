import { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  resetFilters,
  setReqestFiltersReqObj,
  filterRecords
} from '../../../app/slices/ProblemsSlice'
import { authState } from '../../../app/slices/authSlice'
import '../Problems/problems.css'
import { getInvestigations, investigationElastic, investigationsSelectors, setCurrentInvestigation } from '../../../app/slices/investigationsSlice'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const InvestigationsDropdown = () => {
  const investigations = useAppSelector(investigationsSelectors.selectAll)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)

  useEffect(() => {
    dispatch(getInvestigations({ customer_id: user.customer_id }))
  }, [])

  const setInvestigation = (investigation: any) => {
    const investigationFilters = JSON.parse(investigation.query_json)
    localStorage.setItem('record_cur_investigation', JSON.stringify(investigation))
    dispatch(setCurrentInvestigation(investigation))

    if(!investigations) {
      console.log('!investigations investigationElastic')
      dispatch(
        investigationElastic({
          customer_id: user.customer_id,
          filters: investigationFilters
        })
      )
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md  bg-white px-1 text-sm font-medium text-gray-700  hover:bg-gray-50 focus:outline-none ">
          <ChevronDownIcon
            className="h-10 w-10 stroke-icon"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
           
            {investigations.map((investigation: any) => (
              <Menu.Item key={investigation.id}>
                {({ active }) => (
                  <div
                    onClick={() => setInvestigation(investigation)}
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-tuto-primary'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm cursor-pointer font-semibold'
                    )}
                  >
                    {investigation.name}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default InvestigationsDropdown
