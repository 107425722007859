import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { RootState } from '../store'
import {
  GetUsersByCustomer,
  UpdateUsersRole,
  GetUserRoles,
  SendInvitation,
  UpdateCustomerHasDomain,
  DeleteUser
} from '../apis/customerUsersApi'

export interface customerUsersType {
  id: string
  role_name: string
  email: string
}

export const customerUsersAdapter = createEntityAdapter<customerUsersType>({
  selectId: (user) => user.id,
  sortComparer: (a: any, b: any) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
})

export const customerUsersSelectors =
  customerUsersAdapter.getSelectors<RootState>((state) => state.customerUsers)

export const { selectAll, selectById, selectEntities, selectTotal } =
  customerUsersSelectors

export const updateUserRole = createAsyncThunk(
  'customer/updateUserRole',
  async ({ user_id, role_id }: any) => {
    try {
      const res = await UpdateUsersRole(user_id, role_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getUserRoles = createAsyncThunk(
  'customer/getUserRoles',
  async () => {
    try {
      const res = await GetUserRoles()
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const deleteUser = createAsyncThunk(
  'customer/deleteUser',
  async ({email, id}:any) => {
    try {
      const res = await DeleteUser(email, id)
      // console.log(res)
      return res
    } catch (err: any) {
      return err.message
    }
  }
)

export const getCustomerUsers = createAsyncThunk(
  'customer/getCustomerUsers',
  async (customer_id: string) => {
    try {
      const users: any = await GetUsersByCustomer(customer_id)
      // console.log(users.invitations)
      const usersWithInvitations = users.data
        .concat(users.invitations)
        .map((user: any) =>
          user.inviter_id
            ? { ...user, invitation: true }
            : { ...user, invitation: false }
        )

      return usersWithInvitations
    } catch (err: any) {
      return err.message
    }
  }
)

export const sendInvitation = createAsyncThunk(
  'customer/sendInvitation',
  async ({
    customer_id,
    role_id,
    teammates,
    campaign_id,
    sender,
    senderName,
    inviter_id,
    name,
    is_re_invite,
    invitation_id
  }: any) => {
    try {
      const users: any = await SendInvitation(
        customer_id,
        role_id,
        [teammates],
        campaign_id,
        sender,
        senderName,
        inviter_id,
        name,
        is_re_invite,
        invitation_id
      )

      users.data[0].invitation = true

      return users.data[0]
    } catch (err: any) {
      return err.message
    }
  }
)

export const updateCustomerHasDomain = createAsyncThunk(
  'customer/updateCustomerHasDomain',
  async ({ customer_id, hasDomain }: any) => {
    try {
      const users: any = await UpdateCustomerHasDomain(customer_id, hasDomain)
      return users.data.rows
    } catch (err: any) {
      return err.message
    }
  }
)

const customerUsersSlice = createSlice({
  name: 'customer',
  initialState: customerUsersAdapter.getInitialState({
    loading: false,
    error: '',
    roles: [],
    invitationLoading: false
  }),
  reducers: {
    deleteInvitation(state, action) {
      customerUsersAdapter.removeOne(state, action.payload)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerUsers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(
        getCustomerUsers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          customerUsersAdapter.setAll(state, action.payload)
        }
      )
      .addCase(
        getCustomerUsers.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        }
      )
      .addCase(getUserRoles.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getUserRoles.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.roles = action.payload
      })
      .addCase(getUserRoles.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateUserRole.pending, (state, action) => {
        state.loading = true
      })
      .addCase(
        updateUserRole.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          // customerUsersAdapter.updateOne() = action.payload
        }
      )
      .addCase(updateUserRole.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(sendInvitation.pending, (state, action) => {
        state.invitationLoading = true
      })
      .addCase(
        sendInvitation.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.invitationLoading = false
          customerUsersAdapter.addOne(state, action.payload)
        }
      )
      .addCase(sendInvitation.rejected, (state, action: PayloadAction<any>) => {
        state.invitationLoading = false
        state.error = action.payload
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.invitationLoading = true
      })
      .addCase(
        deleteUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.invitationLoading = false
          // console.log('deleted user ', action.payload)
          customerUsersAdapter.removeOne(state, action.payload.id)
        }
      )
      .addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
        state.invitationLoading = false
        state.error = action.payload
      })
  }
})

export const customerUsersState = (state: RootState) => state.customerUsers
export const { deleteInvitation } = customerUsersSlice.actions
export default customerUsersSlice.reducer
