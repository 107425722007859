import React, { useState, useEffect, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import NotificationIcon from './NotificationIcon'
import Button from '../elements/Button'
import './notifiers.css'
import { useDispatch } from 'react-redux'
import { removeNotification } from '../../../app/slices/notificationsSlice'

interface NotificationProps {
  id: string
  message: string
  type: string
  title: string
  withTimer: boolean
  withButton: boolean
  buttonFunction?: Function
  buttonText?: string
  withSecondButton?: boolean
  secondButtonFunction?: Function
  secondButtonText?: string
}

const Notification: React.FunctionComponent<NotificationProps> = ({
  id,
  message,
  type,
  title,
  withTimer,
  withButton,
  buttonText,
  buttonFunction,
  withSecondButton,
  secondButtonFunction,
  secondButtonText
}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (withTimer) {
      setTimeout(() => {
        closeNotification()
      }, 5000)
    }
  }, [])

  const closeNotification = () => {
    dispatch(removeNotification(id))
    setIsOpen(false)
  }

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden dark:bg-dark-secondary dark:border-dark-text">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <NotificationIcon type={type} />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-md font-medium text-gray-900 dark:text-dark-text">{title}</p>
              <p className="mt-1 paragraph-small">{message}</p>
              {withButton && (
                <div className="mt-4 flex">
                  <Button
                    type="button"
                    inverted={false}
                    loading={false}
                    disabled={false}
                    withIcon={false}
                    text={buttonText}
                    spacialClass="mr-2 md:px-2 md:py-1 text-sm"
                    clickFunction={buttonFunction}
                  />
                  {withSecondButton ? (
                    <Button
                      type="button"
                      inverted={true}
                      loading={false}
                      disabled={false}
                      withIcon={false}
                      text={secondButtonText}
                      spacialClass="md:px-2 md:py-1 text-sm"
                      clickFunction={secondButtonFunction}
                    />
                  ) : (
                    <Button
                      type="button"
                      inverted={true}
                      loading={false}
                      disabled={false}
                      withIcon={false}
                      text="Cancel"
                      spacialClass="md:px-2 md:py-1 text-sm"
                      clickFunction={closeNotification}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                onClick={closeNotification}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5 rotate-360" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {withTimer && (
          <div className="bg-tuto-primary w-full absolute bottom-0 h-1 grwo-width"></div>
        )}
      </div>
    </Transition>
  )
}

export default Notification
