import { useEffect, useState } from 'react'
import Button from '../../../utils/elements/Button'
import {
  PlayIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
  CogIcon
} from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  authState,
  loginPlayground,
  setLoader
} from '../../../../app/slices/authSlice'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import Knowledge from '../../Knowledge/Knowledge'
import config from '../../../../config/config'
import { CopyBlock, a11yLight } from 'react-code-blocks'
import Videos from '../../Knowledge/Videos'
import InstructionsForm from '../../Knowledge/InstructionsForm'
import {
  notificationsSelectors,
  removeAllNotifications,
  removeNotification,
  setCoppiedTooltip
} from '../../../../app/slices/notificationsSlice'
import StepByStep from '../../../utils/layout/StepByStep'
import { uiState, setOnboardingMode } from '../../../../app/slices/uiSlice'
import {
  customerDomainsSelectors,
  getCustomerDomains
} from '../../../../app/slices/customerDomainsSlice'
import { useNavigate } from 'react-router-dom'
import PlaygroundPopup from '../../../utils/layout/PlaygroundPopup'
import { ZendeskAPI } from 'react-zendesk'
import InstallationBlock from './InstallationBlock'
import InstallationTabs from './InstallationTabs'

const Installation = () => {
  const { user, isPlayground } = useAppSelector(authState)

  const [knowledgeOpen, setKnowledgeOpen] = useState(false)
  const [instructionsFormOpen, setInstructionsFormOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  
  const navigate = useNavigate()
  const script = ` <!--Start of Record Script-->
  <script>
  !function(b,e,d,f){
    var a=b.createElement("script");a.async=!0,
    a.id="torecord",a.src="${config.scriptSource}?key="+d;
    var c=b.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(a,c)
  }(document,window,"${isPlayground ? 'API_KEY' : user.customer_id}")
</script>
<!--End of Record Script-->`
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  useEffect(() => {
    if (user) {
      Mixpanel.register({
        Page: 'Installation',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })
      Mixpanel.track('Installation Page Viewed', {})
      // dispatch(setOnboardingMode(user.is_onboarding_mode))
    }
  }, [])

  useEffect(() => {
    if (isPlayground && sessionStorage.getItem('redirect') === 'false') {
      sessionStorage.setItem('redirect', 'true')
      dispatch(setLoader())
      navigate('/')
    }
  }, [isPlayground])

  return (
    <div className="installation-wrapper">
      <Knowledge
        title={'Getting Started'}
        isOpen={knowledgeOpen}
        setSlideOpen={setKnowledgeOpen}
      >
        <Videos />
      </Knowledge>
      <Knowledge
        title={'Installation Instructions'}
        isOpen={instructionsFormOpen}
        setSlideOpen={setInstructionsFormOpen}
      >
        <InstructionsForm setSlideOpen={setInstructionsFormOpen} />
      </Knowledge>
      <div className='flex flex-col items-start w-full gap-8'>

      <h1 className='general-title'>Set up your Record account</h1>
      <InstallationTabs />
      </div>
    </div>
  )
}

export default Installation
