import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import {
  GetCustomerSteps,
  GetCustomerStepsDone,
  GetJobsTitles,
  GetCustomerOnboardingSteps,
  UpdateJobTitle,
  GetTiers
} from '../apis/uiApi'
import { RootState } from '../store'

export interface UiState {
  step: number
  error: string
  steps: []
  remainingSteps: number
  slidoverOpen: boolean
  stepsDone: []
  stepsToDo: []
  discoverMode: boolean
  plans: {
    month: []
    year: []
  }
  tierPopup: {
    open: boolean
    feature: string
    img: string
    specialComponent: boolean
    nextTier: string
    newFeature?: string
    blockedFeature: boolean
    newMetric: string
    paragraph?: string
    title: string
    subTitle?: string,
    component?: any,
    specialImgClass?: string
  }
  discoverSteps: any[]
  tiers: any[]
  activeTier: any
  activeDiscoverStep: number
  showConfetti: boolean
  jobsTitles: []
  pgDiscoverSteps: any[]
  pgRecordDiscoverSteps: any[]
  onBoardingSteps: any
  onBoardingMode: boolean
  loadingOnboardingSteps: boolean
  recordDiscoverMode: boolean
  modalOpen: boolean
  isFromIntegration: boolean
  integration_id?: string
  integrationSidebarToggle: boolean
}

const initialState: UiState = {
  step: 1,
  error: '',
  steps: [],
  jobsTitles: [],
  stepsDone: [],
  modalOpen: false,
  slidoverOpen: false,
  remainingSteps: 3,
  stepsToDo: [],
  onBoardingSteps: [],
  plans: {
    month: [],
    year: []
  },
  activeTier: null,
  tierPopup: {
    open: false,
    feature: '',
    img: '',
    specialComponent: false,
    component: null,
    nextTier: '',
    blockedFeature:false,
    newMetric:'',
    title: '', 
    subTitle: '',
    specialImgClass: ''
  },
  discoverMode: false,
  activeDiscoverStep: 1,
  showConfetti: false,
  onBoardingMode: false,
  tiers: [],
  loadingOnboardingSteps: false,
  discoverSteps: [
    {
      title: 'Installation',
      description:
        'In order to install record you need to install the code. Here is your unique code to install.',
      number: 1
    },
    {
      title: 'How To',
      description:
        'If you have any questions about the process, you can always click on the quick start and follow these steps.',
      number: 2
    },
    {
      title: 'Verified Domains',
      description:
        'When the code is installed you need to verify the domain. only verified domains will work.',
      number: 3
    },
    {
      title: 'Home',
      description:
        'When the installation process will end you will be able to see your unique URL that you can share with your users.',
      number: 4
    },
    {
      title: 'Record',
      description:
        'Here you will find all the records your users shared with you and shareable link to share with your company.',
      number: 5
    }
  ],
  pgDiscoverSteps: [
    {
      title: 'Go To The Site Record Installed On',
      description:
        'At any given time you can go to the site Record installed on. We provide a unique button That will enable record mode.',
      number: 1
    },
    {
      title: 'Your Home Page',
      description: 'Welcome to your home page.',
      number: 2
    },
    {
      title: 'Your Unique Link',
      description:
        'This is your own agent link that will allow you to share it with customers to let them Share their problem.',
      number: 3
    },
    {
      title: 'Your Domains',
      description:
        'Here you can find all the installed domains. Choose the right domain to share.',
      number: 4
    },
    {
      title: 'Copy the link',
      description:
        'Here you can find all the installed domain Choose the right domain to share.',
      number: 5
    },
    {
      title: 'Paste',
      description:
        'Please paste it in order to activate Record on the installed dashboard domain',
      number: 6
    }
  ],
  pgRecordDiscoverSteps: [
    {
      title: 'See the Record',
      description: 'This is the record you just created.',
      number: 1
    },
    {
      title: 'Addition information',
      description:
        'Here can be found additional information on the recording, the aget. The user ID and more.',
      number: 2
    },
    {
      title: 'Shareable',
      description:
        'Want to share and collaborate internally? Use this link and send it to your product team, developers etc.',
      number: 3
    },
    {
      title: 'Problem feed',
      description:
        'Here all your records created in the feed with some additional information.',
      number: 4
    }
  ],
  recordDiscoverMode: false,
  isFromIntegration: false,
  integrationSidebarToggle: false
}

export const getCustomerSteps = createAsyncThunk(
  'ui/customerSteps',
  async (customer_id: string) => {
    try {
      const res = await GetCustomerSteps(customer_id)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getCustomerStepsDone = createAsyncThunk(
  'ui/customerStepsDone',
  async (customer_id: string) => {
    try {
      const res = await GetCustomerStepsDone(customer_id)
      // console.log(res.data)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getCustomerOnboardingSteps = createAsyncThunk(
  'ui/customerOnboardingSteps',
  async (customer_id: string) => {
    try {
      const res = await GetCustomerOnboardingSteps(customer_id)
      // console.log(res.data)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getJobsTitles = createAsyncThunk('ui/getJobsTitles', async () => {
  try {
    const res = await GetJobsTitles()
    // console.log(res.data)
    return res.data
  } catch (err: any) {
    return err.message
  }
})

export const updateJobTitle = createAsyncThunk(
  'ui/updateJobTitle',
  async ({ title_id, user_id }: any) => {
    try {
      const res = await UpdateJobTitle(title_id, user_id)
      // console.log(res.data)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)

export const getTiers = createAsyncThunk('ui/getTiers', async () => {
  try {
    const res = await GetTiers()
    return res.data
  } catch (err: any) {
    return err.message
  }
})

const UiSlice = createSlice({
  name: 'customerDomains',
  initialState,
  reducers: {
    toggleSlider: (state) => {
      state.slidoverOpen = !state.slidoverOpen
    },
    setOnboardingMode: (state, action) => {
      state.onBoardingMode = action.payload
    },
    prevDiscoverStep: (state) => {
      if (state.activeDiscoverStep > 1) {
        state.activeDiscoverStep = state.activeDiscoverStep - 1
      } else {
        state.discoverMode = false
        state.activeDiscoverStep = 1
      }
    },
    nextDiscoverStep: (state) => {
      if (state.activeDiscoverStep < 5) {
        state.activeDiscoverStep = state.activeDiscoverStep + 1
      } else {
        state.discoverMode = false
        state.activeDiscoverStep = 1
        state.showConfetti = true
      }
    },
    nextPGDiscoverStep: (state) => {
      if (state.activeDiscoverStep < 6) {
        state.activeDiscoverStep = state.activeDiscoverStep + 1
      } else {
        state.discoverMode = false
        state.activeDiscoverStep = 1
        state.showConfetti = true
      }
    },
    nextPGRecordDiscoverStep: (state) => {
      if (state.activeDiscoverStep < 4) {
        state.activeDiscoverStep = state.activeDiscoverStep + 1
      } else {
        state.recordDiscoverMode = false
        state.activeDiscoverStep = 1
        state.showConfetti = true
      }
    },
    closeDiscoverMode: (state) => {
      state.discoverMode = false
      state.recordDiscoverMode = false
    },
    openDiscoverMode: (state) => {
      state.discoverMode = true
      state.activeDiscoverStep = 1
    },
    openTiersPopup: (state, action) => {
      state.tierPopup.blockedFeature = action.payload.blockedFeature
      state.tierPopup.feature = action.payload.feature
      state.tierPopup.img = action.payload.img
      state.tierPopup.specialComponent = action.payload.specialComponent
      state.tierPopup.nextTier = action.payload.nextTier
      state.tierPopup.newMetric = action.payload.newMetric
      state.tierPopup.paragraph = action.payload.paragraph
      state.tierPopup.title = action.payload.title
      state.tierPopup.subTitle = action.payload.subTitle
      state.tierPopup.component = action.payload.component
      state.tierPopup.specialImgClass = action.payload.specialImgClass
      state.tierPopup.open = true
    },
    closeTiersPopup: (state) => {
      state.tierPopup.open = false
    },
    setPlans: (state, action) => {
      state.plans.month = action.payload.filter((plan:any) => plan.billing_type === 'month')
      state.plans.year = action.payload.filter((plan:any) => plan.billing_type === 'year')
    },
    setShowConfetti: (state, action) => {
      state.showConfetti = action.payload
    },
    openRecordDiscoverMode: (state) => {
      state.discoverMode = false
      state.recordDiscoverMode = true
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload
    },
    setActiveTier: (state, action) => {
      state.activeTier = state.tiers.find((tier) => tier.id === action.payload)
      if(state.activeTier) {
        if(state.activeTier.name === 'Ultimate') {
          state.activeTier = {
            ...state.activeTier,
            nextTier: state.tiers.find((tier) => tier.name === 'Businesses'),
            prevTier: state.tiers.find((tier) => tier.name === 'Starter')
          }
        }
        if(state.activeTier.name === 'Starter') {
          state.activeTier = {
            ...state.activeTier,
            nextTier: state.tiers.find((tier) => tier.name === 'Ultimate'),
            prevTier: state.tiers.find((tier) => tier.name === 'Free')
          }
        }
        if(state.activeTier.name === 'Businesses') {
          state.activeTier = {
            ...state.activeTier,
            prevTier: state.tiers.find((tier) => tier.name === 'Ultimate'),
          }
        }
        if(state.activeTier.name === 'Free') {
          state.activeTier = {
            ...state.activeTier,
            nextTier: state.tiers.find((tier) => tier.name === 'Starter'),
          }
        }
      }
    },
    setFromIntegration: (state, action) => {
      state.isFromIntegration = true
      state.integration_id = action.payload
    },
    integrationSidebarToggleStatus: (state, action) => {
      state.integrationSidebarToggle = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerSteps.pending, (state, action) => {})
      .addCase(
        getCustomerSteps.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.steps = action.payload
        }
      )
      .addCase(
        getCustomerSteps.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addCase(getJobsTitles.pending, (state, action) => {})
      .addCase(getJobsTitles.fulfilled, (state, action: PayloadAction<any>) => {
        state.jobsTitles = action.payload
      })
      .addCase(getJobsTitles.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
      })
      .addCase(getCustomerStepsDone.pending, (state, action) => {})
      .addCase(
        getCustomerStepsDone.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.remainingSteps = action.payload.remainingSteps
          state.stepsDone = action.payload.rows
          state.stepsToDo = action.payload.stepsToDo
        }
      )
      .addCase(
        getCustomerStepsDone.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
        }
      )
      .addCase(getCustomerOnboardingSteps.pending, (state, action) => {
        state.loadingOnboardingSteps = true
      })
      .addCase(
        getCustomerOnboardingSteps.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.onBoardingSteps = action.payload
          state.loadingOnboardingSteps = false
        }
      )
      .addCase(
        getCustomerOnboardingSteps.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload
          state.loadingOnboardingSteps = false
        }
      )
      .addCase(getTiers.fulfilled, (state, action: PayloadAction<any>) => {
        state.tiers = action.payload
      })
  }
})

export const uiState = (state: RootState) => state.ui
export const {
  toggleSlider,
  nextDiscoverStep,
  prevDiscoverStep,
  closeDiscoverMode,
  openDiscoverMode,
  setShowConfetti,
  nextPGDiscoverStep,
  nextPGRecordDiscoverStep,
  openRecordDiscoverMode,
  setModalOpen,
  setFromIntegration,
  setOnboardingMode,
  integrationSidebarToggleStatus,
  setActiveTier,
  openTiersPopup,
  closeTiersPopup,
  setPlans
} = UiSlice.actions
export default UiSlice.reducer
