import React from 'react'
import Button from '../../utils/elements/Button'
import {
  ArrowPathIcon,
} from '@heroicons/react/24/outline'

const ErrorPage = () => {
  return (
    <div className="text-center">
      <p className="text-base font-semibold text-tuto-primary">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Session not found
      </h1>
      <p className="mt-6 text-base leading-7 text-tuto-primary">
      Apologies, the session isn’t found. <br/> If recently created, it may take 2 minutes to appear. Please refresh after a moment.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Button
          text='Refresh'
          withIcon={true}
          Icon={ArrowPathIcon}
          inverted={false}
          disabled={false}
          loading={false}
          type='button'
          clickFunction={() => window.location.reload()}
        />
          
       
        <a href="mailto:support@torecord.it" className="text-sm font-semibold text-gray-900">
          Contact support <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  )
}

export default ErrorPage
