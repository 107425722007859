import React, { useEffect } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { uiState, setShowConfetti } from '../../../app/slices/uiSlice'

const Congrats = () => {
  const { width, height } = useWindowSize()
  const {showConfetti} = useAppSelector(uiState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    hideConfetti()
  }, [showConfetti])

  const hideConfetti = () => {
    setTimeout(() => {
      dispatch(setShowConfetti(false))
    }, 5000)
  }

  return (
    showConfetti ?  <Confetti
      width={width}
      height={height}
    /> : null
  )
}

export default Congrats