import { Link } from 'react-router-dom'
import { Navigate } from 'react-router'
import './Auth.css'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormInput from '../utils/forms/FormInput'
import {
  addNewNotification,
  notificationsSelectors
} from '../../app/slices/notificationsSlice'
import NotificationsProvider from '../utils/notifiers/NotificationsProvider'
import Button from '../utils/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import AuthWrapper from './AuthWrapper'
import {
  authState,
  loginUserMenual,
  continueToCodeStep,
  loginPlayground,
  logOutUser,
  setLoader
} from '../../app/slices/authSlice'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import UserPool from './UserPool'
import { useEffect, useState } from 'react'
import AuthError from './AuthError'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import CONFIG from '../../config/config'
import { Mixpanel } from '../utils/mixPanel/mixPanel'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../utils/shared/PageLoader'
import { IntegrationsTypes } from '../utils/enums'

interface IFormInputs {
  email: string
  password: string
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
})

const Login = () => {
  const dispatch = useAppDispatch()
  const { isLoggedIn, loading } = useAppSelector(authState)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn])
  
  const searchParams = new URLSearchParams(window.location.search)
  const role = searchParams.get('role')
  const customer_id = searchParams.get('state')
  const playground = searchParams.get('playground')
  const [isIntegration, setIsIntegration] = useState(false)
  const [loginImage, setLoginImage] = useState('')

  useEffect(() => {
    if(playground) {
      dispatch(setLoader())
      dispatch(loginPlayground())
    }
    else{
      const searchParams = new URLSearchParams(window.location.search)
      const authCode = searchParams.get('code')
      const integrationState = searchParams.get('state')
      const installationId = searchParams.get('installationId')
      let subDomain
      let integrationId = localStorage.getItem('integrationId')
      const stateArr = integrationState?.split('_')
      if(stateArr && stateArr.length > 1){
        subDomain = stateArr[0]
        integrationId = stateArr[1]
        if(integrationId === IntegrationsTypes.SENTRY){
          subDomain = searchParams.get('orgSlug')
        }
        if(integrationId){
          localStorage.setItem('integrationId', integrationId)
          if(authCode){
            localStorage.setItem('integrationAuthCode', authCode)
          }
          if(subDomain){
            localStorage.setItem('integrationSubDomain', subDomain)
          }
          if(installationId){
            localStorage.setItem('integrationInstallationId', installationId)
          }
        }
      }
      switch(integrationId || integrationState){
        case IntegrationsTypes.ZENDESK:
          setLoginImage("https://assets.torecord.it/Zendesk%2BRecord.png")
          setIsIntegration(true)
          break;
        case IntegrationsTypes.INTERCOM:
          setLoginImage("https://assets.torecord.it/Intercom%2Brecord.png")
          setIsIntegration(true)
          break;
        case IntegrationsTypes.SENTRY:
          setLoginImage("https://assets.torecord.it/sentry-logo.png")
          setIsIntegration(true)
          break;
        default:
          setLoginImage("https://assets.torecord.it/Pepole-photo.png")
          break;
      }
    }
    dispatch(continueToCodeStep('register'))
    Mixpanel.register({'Page': 'Login'})
    Mixpanel.track('Login Page Viewed', {})
    sessionStorage.setItem('invitationActive', 'false')
  }, [])
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const user = new CognitoUser({
      Username: data.email,
      Pool: UserPool
    })

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password
    })
    Mixpanel.track('Signin Clicked', { email: data.email })
    user.authenticateUser(authDetails, {
      onSuccess: async (data: any) => {
        dispatch(
          loginUserMenual({ token: data.idToken.jwtToken, role, customer_id })
        )
      },
      onFailure: (err) => {
        setError(err.message)
      }
    })
  }

  return (
   loading === 'loading' ? <PageLoader /> : <AuthWrapper
    withSteps={false}
    imageUrl={loginImage}
    imageAdditionalClass={isIntegration ? 'h-4 w-4 ' : 'login-image'}
    withTopPurpleCircle={true}
    isIntegration={isIntegration}
    >
 
      <div className="flex-1 flex flex-col justify-center py-12 lg:flex-none lg:px-0 xl:px-0">
        <div className="flex flex-col mx-auto w-full max-w-sm lg:w-96">
        <img
        className="mb-12 w-32 xl:w-40"
        src="https://assets.torecord.it/recordlogoblack.png"
      />
          <div className="flex flex-col items-start mb-4">
            <h2 className="general-title onboarding login-title">Login</h2>
          </div>
          <div
            className="p-2 bg-tuto-light-blue text-white rounded shadow google-button flex cursor-pointer items-center mt-2"
            onClick={() =>{
              Mixpanel.track('Clicked on Continue with Google',{});
              (window.location.href = `${CONFIG.auth}oauth2/authorize?identity_provider=Google&redirect_uri=${CONFIG.dashboard}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid phone profile&prompt=consent`)}
            }
          >
            <img
              src="https://assets.torecord.it/search.png"
              className="google-icon"
            />
            <div className="ml-2">Continue with Google</div>
          </div>
         
          <div className="relative flex justify-center text-sm mt-4">
            <span className="px-2 text-gray-500">Or</span>
          </div>
          <div>
            <div className="mt-6">
              <form
                onSubmit={handleSubmit(formSubmitHandler)}
                className="space-y-6"
                id="login_form"
              >
                <div className="relative">
                  <div className="mt-1">
                    <FormInput
                      name="email"
                      type="email"
                      label="Email address"
                      placeholder=""
                      touched={undefined}
                      watch={watch}
                      error={undefined}
                      registerFunction={register}
                      isCustomClass={false}
                      customClass={undefined}
                    />
                  </div>
                </div>

                <div className="relative space-y-1">
                  <div className="mt-1">
                    <FormInput
                      name="password"
                      type="password"
                      label="Password"
                      placeholder=""
                      touched={undefined}
                      error={undefined}
                      watch={watch}
                      registerFunction={register}
                      isCustomClass={false}
                      customClass={undefined}
                    />
                  </div>
                </div>

                <div className="flex items-start justify-between flex-col">
                  <div className="paragraph-small">
                    <label htmlFor="" className="mr-2 text-gray-900">
                      Forgot your password?
                    </label>
                    <Link
                       {...({'data-cy':'reset-password'})}
                      to="/resetpassword"
                      className="font-medium text-tuto-purple"
                      onClick={() => {Mixpanel.track('Clicked on Reset Password', {})}}
                    >
                      Reset Password
                    </Link>
                  </div>
                </div>

                <Button
                  // clickFunction={changeSubscription}
                  type={'submit'}
                  disabled={false}
                  loading={false}
                  withIcon={true}
                  Icon={ArrowRightIcon}
                  text={'Sign In'}
                  inverted={false}
                />
              </form>
              <div className="my-4 paragraph-small">
                <label htmlFor="remember_me" className="mr-2 paragraph-small">
                  Not registered yet?
                </label>
                <Link to="/signup" className="font-medium text-tuto-purple" onClick={() => {Mixpanel.track('Clicked on Signup - Login page',{})}}   {...({'data-cy':'sign-up-button'})}>
                  Sign Up
                </Link>
              </div>
              {error && <AuthError title="error" description={error} dataCy='error-message'/>}
            </div>
            {/* <div className='mt-8'>
                      <Button
                        type="button"
                        text='Try Playground'
                        clickFunction={() => {
                          Mixpanel.track('Clicked on Try Playground - Login Page', {})
                          dispatch(loginPlayground())
                        } } 
                        loading={false} 
                        inverted={true} 
                        disabled={false} 
                        withIcon={false}                            
                          />
              </div> */}
          </div>
        </div>
      </div>
      <NotificationsProvider />
    </AuthWrapper>
  )
}

export default Login
