import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import { investigationParametersState } from '../../../app/slices/investigationParametersSlice'
import { investigationsState, investigationElastic } from '../../../app/slices/investigationsSlice'
import Layout from '../../utils/layout/Layout'
import CogLoader from '../../utils/shared/CogLoader'
import ElasticTable from './ElasticTable'
import CreationSidebar from './sideComponents/CreationSidebar'

const InvestigationCreatePage = () => {
  const {loadingDetails, currentInvestigation} = useAppSelector(investigationsState)
  const {parameters} = useAppSelector(investigationParametersState)
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   if(Object.keys(currentInvestigation).length === 0 ) {
      

  //     dispatch(
  //       investigationElastic({
  //         customer_id: user.customer_id,
  //         filters: parameters
  //       })
  //     )
  //   }
  // }, [])
  return (
    <Layout withCircles={false}>
      <div className="p-4">
        <div className="grid grid-cols-8 relative">
          <div className="col-span-2 sticky top-20">
            <CreationSidebar />
          </div>
          <div className="col-span-6">
            {loadingDetails ? <div className='flex items-center justify-center min-h-70v'><CogLoader /></div> :
            
            <ElasticTable />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InvestigationCreatePage
