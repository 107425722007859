import React, { useEffect, useState } from 'react'
import { TrashIcon, NoSymbolIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ShortUuid from '../shared/ShortUuid'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { openTiersPopup, uiState } from '../../../app/slices/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import BlockedIssuesTable from '../../pages/Problems/BlockedIssuesTable'
import {
  problemsSelectors,
  problemsState,
  resetFilters,
  setRecordsArray,
  setOrigins,
  setNoResults,
  setFilterDone
} from '../../../app/slices/ProblemsSlice'
import Button from '../elements/Button'
import SearchLoader from './SearchLoader'
import FilteringBar from '../../pages/Problems/filtering/FilteringBar'
import { customerDomainsSelectors } from '../../../app/slices/customerDomainsSlice'
import EmptyRow from './EmptyRow'

interface ProblemsTableProps {
  records?: any
}

const ProblemsTable: React.FC<ProblemsTableProps> = () => {
  const dispatch = useAppDispatch()
  const {
    totalRecordsArchived,
    totalRecordsBlocked,
    filteredRecords,
    loadingFilters,
    recordsArray,
    noResults,
    filterDone
  } = useAppSelector(problemsState)
  const { activeTier } = useAppSelector(uiState)
  const totalRecords = useSelector(problemsSelectors.selectAll)
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const millisToMinutesAndSeconds = (duration: string) => {
    const millis = Number(duration)
    var minutes = Math.floor(millis / 60000)
    var seconds: any = ((millis % 60000) / 1000).toFixed(0)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }
  const [emptyRows, setEmptyRows] = useState<any>([])

  useEffect(() => {
    dispatch(setRecordsArray(totalRecords))
  }, [])

  useEffect(() => {
    if (recordsArray.length < 7) {
      const emptyRowsCount = 7 - recordsArray.length
      const emptyRows = Array(emptyRowsCount).fill(null)
      setEmptyRows(emptyRows)
    } else {
      setEmptyRows([])
    }
  }, [recordsArray])

  useEffect(() => {
    // setNoResults(false)

    if (filteredRecords.length > 0) {
      dispatch(setRecordsArray(filteredRecords))
      dispatch(setNoResults(false))
    } else {
      dispatch(setNoResults(true))
    }
  }, [filteredRecords])

  const clearFilters = () => {
    dispatch(resetFilters())
    dispatch(setOrigins({ domains, type: 'all' }))
    dispatch(setRecordsArray(totalRecords))
    dispatch(setNoResults(false))
    dispatch(setFilterDone(false))
  }

  return (
    <div className="">
      <FilteringBar clearFilters={clearFilters} />
      <div className="mt-4 flex flex-col overflow-x-auto">
        <div className="p-1">
          <div className="inline-block min-w-full  py-2 align-middle">
            <div className="table-wrapper min-h-75v flex">
              {loadingFilters ? (
                <SearchLoader />
              ) : noResults && filterDone ? (
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="flex items-center text-gray-400 text-xl mb-4">
                    <NoSymbolIcon className="h-10 w-10 mr-4" /> No results found
                    for the applied filters
                  </div>
                  <Button
                    loading={false}
                    disabled={false}
                    withIcon={false}
                    text="Clear Filters"
                    inverted={false}
                    type="button"
                    clickFunction={clearFilters}
                  />
                </div>
              ) : (
                <table className="min-w-full  divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="sticky z-10 top-0 border-b bg-super-light-gray dark:bg-gray-700">
                    <tr>
                      <th></th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-light-black dark:text-dark-text sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text w-3/12"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                      >
                        Watch time
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-light-black dark:text-dark-text"
                      >
                        Record ID
                      </th>
                    </tr>
                    {totalRecordsBlocked > 0 && (
                      <tr
                        onClick={() =>
                          dispatch(
                            openTiersPopup({
                              feature: 'Records',
                              img: 'https://assets.torecord.it/RecordVideo.png',
                              specialComponent: true,
                              nextTier:activeTier.is_custom_tier ? '' : activeTier.nextTier.name,
                              title: `You run out of Records`,
                              subTitle: activeTier.is_custom_tier ? '' : `upgrade to unlock ${activeTier.nextTier.max_records_per_months} records per month with ${activeTier.nextTier.name} `,
                              component: BlockedIssuesTable,
                              paragraph: activeTier.is_custom_tier ? 'It seems like you’ve reached the limit of your current plan. Don’t worry, we can help you with that! Our sales team can work with you to create a customized plan that meets all of your needs.' :  ''
                            })
                          )
                        }
                        className="cursor-pointer transition duration-300 p-4 bg-white"
                      >
                        <th className="p-4 " scope="colgroup" colSpan={5}>
                          <div className="flex justify-center items-center">
                            <LockClosedIcon className="w-7 h-7 mr-2" />
                            You Have {totalRecordsBlocked} Records Locked
                          </div>
                        </th>
                      </tr>
                    )}
                  </thead>

                  <tbody className="teammates-table relative">
                    {recordsArray &&
                      recordsArray.map((record: any) => (
                        <tr
                          key={record.id}
                          className="dark:bg-dark-primary dark:divide-gray-600 hover:bg-blue-100 relative transition duration-300"
                        >
                          <td>
                            <Link
                              key={record.id}
                              to={`${record.id}`}
                              className="absolute inset-0"
                            ></Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 w-4/12">
                            <div className="flex items-center">
                              {record.is_watched ? (
                                <div className="h-3 w-3 bg-transparent mr-2 rounded-full"></div>
                              ) : (
                                <div className="h-3 w-3 bg-tuto-purple mr-2 rounded-full"></div>
                              )}

                              <div className="text-gray-500 font-bold">
                                {record.visitor_name ? (
                                  record.visitor_name
                                ) : (
                                  <ShortUuid text={record.visitor_id} />
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="flex items-center">
                              <div className="text-gray-500">
                                {/* {domains && domains.length > 0 ? getDomainName(record.domain_id) : null} */}
                                {moment(
                                  new Date(record.created_at).toLocaleString()
                                ).format('DD/MM/YY HH:mm')}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-gray-500">
                                  {millisToMinutesAndSeconds(record.duration)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-gray-500">
                                  {record.id && <ShortUuid text={record.id} />}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center cursor-pointer">
                          <EllipsisHorizontalIcon className="w-6 h-6" />
                        </td> */}
                        </tr>
                      ))}
                    {emptyRows.map((_: any, index: number) => (
                      <EmptyRow key={recordsArray.length + index} />
                    ))}

                    {totalRecordsArchived > 0 && (
                      <tr
                        onClick={() =>
                          dispatch(
                            openTiersPopup({
                              feature: 'Storage usage',
                              img: 'https://assets.torecord.it/RecordVideo.png',
                              specialImgClass:
                                'transform translate-x-20 translate-y-24 scale-150',
                              specialComponent: false,
                              nextTier: activeTier.nextTier ? activeTier.nextTier.name : '',
                              newMetric: activeTier.nextTier && activeTier.nextTier.data_storage_limit_period !== undefined ? `${activeTier.nextTier.data_storage_limit_period} days data storage` : 'gili',
                              title: activeTier.nextTier ? `Unlock more with ${activeTier.nextTier.name}` : 'Unlock more with Record',
                              subTitle: activeTier.nextTier ? `You Have ${totalRecordsArchived} Records Archived At The Last ${activeTier.data_storage_limit_period}-Days` : '',
                              paragraph: activeTier.is_custom_tier ? 'It seems like you’ve reached the limit of your current plan. Don’t worry, we can help you with that! Our sales team can work with you to create a customized plan that meets all of your needs.' :  `You try to access an expired Record. In your current account, you can access ${activeTier.data_storage_limit_period}-day-old records. Unlock the Ultimate to be able to access all the last ${activeTier.nextTier.data_storage_limit_period} days’ Record’s`
                            })
                          )
                        }
                        className="cursor-pointer bg-white hover:bg-tuto-light-blue transition duration-300 border-red-600 p-4 "
                      >
                        <th
                          className="p-4 pl-12 border"
                          scope="colgroup"
                          colSpan={5}
                        >
                          <div className="flex items-center text-record-red">
                            <TrashIcon className="w-8 h-8 mr-2" />
                            Deleted ({totalRecordsArchived})
                          </div>
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProblemsTable
