import { useState } from 'react'
import { ArrowLeftIcon, CheckCircleIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import Steps from '../../utils/layout/Steps'
import { toggleSlider, uiState, openDiscoverMode } from '../../../app/slices/uiSlice'
import Button from '../../utils/elements/Button'
import { useNavigate } from 'react-router-dom'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { authState } from '../../../app/slices/authSlice'
import { customerSteps } from '../../utils/enums'

const Videos = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user, isPlayground } = useAppSelector(authState)
  const { remainingSteps, stepsToDo, stepsDone } = useAppSelector(uiState)
  const [showVideo, setShowVideo] = useState(false)
  const [videoData, setVideoData] = useState<any>({
    src: '',
    title: '',
    description: ''
  })

  const setVideoToShow = (src: string, title: string, description: string) => {
    setVideoData({ ...videoData, title, src, description })
    Mixpanel.track(`Clicked on ${title}`, {
      user_id: user.id,
      email: user.email,
      customer_id: user.customer_id
    })
    setShowVideo(true)
    navigate('/settings/installation')
  }

  const navigateToStepPage = (name: string, id: string) => {
    dispatch(toggleSlider())
    Mixpanel.track(`Clicked on ${name}`, {
      user_id: user.id,
      email: user.email,
      customer_id: user.customer_id
    })
    let navigateTo
    switch (id) {
      case customerSteps.ADDED_TEAMMATES:
        navigateTo = '/settings/teammates'
        break
      case customerSteps.VERIFY_DOMAIN:
        navigateTo = '/settings/domains'
        break
      default:
        navigateTo = '/settings'
    }
    navigate(navigateTo)
  }

  return (
    <div className="absolute inset-0 px-4 sm:px-8"  data-cy="dsss">
      {showVideo ? (
        <div className="w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setShowVideo(false)}
          >
            <ArrowLeftIcon className="h-5 w-5 text-tuto-primary mr-2" />
            go back
          </div>
          <h1 className="general-title">{videoData.title}</h1>
          <div className="rounded-xl overflow-hidden">
            <video width={'100%'} controls src={videoData.src}></video>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-start">
          <div className="mb-4 flex w-full pb-2">
            <div className='mr-4'> 
              <Button
                dataCy='book-a-demo-videos'
                inverted={true}
                loading={false}
                disabled={false}
                text="Book A Demo"
                type="button"
                withIcon={false}
                spacialClass=""
                clickFunction={() => {
                  Mixpanel.track(`Clicked on Book A Demo`, {
                    email: user.email,
                    customer_id: user.customer_id
                  })
                  window.open('https://booking.akiflow.com/ohad-r-0c48')
                }
                  
                }
              />
            </div>
            <div>
              <Button
                dataCy='take-a-tour-videos'
                inverted={true}
                loading={false}
                disabled={false}
                text="Take a Tour"
                type="button"
                withIcon={true}
                Icon={ArrowRightIcon}
                spacialClass=""
                clickFunction={() => {
                  Mixpanel.track(`Clicked on Take a Tour`, {
                    email: user.email,
                    customer_id: user.customer_id
                  })
                  navigate(isPlayground ? '/home'  : '/settings/installation')
                  dispatch(toggleSlider())
                  dispatch(openDiscoverMode())
                }}
              />
            </div>
          </div>
        
          <div className=''>
            {stepsToDo.filter((step: any) => step.is_optional === false)
              .length > 0 && (
              <h1 className="mb-4 text-lg text-tuto-primary font-semibold border-b-2">
                Wait for your action
              </h1>
            )}
            <div>
              {stepsToDo
                .filter((step: any) => step.is_optional === false)
                .sort(
                  (stepA: any, stepB: any): any =>
                    Number(stepA.step_number) - Number(stepB.step_number)
                )
                .map((step: any) => (
                  <div className="flex flex-col mt-4">
                    <div className="mb-4">Step {step.step_number}</div>
                    <div
                      className="border rounded-lg p-4 mb-2 cursor-pointer hover:border-tuto-primary"
                      onClick={() => {
                        Mixpanel.track('Clicked on step', {
                          step: step.name,
                          type: 'mandatory'
                        })
                        step.video_src
                          ? setVideoToShow(
                              step.video_src,
                              step.title,
                              step.description
                            )
                          : navigateToStepPage(step.name, step.id)
                      }}
                    >
                      <div className="text-xl font-semibold mb-2">
                        {step.title}
                      </div>
                      <div className="mb-4">{step.description}</div>
                      <div className="flex justify-end">
                        <Button
                          dataCy='start-button'
                          inverted={true}
                          loading={false}
                          disabled={false}
                          text="Start"
                          type="button"
                          withIcon={false}
                          spacialClass="text-xs mr-2"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-8 w-full">
            {stepsToDo.filter((step: any) => step.is_optional === true).length >
              0 && <h1 className="mb-4 text-lg text-tuto-primary font-semibold border-b-2">Do More</h1>}
            <div className="flex flex-col ">
              {stepsToDo
                .filter((step: any) => step.is_optional === true)
                .map((step: any) => (
                  <div
                    className="border rounded-lg p-4 mb-2 cursor-pointer hover:border-tuto-primary"
                    onClick={() => {
                      Mixpanel.track('Clicked on step', {
                        step: step.name,
                        type: 'optional'
                      })
                      step.video_src
                        ? setVideoToShow(
                            step.video_src,
                            step.title,
                            step.description
                          )
                        : navigateToStepPage(step.name, step.id)
                    }}
                  >
                    <div className="text-xl font-semibold mb-2">
                      {step.title}
                    </div>
                    <div className="mb-4">{step.description}</div>
                    <div className="flex justify-end">
                      <Button
                        inverted={true}
                        loading={false}
                        disabled={false}
                        text="Start"
                        type="button"
                        withIcon={false}
                        spacialClass="text-xs mr-2"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-8 w-full">
            {stepsDone.length > 0 && (
              <h1 className="mb-4 text-lg text-tuto-primary font-semibold border-b-2">
                Completed Tasks
              </h1>
            )}
            {stepsDone.map((step: any) => (
              <div
                key={step.key}
                className="border rounded-lg p-4 mb-4 flex items-center"
              >
                <CheckCircleIcon className="w-8 h-8 text-green-500 mr-4" />
                <span>{step.step_title}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Videos
