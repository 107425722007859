import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'
import React, { FC, useEffect, useState } from 'react'
interface TranscriptProps {
  transcript: any
  oneAi: any
}

const Transcript: FC<TranscriptProps> = ({ transcript, oneAi }) => {
  const [text, setText] = useState(null)

  useEffect(() => {
    if (transcript !== '') {
      
      setText(transcript)
    }
    return () => {
      setText(null)
    }
  }, [transcript])
  return (
    <div className="flex flex-col gap-8">
      
      {oneAi && oneAi.summary && (
        <div className="flex flex-col gap-4">
          <div className="flex">
            <Bars3BottomLeftIcon className="h-8 w-8 mr-4 text-tuto-primary" />
            <h1 className="general-title">Summary</h1>
          </div>
          <div>
            {' '}
            {oneAi && oneAi.summary && (
              <div className="text-gray-400 mb-4">{oneAi.summary.replaceAll('"', '')}</div>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex">
          <Bars3BottomLeftIcon className="h-8 w-8 mr-4 text-tuto-primary" />
          <h1 className="general-title">Full Transcript</h1>
        </div>
        <div>
          {text ? text : 'We couldn`t create a transcript for this recording.'}
        </div>
      </div>
    </div>
  )
}

export default Transcript
