import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import React, { FC, Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom'
import BarChart from './BarChart'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  deleteInvestigation,
  getSimilarities,
  investigationsState
} from '../../../app/slices/investigationsSlice'

import Toggle from '../../utils/elements/Toggle'
import {
  unprotectedGetRequest,
  unprotectedPostRequest
} from '../../../app/apis/apiGenerics'
import { authState } from '../../../app/slices/authSlice'
import ButtonTooltip from '../../utils/elements/ButtonTooltip'
import Button from '../../utils/elements/Button'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface InvestigationCreateProps {
  setInvestigationFormOpen: any
  investigation: any
  setSimilarities: any
  setLoadingSimilarities: any
}

const Investigation: FC<InvestigationCreateProps> = ({
  setInvestigationFormOpen,
  investigation,
  setSimilarities,
  setLoadingSimilarities
}) => {
  const handleEditClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setInvestigationFormOpen(true)
  }

  const { investigationSimilarities } = useAppSelector(investigationsState)
  const { user } = useAppSelector(authState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [formOpen, setFormOpen] = useState(false)
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(
    user.investigation_subscriptions.includes(investigation.id)
  )

  const toggleMailSubscribe = async () => {
    try {
      const enabledRes = await unprotectedPostRequest(
        'investigations/updateInvestigationSubscription',
        {
          customer_id: user.customer_id,
          user_id: user.id,
          investigation_id: investigation.id,
          is_enable: !subscriptionEnabled
        }
      )
      // console.log(enabledRes)
      setSubscriptionEnabled(!subscriptionEnabled)
    } catch (error) {
      console.log(error)
    }
  }

  const getInvestigationSimilarities = async () => {
    setInvestigationFormOpen(true)
    try {
      setLoadingSimilarities(true)
      const similarities = await unprotectedGetRequest(
        `investigations/getSimilarities?customer_id=${user.customer_id}&investigation_id=${investigation.id}&user_id=${user.id}`
      )

      setSimilarities(similarities.data)
      console.log(similarities.data)
      setLoadingSimilarities(false)
    } catch (error) {
      console.log(error)
      setLoadingSimilarities(false)
    }
  }

  return (
    <div
      // to={`/investigate/${investigation.id}`}
      className="border bg-white relative  border-tuto-primary rounded-md shadow-small grid grid-cols-10 items-center px-4 py-4 transition duration-300 hover:shadow-blue hover:border-tuto-light-blue"
    >
      <Link
        className={`absolute inset-0 ${
          investigation.aggregatedData &&
          investigation.aggregatedData[0].totalSessions === 0 &&
          investigation.aggregatedData &&
          investigation.aggregatedData[0].totalRecords === 0
            ? 'cursor-not-allowed'
            : ''
        }`}
        to={`${
          investigation.aggregatedData &&
          investigation.aggregatedData[0].totalSessions === 0 &&
          investigation.aggregatedData &&
          investigation.aggregatedData[0].totalRecords === 0
            ? `/investigate`
            : `/investigate/${investigation.id}`
        }`}
      ></Link>
      <div className="flex flex-col items-start pl-4 gap-2">
        <Toggle
          enabled={subscriptionEnabled}
          setEnabled={toggleMailSubscribe}
          backgoundClass={'blue-400'}
        />
        <div className="relative flex items-center gap-2">
          <p className="text-dark-gray text-sm">
            {subscriptionEnabled ? (
              <span className="text-blue-400">Subscribed</span>
            ) : (
              <span>Unsubscribed</span>
            )}
          </p>
          <ButtonTooltip
            helpText={true}
            text="Subscribing will send you a daily report of new entries within the last 24 hours of the investigation."
          >
            <div className="text-xs cursor-pointer bg-gray-200 py-1/2 px-1 rounded-full">
              ?
            </div>
          </ButtonTooltip>
        </div>
      </div>

      {/* {investigationSimilarities ? investigationSimilarities : null} */}
      <div>
        <h1 className="text-xl font-bold text-tuto-primary">
          {investigation.name}
        </h1>
      </div>

      {investigation.aggregatedData &&
        investigation.aggregatedData.length > 0 && (
          <Fragment>
            <div className="flex items-center col-start-3 col-span-4 justify-self-end gap-14">
              <BarChart data={investigation.aggregatedData[0].chartObj} />
            </div>
            <div className="flex  col-start-8 col-span-1">
              <Button
                loading={false}
                inverted={false}
                disabled={false}
                text="🪄 AI Insights"
                type="button"
                withIcon={false}
                spacialClass="white-btn"
                clickFunction={getInvestigationSimilarities}
              />
            </div>
            <div className="flex items-center col-start-9 col-span-2 justify-self-end gap-2">
              <div className="flex  gap-4">
                <div className="flex flex-col items-center justify-center gap-2">
                  <h1 className="text-lg text-tuto-primary p-2 font-black">
                    {investigation.aggregatedData[0].totalRecords}
                  </h1>
                  <p className="text-sm">Records</p>
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                  <h1 className="text-lg text-tuto-primary p-2 font-black">
                    {investigation.aggregatedData[0].totalSessions}
                  </h1>
                  <p className="text-sm">Sessions</p>
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                  <h1 className="text-lg text-white rounded-full bg-blue-400 p-2 font-black">
                    {investigation.aggregatedData[0].totalUnwatched}
                  </h1>
                  <p className="text-sm">Unwatched</p>
                </div>
              </div>
              <div className="justify-self-end ">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md  bg-white px-1 relative text-sm font-medium text-gray-700  hover:bg-gray-50 focus:outline-none ">
                      <EllipsisVerticalIcon
                        className="h-10 w-10 stroke-icon"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() =>
                                navigate(
                                  `/investigate-create/${investigation.id}`
                                )
                              }
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-tuto-primary'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer font-semibold'
                              )}
                            >
                              Edit
                            </div>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() =>
                                dispatch(
                                  deleteInvestigation({
                                    investigation_id: investigation.id
                                  })
                                )
                              }
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-tuto-primary'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer font-semibold'
                              )}
                            >
                              Delete
                            </div>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </Fragment>
        )}
      {/* <SlideOver
        title={`Edit Investigation`}
        isOpen={formOpen}
        setSlideOpen={setFormOpen}
      >
        <InvestigationCreate
          investigation={investigation}
          setFormOpen={setFormOpen}
          type={'update'}
        />
      </SlideOver> */}
    </div>
  )
}

export default Investigation
