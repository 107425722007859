import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Layout from '../utils/layout/Layout'
import {
  authState,
  getCurrentUser,
  getCurrentUserGoogle,
  loginUser,
  logOutUser
} from '../../app/slices/authSlice'
import PageLoader from '../utils/shared/PageLoader'

const ProtectedRoutes = () => {
  const { isLoggedIn, loading } = useAppSelector(authState)

  return loading === 'idle' || (loading === 'loading' && !isLoggedIn) ? (
    <PageLoader />
  ) : loading === 'success' && isLoggedIn ? (
    <Layout withCircles={true}>
      <Outlet />
    </Layout>
  ) : loading === 'failed' ? (
    <Navigate to="/login" />
  ) : (
    <PageLoader />
  )
}

export default ProtectedRoutes
