import { unprotectedGetRequest, unprotectedPostRequest } from './apiGenerics'

export const GetCustomerSteps = async (customer_id: string) => {
  const steps = await unprotectedGetRequest(
    `onboarding/getOnboardingSteps?customer_id=${customer_id}`
  )
  
  return steps
}

export const GetCustomerStepsDone = async (customer_id: string) => {
  const steps = await unprotectedGetRequest(
    `onboarding/getCustomerOnboardingSteps?customer_id=${customer_id}`
  )
  
  return steps
}

export const GetCustomerOnboardingSteps = async (customer_id: string) => {
  const steps = await unprotectedGetRequest(
    `customers/getCustomerOnboarding?customer_id=${customer_id}`
  )
  
  return steps
}

export const GetJobsTitles = async () => {
  const jobsTitles = await unprotectedGetRequest(
    `onboarding/getJobsTitles`
  )
  
  return jobsTitles
}

export const UpdateJobTitle = async (title_id: string, user_id: string) => {
  const res = await unprotectedPostRequest('onboarding/updateJobTitle', {
    title_id,
    user_id
  })
  
  return res
}

export const GetTiers = async () => {
  const res = await unprotectedGetRequest('customers/getTiers')
  
  return res
}