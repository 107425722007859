// ValuesTextField.tsx

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'
import { investigationParametersState } from '../../../../app/slices/investigationParametersSlice'
import { investigationElastic } from '../../../../app/slices/investigationsSlice'
import Button from '../../../utils/elements/Button'

interface ValuesTextFieldProps {
  onChange: (value: string) => void
  selectedParameter: any
}

const ValuesTextField = ({
  onChange,
  selectedParameter
}: ValuesTextFieldProps) => {

  const dispatch = useAppDispatch()
  const {parameters} = useAppSelector(investigationParametersState)
  const {user} = useAppSelector(authState)

  return (
    <form className='flex gap-2' onSubmit={(e) => {
      e.preventDefault()
      dispatch(
      investigationElastic({
        customer_id: user.customer_id,
        filters: parameters
      })
    )}}>
      <input
        type="text"
        onChange={(e) => onChange(e.target.value)}
        defaultValue={selectedParameter.selectedValues}
        placeholder="Enter value"
        className="border-0 border-b-tuto-primary border-b-2 bg-gray-300 rounded-md  focus:outline-none focus:ring-0"
      />
      <Button
        inverted={true}
        loading={false}
        disabled={false} // Update this based on your validation logic
        text={''}
        type="submit"
        withIcon={true}
        Icon={MagnifyingGlassIcon}
        spacialClass="parameter-btn"
        
      />
    </form>
  )
}

export default ValuesTextField
