import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getRequest } from '../../../app/apis/apiGenerics'
import CogLoader from '../../utils/shared/CogLoader'
import { pickBy, includes, toLower } from 'lodash';



interface CustomDataTabProps {
  data: any
  isURL?: boolean
  windowURL?: string
}

const CustomDataTab: React.FC<CustomDataTabProps> = ({ data, isURL, windowURL }) => {

  const [loading, setLoading] = useState(false)
  const [windowJSON, setWindowJSON] = useState({})
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (windowURL) {
      // console.log(windowURL)
      const fetchData = async () => {
        setLoading(true)
        const res = await getRequest(windowURL)
        const jsonData = await res;
        // console.log(jsonData)
        setWindowJSON(jsonData)
        setLoading(false)
      }
      fetchData()
    }

    return () => {
      setWindowJSON({})
    }
  }, [windowURL])

  
  const filteredData = pickBy(windowJSON, (value, key) => {
    if (searchTerm === "") return true;
  
    if (searchTerm.length >= 2) {
      const loweredSearchTerm = toLower(searchTerm);
      const valueMatch = includes(toLower(JSON.stringify(value)), loweredSearchTerm);
      const keyMatch = includes(toLower(key), loweredSearchTerm);
  
      return valueMatch || keyMatch;
    }
  
    return false;
  });
  
  
  
  
  const displayJSON = () => {
    return (
      <div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
          placeholder="Search"
          className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        {Object.entries(filteredData).map(([key, value]: any) => (
          <div className="my-2 max-w-full overflow-x-auto">
            <span className="font-bold text-lg" dangerouslySetInnerHTML={{
              __html: searchTerm.length >= 2 ?
                key.replace(
                  new RegExp(searchTerm, "gi"),
                  (match: string) => `<span class="bg-yellow-200">${match}</span>`
                ) :
                key
            }} />
            {" : "}
            <span dangerouslySetInnerHTML={{
              __html: searchTerm.length >= 2 ?
                JSON.stringify(value).replace(
                  new RegExp(searchTerm, "gi"),
                  (match: string) => `<span class="bg-yellow-200">${match}</span>`
                ) :
                JSON.stringify(value)
            }} />
          </div>
        ))}
      </div>
    );
  };
  
  const handleSearchInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchTerm.length >= 2) {
      const contentEditableElements = document.querySelectorAll("[contenteditable=true]") as NodeListOf<HTMLElement>;
      contentEditableElements.forEach((element) => {
        const elementText = element.innerText;
        const index = elementText.toLowerCase().indexOf(searchTerm.toLowerCase());
        if (index !== -1) {
          const range = new Range();
          range.setStart(element.childNodes[0], index);
          range.setEnd(element.childNodes[0], index + searchTerm.length);
          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      });
    }
  };
  
  
  



  const displayCustomJSON = (json: {}) => {
   
    return <div>
       {Object.entries(json).map(([key, value]: any) => (
      <div className="my-2">
        <span className="font-bold text-lg">{key}</span> :{' '}
        {JSON.stringify(value)}
      </div>
    ))}

    </div>
  }

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  

  return (
    <div className="p-4">
      {windowURL && loading ?
      (
        <div className="flex flex-col items-center justify-center mt-52">
        <CogLoader />
      </div>
      )
      : windowURL && windowJSON && 
        Object.entries(windowJSON).length > 0 ? displayJSON()
      : Object.entries(data).length > 0 ? displayCustomJSON(data)
       : (
        <div className="my-2">
          <span className="font-semibold text-center">
            No Custom Data has been found. To add some custom properties to your
            Records, please see our{' '}
            <Link target={'_blank'} className='text-tuto-purple hover:underline' to="/settings/docs">documentation</Link> , in Identification
            & Custom Data under Advanced Integration
          </span>
        </div>
      )}
    </div>
  )
}

export default CustomDataTab
