import CryptoJS from 'crypto-js'

const key = process.env.REACT_APP_ENC_CODE || ''

export function encryptData(
  redirect_to: string,
  user_id: string,
  user_name: string,
  customer_name: string
) {
  console.log( 'inside encryptData',
    process.env.REACT_APP_STAGE,
    redirect_to,
    user_id,
    user_name,
    customer_name
  )
  try {
    const data = {
      env: process.env.REACT_APP_STAGE,
      record_redirect_url: redirect_to,
      record_user_id: user_id,
      record_user_name: user_name,
      record_customer_name: customer_name
    }
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  } catch (err) {
    console.log(err)
    return err
  }
}

export function decryptData(data: any) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, key)
    const serializedData = bytes.toString(CryptoJS.enc.Utf8)
    const parsed = JSON.parse(serializedData)
    console.log('parsed insie decryptdata', JSON.stringify(parsed))
    return parsed
  } catch (err) {
    console.log(err)
    throw err
  }
}

// record_redirect_url
// record_is_recording: bool \\maybe not needed...
// record_user_id: uuid ?
// record_ticket_id: uuid ?
// record_env: string ? \\null means prod
