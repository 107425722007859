import { ArrowUpOnSquareIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import AuthWrapper from './AuthWrapper'
import Button from '../utils/elements/Button'
import FormInput from '../utils/forms/FormInput'
import '../Auth/Auth.css'
import UserPool from './UserPool'
import { CognitoUser } from 'amazon-cognito-identity-js'
import AuthError from './AuthError'
import { Navigate } from 'react-router-dom'
import { Mixpanel } from '../utils/mixPanel/mixPanel'
import { IsUserExist } from '../../app/apis/customerUsersApi'
import CONFIG from '../../config/config'
import { IntegrationsTypes } from '../utils/enums'


interface IFormInputs {
  password: string
  confirmPassword: string
  verificationCode: string
}

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .required()
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, {
      message: 'password must contain uppercase,lowercase & numbers'
    }),
  confirmPassword: yup
    .string()
    .min(8)
    .required()
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, {
      message: 'password must contain uppercase,lowercase & numbers'
    }),
  verificationCode: yup.string().required()
})

const ResetPassword = () => {
  const [isCodeVerificationSent, setVerificationSent] = useState(false)
  const [userName, setUserName] = useState('')
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isIntegration, setIsIntegration] = useState(false)
  const [loginImage, setLoginImage] = useState("https://assets.torecord.it/Pepole-photo.png")
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  useEffect(() => {
    let integrationState = localStorage.getItem('integrationId')
      const stateArr = integrationState?.split('_')
      if(stateArr && stateArr.length > 1){
        switch(stateArr[1]){
          case IntegrationsTypes.ZENDESK:
            setLoginImage("https://assets.torecord.it/Zendesk%2BRecord.png")
            setIsIntegration(true)
            break;
          case IntegrationsTypes.INTERCOM:
            setLoginImage("https://assets.torecord.it/Intercom%2Brecord.png")
            setIsIntegration(true)
            break;
          default:
            setLoginImage("https://assets.torecord.it/Pepole-photo.png")
            break;
        }
    }
    Mixpanel.track('Reset Password Page Viewed', {})
  }, [])

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    confirmNewPassword(userName, data.verificationCode, data.password)
  }

  const confirmNewPassword = async (
    userName: string,
    verificationCode: string,
    newPassword: string
  ) => {
    setLoading(true)
    const user = new CognitoUser({
      Username: userName,
      Pool: UserPool
    })
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: async () => {
        // console.log('success')
        setLoading(false)
        setPasswordChanged(true)
      },
      onFailure: async (err) => {
        // console.log('error')
        setLoading(false)
        setError(err.message)
      }
    })
  }

  const getVerificationCode = async(userName: string) => {
    setError('')
    setLoading(true)
    const isUserExist = await IsUserExist(userName)
    if(isUserExist && !isUserExist.data){
      setLoading(false)
      setError(`${userName} does not exist, please sign up first or try another email`)
    }
    else{
      const user = new CognitoUser({
        Username: userName,
        Pool: UserPool
      })
      user.forgotPassword({
        onSuccess: async (data) => {
          // console.log('verfication code success', data)
          setVerificationSent(true)
          setLoading(false)
        },
        onFailure: async (err) => {
          // console.log('verfication code error', err)
          setLoading(false)
          setError(err.message)
        }
      })
  }
  }

  return (
    <AuthWrapper
      withSteps={false}
      imageUrl={loginImage}
      imageAdditionalClass="login-image"
      withLoginLink={true}
      withTopPurpleCircle={true}
      isIntegration={isIntegration}
    >
      {!isCodeVerificationSent ? (
        <div>
          <div className="mt-4">
            <div className="flex flex-col items-start">
              <h2 className="general-title onboarding login-title">
                Reset Password
              </h2>
              <div className="my-6 w-full">
                <FormInput
                  error={errors.verificationCode?.message}
                  name="email"
                  type="text"
                  label="Email"
                  placeholder="Enter your email"
                  isCustomClass={false}
                  onChangeFunction={(e: any) => {
                    setUserName(e.target.value)
                  }}
                />
                {error && (<p className='text-red-600'>{error}</p>)}
              </div>
              <Button
                clickFunction={() => {
                  if(!error){
                  Mixpanel.track('Clicked on Get Verification Code', {email: userName})
                  getVerificationCode(userName)
                }
                else{
                  Mixpanel.track('Clicked on Sign Up after reset password', {email: userName})
                  window.location.href = `${CONFIG.dashboard}signup`
                }
                }}
                type={'button'}
                disabled={loading}
                loading={loading}
                withIcon={false}
                text={error ? 'Sign Up' : 'Get Verification Code'}
                inverted={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <form id='reset_password' onSubmit={handleSubmit(formSubmitHandler)}>
          <div className="">
            <h3 className="general-title onboarding login-title">
              Create new password!
            </h3>
            <div className="rounded-md bg-blue-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-blue-800">
                    Verification code is required
                  </h3>
                  <div className="mt-2 text-sm text-blue-700">
                    <ul role="list" className="list-disc pl-5 space-y-1">
                      <li>Please check your mail for a verification code</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ss-form pb-2">
            <div className="">
              <div className="mb-6">
                <a
                  className="cursor-pointer underline text-sm text-gray-500"
                  onClick={() => getVerificationCode(userName)}
                >
                  didn't receive verification code email?
                </a>
              </div>
              <FormInput
                error={errors.verificationCode?.message}
                name="verificationCode"
                type="text"
                label="Verification Code"
                placeholder="Your Verification Code"
                registerFunction={register}
                touched={touchedFields.verificationCode}
                watch={watch}
              />

              <FormInput
                error={errors.password?.message}
                name="password"
                type="password"
                label="Password (uppercase,lowercase & numbers)"
                placeholder="**********"
                registerFunction={register}
                touched={touchedFields.password}
                validMessage="Password is valid"
                watch={watch}
              />

              <FormInput
                error={errors.confirmPassword?.message}
                name="confirmPassword"
                type="password"
                label="Confirm Password (uppercase,lowercase & numbers)"
                placeholder="**********"
                registerFunction={register}
                touched={touchedFields.confirmPassword}
                validMessage="Password is valid"
                watch={watch}
              />
            </div>
          </div>

          <div className="">
            <div className="flex flex-col items-start justify-center">
              <Button
                loading={loading}
                text={'Submit'}
                inverted={false}
                Icon={ArrowUpOnSquareIcon}
                type={'submit'}
                disabled={false}
                withIcon={false}
              />
            </div>
            <div className="mt-4">
              {error && <AuthError title="error" description={error} />}
              {passwordChanged && <Navigate to='/login' />}
            </div>
          </div>
        </form>
      )}
    </AuthWrapper>
  )
}
export default ResetPassword
