import React, { FC, useEffect, useState } from 'react'
import {getColor} from './colorMapping'

interface LogTagsGeneratorProps {
  console_events: Array<{ content: string, type: string, timestamp: string }>,
  network_events: Array<{ request: { method: string }, response: any }>,
}

const LogTagsGenerator:FC<LogTagsGeneratorProps> = ({console_events, network_events}) => {

  const [tags, setTags] = useState<Set<string>>(new Set());

  useEffect(() => {
    const newTags = new Set<string>();
    
    Object.keys(console_events).length > 0 && console_events.forEach(item => {
      newTags.add(item.type);
    });

    Object.keys(network_events).length > 0 && network_events.forEach(item => {
      newTags.add(item.request.method);
    });

    setTags(newTags);
  }, [console_events, network_events]);

  return (
    <div className='flex gap-2 max-w-6'>
      {
        Array.from(tags).map((tag, index) => (
          <span key={index} className={`badge px-1 capitalize shadow-tag rounded-md ${getColor(tag, tag)}`}>
            {tag}
          </span>
        ))
      }
      {tags.size === 0 && '-'}
    </div>
  )
}

export default LogTagsGenerator 