import React from 'react'

const Unsubscribe = () => {
  return (
    <div>
      <p className="text-sm text-gray-500 mb-4">
        The weekly report summarizes for you all you need to know about your
        activity at Record:
      </p>
      <p className="text-sm text-gray-500 mb-4">
        <li>total of use</li>
        <li>most engaged agent</li>
        <li>time saved hence to Record</li>
      </p>
      <p className="text-sm text-gray-500">
        Enabling weekly report is highly recommended and most common among our
        users.
      </p>
    </div>
  )
}

export default Unsubscribe
