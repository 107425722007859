import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authState } from '../../../app/slices/authSlice'
import Button from '../../utils/elements/Button'
import Knowledge from '../Knowledge/Knowledge'
import Investigation from './Investigation'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import InvestigationCreate from './InvestigationCreate'
import { PlusIcon } from '@heroicons/react/20/solid'

import {
  getInvestigations,
  investigationsSelectors,
  investigationsState
} from '../../../app/slices/investigationsSlice'
import CogLoader from '../../utils/shared/CogLoader'
import SlideOver from '../../utils/elements/SlideOver'
import InvestigationSimilarities from './InvestigationSimilarities'
import JsonSearch from './JsonSearch'
import SetupBanner from '../Inbox/SetupBanner'
import OnboardingPopup from '../Inbox/OnBoardingPopup'


const Investigations = () => {
  const [investigationFormOpen, setInvestigationFormOpen] = useState(false)
  const [howItWorksOpen, setHowItWorksOpen] = useState(false)

  const { user } = useAppSelector(authState)
  const { loading } = useAppSelector(investigationsState)
  const [showPopup, setShowPopup] = useState(false)
  const dispatch = useAppDispatch()
  const investigations = useAppSelector(investigationsSelectors.selectAll)
  const navigate = useNavigate()
  const [similarities, setSimilarities] = useState<any>({})
  const [loadingSimilarities, setLoadingSimilarities] = useState(false)
  useEffect(() => {
    // Check if the page has been visited
    const pageVisited = localStorage.getItem('Investigation')
    if (!pageVisited) {
      setShowPopup(true) // Show the popup if the page hasn't been visited
      localStorage.setItem('Investigation', 'visited') // Set the page as visited
    }
  }, [])

  const closePopup = () => {
    setShowPopup(false)
  }

  useEffect(() => {
    if (user) {
      Mixpanel.track('Investigations Page Viewed', {})
      Mixpanel.register({
        Page: 'Investigations',
        user_id: user.id,
        email: user.email,
        customer_id: user.customer_id
      })

      if (investigations.length === 0) {
        dispatch(getInvestigations({ customer_id: user.customer_id }))
      }
    }
  }, [user])

  useEffect(() => {
    if(!investigationFormOpen) {
      setSimilarities({})
    }
  }, [investigationFormOpen])

  return (
    <div>
      <SetupBanner />
      {showPopup && (
        <OnboardingPopup
          videoSource="https://assets.torecord.it/Investigation_onbording.mp4"
          buttonText='Show me an Investigation'
          onClose={closePopup}
          title={`Hi ${user.name}`}
          text={<h1 className='font-semibold'><span className='text-tuto-light-blue'>INVESTIGATE AND MONITOR</span> USER ISSUES  </h1>}
        />
      )}
      <div className="px-8 py-4">
        <div className="grid grid-cols-3">
          <div className="col-span-2">
            <h1 className="text-4xl font-bold mb-2 text-tuto-primary">
              Investigate
            </h1>
            <p>
              Monitor and investigate issues to observe what happens before,
              during, and after the specific issue you’re investigating. <br />{' '}
              Investigations can be carried out by examining specific
              properties, which may include records and user sessions if they
              are activated.
            </p>
          </div>
          <div className="col-span-1 self-center justify-self-end">
            <Button
              loading={false}
              inverted={false}
              disabled={false}
              text="Create New"
              type="button"
              withIcon={true}
              Icon={PlusIcon}
              clickFunction={() => navigate('/investigate-create')}
            />
          </div>
        </div>
        <div className="mt-12 flex flex-col gap-6 min-h-70v">
          {loading ? (
            <div className="my-auto">
              {' '}
              <CogLoader />{' '}
            </div>
          ) : investigations && investigations.length > 0 ? (
            investigations.map((investigation) => (
              <Investigation
                key={investigation.id}
                investigation={investigation}
                setInvestigationFormOpen={setInvestigationFormOpen}
                setSimilarities={setSimilarities}
                setLoadingSimilarities={setLoadingSimilarities}
              />
            ))
          ) : (
            <div className="flex flex-col gap-6 items-center justify-center my-auto">
              <h1 className="text-3xl text-dark-gray">
                There are no active investigations
              </h1>
              <div className="flex gap-4">
                <Button
                  loading={false}
                  inverted={false}
                  disabled={false}
                  text="Create New"
                  type="button"
                  withIcon={false}
                  spacialClass="white-btn"
                  clickFunction={() => navigate('/investigate-create')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="inv-purple"></div>
      <div className="inv-yellow"></div>
      <SlideOver
        title={`Investigation Similarities`}
        isOpen={investigationFormOpen}
        setSlideOpen={setInvestigationFormOpen}
      >
        {/* <InvestigationCreate type="create" /> */}
        {loadingSimilarities ? (
          <CogLoader />
        ) : (
          <>
          {similarities && <JsonSearch data={{...similarities.CommonValues}} />}
            
            {similarities && similarities.Insights && similarities.Insights.length > 0 ? (
              <InvestigationSimilarities
                title={'Insights'}
                similarities={similarities.Insights}
              />
            ) : (
              <div>No Insights found</div>
            )}
            {similarities && similarities.Suggestions && similarities.Suggestions.length > 0 ? (
              <InvestigationSimilarities
                title={'Suggestions'}
                similarities={similarities.Suggestions}
              />
            ) : (
              <div>No Suggestions found</div>
            )}
          </>
        )}
      </SlideOver>
      <Knowledge
        title={`How to create an Investigation`}
        isOpen={howItWorksOpen}
        setSlideOpen={setHowItWorksOpen}
      >
        <div>video will be here</div>
      </Knowledge>
    </div>
  )
}

export default Investigations
