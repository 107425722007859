import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Steps from '../../utils/layout/Steps'

interface SlideOverProps {
  setSlideOpen: any
  isOpen: boolean
  title: string
  children: React.ReactNode
}

const SlideOver: React.FC<SlideOverProps> = ({
  setSlideOpen,
  isOpen,
  children,
  title
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setSlideOpen(false)} />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="pointer-events-auto absolute inset-y-0 right-0 flex  w-screen max-w-2xl" data-cy = "dialog-panel">
            <div className="flex h-full w-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div className="px-4 sm:px-6 bg-tuto-primary">
                <div className="flex items-start justify-between py-5">
                  <div className="text-lg font-medium text-white">
                    {' '}
                    {title === 'Getting Started' ? <Steps /> : title}{' '}
                  </div>
                  <div className="ml-3 flex h-7 items-center">
                    <button
                      type="button"
                      className="rounded-md text-white hover:text-gray-500"
                      onClick={() => setSlideOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="relative mt-6 flex-1 px-4 sm:px-6">
                {/* Replace with your content */}
                {children}
                {/* /End replace */}
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  )
}

export default SlideOver