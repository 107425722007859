import React, { useEffect } from 'react'

const BookDemo = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <div className=' '>
       <p className="onBoarding-p mb-8">
       To accelerate the progress, streamline your setup, and fully comprehend Record's functionalities such as Investigations, Integrations, and Identification -  book an onboarding session with us. 
      </p>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings-eu1.hubspot.com/ohad-ronen/record-onboarding-session?embed=true"
        dangerouslySetInnerHTML={{ __html: '' }}
      />
    </div>
  )
}

export default BookDemo
