import { unprotectedGetRequest, unprotectedPostRequest } from "./apiGenerics"

export const GetInvestigations = async (customer_id: string) => {
  const views = await unprotectedGetRequest(
    `investigations/getCustomerInvestigations?customer_id=${customer_id}`
  )
  // console.log(records)
  return views
}

export const CreateInvestigation = async (investigationData: any) => {
  // console.log('entered CreateInvestigation')
  const newInvestigation = await unprotectedPostRequest(
    `investigations/createInvestigation`, 
    {...investigationData}
  )
  // console.log('newInvestigation', newInvestigation)
  return newInvestigation
}

export const DeleteInvestigation = async (investigation_id: string) => {
  const deletedView = await unprotectedPostRequest(
    `investigations/deleteInvestigation`, 
    {investigation_id}
  )
  
  return deletedView
}

export const UpdateInvestigation = async (investigation_id: string, query_json:string, name: string) => {
  // console.log(query_json)
  const updatedView = await unprotectedPostRequest(
    `investigations/updateInvestigation`, 
    {investigation_id, query_json, name}
  )
  
  return updatedView
}

export const GetInvestigationDetails = async (investigation_id: string) => {
  // console.log(investigation_id)
  const updatedView = await unprotectedGetRequest(
    `investigations/getInvestigationDetails?investigation_id=${investigation_id}`
    
  )
  
  return updatedView
}

export const GetElasticFilterOptions = async (customer_id: string) => {
 
  const options = await unprotectedGetRequest(
    `records/getFilterOptionsByCustomerElastic?customer_id=${customer_id}`
    
  )
  
  return options
}

export const InvestigationElastic = async (searchQuery: any , customer_id: string) => {
  // console.log(filters)
  const res = await unprotectedPostRequest(
    `records/elasticSearchQuery`,
    {searchQuery, customer_id}
    
  )
  
  return res
}

export const GetSimilarities = async (customer_id: string, investigation_id: string, user_id: string) => {
  const views = await unprotectedGetRequest(
    `investigations/getSimilarities?customer_id=${customer_id}&investigation_id=${investigation_id}&user_id=${user_id}`
  )
  // console.log(records)
  return views
}