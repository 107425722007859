import React from 'react'
import {
  CheckIcon,
  InformationCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline'

interface NotificationIconProps {
  type: string
  size?: string
  color?: string
}

const NotificationIcon: React.FunctionComponent<NotificationIconProps> = ({
  type,
  size,
  color
}) => {
  const renderIcon = () => {
    switch (type) {
      case 'WARN': {
        return (
          <ExclamationCircleIcon
            className={`${size ? 'h-' + size + ' w' + size : 'h-6 w-6'} ${
              color ? 'text-' + color + '-400' : 'text-red-400'
            }`}
          />
        )
      }

      case 'SUCCESS': {
        return (
          <CheckIcon
            className={`${size ? 'h-' + size + ' w' + size : 'h-6 w-6'} ${
              color ? 'text-' + color + '-400' : 'text-green-400'
            }`}
          />
        )
      }

      case 'INFO': {
        return (
          <InformationCircleIcon
            className={`${size ? 'h-' + size + ' w' + size : 'h-6 w-6'} ${
              color ? 'text-' + color + '-400' : 'text-blue-400'
            }`}
          />
        )
      }

      default: {
        return <InformationCircleIcon className="h-6 w-6 text-blue-400" />
      }
    }
  }

  return <>{renderIcon()}</>
}

export default NotificationIcon
